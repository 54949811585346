/**
 * eslint-disable react-hooks/exhaustive-deps
 *
 * @format
 */

//todo MODAL AÇILMASI GEREKTİREN İŞLEMLER SONU MODAL İLE BİTEN DOSYALARDA(fatura seçilerek yapılan işlemlerde),
//todo DİREKT İSTEKLE HALLEDİLEBİLİR İŞLEMLER 'ProcessesAndProcessMenu' DOSYASINDADIR.

import React, {
    useState,
    useMemo,
    useEffect,
    useRef,
    useCallback,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";

// Grids---------
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import gridSideBar from "../../../components/GridSideBar/gridSideBar.js";

import { createColumnDefs } from "./gridOptionsAndcolumnDefs.js";
import { detailsGridOptions } from "./gridOptionsAndcolumnDefs.js";
import localeTextTr from "../../../locale.tr.js";

//Components
import DateRangePicker from "../../../components/DateRangePicker/index.js";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import localStorage from "local-storage";
//Modals and Menus
import ProcessMenu from "./processesAndProcessMenu.js";

//Icons
import KeyboardDoubleArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowLeftOutlined";
import KeyboardDoubleArrowRightOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowRightOutlined";

import { Grid, Typography, Button, IconButton, Box } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
//Icons

//Apis
import { getAdvisorMyUserList } from "../../../api/advisor/advisorMyUserPageApis/index.js";
import { CreateSelectedUsersSwal } from "./createUsersSwal.js";
import { UpdateSelectedUserSwal } from "./updateUserSwal.js";
import { GivePermissionToUsers } from "./givePermissionToUsersSwal.js";
import { RemovePermissionFromUsers } from "./removePermissionFromUsersSwal.js";
import { DeleteSelectedUsersSwal } from "./deleteUsersSwal.js";
import { createTurkishFilterParams } from "../../../utils/agGridTextFilterTr/agGridFilterUtils.js";

//Apis

function MyUsersPage() {
    const [isOpen, setIsOpen] = useState(false);

    //modals açılma durumlar
    const [modalId, setModalId] = useState("1");
    const [modalOpen, setModalOpen] = useState(false);
    const handleModalOpen = () => setModalOpen(true);
    const handleModalClose = () => setModalOpen(false);
    //--------
    //grid fatura görüntüleme modalı için açılır kapanır state'si
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    //grid fatura görüntüleme modalı için açılır kapanır state'si

    const [loading, setLoading] = useState(true);
    const [firstLoading, setFirstLoading] = useState(true); //SAYFA İLK AÇTIĞINDA getListInvoices ve companies'e İSTEK ATMAMASI İÇİN FLAG

    //Modallarda Yapılan işlemlerde sonra tablonun güncellemesi için
    const [updateRow, setUpdateRow] = useState(false);

    useEffect(() => {
        if (updateRow === true) {
            setLoading(true);
            getAdvisorMyUserList()
                .then((resp) => {
                    setRowData(resp.data);
                    setFilteredCount(resp.data.length);
                    setUpdateRow(false);
                    setLoading(false);
                })
                .catch((err) => {
                    setLoading(false);
                    setUpdateRow(false);
                });
        }
    }, [updateRow]);
    //Modallarda Yapılan işlemlerde sonra tablonun güncellemesi için

    const location = useLocation();
    const navigate = useNavigate();

    const [rowData, setRowData] = useState([]);
    const gridRef = useRef(null);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [user, setUser] = useState([]);
    const [filteredCount, setFilteredCount] = useState(0);

    const onSelectionChanged = () => {
        const selectedRows = gridRef.current.api.getSelectedRows();
        setSelectedUsers(selectedRows);
    };

    const [startDate, setStartDate] = useState(
        moment()
            .subtract(1, "month")
            .startOf("month")
            .format("YYYY-MM-DD HH:mm:ss")
    );
    const [endDate, setEndDate] = useState(
        moment()
            .subtract(1, "month")
            .endOf("month")
            .format("YYYY-MM-DD HH:mm:ss")
    );

    const handlePreviousMonth = () => {
        setStartDate(moment(startDate).subtract(1, "month").startOf("month"));
        setEndDate(moment(endDate).subtract(1, "month").endOf("month"));
    };

    const handleNextMonth = () => {
        setStartDate(moment(startDate).add(1, "month").startOf("month"));
        setEndDate(moment(endDate).add(1, "month").endOf("month"));
    };

    useEffect(() => {
        const sideBarOpen = localStorage.get("sidebar");
        if (sideBarOpen === "false") {
            setIsOpen(false);
        } else {
            setIsOpen(true);
        }

        getAdvisorMyUserList()
            .then((resp) => {
                setRowData(resp.data);
                setFilteredCount(resp.data.length);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        setStartDate(
            moment()
                .subtract(1, "month")
                .startOf("month")
                .format("YYYY-MM-DD HH:mm:ss")
        );
        setEndDate(
            moment()
                .subtract(1, "month")
                .endOf("month")
                .format("YYYY-MM-DD HH:mm:ss")
        );

        if (loading === true && firstLoading === false) {
            getAdvisorMyUserList()
                .then((resp) => {
                    setRowData(resp.data);
                    setFilteredCount(resp.data.length);
                    setLoading(false);
                    setFirstLoading(true);
                })
                .catch((err) => {
                    setLoading(false);
                    setFirstLoading(true);
                });
        }
    }, [location.pathname]);

    const onFilterChanged = useCallback((event) => {
        const filteredRows = event.api.getModel().getRowCount(); // Filtrelenmiş veri sayısını alıyoruz
        setFilteredCount(filteredRows);
    }, []);

    const defaultColDef = useMemo(
        () => ({
            filter: true,
            sortable: true,
            resizable: true,
            floatingFilter: true,
            filterParams: createTurkishFilterParams(),
        }),
        []
    );

    const columnDefs = useMemo(() => createColumnDefs);

    const detailCellRendererParams = useMemo(() => {
        return {
            detailGridOptions: detailsGridOptions,
            getDetailRowData: (params) => {
                params.successCallback(params.data.Companies);
            },
        };
    }, []);

    const handleSearch = () => {
        setLoading(true);
        getAdvisorMyUserList()
            .then((resp) => {
                setRowData(resp.data);
                setFilteredCount(resp.data.length);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    const getMainMenuItems = (params) => {
        const allMenuItems = params.defaultItems;
        const itemsToRemove = ["toolPanelButton"]; // 'choose columns' seçeneği

        return allMenuItems.filter((item) => !itemsToRemove.includes(item));
    };

    return (
        <Grid container>
            <Grid
                item
                md={12}
                sx={{ display: "flex", flexDirection: "column" }}
            >
                <Grid
                    className="grid-area"
                    sx={{
                        padding: 1,
                        mb: 1,
                        alignItems: "center",
                    }}
                >
                    <Grid
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        <Grid sx={{ display: "flex", alignItems: "center" }}>
                            <LocalizationProvider
                                dateAdapter={AdapterMoment}
                                adapterLocale="tr"
                            >
                                <Grid
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: 1,
                                        pl: 1,
                                        pt: 0.5,
                                    }}
                                >
                                    <IconButton
                                        onClick={handlePreviousMonth}
                                        className="date-button"
                                    >
                                        <KeyboardDoubleArrowLeftOutlinedIcon fontSize="small"></KeyboardDoubleArrowLeftOutlinedIcon>
                                    </IconButton>
                                    <DateRangePicker
                                        setStartDate={setStartDate}
                                        startDate={startDate}
                                        endDate={endDate}
                                        setEndDate={setEndDate}
                                        isLimitedRangeForMonth={true}
                                    />
                                    <IconButton
                                        onClick={handleNextMonth}
                                        className="date-button"
                                    >
                                        <KeyboardDoubleArrowRightOutlinedIcon fontSize="small"></KeyboardDoubleArrowRightOutlinedIcon>
                                    </IconButton>
                                    <Button
                                        variant="contained"
                                        className="custom-button"
                                        startIcon={<SearchIcon />}
                                        onClick={() => {
                                            handleSearch();
                                        }}
                                        sx={{
                                            width: "5rem",
                                            ml: 2,
                                        }}
                                    >
                                        Ara
                                    </Button>
                                </Grid>
                            </LocalizationProvider>
                        </Grid>
                        <Grid
                            sx={{
                                display: "flex",
                                gap: 2,
                                alignItems: "center",
                            }}
                        >
                            <ProcessMenu
                                startDate={startDate}
                                endDate={endDate}
                                setModalId={setModalId}
                                handleModalOpen={handleModalOpen}
                                setUpdateRow={setUpdateRow}
                            ></ProcessMenu>
                            {modalId === "#createSelectedUsersSwal" ? (
                                <CreateSelectedUsersSwal
                                    open={modalOpen}
                                    handleClose={handleModalClose}
                                    setUpdateRow={setUpdateRow}
                                />
                            ) : modalId === "#updateSelectedUsersSwal" ? (
                                <UpdateSelectedUserSwal
                                    open={modalOpen}
                                    handleClose={handleModalClose}
                                    selectedUsers={selectedUsers}
                                    setUpdateRow={setUpdateRow}
                                />
                            ) : modalId === "#givePermissionToUsers" ? (
                                <GivePermissionToUsers
                                    open={modalOpen}
                                    handleClose={handleModalClose}
                                    selectedUsers={selectedUsers}
                                    setUpdateRow={setUpdateRow}
                                />
                            ) : modalId === "#removePermissionFromToUsers" ? (
                                <RemovePermissionFromUsers
                                    open={modalOpen}
                                    handleClose={handleModalClose}
                                    selectedUsers={selectedUsers}
                                    setUpdateRow={setUpdateRow}
                                />
                            ) : modalId === "#deleteSelectedUsers" ? (
                                <DeleteSelectedUsersSwal
                                    open={modalOpen}
                                    handleClose={handleModalClose}
                                    selectedUsers={selectedUsers}
                                    setUpdateRow={setUpdateRow}
                                />
                            ) : (
                                ""
                            )}
                        </Grid>
                    </Grid>
                </Grid>

                <Box
                    className="grid-area"
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 0,
                        backgroundColor: "#ffffff",
                        p: 2,
                        mb: 2,
                    }}
                >
                    <Grid
                        className="ag-theme-quartz"
                        style={{
                            minHeight: rowData.length > 6 ? "85vh" : "60vh",
                            width: "100%",
                            borderRadius: "10%",
                        }}
                    >
                        <AgGridReact
                            ref={gridRef}
                            onSelectionChanged={onSelectionChanged}
                            onFilterChanged={onFilterChanged}
                            localeText={localeTextTr}
                            rowSelection="multiple"
                            loading={loading}
                            animateRows={true}
                            rowDragManaged={true}
                            rowMultiSelectWithClick={true}
                            readOnlyEdit={true}
                            singleClickEdit={true}
                            stopEditingWhenCellsLoseFocus={true}
                            rowData={rowData}
                            columnDefs={columnDefs}
                            enableRangeSelection={true}
                            sideBar={gridSideBar.gridSideBar}
                            copyHeadersToClipboard={false}
                            defaultColDef={defaultColDef}
                            masterDetail={true}
                            detailRowAutoHeight={true}
                            detailCellRendererParams={detailCellRendererParams}
                            getMainMenuItems={getMainMenuItems}
                        />
                    </Grid>
                    <Typography
                        sx={{
                            backgroundColor: "white",
                            fontWeight: 400,
                            color: "#605f66",
                            ml: 1,
                            mt: 1,
                            mb: 1,
                        }}
                    >
                        Ekrandaki Kullanıcı Sayısı: <b>{filteredCount}</b>
                    </Typography>
                </Box>
            </Grid>
        </Grid>
    );
}

export default MyUsersPage;
