import { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";

import { Autocomplete, Button, TextField } from "@mui/material";
import { emailFormatControl } from "../../../utils/emailFormatControl";

import withReactContent from "sweetalert2-react-content";
import { givePermissionToUsers, removePermissionFromUsers, updateSubUser } from "../../../api/advisor/advisorMyUserPageApis";
import { getAdvisorCompanyList } from "../../../api/advisor/getAdvisorCompanyList";
import { showLoadingSwal } from "../../../components/loadingSwal";
const MySwal = withReactContent(Swal);

// Yükleniyor Swal bileşeni
export const RemovePermissionFromUsers = ({
  open,
  handleClose,
  selectedUsers,
  setUpdateRow,
}) => {
  const listCompanies = useRef([]);
  const selectedCompaniesRef = useRef([]);
  useEffect(() => {
    if (open) {
      if (selectedUsers.length < 1) {
        Swal.fire({
          title: "Uyarı",
          text: "Lütfen en az bir adet kullanıcı seçin.",
          icon: "error",
          confirmButtonText: "Tamam",
        });
        handleClose();
        return;
      }
      showLoadingSwal();
      getAdvisorCompanyList()
        .then((resp) => {
          Swal.close();
          listCompanies.current = resp.data.companies;
          MySwal.fire({
            title: "Mükellefleri Seçiniz...",
            icon: "warning",
            showConfirmButton: false,
            showCancelButton: false,
            html: (
              <div style={{ width: "100%", padding: "10px 0" }}>
                {" "}
                <Autocomplete
                  multiple
                  options={resp.data.companies}
                  onChange={(event, newValue) => {
                    selectedCompaniesRef.current = newValue.map(company => company.id);
                  }}
                  noOptionsText={"Seçenek bulunamadı"}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      // Eğer liste açıksa ve bir seçenek vurgulanmışsa, onu seç
                      const highlightedOption = event.target.querySelector(
                        'li[data-focus="true"]'
                      );
                      if (highlightedOption) {
                        highlightedOption.click();
                      }
                    }
                  }}
                  autoHighlight
                  selectOnFocus
                  blurOnSelect
                  openOnFocus
                  disablePortal
                  ListboxProps={{ className: "specialListBox" }}
                  getOptionLabel={(option) =>
                    `${option.name} - ${option.companyId}-${option.periodId}-${
                      option.year
                    }${
                      option.class === 1
                        ? "-BİLANÇO"
                        : option.class === 2
                        ? "-İŞLETME"
                        : ""
                    }`
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Firma seçiniz..."
                      variant="outlined"
                      size="small"
                    />
                  )}
                  style={{ width: "100%" }}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 10,
                    gap: 10,
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor:
                        "#15356d" /* Onay butonunun rengini mavi yapar */,
                      color: "#ffffff",
                      "&:hover": {
                        backgroundColor: "#112d5e",
                      },
                    }}
                    onClick={() => MySwal.clickConfirm()}
                    className="swal2-confirm swal2-styled"
                  >
                    Devam
                  </Button>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#d33",
                      color: "#ffffff",
                      "&:hover": {
                        backgroundColor: "#a12929",
                      },
                    }}
                    onClick={() => MySwal.clickCancel()}
                    className="swal2-cancel swal2-styled"
                  >
                    İptal
                  </Button>
                </div>
              </div>
            ),
            customClass: {
              popup: "swal2-custom-autocomplete",
            },
            preConfirm: () => {
              let subUserIds=selectedUsers.map(user => user.id)
              let data={companyIds:selectedCompaniesRef.current,subUserIds:subUserIds}
              return data;
            },
          }).then((result) => {
            if (result.isConfirmed) {
              showLoadingSwal()
              removePermissionFromUsers(result.value)
                .then((resp) => {
                  Swal.close()
                  setUpdateRow(true);
                  Swal.fire({
                    title: "Başarılı!",
                    html: resp.data.message,
                    icon: "success",
                    confirmButtonText: "Tamam",
                  });
                })
                .catch((err) => {
                  Swal.close()
                  setUpdateRow(true);
                  Swal.fire({
                    title: "Bir hata oluştu!",
                    text: err.response.data.message,
                    icon: "error",
                    confirmButtonText: "Tamam",
                  });
                });
            }
          });
        })
        .catch((err) => {
          Swal.close();
          Swal.fire({
            title: "Firmalar geitirilirken bir hata oluştu!",
            text: err.response.data.message,
            icon: "error",
            confirmButtonText: "Tamam",
          });
        });

      handleClose();
    }
  }, [open, handleClose]);

  return null;
};
