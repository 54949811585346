/** @format */

import React, { useState, useEffect } from "react";

import {
    Autocomplete,
    Button,
    CircularProgress,
    Divider,
    FormControl,
    Grid,
    InputAdornment,
    ListItemIcon,
    TextField,
    Typography,
} from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import SettingsIcon from "@mui/icons-material/Settings";
import { getCompanyList } from "../../api/advisor/getCompanyList";
import { getPeriodList } from "../../api/advisor/getPeriodList";
import { getCompanyDetails } from "../../api/advisor/getCompanyDetails";
import { useNavigate } from "react-router-dom";
import { logout } from "../../api/auth/logout";
import { setJsonItem } from "../../utils/localStorageProcess";

function DashBoard() {
    const [company, setCompany] = useState("");
    const [period, setPeriod] = useState("");
    const [companyLoading, setCompanyLoading] = useState(true);
    const [periodLoading, setPeriodLoading] = useState(true);

    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();
    const [periods, setPeriods] = useState([]);
    const [companies, setCompanies] = useState([]);
    const handleLogout = () => {
        logout();
    };

    const handleNavigate = () => {
        if (isLoading) return;
        setIsLoading(true);
        if (company && period) {
            getCompanyDetails(company.value, period)
                .then(async (resp) => {
                    setIsLoading(false);
                    await setJsonItem(`#${company.value}/${period}`, resp.data);
                    navigate(`/company/${company.value}/${period}/invoices`);
                })
                .catch((err) => {
                    setIsLoading(false);
                    console.log(err);
                });
        }
    };

    useEffect(() => {
        document.title = `Firma Seç - Rahat Aktarım`;
        getCompanyList()
            .then((resp) => {
                setCompanies(resp.data);
                setCompanyLoading(false);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    useEffect(() => {
        if (company) {
            setPeriod("");
            getPeriodList(company.value)
                .then((resp) => {
                    setPeriods(resp.data);
                    if (resp.data.length > 0) {
                        setPeriodLoading(false);
                        setPeriod(resp.data[0].value);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [company]);

    return (
        <Grid container sx={{}}>
            <Grid
                container
                className="grid-area"
                xs={12}
                sm={6}
                md={3}
                sx={{
                    position: "absolute",
                    gap: 2,
                    mt: 0,
                    p: 3,
                    left: "50%",
                    top: "50%",
                    transform: "translate(-50%,-50%)",
                    width: "450px",
                }}
            >
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    sx={{ display: "flex", flexDirection: "column", gap: 3 }}
                >
                    <Typography variant="h5" color={"gray"}>
                        Firma ve Dönem Seçiniz
                    </Typography>
                    <Grid>
                        {" "}
                        <Typography
                            variant="subtitle1"
                            color={"041737"}
                            gutterBottom
                        >
                            Firma
                        </Typography>
                        <FormControl fullWidth>
                            <Autocomplete
                                disablePortal
                                options={companies}
                                sx={{ width: "100%" }}
                                autoHighlight
                                selectOnFocus
                                blurOnSelect
                                openOnFocus
                                onKeyDown={(event) => {
                                    if (event.key === "Enter") {
                                        // Eğer liste açıksa ve bir seçenek vurgulanmışsa, onu seç
                                        const highlightedOption =
                                            event.target.querySelector(
                                                'li[data-focus="true"]'
                                            );
                                        if (highlightedOption) {
                                            highlightedOption.click();
                                        }
                                    }
                                }}
                                getOptionLabel={(option) =>
                                    option.label || option
                                }
                                isOptionEqualToValue={(option, value) =>
                                    (option.label || option).toLocaleLowerCase(
                                        "tr-TR"
                                    ) ===
                                    (value.label || value).toLocaleLowerCase(
                                        "tr-TR"
                                    )
                                }
                                filterOptions={(options, { inputValue }) => {
                                    const normalized =
                                        inputValue.toLocaleLowerCase("tr-TR");
                                    return options.filter((option) =>
                                        (option.label || option)
                                            .toLocaleLowerCase("tr-TR")
                                            .includes(normalized)
                                    );
                                }}
                                noOptionsText={
                                    companyLoading
                                        ? "Yükleniyor..."
                                        : "Seçenek bulunamadı"
                                }
                                ListboxProps={{ style: { maxHeight: "250px" } }}
                                onChange={(event, newValue) => {
                                    setPeriodLoading(true);
                                    setPeriod("");
                                    setPeriods([]);
                                    setCompany(newValue ? newValue : "");
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder="Firma Seçiniz..."
                                        className="input-field"
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <>
                                                    {companyLoading ? (
                                                        <InputAdornment position="end">
                                                            <CircularProgress
                                                                size={17}
                                                            />
                                                        </InputAdornment>
                                                    ) : null}
                                                    {
                                                        params.InputProps
                                                            .endAdornment
                                                    }
                                                </>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid>
                        <Typography
                            variant="subtitle1"
                            color={"041737"}
                            gutterBottom
                        >
                            Dönem
                        </Typography>
                        <FormControl fullWidth>
                            <Autocomplete
                                disablePortal
                                options={periods}
                                value={
                                    periods.find((p) => p.value === period) ||
                                    null
                                }
                                ListboxProps={{ style: { maxHeight: "20vh" } }}
                                sx={{ width: "100%" }}
                                getOptionLabel={(option) => `${option.label}`}
                                onChange={(event, newValue) => {
                                    setPeriod(newValue?.value);
                                }}
                                onKeyDown={(event) => {
                                    if (event.key === "Enter") {
                                        // Eğer liste açıksa ve bir seçenek vurgulanmışsa, onu seç
                                        const highlightedOption =
                                            event.target.querySelector(
                                                'li[data-focus="true"]'
                                            );
                                        if (highlightedOption) {
                                            highlightedOption.click();
                                        }
                                    }
                                }}
                                noOptionsText={
                                    company === ""
                                        ? "Lütfen Firma Seçiniz"
                                        : periodLoading
                                        ? "Yükleniyor..."
                                        : "Seçenek bulunamadı"
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder="Dönem Seçiniz..."
                                        className="input-field"
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <>
                                                    {company !== "" &&
                                                    periodLoading ? (
                                                        <InputAdornment position="end">
                                                            <CircularProgress
                                                                size={17}
                                                            />
                                                        </InputAdornment>
                                                    ) : null}
                                                    {
                                                        params.InputProps
                                                            .endAdornment
                                                    }
                                                </>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>

                    <Grid sx={{ display: "flex", mb: 5 }}>
                        <Button
                            fullWidth
                            variant="contained"
                            color="secondary"
                            type="submit"
                            onClick={() => handleNavigate()}
                            sx={{ textTransform: "none" }}
                            // onClick={handleLogin}
                            className={
                                !company || !period || isLoading
                                    ? ""
                                    : "custom-button"
                            }
                            // Inline styles for background color and width
                            disabled={!company || !period || isLoading}
                        >
                            <Typography
                                fontSize={17}
                                sx={{ display: "flex", alignItems: "center" }}
                            >
                                Giriş
                                {isLoading && (
                                    <CircularProgress
                                        size={17}
                                        sx={{
                                            color: "#fff",
                                            marginLeft: 2, // Spinner'ı giriş yazısının yanına hizalar
                                        }}
                                    />
                                )}
                            </Typography>
                        </Button>
                    </Grid>
                    <Divider />
                    <Grid sx={{ display: "flex" }}>
                        <Button
                            fullWidth
                            variant="contained"
                            type="submit"
                            onClick={() => {
                                navigate("/user/profile", {
                                    state: { from: "settings" },
                                });
                            }}
                            sx={{
                                backgroundColor: "#dedede",
                                textTransform: "none", // Kırmızı arka plan
                                "&:hover": {
                                    backgroundColor: "#c0bebe", // Hover durumunda koyu kırmızı
                                },
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    color: "#041737",
                                    gap: 1,
                                    alignItems: "center",
                                }}
                            >
                                <SettingsIcon
                                    fontSize="medium"
                                    sx={{ color: "#041737" }}
                                />
                                <Typography fontSize={16} color={"#041737"}>
                                    Müşavir Ayarları
                                </Typography>
                            </ListItemIcon>
                        </Button>
                    </Grid>
                    <Grid sx={{ display: "flex" }}>
                        <Button
                            fullWidth
                            variant="contained"
                            type="submit"
                            onClick={() => {
                                handleLogout();
                            }}
                            sx={{
                                backgroundColor: "error.main",
                                textTransform: "none", // Kırmızı arka plan
                                "&:hover": {
                                    backgroundColor: "error.dark", // Hover durumunda koyu kırmızı
                                },
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    color: "white",
                                    gap: 1,
                                    alignItems: "center",
                                }}
                            >
                                <LogoutIcon
                                    fontSize="medium"
                                    sx={{ color: "white" }}
                                />
                                <Typography fontSize={16}>Çıkış Yap</Typography>
                            </ListItemIcon>
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default DashBoard;
