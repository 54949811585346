
export const createBlackListColumnDefs = [
  {
    headerName: "",
    checkboxSelection: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    headerClass: "checkbox",
    pinned: "left",
    width: 48,
    field: "checkboxBtn",
    resizable: false,
    lockPosition: "left",
    suppressAutoSize: true,
    suppressColumnsToolPanel: true,
    suppressMenu: true,
    editable: false,
    filter:false
  },
  {
    field: "direction",
    headerName: "Fatura Yönü",
    flex: 1,
    cellRenderer: (params) => {
      return params.value;
    },
    cellEditor: "agRichSelectCellEditor",
    cellEditorParams: {
      values: ["Gelen"],
      cellHeight: 30,
      cellRenderer: (params) => {
        return params.value;
      },
    },
  },
  {
    field: "title",
    headerName: "Gönderici Unvan",
    flex: 2,
  },
  {
    field: "vkn_tckn",
    headerName: "Gönderici VKN/TCKN",
    cellRenderer: (params) => {
      return params.value;
    },
    flex: 1,
  },
  {
    field: "address",
    headerName: "Alıcı Adres",
    cellRenderer: (params) => {
      return params.value;
    },
    flex: 3,
  },
];
