/** @format */

import React, { useState, useRef, useEffect } from "react";
import {
  Modal,
  Box,
  Button,
  Typography,
  Grid,
  TextField,
  Divider,
  FormControl,
  Autocomplete,
  Alert,
} from "@mui/material";
import Swal from "sweetalert2";
import { CreateLucaAccountSwal } from "../../components/createLucaAccountSwal/index.js";
import { getLucaAccounts } from "../../api/company/invoices/getLucaAccounts/index.js";
import { UploadIncomeExpenseFichesFromExcel } from "../../api/company/IncomeExpenseFiches/syncFicheReportsFromExcel/index.js";
import { checkTempProcess } from "../../api/company/invoices/checkTempProcess/index.js";
const uppercaseAlphabet = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];
const dateFormats = [
  { value: "DD.MM.YYYY", label: "GG.AA.YYYY" },
  { value: "DD-MM-YYYY", label: "GG-AA-YYYY" },
  { value: "YYYY-MM-DD", label: "YYYY-AA-GG" },
  { value: "YYYY.MM.DD", label: "YYYY.AA.GG" },
  { value: "MM/DD/YYYY", label: "AA/GG/YYYY" },
  { value: "DD/MM/YYYY", label: "GG/AA/YYYY" },
  { value: "YYYY/MM/DD", label: "YYYY/AA/GG" },
  { value: "MM-DD-YYYY", label: "AA-GG-YYYY" },
  { value: "YYYY-DD-MM", label: "YYYY-GG-AA" },
  { value: "MM.DD.YYYY", label: "AA.GG.YYYY" },
  { value: "YYYY/DD/MM", label: "YYYY/GG/AA" },
];
const separatorOptions = [
  { value: "", label: "Yok" },
  { value: ",", label: ", (virgül)" },
  { value: ".", label: ". (nokta)" },
];
export const XmlFileModalForFiches = ({
  open,
  handleClose,
  companyDetails,
  accountCodeList,
  setUpdateRow,
}) => {
  const [accountCodes, setAccountCodes] = useState(
    accountCodeList ? accountCodeList : []
  );

  const [formState, setFormState] = useState({
    selectedAccountCode: "",
    direction: "",
    selectedDateFormat: "",
    selectedDateCol: "",
    selectedNumberCol: "",
    selectedTaxPercentCol: "",
    selectedDescriptionCol: "",
    selectedPayableAmountCol: "",
    selectedSenderReceiverName: "",
    selectedSenderReceiverVknTckn: "",
    selectedThousandSeparate: "",
    selectedFractionSeparate: "",
  });

  const updateFormState = (field, value) => {
    setFormState((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };
  const [files, setFiles] = useState([]);
  const [fileBase64, setFileBase64] = useState("");
  const fileInputRef = useRef(null);
  const isOptionDisabled = (option, currentValue, otherValue) => {
    if (option.value === "") return false;
    if (!otherValue) return false;
    return option.value === otherValue.value;
  };
  useEffect(() => {
    setFiles([]);
    setFileBase64("");
  }, [handleClose]);
  useEffect(() => {
    if (!accountCodeList) {
      getLucaAccounts(companyDetails?.companyId, companyDetails?.periodId)
        .then((resp) => {
          const updateData = resp?.data.list.filter(
            (item, index, self) =>
              index === self.findIndex((t) => t.id === item.id)
          );
          if (resp.data.message !== "") {
            Swal.fire({
              icon: "warning",
              title: "Uyarı",
              html: resp.data.message,
              confirmButtonText: "Tamam",
            })
          }
          setAccountCodes(updateData);
          //   setLoading(false);
        })
        .catch((err) => {
          //   setLoading(false);
        });
    }
    setAccountCodes(accountCodeList);
  }, [accountCodeList]);

  const isXlsFile = (file) =>
    file.name.endsWith(".xls") || file.name.endsWith(".xlsx");

  const handleFileSelect = (event) => {
    const file = event.target.files[0]; // Tek bir dosya seçimi
    if (file) {
      if (isXlsFile(file)) {
        // Dosyayı Base64'e dönüştür
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => {
          const base64String = reader.result.split(",")[1]; // Dosyanın Base64 hali
          setFileBase64(base64String);
          setFiles([file]); // Dosyayı dizinin içine alıyoruz
        };

        reader.onerror = (error) => {
          console.error("Dosya okuma hatası: ", error);
        };
      } else {
        alert("Yalnızca .xls veya .xlsx uzantılı dosyalar seçebilirsiniz.");
      }
    } else {
      handleCancel(event);
      return;
    }
  };

  const handleUploadClick = () => {
    if (files?.length > 0) {
      for (let key in formState) {
        if (companyDetails?.class === 2 && key === "selectedAccountCode") {
        } else if (
          (formState[key] === "" || formState[key] === null) &&
          key !== "selectedThousandSeparate" &&
          key !== "selectedFractionSeparate"
        ) {
          // Eğer boş veya null bir değer varsa uyarı göster
          Swal.fire({
            title: "Uyarı!",
            text: "Lütfen tüm sütunları doldurun.",
            icon: "warning",
            didOpen: () => {
              document.querySelector(".swal2-container").style.zIndex = "1500";
            },
          });
          return false; // İşlemi durdur
        }
      }

      const values = Object.entries(formState)
        .filter(
          ([key]) =>
            ![
              "selectedAccountCode",
              "direction",
              "selectedDateFormat",
              "selectedThousandSeparate",
              "selectedFractionSeparate",
            ].includes(key)
        )
        .map(([_, value]) => value)
        .filter((value) => value !== "");

      const duplicates = values.length !== new Set(values).size;
      if (duplicates) {
        Swal.fire({
          title: "Uyarı!",
          text: "Eşleşen sütunlar var. Lütfen tüm sütunları farklı işaretleyiniz.",
          icon: "warning",
          didOpen: () => {
            document.querySelector(".swal2-container").style.zIndex = "1500";
          },
        });
        return false;
      }
    } else {
      Swal.fire({
        title: "Uyarı!",
        text: "Lütfen bir Excel dosyası yükleyin.",
        icon: "warning",
        didOpen: () => {
          document.querySelector(".swal2-container").style.zIndex = "1500";
        },
      });
      return false;
    }
    let extractData = {
      direction: formState.direction,
      excelBase64: fileBase64,
      dateColumn: formState.selectedDateCol,
      numberColumn: formState.selectedNumberCol,
      payableAmountColumn: formState.selectedPayableAmountCol,
      dateFormat: formState.selectedDateFormat?.value
        ? formState.selectedDateFormat.value
        : "",
      thousandSeparator: formState.selectedThousandSeparate?.value
        ? formState.selectedThousandSeparate.value
        : "",
      decimalSeparator: formState.selectedFractionSeparate?.value
        ? formState.selectedFractionSeparate.value
        : "",
      lucaAccountCode:
        companyDetails?.class === 2 ? undefined : formState.selectedAccountCode,
      senderVknTcknColumn: formState.selectedSenderReceiverVknTckn,
      senderNameColumn: formState.selectedSenderReceiverName,
      taxPercentColumn: formState.selectedTaxPercentCol,
      descriptionColumn: formState.selectedDescriptionCol,
    };

    Swal.fire({
      title: "Rapor Oluşturuluyor...",
      text: "Lütfen bekleyiniz",
      icon: "info",
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      customClass: {
        popup: "swal2-content-centered",
      },
      willOpen: () => {
        Swal.showLoading();
      },
      didOpen: () => {
        document.querySelector(".swal2-container").style.zIndex = "9999";
      },
    });

    UploadIncomeExpenseFichesFromExcel(
      companyDetails?.companyId,
      companyDetails?.periodId,
      extractData
    )
      .then(async (resp) => {
        let flag = true;
        while (flag) {
          try {
            const checkResp = await checkTempProcess(
              companyDetails?.companyId,
              companyDetails?.periodId,
              resp.data.id
            );
            flag = checkResp?.data?.isActive;
            if (checkResp.data?.isActive === false) {
              if (checkResp.data?.result?.errorMessage) {
                Swal.fire({
                  title: "Bir hata oluştu!",
                  text: checkResp.data?.result?.errorMessage,
                  icon: "error",
                  confirmButtonText: "Tamam",
                  didOpen: () => {
                    document.querySelector(".swal2-container").style.zIndex =
                      "9999";
                  },
                });
                handleClose();
              } else if (checkResp?.data?.result) {
                Swal.close();
                Swal.fire({
                  title: "Yükleme Sonuçları",
                  html: `
        <div class="result-container">
          ${
            checkResp.data.result.succesfullFicheReports > 0
              ? `<div class="result-item">
                  <span class="result-icon success"><i class="ti ti-checks ti-xs"></i></span>
                  <span class="label">Başarılı işlem adedi</span>
                  <span style="font-weight: bold; color: #36cb85;" >${checkResp.data.result.succesfullFicheReports}</span>
                </div>`
              : ""
          }
          ${
            checkResp.data.result.failedFicheReports > 0
              ? `<div class="result-item">
                  <span class="result-icon failed"><i class="ti ti-x ti-xs"></i></span>
                  <span class="label">Başarısız işlem adedi</span>
                  <span style="font-weight: bold; color: #ea5455;" >${checkResp.data.result.failedFicheReports}</span>
                </div>`
              : ""
          }
          <div class="result-item">
            <span class="result-icon total"><i class="ti ti-list-details ti-xs"></i></span>
            <span class="label">Toplam işlem adedi</span>
            <span style="font-weight: bold; color: #00cfe8;" >${
              checkResp.data.result.totalFoundExtracts
            }</span>
          </div>
        </div>
`,
                  icon: "success",
                  confirmButtonText: "Tamam",
                  didOpen: () => {
                    document.querySelector(".swal2-container").style.zIndex =
                      "9999";
                  },
                });
                handleClose();
              }
              setUpdateRow(true);
            }
          } catch (err) {
            Swal.fire({
              title: "Bir hata oluştu!",
              text: err.response.data.message,
              icon: "error",
              confirmButtonText: "Tamam",
              didOpen: () => {
                document.querySelector(".swal2-container").style.zIndex =
                  "9999";
              },
            });
            handleClose();
          }
          await new Promise((resolve) => setTimeout(resolve, 10000));
        }
      })
      .catch((err) => {
        Swal.fire({
          title: "Bir hata oluştu!",
          text: err.response.data.message,
          icon: "error",
          confirmButtonText: "Tamam",
          didOpen: () => {
            document.querySelector(".swal2-container").style.zIndex = "9999";
          },
        });
        handleClose();
      });
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleCancel = (event) => {
    event.stopPropagation();
    setFiles([]);
    setFileBase64("");
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="lg"
      disableEnforceFocus
    >
      <Box
        sx={{
          position: "absolute",
          top: "5%",
          left: "50%",
          transform: "translate(-50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          width: "30%",
          maxHeight: "80%",
          border: "2px solid transparent",
          overflowY: "auto",
          overflowX: "hidden",
        }}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
      >
        <Button
          onClick={handleClose}
          sx={{
            borderRadius: 2,
            position: "absolute",
            top: 0,
            right: 0,
            backgroundColor: "whitesmoke",
            color: "gray",
            boxShadow: 4,
            minWidth: "0px",
            width: "30px",
            height: "30px",
            border: "2px solid transparent",
            "&:hover": {
              backgroundColor: "#e7eaeb",
            },
          }}
        >
          X
        </Button>
        <Typography variant="h6" component="h2" align="center" gutterBottom>
          Z-Raporu / Gider Fişi Listesi(Excel)
        </Typography>
        <Grid>
          <div
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            className="drop-container"
            onClick={handleButtonClick}
          >
            {files.length > 0 ? (
              <span
                className="drop-title"
                sx={{ display: "flex", alignItems: "center" }}
              >
                Yüklenen EXCEL Dosyası:
                <Typography sx={{ ml: 1 }}>
                  {files.length > 0 ? files[0].name : "Hiçbir dosya seçilmedi"}
                </Typography>
              </span>
            ) : (
              <span
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyItems: "center",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <span className="drop-title">Dosyayı buraya sürükleyin</span>
                <Typography>veya</Typography>
                <span className="drop-title">Tıklayarak Seçin</span>
              </span>
            )}
          </div>
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileSelect}
            style={{ display: "none" }}
            accept=".xls,.xlsx"
          />
        </Grid>
        <Grid container sx={{ gap: 1, mt: 3 }}>
          <Grid
            container
            xs={12}
            md={12}
            sx={{
              justifyContent: "space-around",
              overflowY: "auto",
              gap: 3,
            }}
          >
            <Grid item xs={12}>
              <Divider
                variant="middle"
                sx={{
                  "&::before, &::after": {
                    borderColor: "#e08434",
                    border: "1px solid #e08434",
                  },
                }}
              >
                <Typography
                  variant="overline"
                  display="block"
                  sx={{
                    textTransform: "none",
                    margin: 0,
                  }}
                >
                  Sütun Seçimi
                </Typography>
              </Divider>
            </Grid>

            <Grid container xs={12} sx={{ justifyContent: "space-between" }}>
              <Grid
                item
                xs={12}
                md={5.7}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <FormControl fullWidth>
                  <Autocomplete
                    options={uppercaseAlphabet}
                    value={formState.selectedDateCol}
                    onChange={(event, newValue) => {
                      updateFormState("selectedDateCol", newValue);
                    }}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        // Eğer liste açıksa ve bir seçenek vurgulanmışsa, onu seç
                        const highlightedOption = event.target.querySelector(
                          'li[data-focus="true"]'
                        );
                        if (highlightedOption) {
                          highlightedOption.click();
                        }
                      }
                    }}
                    autoHighlight
                    selectOnFocus
                    blurOnSelect
                    openOnFocus
                    disablePortal
                    ListboxProps={{
                      style: { maxHeight: "150px" },
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Tarih Sütununu Seçiniz"
                        variant="outlined"
                        size="small"
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderTopRightRadius: "0px",
                            borderBottomRightRadius: "0px",
                          },
                        }}
                      />
                    )}
                    style={{ width: "100%" }}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                md={5.7}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <FormControl fullWidth>
                  <Autocomplete
                    options={dateFormats}
                    value={formState.selectedDateFormat}
                    onChange={(event, newValue) => {
                      updateFormState("selectedDateFormat", newValue);
                    }}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        // Eğer liste açıksa ve bir seçenek vurgulanmışsa, onu seç
                        const highlightedOption = event.target.querySelector(
                          'li[data-focus="true"]'
                        );
                        if (highlightedOption) {
                          highlightedOption.click();
                        }
                      }
                    }}
                    autoHighlight
                    selectOnFocus
                    blurOnSelect
                    openOnFocus
                    disablePortal
                    ListboxProps={{
                      style: { maxHeight: "150px" },
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Tarih Formatını Seçiniz"
                        variant="outlined"
                        size="small"
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderTopLeftRadius: "0px",
                            borderBottomLeftRadius: "0px",
                          },
                        }}
                      />
                    )}
                    style={{ width: "100%" }}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Grid container xs={12} sx={{ justifyContent: "space-between" }}>
              <Grid item xs={12} md={companyDetails?.class === 1 ? 5.7 : 12}>
                <FormControl fullWidth>
                  <Autocomplete
                    disablePortal
                    value={
                      formState.direction &&
                      formState.direction !== "" &&
                      formState.direction === 2
                        ? "Gelen"
                        : formState.direction &&
                          formState.direction !== "" &&
                          formState.direction === 1
                        ? "Giden"
                        : ""
                    }
                    options={[
                      {
                        value: 2,
                        label: "Gelen",
                      },
                      {
                        value: 1,
                        label: "Giden",
                      },
                    ]}
                    autoHighlight
                    selectOnFocus
                    disableClearable
                    onChange={(event, newValue) => {
                      updateFormState("direction", newValue.value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Fiş Yönü Seçiniz"
                        variant="outlined"
                        size="small"
                      />
                    )}
                    style={{ width: "100%" }}
                  />
                </FormControl>
              </Grid>
              {companyDetails?.class === 1 && (
                <Grid item xs={12} md={5.7}>
                  <FormControl fullWidth>
                    <Autocomplete
                      options={accountCodes}
                      value={
                        accountCodes.find(
                          (code) =>
                            code.hesap_kodu === formState.selectedAccountCode
                        ) || null
                      }
                      isOptionEqualToValue={(option, value) =>
                        option.hesap_kodu === value
                      }
                      onChange={async (event, newValue) => {
                        if (newValue?.hesap_kodu === "add-new") {
                          let newAccount = await CreateLucaAccountSwal(
                            companyDetails?.companyId,
                            companyDetails?.periodId
                          );
                          setAccountCodes((prevItems) => [
                            ...prevItems,
                            {
                              id: Math.floor(
                                100000 + Math.random() * 900000
                              ).toString(),
                              hesap_adi: newAccount?.input1,
                              hesap_kodu: newAccount?.input2,
                            },
                          ]);
                          return;
                        }

                        updateFormState(
                          "selectedAccountCode",
                          newValue?.hesap_kodu ? newValue?.hesap_kodu : ""
                        );
                      }}
                      // noOptionsText={loading ? "Yükleniyor..." : "Seçenek bulunamadı"}
                      onKeyDown={(event) => {
                        if (event.key === "Enter") {
                          // Eğer liste açıksa ve bir seçenek vurgulanmışsa, onu seç
                          const highlightedOption = event.target.querySelector(
                            'li[data-focus="true"]'
                          );
                          if (highlightedOption) {
                            highlightedOption.click();
                          }
                        }
                      }}
                      filterOptions={(options, params) => {
                        const filtered = options.filter((option) => {
                          let label = `${option.hesap_kodu} ${option.hesap_adi}`;
                          return label
                            .toLocaleLowerCase("tr")
                            .includes(
                              params?.inputValue?.toLocaleLowerCase("tr")
                            );
                        });
                        // Girilen metin, mevcut seçenekler arasında yoksa özel bir seçenek ekle
                        if (filtered?.length === 0) {
                          filtered.push({
                            hesap_adi: "Yeni Hesap Kodu Eklemek İçin Tıklayın",
                            hesap_kodu: "add-new", // Özel seçenek için belirleyici bir value
                          });
                        }

                        return filtered;
                      }}
                      autoHighlight
                      selectOnFocus
                      blurOnSelect
                      openOnFocus
                      disablePortal
                      ListboxProps={{
                        style: { maxHeight: "250px" },
                      }}
                      getOptionLabel={(option) => {
                        if (option?.hesap_kodu === "add-new") {
                          return `${option.adi || ""}`;
                        }
                        return option
                          ? `${option.hesap_kodu || ""} - ${
                              option.hesap_adi || ""
                            } - Bakiye: ${option.bakiye || ""}`
                          : "";
                      }}
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          {...props}
                          key={option?.hesap_kodu || ""}
                          style={{
                            paddingLeft: "7px",
                            maxWidth: "100%",
                          }}
                        >
                          <Typography>
                            {option.hesap_kodu === "add-new" ? (
                              <>{option.hesap_adi}</>
                            ) : (
                              <>
                                <strong>{option.hesap_kodu}</strong> -{" "}
                                {option.hesap_adi} - Bakiye: {option.bakiye}
                              </>
                            )}
                          </Typography>
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Hesap Kodu Seçiniz (Kasa,K.Kartı vb.)"
                          variant="outlined"
                          size="small"
                        />
                      )}
                      style={{ width: "100%" }}
                    />
                  </FormControl>
                </Grid>
              )}
            </Grid>

            <Grid container xs={12} sx={{ justifyContent: "space-between" }}>
              <Grid item xs={12} md={5.7}>
                <FormControl fullWidth>
                  <Autocomplete
                    options={uppercaseAlphabet}
                    value={formState.selectedDescriptionCol}
                    onChange={(event, newValue) => {
                      updateFormState("selectedDescriptionCol", newValue);
                    }}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        // Eğer liste açıksa ve bir seçenek vurgulanmışsa, onu seç
                        const highlightedOption = event.target.querySelector(
                          'li[data-focus="true"]'
                        );
                        if (highlightedOption) {
                          highlightedOption.click();
                        }
                      }
                    }}
                    autoHighlight
                    selectOnFocus
                    blurOnSelect
                    openOnFocus
                    disablePortal
                    ListboxProps={{
                      style: { maxHeight: "100px" },
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Açıklama sütunu Seçiniz"
                        variant="outlined"
                        size="small"
                      />
                    )}
                    style={{ width: "100%" }}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={5.7}>
                <FormControl fullWidth>
                  <Autocomplete
                    options={uppercaseAlphabet}
                    value={formState.selectedTaxPercentCol}
                    onChange={(event, newValue) => {
                      updateFormState("selectedTaxPercentCol", newValue);
                    }}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        // Eğer liste açıksa ve bir seçenek vurgulanmışsa, onu seç
                        const highlightedOption = event.target.querySelector(
                          'li[data-focus="true"]'
                        );
                        if (highlightedOption) {
                          highlightedOption.click();
                        }
                      }
                    }}
                    autoHighlight
                    selectOnFocus
                    blurOnSelect
                    openOnFocus
                    disablePortal
                    ListboxProps={{
                      style: { maxHeight: "100px" },
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Vergi Oranı Sütununu Seçiniz"
                        variant="outlined"
                        size="small"
                      />
                    )}
                    style={{ width: "100%" }}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Grid container xs={12} sx={{ justifyContent: "space-between" }}>
              <Grid item xs={12} md={5.7}>
                <FormControl fullWidth>
                  <Autocomplete
                    disablePortal
                    options={uppercaseAlphabet}
                    value={formState.selectedNumberCol}
                    onChange={(event, newValue) => {
                      updateFormState("selectedNumberCol", newValue);
                    }}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        // Eğer liste açıksa ve bir seçenek vurgulanmışsa, onu seç
                        const highlightedOption = event.target.querySelector(
                          'li[data-focus="true"]'
                        );
                        if (highlightedOption) {
                          highlightedOption.click();
                        }
                      }
                    }}
                    autoHighlight
                    selectOnFocus
                    blurOnSelect
                    openOnFocus
                    ListboxProps={{
                      style: { maxHeight: "150px" },
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Fiş No Sütununu Seçiniz"
                        variant="outlined"
                        size="small"
                      />
                    )}
                    style={{ width: "100%" }}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={5.7}>
                <FormControl fullWidth>
                  <Autocomplete
                    options={uppercaseAlphabet}
                    value={formState.selectedPayableAmountCol}
                    onChange={(event, newValue) => {
                      updateFormState("selectedPayableAmountCol", newValue);
                    }}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        // Eğer liste açıksa ve bir seçenek vurgulanmışsa, onu seç
                        const highlightedOption = event.target.querySelector(
                          'li[data-focus="true"]'
                        );
                        if (highlightedOption) {
                          highlightedOption.click();
                        }
                      }
                    }}
                    autoHighlight
                    selectOnFocus
                    blurOnSelect
                    openOnFocus
                    disablePortal
                    ListboxProps={{
                      style: { maxHeight: "100px" },
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Ödenecek Tutar sütunu Seçiniz"
                        variant="outlined"
                        size="small"
                      />
                    )}
                    style={{ width: "100%" }}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Grid container xs={12} sx={{ justifyContent: "space-between" }}>
              <Grid item xs={12} md={5.7}>
                <FormControl fullWidth>
                  <Autocomplete
                    options={uppercaseAlphabet}
                    value={formState.selectedSenderReceiverVknTckn}
                    onChange={(event, newValue) => {
                      updateFormState(
                        "selectedSenderReceiverVknTckn",
                        newValue
                      );
                    }}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        // Eğer liste açıksa ve bir seçenek vurgulanmışsa, onu seç
                        const highlightedOption = event.target.querySelector(
                          'li[data-focus="true"]'
                        );
                        if (highlightedOption) {
                          highlightedOption.click();
                        }
                      }
                    }}
                    autoHighlight
                    selectOnFocus
                    blurOnSelect
                    openOnFocus
                    disablePortal
                    ListboxProps={{
                      style: { maxHeight: "100px" },
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Gönderici Vkn/Tckn sütunu Seçiniz"
                        variant="outlined"
                        size="small"
                      />
                    )}
                    style={{ width: "100%" }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={5.7}>
                <FormControl fullWidth>
                  <Autocomplete
                    options={uppercaseAlphabet}
                    value={formState.selectedSenderReceiverName}
                    onChange={(event, newValue) => {
                      updateFormState("selectedSenderReceiverName", newValue);
                    }}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        // Eğer liste açıksa ve bir seçenek vurgulanmışsa, onu seç
                        const highlightedOption = event.target.querySelector(
                          'li[data-focus="true"]'
                        );
                        if (highlightedOption) {
                          highlightedOption.click();
                        }
                      }
                    }}
                    autoHighlight
                    selectOnFocus
                    blurOnSelect
                    openOnFocus
                    disablePortal
                    ListboxProps={{
                      style: { maxHeight: "100px" },
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Gönderici Ünvan sütunu Seçiniz"
                        variant="outlined"
                        size="small"
                      />
                    )}
                    style={{ width: "100%" }}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Grid container xs={12} sx={{ justifyContent: "space-between" }}>
              <Grid item xs={12} md={5.7} sx={{ mb: 3 }}>
                <FormControl fullWidth>
                  <Autocomplete
                    options={separatorOptions}
                    value={formState.selectedThousandSeparate}
                    onChange={(event, newValue) => {
                      updateFormState("selectedThousandSeparate", newValue);
                    }}
                    getOptionDisabled={(option) =>
                      isOptionDisabled(
                        option,
                        formState.selectedThousandSeparate,
                        formState.selectedFractionSeparate
                      )
                    }
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        // Eğer liste açıksa ve bir seçenek vurgulanmışsa, onu seç
                        const highlightedOption = event.target.querySelector(
                          'li[data-focus="true"]'
                        );
                        if (highlightedOption) {
                          highlightedOption.click();
                        }
                      }
                    }}
                    autoHighlight
                    selectOnFocus
                    blurOnSelect
                    openOnFocus
                    disablePortal
                    ListboxProps={{
                      style: { maxHeight: "100px" },
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Binlik Ayıracını Seçiniz"
                        variant="outlined"
                        size="small"
                      />
                    )}
                    style={{ width: "100%" }}
                  />
                  <Typography
                    variant="overline"
                    display="block"
                    sx={{
                      textTransform: "none",
                      margin: 0,
                    }}
                  >
                    (opsiyonel)
                  </Typography>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={5.7}>
                <FormControl fullWidth>
                  <Autocomplete
                    options={separatorOptions}
                    value={formState.selectedFractionSeparate}
                    onChange={(event, newValue) => {
                      updateFormState("selectedFractionSeparate", newValue);
                    }}
                    getOptionDisabled={(option) =>
                      isOptionDisabled(
                        option,
                        formState.selectedFractionSeparate,
                        formState.selectedThousandSeparate
                      )
                    }
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        // Eğer liste açıksa ve bir seçenek vurgulanmışsa, onu seç
                        const highlightedOption = event.target.querySelector(
                          'li[data-focus="true"]'
                        );
                        if (highlightedOption) {
                          highlightedOption.click();
                        }
                      }
                    }}
                    autoHighlight
                    selectOnFocus
                    blurOnSelect
                    openOnFocus
                    disablePortal
                    ListboxProps={{
                      style: { maxHeight: "100px" },
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Küsurat Ayıracını Seçiniz"
                        variant="outlined"
                        size="small"
                      />
                    )}
                    style={{ width: "100%" }}
                  />
                  <Typography
                    variant="overline"
                    display="block"
                    sx={{
                      textTransform: "none",
                      margin: 0,
                    }}
                  >
                    (opsiyonel)
                  </Typography>
                </FormControl>
              </Grid>
              <Alert
                severity="warning"
                sx={{
                  width: "100%",
                  borderRadius: "8px",
                  border: "1px solid #ef7918",
                  transition: "opacity 0.5s ease-in-out",
                  mb: 1,
                }}
              >
                Excel yükledikten sonra{" "}
                <span>
                  <b style={{ letterSpacing: "0.05em" }}>
                    toplam adet, tutar ve tarih
                  </b>
                </span>
                <span> kontrolü yapınız.</span>
              </Alert>
            </Grid>
          </Grid>

          <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              variant="contained"
              className={files.length === 0 ? "" : "custom-button"}
              onClick={() => {
                handleUploadClick();
              }}
              sx={{ mr: 2, width: "auto", textTransform: "none" }}
              disabled={files.length === 0}
            >
              Yükle
            </Button>
            <Button variant="outlined" onClick={handleClose}>
              İptal
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};
