/** @format */

import axiosInstance from "../../../../utils/maintenanceController/axios";

export const addBlackList = async (companyId, periodId, filteredBlackList) => {
    return axiosInstance.post(
        `/company/${companyId}/${periodId}/add-black-list`,
        { blackList: filteredBlackList }
    );
};

export const removeBlackList = async (
    companyId,
    periodId,
    filteredBlackList
) => {
    return axiosInstance.post(
        `/company/${companyId}/${periodId}/remove-black-list`,
        { blackList: filteredBlackList }
    );
};
