import axiosInstance from "../../../../utils/maintenanceController/axios";
import moment from "moment";

export const fetchInvoices = async (companyId, periodId, startDate, endDate) => {
  let startDateFormat = moment(startDate).format("YYYY-MM-DD");
  let endDateFormat = moment(endDate).format("YYYY-MM-DD");
  
  return axiosInstance.post(
    `/company/${companyId}/${periodId}/fetch-invoices`,
    {
      startDate: startDateFormat,
      endDate: endDateFormat
    }
  );
};

export const syncInvoicesFromXml = async (companyId, periodId, data) => {
  return axiosInstance.post(
    `/company/${companyId}/${periodId}/sync-invoices-from-xml`,
    {
      files: data.files,
      vkn: data.vkn,
      tckn: data.tckn,
      startDate: data.startDate,
      endDate: data.endDate,
      direction: data.direction
    }
  );
};
