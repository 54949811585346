/** @format */
import { cookies } from "../../../utils/cookie";
import localStorage from "local-storage";
export const logout = () => {
  cookies.remove("token");
  const sideBarOpen = localStorage.get("sidebar");
  const rememberMe = localStorage.get("rememberMe");
  localStorage.clear();
  if(rememberMe){
    localStorage.set("rememberMe", `${rememberMe}`);
  }
  if(sideBarOpen){
    localStorage.set("sidebar", `${sideBarOpen}`);
  }
  window.location.href = "/login"
};
