import axiosInstance from "../../../../utils/maintenanceController/axios";
import moment from "moment";

export const getListFiches = async (companyId, periodId, startDate, endDate) => {
  const startDateFormat = moment(startDate).format("YYYY-MM-DD HH:mm:ss");
  const endDateFormat = moment(endDate).format("YYYY-MM-DD HH:mm:ss");

  return axiosInstance.get(
    `/company/${companyId}/${periodId}/list-fiches`,
    {
      params: {
        startDate: startDateFormat,
        endDate: endDateFormat,
      },
    }
  );
};
