/** @format */

import axiosInstance from "../../../utils/maintenanceController/axios";

export const createApiKey = async () => {
    return axiosInstance.post(`/advisor/create-api-key`);
};

export const createApiKeyForSubUser = async (subUserId) => {
    return axiosInstance.post(`/advisor/create-api-key-for-sub-user`, {
        subUserId,
    });
};
