//TODO  BU SAYFADA MASTER GRİD(SUBDETAILS) AYARLARI VE COLDEFS AYARLARI BULUNUR.
//TODO  ANA GRİDİN column ayarları ulunur

import moment from "moment";
import sideBar from "../../components/GridSideBar/gridSideBar.js";
import localeTextTr from "../../locale.tr.js";
import { updateBankExtract } from "../../api/company/bankExtracts/updateBankExtract/index.js";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { FaCircleXmark } from "react-icons/fa6";
import { TbCopyPlus } from "react-icons/tb";
import { FaCircleCheck } from "react-icons/fa6";
import Swal from "sweetalert2";
import { createTurkishFilterParams } from "../../utils/agGridTextFilterTr/agGridFilterUtils.js";


const StatusRenderer = (props) => {
  const { value } = props;

  if (value === 100) {
    return null;
  } else if (value === 102) {
    return (
      <span
        title="Hesap Kodu Bulunamadı"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <FaCircleXmark style={{ color: "#ea5455" }} size={24} />
      </span>
    );
  } else if (value === 103) {
    return (
      <span
        title="Çoklu Hesap Bulundu"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <TbCopyPlus
          style={{
            color: "#ffffff",
            backgroundColor: "#00cfe8",
            padding: 5,
            borderRadius: "50%",
          }}
          size={14}
        />
      </span>
    );
  } else if (value === 104) {
    return (
      <span
        title="Aktarılabilir"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <FaCircleCheck style={{ color: "#28c76f" }} size={24} />
      </span>
    );
  } else {
    return value;
  }
};

export const createColumnDefs = (companyId, periodId,ItemSelectorCellEditor) => [
  {
    headerName: "",
    checkboxSelection: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    headerClass: "checkbox",
    pinned: "left",
    width: 48,
    field: "checkboxBtn",
    resizable: false,
    lockPosition: "left",
    suppressAutoSize: true,
    suppressColumnsToolPanel: true,
    suppressMenu: true,
    editable: false,
    filter: false,
  },
  {
    field: "master",
    headerName: "",
    cellRenderer: "agGroupCellRenderer",
    editable: false,
    filter: false,
    suppressMenu: true,
    flex: 0,
    width: 48,
    resizable: false,
  },
  {
    field: "status",
    headerName: "Aktarım Durumu",
    flex: 0,
    filter: "agSetColumnFilter",
    cellStyle: { textAlign: "center" },
    width: 155,
    filterParams: {
      valueFormatter: function (params) {
        if (params.value === 102) {
          return "Hesap Kodu Bulunamadı";
        } else if (params.value === 103) {
          return "Çoklu Hesap Bulundu";
        } else if (params.value === 104) {
          return "Aktarılabilir";
        }
      },
    },
    editable: false,
    cellRenderer: StatusRenderer,
  },
  {
    field: "id",
    hide: true,
    suppressColumnsToolPanel: true,
    width: 100,
  },
  {
    field: "number",
    headerName: "İşlem Numarası",
    filter: "agTextColumnFilter",
    width: 120,
    editable: false,
  },
  {
    field: "direction",
    headerName: "Yön",
    filter: "agSetColumnFilter",
    width: 80,
    filterParams: {
      valueFormatter: function (params) {
        if (params.value === 2) {
          return "Gelen";
        } else if (params.value === 1) {
          return "Giden";
        } else {
          return params.value;
        }
      },
    },
    valueFormatter: function (params) {
      if (params.value === 2) {
        return "Gelen";
      } else if (params.value === 1) {
        return "Giden";
      } else {
        return params.value;
      }
    },
  },
  {
    field: "transactionDate",
    headerName: "Tarih",
    width: 110,
    editable: false,
    valueFormatter: function (params) {
      return moment.utc(params.value).format("DD/MM/YYYY");
    },
    valueSetter: function (params) {
      var newValue = moment(params.newValue, "DD/MM/YYYY", true);
      if (newValue.isValid()) {
        params.data.transactionDate = newValue.format("YYYY-MM-DD"); // Tarihi YYYY-MM-DD formatına dönüştürüp kaydedin
        return true; // Yeni değer kabul edildi
      } else {
        alert("Lütfen geçerli bir tarih formatı girin (GG/AA/YYYY)");
        return false;
      }
    },
    onCellValueChanged: function (params) {
      if (params.data.transactionDate) {
        let extractData = {
          id: Number(params.data.id),
          transactionDate: params.data.transactionDate, // Tarihi direkt kullanın
        };
        updateBankExtract(companyId, periodId, extractData)
          .then((resp) => {
            params.node.setDataValue("transactionDate", resp.transactionDate);
          })
          .catch((err) => {
            Swal.fire({
              html: `Tarih güncellenirken bir hata oluştu !<br>Lütfen tekrar deneyiniz.<br>`,
              icon: "error",
              confirmButtonText: "Tamam",
            });
          });
      }
    },
  },
  {
    field: "amount",
    headerName: "Tutar",
    filter: "agNumberColumnFilter",
    valueFormatter: (params) => {
      if (params.value !== null && params.value !== undefined) {
        // Number to locale string conversion
        return new Intl.NumberFormat("tr-TR", {
          currency: "TRY",
        }).format(params.value);
      } else {
        return "";
      }
    },
    width: 100,
  },
  {
    field: "balance",
    headerName: "Hesap Bakiyesi",
    filter: "agNumberColumnFilter",
    valueFormatter: (params) => {
      if (params.value !== null && params.value !== undefined) {
        // Number to locale string conversion
        return new Intl.NumberFormat("tr-TR", {
          currency: "TRY",
        }).format(params.value);
      } else {
        return "";
      }
    },
    width: 80,
  },
  {
    field: "description",
    headerName: "Açıklama",
    filter: "agTextColumnFilter",
    width: 300,
  },
  {
    field: "senderName",
    headerName: "Gönderici Ünvan",
    filter: "agTextColumnFilter",
    width: 150,
  },
  {
    field: "receiverName",
    headerName: "Alıcı Ünvan",
    filter: "agTextColumnFilter",
    width: 150,
  },
  {
    field: "lucaCurrentCode",
    headerName: "Luca Hesap Kodu",
    filter: "agTextColumnFilter",
    editable: true,
    width: 400,
    cellRenderer: (params) => params.value,
    cellStyle: (params) => {
      return params.data.lucaCurrentCode
        ? { backgroundColor: "transparent" }
        : params.data.possibleLucaAccounts.length > 0
        ? { backgroundColor: "#17a2b8" }
        : { backgroundColor: "#ea5455" };
    },
    cellEditor:ItemSelectorCellEditor,
    valueSetter: (params) => params.data.lucaCurrentCode = params.newValue,
    suppressKeyboardEvent: (params) => {
      // Eğer Enter tuşuna basılmışsa
      if (params.event.key === 'Enter') {
        return true; // Olayı bastır (düzenleyiciyi kapatma)
      }
      return false; // Diğer tuşlar normal çalışsın
    },
  },
  {
    field: "lucaCurrentName",
    headerName: "Luca Hesap Adı",
    filter: "agTextColumnFilter",
    width: 200,
  },
  {
    field: "bankName",
    headerName: "Banka",
    width: 100,
    filter: "agSetColumnFilter",
    cellStyle: { textAlign: "center" },
  },
];

//////////////////DETAILS AYARLARI/////////////////

const createDetailsColumnDefs = (ButtonRenderer) => [
  {
    field: "hesap_kodu",
    headerName: "Hesap Kodu",
    filter: "agTextColumnFilter",
    width: 400,
  },
  {
    field: "borc",
    headerName: "Borç",
    filter: "agNumberColumnFilter",
    valueFormatter: (params) => {
      return new Intl.NumberFormat("tr-TR", {
        style: "currency",
        currency: "TRY",
      }).format(params.value);
    },
  },
  {
    field: "alacak",
    headerName: "Alacak",
    filter: "agNumberColumnFilter",
    valueFormatter: (params) => {
      return new Intl.NumberFormat("tr-TR", {
        style: "currency",
        currency: "TRY",
      }).format(params.value);
    },
  },
];
export const detailsGridOptions = (ButtonRenderer) => {
  return {
    columnDefs: createDetailsColumnDefs(ButtonRenderer),
    defaultColDef: {
      resizable: true,
      floatingFilter: true,
      filterParams:createTurkishFilterParams(),
    },
    sideBar: sideBar.detailsSideBar,
    localeText: localeTextTr,
    rowDragManaged: true,
    animateRows: true,
    rowSelection: "multiple",
    singleClickEdit: true,
    rowMultiSelectWithClick: true,
  };
};
