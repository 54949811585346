/** @format */
//todo TOKEN DEĞİŞİKLİKLERİNİ YAP !!!!!!!!!!!!!!!!!!

import { cookies } from "../cookie";
import { jwtDecode } from "jwt-decode";

const removeCookies = () => {
  cookies.remove("token", { path: "/" });
};

const CheckToken = async () => {
  const decodeToken = await jwtDecode(cookies.get("token"));

  if (new Date(decodeToken.exp * 1000) > new Date()) {
    return cookies.get("token");
  }
  removeCookies();
  return false;
};

export default CheckToken;
