import React, { useEffect, useState } from "react";
import {
  Modal,
  Grid,
  Typography,
  Box,
  Button,
  Divider,
  TextField,
} from "@mui/material";
import { getAdvisorThenId } from "../../../api/adminApis/getAdvisorThenId";
import Swal from "sweetalert2";
import moment from "moment";
import { adminUpdateAdvivorSettings } from "../../../api/adminApis/updateAdvivors";
function compareObjects(obj1, obj2) {
  const differences = {};

  function compare(a, b, path = '') {
    if (typeof a !== typeof b) {
      differences[path] = b;
      return;
    }

    if (typeof a !== 'object' || a === null || b === null) {
      if (a !== b) {
        differences[path] = b;
      }
      return;
    }

    const keys = new Set([...Object.keys(a), ...Object.keys(b)]);

    for (const key of keys) {
      const newPath = path ? `${path}.${key}` : key;
      if (!(key in a)) {
        differences[newPath] = b[key];
      } else if (!(key in b)) {
        differences[newPath] = undefined;
      } else {
        compare(a[key], b[key], newPath);
      }
    }
  }

  compare(obj1, obj2);
  differences.id = obj1.id || obj2.id;
  return differences;
}
export const ViewAdvisor = ({ open, handleClose, advisorId }) => {
  const [advisorData, setAdvisorData] = useState({});
  const [backupData, setBackupData] = useState({});

  useEffect(() => {
    getAdvisorThenId(advisorId)
      .then((resp) => {
        const advisorWithPassword = {
          ...resp.data.advisor,
          password: "",
        };
        setAdvisorData(advisorWithPassword);
        setBackupData(advisorWithPassword);
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, [open]);
  
  const handleOnClick = () => {
    
    const changedFields = compareObjects(backupData, advisorData);
if(Object.keys(changedFields).length < 2){
  Swal.fire({
    title: "Hata",
    text: "Değişiklik yapmadınız..",
    icon: "warning",
    confirmButtonText: "Tamam",
  });
}else    if (
      !advisorData.id ||
      !advisorData.name ||
      !advisorData.vkn ||
      !advisorData.customerCode ||
      !advisorData.username ||
      !advisorData.email ||
      !advisorData.dvdPassword ||
      !advisorData.lucaPassword ||
      !advisorData.phone
    ) {
      // Eğer herhangi bir alan boşsa, uyarı göster
      Swal.fire({
        title: "Hata",
        text: "Lütfen tüm alanları doldurun.(Şifre zorunlu değil)",
        icon: "warning",
        confirmButtonText: "Tamam",
      });
    } else {
      
      adminUpdateAdvivorSettings(changedFields).then((resp)=>{
        Swal.fire({
          html: resp.data.message,
          icon: 'success',
          confirmButtonText: 'Tamam',
        });
      }).catch((err)=>{
        Swal.fire({
          title: err.response.data.message,
          icon: 'error',
          confirmButtonText: 'Tamam',
        });
      })
    }
  };
  const updateAdvisorData = (field, value) => {
    setAdvisorData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };
  return (
    <Modal open={open} onClose={handleClose} style={{ zIndex: 1000 }}>
      <Box
        sx={{
          position: "absolute",
          top: "50%", // "3%" yerine "50%" kullanın
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          width: "70%",
          border: "2px solid transparent",
          maxHeight: "80vh" /* Maksimum yükseklik */,
        }}
      >
        <Grid
          container
          item
          md={12}
          justifyContent={"space-between"}
          sx={{ alignItems: "flex-start", mb: 2, gap: 1 }}
        >
          <Grid item xs={12} md={5.9} sx={{ mb: 3 }}>
            <TextField
              fullWidth
              className="input-field"
              label="Ünvan"
              variant="outlined"
              defaultValue={''}
              value={advisorData?.name}
              size="small"
              onChange={(e) => {
                updateAdvisorData("name", e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={5.9} sx={{ mb: 3 }}>
            <TextField
              fullWidth
              className="input-field"
              label="E-Posta Adresi"
              variant="outlined"
              defaultValue={''}
              value={advisorData?.email}
              size="small"
              onChange={(e) => {
                updateAdvisorData("email", e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={5.9} sx={{ mb: 3 }}>
            <TextField
              fullWidth
              className="input-field"
              label="Telefon Numarası"
              placeholder="532*******"
              variant="outlined"
              defaultValue={''}
              value={advisorData?.phone}
              size="small"
              onChange={(e) => {
                updateAdvisorData("phone", e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={5.9} sx={{ mb: 3 }}>
            <TextField
              fullWidth
              className="input-field"
              label="VKN"
              variant="outlined"
              defaultValue={''}
              value={advisorData?.vkn}
              size="small"
              onChange={(e) => {
                updateAdvisorData("vkn", e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={12} sx={{ mb: 3 }}>
            <TextField
              fullWidth
              className="input-field"
              label="Şifre"
              variant="outlined"
              placeholder="Şifre güvenlik gereği gösterilmemektedir. Güncellemek istiyırsanız yeni şifreyi girebilirsiniz "
              defaultValue={''}
              value={advisorData?.password}
              size="small"
              onChange={(e) => {
                updateAdvisorData("password", e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={5.9}>
            <TextField
              fullWidth
              className="input-field"
              label="Oluşturulma Tarihi"
              variant="outlined"
              defaultValue={''}
              value={advisorData?.createdAt ? moment(advisorData.createdAt).format('DD/MM/YYYY HH:mm:ss') : ''}
              size="small"
              disabled
              onChange={(e) => {
                updateAdvisorData("createdAt", e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={5.9}>
            <TextField
              fullWidth
              className="input-field"
              label="Güncelleme Tarihi"
              variant="outlined"
              value={advisorData?.updatedAt ? moment(advisorData.updatedAt).format('DD/MM/YYYY HH:mm:ss') : ''}
              size="small"
              disabled
              onChange={(e) => {
                updateAdvisorData("updatedAt", e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={12} sx={{ justifyContent: "space-around" }}>
            <Divider
              variant="middle"
              sx={{
                "&::before, &::after": {
                  borderColor: "#e08434",
                  border: "1px solid #e08434",
                },
              }}
            >
              <Typography
                variant="overline"
                display="block"
                sx={{ textTransform: "none", margin: 0 }}
              >
                Luca Bilgileri
              </Typography>
            </Divider>
          </Grid>
          <Grid item xs={12} md={3.3}>
            <TextField
              fullWidth
              label="Luca Kullanıcı Adı"
              variant="outlined"
              className="input-field"
              defaultValue={''}
              value={advisorData?.customerCode}
              size="small"
              onChange={(e) => {
                updateAdvisorData("customerCode", e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={3.3}>
            <TextField
              fullWidth
              label="Luca Kullanıcı Kodu"
              className="input-field"
              variant="outlined"
              defaultValue={''}
              value={advisorData?.username}
              size="small"
              onChange={(e) => {
                updateAdvisorData("username", e.target.value);
              }}
            />
          </Grid>

          <Grid item xs={12} md={3.3}>
            <TextField
              fullWidth
              label="Luca Kullanıcı Şifresi"
              variant="outlined"
              className="input-field"
              defaultValue={''}
              value={advisorData?.lucaPassword}
              size="small"
              onChange={(e) => {
                updateAdvisorData("lucaPassword", e.target.value);
              }}
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <Divider
              variant="middle"
              sx={{
                "&::before, &::after": {
                  borderColor: "#81BEFA",
                  border: "1px solid #81BEFA",
                },
                height: "auto",
                paddingBottom: 0,
              }}
            >
              <Typography
                variant="overline"
                display="block"
                sx={{ textTransform: "none", margin: 0 }}
              >
                Dijital Vergi Dairesi Bilgileri
              </Typography>
            </Divider>
          </Grid>

          <Grid item xs={12} md={12}>
            <TextField
              fullWidth
              label="DVD Şifresi"
              variant="outlined"
              className="input-field"
              defaultValue={''}
              value={advisorData?.dvdPassword}
              size="small"
              onChange={(e) => {
                updateAdvisorData("dvdPassword", e.target.value);
              }}
            />
          </Grid>

          <Grid
            container
            xs={12}
            sx={{
              mt: "12px",
            }}
          ></Grid>

          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              mt: "12px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid xs={12} md={2}>
              <Button
                variant="contained"
                color="primary"
                className="custom-button"
                onClick={handleOnClick}
              >
                Değişiklikleri Kaydet
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};
