/** @format */

import React, { useState } from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {
    MdOutlinePlaylistAdd,
    MdOutlinePlaylistRemove,
    MdOutlineQueryStats,
} from "react-icons/md";

import {
    TbTransfer,
    TbUserCheck,
    TbUserMinus,
    TbBrush,
    TbBrushOff,
} from "react-icons/tb";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Grid, Typography } from "@mui/material";

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        keepMounted
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "right",
        }}
        {...props}
    />
))(({ theme }) => ({
    "& .MuiPaper-root": {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === "light"
                ? "rgb(55, 65, 81)"
                : theme.palette.grey[300],
        boxShadow:
            "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
        "& .MuiMenu-list": {
            padding: "4px 0",
        },
        "& .MuiMenuItem-root": {
            "& .MuiSvgIcon-root": {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            "&:active": {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity
                ),
            },
            // Typography için eklenen yeni stiller
            "& .MuiTypography-root:not([data-exempt='true'])": {
                fontSize: "14px",
                fontWeight: 400,
                color: "#605f66",
            },
        },
    },
}));

export default function ProcessMenu({
    setModalId,
    handleModalOpen,
    setUpdateRow,
}) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    //Fatura Seri Kontrol Raporu Oluştur////----------
    return (
        <Grid>
            <Button
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                variant="contained"
                disableElevation
                sx={{
                    textTransform: "none",
                    backgroundColor: "#dbf0fd",
                    color: "#2ea8f3",
                    "&:hover": {
                        backgroundColor: "#cfe6f5",
                    },
                }}
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon />}
            >
                İşlemler
            </Button>
            <StyledMenu
                id="simple-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                disableScrollLock
            >
                <MenuItem
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                        color: "error",
                    }}
                    onClick={() => {
                        setModalId("#copyRuleToCompanyControl");
                        handleClose();
                    }}
                >
                    <TbTransfer color="#07d0e9" />
                    <Typography sx={{ color: "#07d0e9 !important" }}>
                        Seçilen Mükelleflere Kural Kopyala
                    </Typography>
                </MenuItem>
                <MenuItem
                    sx={{ display: "flex", alignItems: "center", gap: 1 }}
                    onClick={() => {
                        setModalId("#selectedAdvisorsMakeActiveControl");
                        handleClose();
                    }}
                >
                    <TbUserCheck color="#28c76f" />
                    <Typography sx={{ color: "#28c76f !important" }}>
                        Seçilen Müşavirlerin Durumunu Aktif Yap
                    </Typography>
                </MenuItem>
                <MenuItem
                    sx={{ display: "flex", alignItems: "center", gap: 1 }}
                    onClick={() => {
                        setModalId("#selectedAdvisorsMakeDeactiveControl");
                        handleClose();
                    }}
                >
                    <TbUserMinus color="#ea5455" />
                    <Typography sx={{ color: "#ea5455 !important" }}>
                        Seçilen Müşavirlerin Durumunu Pasif Yap
                    </Typography>
                </MenuItem>
                <MenuItem
                    sx={{ display: "flex", alignItems: "center", gap: 1 }}
                    onClick={() => {
                        setModalId(
                            "#selectedAdvisorsAutoFetchInvoicesOpenControl"
                        );
                        handleClose();
                    }}
                >
                    <MdOutlinePlaylistAdd />
                    <Typography>
                        Seçilen Müşavirlerin Toplu Fatura Çekme İşlemini Aç
                    </Typography>
                </MenuItem>
                <MenuItem
                    sx={{ display: "flex", alignItems: "center", gap: 1 }}
                    onClick={() => {
                        setModalId(
                            "#selectedAdvisorsAutoFetchInvoicesCloseControl"
                        );
                        handleClose();
                    }}
                >
                    <MdOutlinePlaylistRemove />
                    <Typography>
                        Seçilen Müşavirlerin Toplu Fatura Çekme İşlemini Kapat
                    </Typography>
                </MenuItem>
                <MenuItem
                    sx={{ display: "flex", alignItems: "center", gap: 1 }}
                    onClick={() => {
                        setModalId("#checkActiveUsingAndJobs");
                        handleClose();
                        handleModalOpen();
                    }}
                >
                    <MdOutlineQueryStats />
                    <Typography>
                        Aktif Senkronizasyonları ve Kullanıcıları Kontrol Et
                    </Typography>
                </MenuItem>
                <MenuItem
                    sx={{ display: "flex", alignItems: "center", gap: 1 }}
                    onClick={() => {
                        setModalId("#createMaintenance");
                        handleClose();
                        handleModalOpen();
                    }}
                >
                    <TbBrush color="#28c76f" />
                    <Typography>Sistemi Bakım Moduna Al</Typography>
                </MenuItem>
                <MenuItem
                    sx={{ display: "flex", alignItems: "center", gap: 1 }}
                    onClick={() => {
                        setModalId("#finishMaintenance");
                        handleClose();
                        handleModalOpen();
                    }}
                >
                    <TbBrushOff color="#ea5455" />
                    <Typography>Aktif Bakım Modunu Kapat</Typography>
                </MenuItem>
            </StyledMenu>
        </Grid>
    );
}
