import { useEffect } from "react";
import Swal from "sweetalert2";
import { finishMaintenance } from "../../../api/adminApis/finishMaintenance";
// import { deleteFiches } from "../../api/company/fiches/deleteFiches";
// Yükleniyor Swal bileşeni
export const FinishMaintenanceSwal = ({ open, handleClose }) => {
  useEffect(() => {
    if (open) {
      Swal.fire({
        title: "Bakım hakkında bilgi veriniz",
        input: "text",
        inputPlaceholder: "Bakım hakkında bilgi girin",
        showCancelButton: true,
        confirmButtonText: "Gönder",
        cancelButtonText: "İptal",
        showLoaderOnConfirm: true,
        preConfirm: (info) => {
          finishMaintenance(info)
            .then((resp) => {
              Swal.fire({
                text: resp.data.message,
                icon: "success",
                confirmButtonText: "Tamam",
              });
            })
            .catch((err) => {
              Swal.fire({
                icon: "error",
                title: "İşlem Başarısız!",
                text: err.response.data.message,
                confirmButtonText: "Tamam",
              });
            });
        },
      });

      handleClose();
    }
  }, [open, handleClose]);

  return null;
};
