import { useEffect } from "react";
import Swal from "sweetalert2";
import { deleteInvoices } from "../../api/company/invoices/deleteInvoices";
import { checkTempProcess } from "../../api/company/invoices/checkTempProcess";
// Yükleniyor Swal bileşeni
export const DeleteSelectedInvoicesSwal = ({
  open,
  handleClose,
  companyId,
  periodId,
  selectedInvoices,
  setUpdateRow,
}) => {
  useEffect(() => {
    if (open) {
      if (selectedInvoices.length === 0) {
        Swal.fire({
          title: "Uyarı",
          text: "Lütfen en az 1 fatura seçin.",
          icon: "error",
          confirmButtonText: "Tamam",
        });
        handleClose();
        return;
      }
      Swal.fire({
        html: `<b>${selectedInvoices.length}</b> adet fatura silinecek. Emin misiniz?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Evet, Sil!",
        cancelButtonText: "Hayır",
      }).then(async (result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: "Faturalar siliniyor...",
            text: "Lütfen bekleyiniz",
            icon: "info",
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            customClass: {
              popup: "swal2-content-centered",
            },
            willOpen: () => {
              Swal.showLoading();
            },
          });
          const selectedIds = selectedInvoices.map((detail) => detail.id);
          deleteInvoices(companyId, periodId, selectedIds)
            .then(async (resp) => {
              let flag = true;
              while (flag) {
                try {
                  const checkResp = await checkTempProcess(
                    companyId,
                    periodId,
                    resp.data.id
                  );
                  flag = checkResp.data?.isActive;
                  if (checkResp.data?.isActive === false) {
                    if (checkResp.data.result?.error) {
                      Swal.close();
                      Swal.fire({
                        html: `<p> ${checkResp.data.result.error} </p>`,
                        icon: "error",
                        confirmButtonText: "Tamam",
                      })
                    }  else {
                      Swal.close();
                      Swal.fire({
                        title: `${checkResp.data.result.message}`,
                        confirmButtonText: 'Tamam',
                        icon: 'success',
                      });
                    }
                    setUpdateRow(true);
                  }
                } catch (err) {
                  Swal.fire({
                    title: "Bir hata oluştu!",
                    text: err.response.data.message,
                    icon: "error",
                    confirmButtonText: "Tamam",
                  });
                }
                await new Promise((resolve) => setTimeout(resolve, 10000));
              }
            })
            .catch((err) => {
              console.log(err);
              Swal.fire({
                title: "Bir hata oluştu!",
                text: err.response.data.message,
                icon: "error",
                confirmButtonText: "Tamam",
              });
            });
        }
      });

      handleClose();
    }
  }, [open, handleClose, selectedInvoices]);

  return null;
};
