import { Grid, TextField, Button, Typography } from "@mui/material";
import { TbLogout, TbView360Arrow } from "react-icons/tb";

import { useEffect, useRef, useState } from "react";
import { getJsonItem } from "../../utils/localStorageProcess";
import { Link, useLocation } from "react-router-dom";
import { logout } from "../../api/auth/logout";
import { cookies } from "../../utils/cookie";

const AdminNavbar = ({ setLoading,setFirstLoading,firstLoading,page }) => {
  const location = useLocation();
  const [companyDetails, setCompanyDetails] = useState();
  const companyDetailsRef = useRef(companyDetails);
  useEffect(() => {
    let locationSplit = location.pathname.split("/");
    const details = getJsonItem(`#${locationSplit[2]}/${locationSplit[3]}`);
    setCompanyDetails(details);
    companyDetailsRef.current = details;
     // referansı güncelle
  }, [location.pathname]);
  
  useEffect(() => {
    let locationSplit = location.pathname.split("/");
    const details = getJsonItem(`#${locationSplit[2]}/${locationSplit[3]}`);
    setCompanyDetails(details);
    companyDetailsRef.current = details;

  }, []);
  const handleClick = () => {
    const jwtToken = cookies.get("token");
    const url = new URL(`${process.env.REACT_APP_API_URL}/admin/queues`);
    
    // Token'ı URL'ye query parametresi olarak ekleyin
    url.searchParams.append('token', jwtToken);
  
    // Yeni pencerede açın
    window.open(url.toString(), "_blank");
  };
  return (
    <Grid container className="navbar">
      <Grid sx={{ display: "flex", alignItems: "center",pl:1 }}>
        <Typography color={"#04173793"} fontWeight={"500"}>
          ADMİN || {page}
        </Typography>
      </Grid>
      <Grid
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "50%",
        }}
      >
        {/* <Button  TEMA BUTONU
          sx={{
            borderRadius: "50%",
            minWidth: 0,
            width: "3rem",
            height: "3rem",
            padding: 0,
            boxShadow: "none",
            "&:hover": {
              backgroundColor: "rgba(126, 126, 126, 0.2)",
            },
          }}
        >
          <MoonStars color="black" />
        </Button> */}
        <Grid
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap:1
          }}
        >
          <Button
            sx={{
              width: "9rem",
              height: "2rem",
              backgroundColor: "#f8f7fa",
              textTransform: "none",
              borderTopRightRadius: "0px",
              borderBottomRightRadius: "0px",
              "&:hover": {
                backgroundColor: "#e5e5e7",
                borderTopRightRadius: "0px",
                borderBottomRightRadius: "0px",
              },
            }}

            onClick={() => {handleClick()}} // .env'deki URL'yi kullan
  
            startIcon={<TbView360Arrow color="3f3f3f"/>}
          >
            <Typography color={"#3f3f3f"} >
              Kuyruklara Git
            </Typography>
          </Button>
          <Button
            sx={{
              width: "9rem",
              height: "2rem",
              backgroundColor: "#f7d1d1b7",
              textTransform: "none",
              borderTopRightRadius: "0px",
              borderBottomRightRadius: "0px",
              "&:hover": {
                backgroundColor: "#f8c5c5b7",
                borderTopRightRadius: "0px",
                borderBottomRightRadius: "0px",
              },
            }}
            onClick={logout}
            startIcon={<TbLogout color="#ff0000"/>}
          >
            <Typography color={"#ff0000"} >
              Çıkış Yap
            </Typography>
          </Button>
          
        </Grid>

      </Grid>
    </Grid>
  );
};

export default AdminNavbar;
