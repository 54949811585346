/** @format */

import { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";

import {
    Autocomplete,
    Button,
    IconButton,
    InputAdornment,
    TextField,
} from "@mui/material";
import { emailFormatControl } from "../../../utils/emailFormatControl";

import withReactContent from "sweetalert2-react-content";
import { updateSubUser } from "../../../api/advisor/advisorMyUserPageApis";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { showLoadingSwal } from "../../../components/loadingSwal";
import { createApiKeyForSubUser } from "../../../api/advisor/ApiKey";
import { Grid } from "@mui/material";
const MySwal = withReactContent(Swal);
// Yükleniyor Swal bileşeni
export const UpdateSelectedUserSwal = ({
    open,
    handleClose,
    selectedUsers,
    setUpdateRow,
}) => {
    const emailRef = useRef();
    const usernameRef = useRef();
    const passwordRef = useRef();
    const isCanSendFiche = useRef();
    const apiKey = useRef(selectedUsers[0]?.ApiKey);

    const handleCopyApiKey = () => {
        // Burada kopyalama işlemini gerçekleştir
        navigator.clipboard.writeText(apiKey.current);
    };
    const handleCreateApiKey = () => {
        Swal.fire({
            html: "Yeni API anahtarı oluşturulacak. Mevcut anahtarınız <span color='red'>iptal</span> edilecek. Emin misiniz?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Evet",
            cancelButtonText: "Hayır",
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            focusCancel: true,
        }).then((result) => {
            if (result.isConfirmed) {
                showLoadingSwal("Yeni API anahtarı oluşturuluyor...");
                createApiKeyForSubUser(selectedUsers[0].id)
                    .then((resp) => {
                        apiKey.current = resp.data.apiKey;
                        Swal.fire({
                            icon: "success",
                            title: "Başarılı",
                            text: "Yeni API anahtarı oluşturuldu.",
                            confirmButtonText: "Tamam",
                        });
                        setUpdateRow(true);
                    })
                    .catch((err) => {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text:
                                "Bir hata oluştu! Detay: " +
                                err.response.data.message,
                            confirmButtonText: "Tamam",
                        });
                    });
            }
        });
    };
    useEffect(() => {
        if (open) {
            if (selectedUsers.length !== 1) {
                Swal.fire({
                    title: "Uyarı",
                    text: "Lütfen bir adet kullanıcı seçin.",
                    icon: "error",
                    confirmButtonText: "Tamam",
                });
                apiKey.current = selectedUsers[0]?.ApiKey;
                handleClose();
                return;
            }

            MySwal.fire({
                title: "Kullanıcı Güncelle",
                html: (
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "20px",
                        }}
                    >
                        <TextField
                            inputRef={emailRef}
                            className="input-field"
                            fullWidth
                            label="E-Posta"
                            defaultValue={selectedUsers[0].email}
                            placeholder="E-posta adresini giriniz"
                            InputLabelProps={{ shrink: true }}
                            variant="outlined"
                        />
                        <TextField
                            inputRef={usernameRef}
                            className="input-field"
                            fullWidth
                            label="Kullanıcı Adı"
                            placeholder="Kullanıcı adı giriniz"
                            defaultValue={selectedUsers[0].username}
                            InputLabelProps={{ shrink: true }}
                            variant="outlined"
                        />
                        <TextField
                            inputRef={passwordRef}
                            className="input-field"
                            fullWidth
                            label="Şifre"
                            placeholder="Şifre giriniz"
                            defaultValue={selectedUsers[0].password}
                            InputLabelProps={{ shrink: true }}
                            variant="outlined"
                        />
                        <Autocomplete
                            disablePortal
                            options={[
                                { value: true, label: "Evet" },
                                { value: false, label: "Hayır" },
                            ]}
                            autoHighlight
                            selectOnFocus
                            blurOnSelect
                            disableClearable
                            defaultValue={
                                selectedUsers[0].isCanSendFicheToLuca
                                    ? "Evet"
                                    : "Hayır"
                            }
                            onChange={(event, newValue) => {
                                isCanSendFiche.current = newValue.value;
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Fiş Gönderme Yetkisi Verilsin mi?"
                                    variant="outlined"
                                    size="small"
                                />
                            )}
                            style={{ width: "100%" }}
                        />
                        <Grid
                            item
                            xs={12}
                            md={12}
                            sx={{ display: "flex", gap: 2 }}
                        >
                            <TextField
                                label="API Anahtarı"
                                variant="outlined"
                                className="input-field"
                                value={selectedUsers[0]?.ApiKey || ""}
                                size="small"
                                sx={{ width: "100%" }}
                                InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={handleCopyApiKey}
                                                edge="end"
                                            >
                                                <ContentCopyIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />

                            <Button
                                variant="contained"
                                size="small"
                                sx={{
                                    textTransform: "none",
                                    whiteSpace: "nowrap",
                                    flex: 1,
                                }}
                                onClick={handleCreateApiKey}
                                color="primary"
                                className="custom-button"
                            >
                                Oluştur
                            </Button>
                        </Grid>
                    </div>
                ),
                showCancelButton: true,
                confirmButtonText: "Güncelle",
                cancelButtonText: "İptal",

                preConfirm: () => {
                    const email = emailRef.current.value;
                    const username = usernameRef.current.value;
                    const password = passwordRef.current.value;
                    const isCanSendFicheToLuca = isCanSendFiche.current;

                    if (
                        !email ||
                        !username ||
                        !password ||
                        isCanSendFicheToLuca === "" ||
                        isCanSendFicheToLuca === null
                    ) {
                        Swal.showValidationMessage(
                            "Lütfen tüm alanları doldurunuz!"
                        );
                        return false;
                    }

                    if (!emailFormatControl(email)) {
                        Swal.showValidationMessage(
                            "Lütfen E-Posta adresinizi doğru giriniz!"
                        );
                        return false;
                    }
                    let data = {
                        email,
                        username,
                        password,
                        subUserId: selectedUsers[0].id,
                        isCanSendFicheToLuca,
                    };
                    return data;
                },
            }).then((result) => {
                if (result.isConfirmed) {
                    updateSubUser(result.value)
                        .then((resp) => {
                            setUpdateRow(true);
                            Swal.fire({
                                title: "Başarılı!",
                                html: resp.data.message,
                                icon: "success",
                                confirmButtonText: "Tamam",
                            });
                        })
                        .catch((err) => {
                            setUpdateRow(true);
                            Swal.fire({
                                title: "Bir hata oluştu!",
                                text: err.response.data.message,
                                icon: "error",
                                confirmButtonText: "Tamam",
                            });
                        });
                }
            });

            handleClose();
        }
    }, [open, handleClose]);

    return null;
};
