/** @format */

import { useEffect } from "react";
import Swal from "sweetalert2";
import { checkTempProcess } from "../../api/company/invoices/checkTempProcess";
import { createFiches } from "../../api/company/invoices/createSelectedFiches";
import moment from "moment";
export const CreateSelectedFiscesSwal = ({
    open,
    handleClose,
    companyId,
    periodId,
    selectedInvoices,
    setUpdateRow,
}) => {
    useEffect(() => {
        if (open) {
            if (selectedInvoices.length === 0) {
                Swal.fire({
                    title: "Uyarı",
                    text: "Lütfen en az 1 fatura seçin.",
                    icon: "error",
                    confirmButtonText: "Tamam",
                });
                handleClose();
                return;
            }

            const selectedData = selectedInvoices.map((detail) => detail.id);
            const uniqueInvoices = [];
            const duplicateInvoices = [];
            for (const invoice of selectedInvoices) {
                if (
                    !uniqueInvoices.find(
                        (item) =>
                            item.number === invoice.number &&
                            item.direction === invoice.direction
                    )
                ) {
                    uniqueInvoices.push({
                        number: invoice.number,
                        direction: invoice.direction,
                    });
                } else {
                    duplicateInvoices.push(invoice);
                }
            }

            if (duplicateInvoices.length > 0) {
                Swal.fire({
                    html: `<b>${selectedData.length}</b> adet fatura da <b style="color:red">${duplicateInvoices.length} adet mükerrer fatura var</b> . Devam etmek istediğinize emin misiniz? `,
                    icon: "warning",
                    denyButtonText: "Mükerrer faturaları göster",
                    showDenyButton: true,
                    confirmButtonText: "Evet, devam et!",
                    focusDeny: true,
                    showCancelButton: true,
                    cancelButtonText: "Vazgeç",
                }).then((result) => {
                    if (result.isConfirmed) {
                        Swal.fire({
                            html: `<b>${selectedData.length}</b> adet fatura Luca aktarımı için fişleştirilecek. Emin misiniz?`,
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonText: "Evet, devam et!",
                            cancelButtonText: "Hayır",
                        }).then(async (result) => {
                            if (result.isConfirmed) {
                                Swal.fire({
                                    title: "Faturaların fişleri oluşturuluyor..",
                                    text: "Lütfen bekleyiniz...",
                                    icon: "info",
                                    showConfirmButton: false,
                                    allowOutsideClick: false,
                                    allowEscapeKey: false,
                                    allowEnterKey: false,
                                    customClass: {
                                        popup: "swal2-content-centered",
                                    },
                                    willOpen: () => {
                                        Swal.showLoading();
                                    },
                                });
                                createFiches(companyId, periodId, selectedData)
                                    .then(async (resp) => {
                                        let flag = true;
                                        while (flag) {
                                            try {
                                                const checkResp =
                                                    await checkTempProcess(
                                                        companyId,
                                                        periodId,
                                                        resp.data.id
                                                    );
                                                flag = checkResp.data?.isActive;
                                                if (
                                                    checkResp.data?.isActive ===
                                                    false
                                                ) {
                                                    if (
                                                        checkResp.data.result
                                                            ?.error
                                                    ) {
                                                        Swal.close();
                                                        Swal.fire({
                                                            html: `<p> ${checkResp.data.result.error} </p>`,
                                                            confirmButtonText:
                                                                checkResp.data.result.error.includes(
                                                                    "kural"
                                                                )
                                                                    ? "Fatura Ayarlarına Git"
                                                                    : "Tamam",
                                                            icon: "error",
                                                            showCancelButton:
                                                                checkResp.data.result.error.includes(
                                                                    "kural"
                                                                ),
                                                            cancelButtonText:
                                                                "Kapat",
                                                        }).then((result) => {
                                                            if (
                                                                result.isConfirmed &&
                                                                checkResp.data.result.error.includes(
                                                                    "kural"
                                                                )
                                                            ) {
                                                                //todo window.location.href = `settings`; /// fatura ayalarına yönlendir.
                                                            }
                                                        });
                                                    } else {
                                                        Swal.close();
                                                        Swal.fire({
                                                            title: `${checkResp.data.result.message}`,
                                                            confirmButtonText:
                                                                "Fişlere Git",
                                                            icon: "success",
                                                            showCancelButton: true,
                                                            cancelButtonText:
                                                                "Kapat",
                                                        }).then((result) => {
                                                            if (
                                                                result.isConfirmed
                                                            ) {
                                                                // window.location.href = `fiches`; //todo sayfa oluşturulunca yönlendirmeyi yap...
                                                            }
                                                        });
                                                        setUpdateRow(true);
                                                    }
                                                }
                                            } catch (err) {
                                                Swal.fire({
                                                    title: "Bir hata oluştu!",
                                                    text: err.response.data
                                                        .message,
                                                    icon: "error",
                                                    confirmButtonText: "Tamam",
                                                });
                                            }
                                            await new Promise((resolve) =>
                                                setTimeout(resolve, 10000)
                                            );
                                        }
                                    })
                                    .catch((err) => {
                                        Swal.fire({
                                            title: "Bir hata oluştu!",
                                            text: err.response.data.message,
                                            icon: "error",
                                            confirmButtonText: "Tamam",
                                        });
                                    });
                            }
                        });
                    } else {
                        if (result.isDenied) {
                            (() => {
                                const duplicateInvoicesListHTML = `
                <html>
                <head>
                  <title>Mükerrer Faturalar</title>
                  <meta charset="UTF-8">
                  <style>
                    table {
                      border-collapse: collapse;
                      width: 100%;
                    }
                    th, td {
                      border: 1px solid #dddddd;
                      text-align: left;
                      padding: 8px;
                    }
                    th {
                      background-color: #f2f2f2;
                    }
                  </style>
                </head>
                <body>
                  <h2>Mükerrer Faturalar</h2>
                  <h3>Asağıdaki tabloda mükerrer faturalar bulunmaktadır. Mükerrer faturaları Fatura No ile Faturalar sayfasından aratabilirsiniz.</h3>
                  <table>
                    <thead>
                      <tr>
                        <th>Fatura Tarihi</th>
                        <th>Fatura Yönü</th>
                        <th>Fatura No</th>
                        <th>Fatura ETTN</th>
                        <th>Fatura Profili</th>
                        <th>Fatura Tipi</th>
                        <th>Gönderici VKN/TCKN</th>
                        <th>Gönderici Unvan</th>
                        <th>Alıcı VKN/TCKN</th>
                        <th>Alıcı Unvan</th>
                        <th>Tutar</th>
                      </tr>
                    </thead>
                    <tbody>
                      ${duplicateInvoices
                          .map(
                              (invoice, index) => `
                        <tr>
                          <td>${moment(invoice.date).format("DD/MM/YYYY")}</td>
                          <td>${
                              invoice.direction === 1 ? "Giden" : "Gelen"
                          }</td>
                          <td>${invoice.number}</td>
                          <td>${invoice.uuid}</td>
                          <td>${invoice.profile_id}</td>
                          <td>${invoice.type_code}</td>
                          <td>${invoice.sender_tax}</td>
                          <td>${invoice.sender_name}</td>
                          <td>${invoice.receiver_tax}</td>
                          <td>${invoice.receiver_name}</td>
                          <td>${invoice.payable_amount}</td>
                        </tr>
                      `
                          )
                          .join("")}
                    </tbody>
                  </table>
                </body>
              </html>
                `;

                                const duplicateInvoicesBlob = new Blob(
                                    [duplicateInvoicesListHTML],
                                    { type: "text/html" }
                                );
                                const duplicateInvoicesUrl =
                                    URL.createObjectURL(duplicateInvoicesBlob);

                                window.open(duplicateInvoicesUrl, "_blank");
                            })();
                        }

                        return;
                    }
                });
            } else {
                Swal.fire({
                    html: `<b>${selectedData.length}</b> adet fatura Luca aktarımı için fişleştirilecek. Emin misiniz?`,
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Evet, devam et!",
                    cancelButtonText: "Hayır",
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        Swal.fire({
                            title: "Faturaların fişleri oluşturuluyor..",
                            text: "Lütfen bekleyiniz...",
                            icon: "info",
                            showConfirmButton: false,
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            allowEnterKey: false,
                            customClass: {
                                popup: "swal2-content-centered",
                            },
                            willOpen: () => {
                                Swal.showLoading();
                            },
                        });
                        createFiches(companyId, periodId, selectedData)
                            .then(async (resp) => {
                                let flag = true;
                                while (flag) {
                                    try {
                                        const checkResp =
                                            await checkTempProcess(
                                                companyId,
                                                periodId,
                                                resp.data.id
                                            );
                                        flag = checkResp.data?.isActive;
                                        if (
                                            checkResp.data?.isActive === false
                                        ) {
                                            if (checkResp.data.result?.error) {
                                                Swal.close();
                                                Swal.fire({
                                                    title: `${checkResp.data.result.error}`,
                                                    confirmButtonText:
                                                        checkResp.data.result.error.includes(
                                                            "kural"
                                                        )
                                                            ? "Fatura Ayarlarına Git"
                                                            : "Tamam",
                                                    icon: "error",
                                                    showCancelButton: true,
                                                    cancelButtonText: "Kapat",
                                                }).then((result) => {
                                                    if (result.isConfirmed) {
                                                        // window.location.href = `settings`;  //todo sayfa oluşturulunca yönlendirmeyi yap...
                                                    }
                                                });
                                            } else {
                                                Swal.close();
                                                setUpdateRow(true);
                                                Swal.fire({
                                                    title: `${checkResp.data.result.message}`,
                                                    confirmButtonText:
                                                        "Fişlere Git",
                                                    icon: "success",
                                                    showCancelButton: true,
                                                    cancelButtonText: "Kapat",
                                                }).then((result) => {
                                                    if (result.isConfirmed) {
                                                        window.location.href = `fiches`; //todo sayfa oluşturulunca yönlendirmeyi yap...
                                                    }
                                                });
                                            }
                                        }
                                    } catch (err) {
                                        Swal.fire({
                                            title: "Bir hata oluştu!",
                                            text: err.response.data.message,
                                            icon: "error",
                                            confirmButtonText: "Tamam",
                                        });
                                    }
                                    await new Promise((resolve) =>
                                        setTimeout(resolve, 10000)
                                    );
                                }
                            })
                            .catch((err) => {
                                Swal.fire({
                                    title: "Bir hata oluştu!",
                                    text: err.response.data.message,
                                    icon: "error",
                                    confirmButtonText: "Tamam",
                                });
                            });
                    }
                });
            }

            handleClose();
        }
    }, [open, handleClose, selectedInvoices]);

    return null;
};
