import axiosInstance from "../../../../utils/maintenanceController/axios";
import moment from "moment";

export const createExcelForInvoicesLines = async (
  companyId,
  periodId,
  startDate,
  endDate,
  direct
) => {
  const startDateFormat = moment(startDate)
    .startOf("day")
    .format("YYYY-MM-DD HH:mm:ss");
  const endDateFormat = moment(endDate)
    .endOf("day")
    .format("YYYY-MM-DD HH:mm:ss");
  return axiosInstance.post(
    `/company/${companyId}/${periodId}/create-excel-for-invoices-lines`,
    {startDate:startDateFormat,endDate:endDateFormat,direction:direct}
  );
};
