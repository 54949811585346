/** @format */

import {
    Dialog,
    DialogContent,
    Grid,
    Typography,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Autocomplete,
    TextField,
    ListItemText,
    Popper,
} from "@mui/material";
import withReactContent from "sweetalert2-react-content";
import { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import moment from "moment";
import { getValueFromServer } from "../../api/company/invoices/getValueFromServer";
import { getLucaAccounts } from "../../api/company/invoices/getLucaAccounts";
import LoadingAnimation from "../../components/loadingAnimation";
import {
    selectedInvoicesDetails,
    fetchInvoice,
} from "../../api/company/invoices/viewSelectedInvoices";
import { insertTableInvoiceRule } from "../../api/company/invoices/insertTableInvoiceRule";
import { showLoadingSwal } from "../../components/loadingSwal";
import { setJsonItem } from "../../utils/localStorageProcess";
import { getCompanyDetails } from "../../api/advisor/getCompanyDetails";
import {
    createBalanceRuleStringForBackend,
    createBusinessRuleStringForBackend,
} from "../../utils/ruleUtils";
import { addRule } from "../../api/company/invoices/addRule";
import { CreateLucaAccountSwal } from "../../components/createLucaAccountSwal";
const MySwal = withReactContent(Swal);
export const ViewSelectedInvoices = ({
    open,
    handleClose,
    companyDetails,
    setCompanyDetails,
    selectedInvoices,
    sortModel,
}) => {
    const loadingRef = useRef(true);
    const [invoices, setInvoices] = useState([]);
    const [invoiceRulesListForSelect, setInvoiceRulesListForSelect] = useState(
        []
    );
    const [accountCodes, setAccountCodes] = useState([]);
    const [subRecordTypes, setSubRecordTypes] = useState([]);
    const [totalOutgoing, setTotalOutgoing] = useState(0);
    const [totalIngoing, setTotalIngoing] = useState(0);
    const [totalsByCurrency, setTotalsByCurrency] = useState(0);

    const handleAddrule = (note, vkn, direction, companyClass, inputType) => {
        let incoming = [];
        let outgoing = [];
        if (companyClass === 2) {
            subRecordTypes.forEach((record) => {
                if (record.direction === 1) {
                    outgoing.push(record);
                } else if (record.direction === 2) {
                    incoming.push(record);
                }
            });
        }
        let ruleData;
        if (companyClass === 1) {
            ruleData = {
                direction: direction === 1 ? "out" : "in",
                field:
                    inputType === "vkn"
                        ? direction === 1
                            ? "invoice.receiver_object.vkn_tckn"
                            : "invoice.sender_object.vkn_tckn"
                        : "line?.name",
                process: inputType === "vkn" ? "==" : "includes",
                input: vkn,
                return: [
                    {
                        hesap_kodu: "",
                        borc: direction === 1 ? "0" : "line.amount",
                        alacak: direction === 1 ? "line.amount" : "0",
                    },
                ],
                note: note,
                subRules: [
                    {
                        field: "line.type",
                        process: "==",
                        input: "taxable",
                    },
                    {
                        field: "line.code",
                        process: "==",
                        input: "0015",
                    },
                ],
            };
        } else {
            ruleData = {
                direction: direction === 1 ? 1 : 2,
                field:
                    inputType === "vkn"
                        ? direction === 1
                            ? "invoice.receiver_tax"
                            : "invoice.sender_tax"
                        : "line.name",
                process: inputType === "vkn" ? "==" : "includes",
                input: vkn,
                return: "",
                note: note,
            };
        }

        MySwal.fire({
            title:
                companyClass === 1
                    ? "Hesap Kodu Seçiniz..."
                    : "Kayıt Alt Türü Seçiniz",
            icon: "warning",
            showConfirmButton: false,
            showCancelButton: false,
            html: (
                <div style={{ width: "100%", padding: "10px 0" }}>
                    {" "}
                    <Autocomplete
                        options={
                            companyClass === 1
                                ? accountCodes
                                : direction === 2
                                ? incoming
                                : outgoing
                        }
                        filterOptions={(options, params) => {
                            if (companyClass !== 1) {
                                const filtered = options.filter((option) => {
                                    let label = `${option.label}`;
                                    return label
                                        .toLocaleLowerCase("tr")
                                        .includes(
                                            params?.inputValue?.toLocaleLowerCase(
                                                "tr"
                                            )
                                        );
                                });
                                return filtered;
                            }
                            const filtered = options.filter((option) => {
                                let label = `${option.hesap_kodu} - ${option.hesap_adi}`;
                                return label
                                    .toLocaleLowerCase("tr")
                                    .includes(
                                        params?.inputValue?.toLocaleLowerCase(
                                            "tr"
                                        )
                                    );
                            });
                            // Girilen metin, mevcut seçenekler arasında yoksa özel bir seçenek ekle
                            if (filtered?.length === 0) {
                                filtered.push({
                                    label: "Yeni Hesap Kodu Eklemek İçin Tıklayın",
                                    value: "add-new", // Özel seçenek için belirleyici bir value
                                });
                            }

                            return filtered;
                        }}
                        onChange={async (event, newValue) => {
                            if (newValue?.value === "add-new") {
                                let newAccount = await CreateLucaAccountSwal(
                                    companyDetails?.companyId,
                                    companyDetails?.periodId
                                );
                                setAccountCodes((prevItems) => [
                                    ...prevItems,
                                    {
                                        id: Math.floor(
                                            100000 + Math.random() * 900000
                                        ).toString(),
                                        hesap_adi: newAccount?.input1,
                                        hesap_kodu: newAccount?.input2,
                                    },
                                ]);
                            } else {
                                // Seçeneklerden biri seçildiyse işlemleri yap
                                companyClass === 1
                                    ? (ruleData.return[0].hesap_kodu =
                                          newValue?.hesap_kodu ?? null)
                                    : (ruleData.return =
                                          newValue?.giderKayitTuruKodu &&
                                          newValue?.value
                                              ? `${newValue.giderKayitTuruKodu}-${newValue.value}`
                                              : null);
                            }
                        }}
                        noOptionsText={"Seçenek bulunamadı"}
                        onKeyDown={(event) => {
                            if (event.key === "Enter") {
                                // Eğer liste açıksa ve bir seçenek vurgulanmışsa, onu seç
                                const highlightedOption =
                                    event.target.querySelector(
                                        'li[data-focus="true"]'
                                    );
                                if (highlightedOption) {
                                    highlightedOption.click();
                                }
                            }
                        }}
                        autoHighlight
                        selectOnFocus
                        blurOnSelect={false}
                        openOnFocus
                        disablePortal
                        ListboxProps={{ className: "specialListBox" }}
                        getOptionLabel={(option) =>
                            companyClass === 1
                                ? `${option.hesap_kodu} - ${option.hesap_adi}`
                                : `${option.label}`
                        }
                        renderOption={(props, item) => (
                            <li
                                {...props}
                                key={
                                    item.key ||
                                    item.value ||
                                    item.hesap_kodu ||
                                    item.hesap_adi
                                }
                            >
                                <ListItemText>
                                    {item.label ||
                                        `${item.hesap_kodu} - ${item.hesap_adi}`}
                                </ListItemText>
                            </li>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Seçiniz"
                                variant="outlined"
                                size="small"
                            />
                        )}
                        style={{ width: "100%" }}
                    />
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: 10,
                            gap: 10,
                        }}
                    >
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor:
                                    "#15356d" /* Onay butonunun rengini mavi yapar */,
                                color: "#ffffff",
                                "&:hover": {
                                    backgroundColor: "#112d5e",
                                },
                            }}
                            onClick={() => {
                                MySwal.clickConfirm();
                            }}
                            className="swal2-confirm swal2-styled"
                        >
                            Devam
                        </Button>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: "#d33",
                                color: "#ffffff",
                                "&:hover": {
                                    backgroundColor: "#a12929",
                                },
                            }}
                            onClick={() => MySwal.clickCancel()}
                            className="swal2-cancel swal2-styled"
                        >
                            İptal
                        </Button>
                    </div>
                </div>
            ),
            customClass: {
                popup: "swal2-custom-autocomplete",
            },
            didOpen: () => {
                document.querySelector(".swal2-container").style.zIndex =
                    "1500";
            },
            preConfirm: () => {
                if (companyClass === 1) {
                    if (ruleData?.return[0]?.hesap_kodu) {
                        return ruleData;
                    } else {
                        Swal.showValidationMessage("Lütfen bir seçim yapın.");
                    }
                } else {
                    if (ruleData?.return) {
                        return ruleData;
                    } else {
                        Swal.showValidationMessage("Lütfen bir seçim yapın.");
                    }
                }
            },
        }).then(async (result) => {
            if (result.isConfirmed) {
                let formatData;
                if (companyClass === 2) {
                    formatData = await createBusinessRuleStringForBackend(
                        ruleData.direction,
                        ruleData.note,
                        ruleData.process,
                        ruleData.field,
                        ruleData.input,
                        ruleData.return,
                        []
                    );
                    showLoadingSwal();
                } else if (companyClass === 1) {
                    formatData = await createBalanceRuleStringForBackend(
                        ruleData.direction,
                        ruleData.process,
                        ruleData.field,
                        ruleData.input,
                        ruleData.subRules,
                        ruleData.note
                    );
                    formatData = {
                        ...formatData, // Mevcut objenin tüm özelliklerini korur
                        return: ruleData.return, // Güncellenmiş return dizisini ekler
                    };

                    showLoadingSwal();
                }
                addRule(
                    companyDetails?.companyId,
                    companyDetails?.periodId,
                    formatData
                )
                    .then((resp) => {
                        Swal.fire({
                            title: "Başarılı",
                            text: resp.data.message,
                            icon: "success",
                            confirmButtonText: "Tamam",
                            didOpen: () => {
                                const swalPopup =
                                    document.querySelector(".swal2-container");
                                if (swalPopup) {
                                    swalPopup.style.zIndex = "9999";
                                }
                            },
                        });
                    })
                    .catch((err) => {
                        Swal.fire({
                            title: "Bir hata oluştu!",
                            text: err.response.data.message
                                ? err.response.data.message
                                : "Hata Oluştu",
                            icon: "error",
                            confirmButtonText: "Tamam",
                            didOpen: () => {
                                const swalPopup =
                                    document.querySelector(".swal2-container");
                                if (swalPopup) {
                                    swalPopup.style.zIndex = "9999";
                                }
                            },
                        });
                    });
            }
        });
    };

    async function getValueFromServerForLucaAccounts() {
        if (accountCodes === undefined || accountCodes.length === 0) {
            await getLucaAccounts(
                companyDetails?.companyId,
                companyDetails?.periodId
            )
                .then((resp) => {
                    resp.data.list.push({
                        hesap_adi: "Özel Kural Ekle",
                        hesap_kodu: "special",
                    });
                    setAccountCodes(resp.data.list);
                    if (resp.data.message !== "") {
                        Swal.fire({
                        icon: "warning",
                        title: "Uyarı",
                        html: resp.data.message,
                        confirmButtonText: "Tamam",
                        })
                    }
                })
                .catch((err) => {});
        }
    }

    async function getValueFromServerFunction() {
        if (subRecordTypes === undefined || subRecordTypes.length === 0) {
            let subRecordTypesData = await getValueFromServer(
                companyDetails?.companyId,
                companyDetails?.periodId
            );
            setSubRecordTypes(subRecordTypesData);
        }
    }
    async function insertInvoiceRulesForAddRuleFromInvoices(
        vkn_tckn,
        id,
        name
    ) {
        Swal.fire({
            title: "Uyarı",
            html: `"${name}" kuralına ${vkn_tckn} VKN/TCKN bilgisi eklenecek. Devam etmek istiyor musunuz?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Evet",
            cancelButtonText: "Vazgeç",
            focusCancel: true,
            didOpen: () => {
                document.head.insertAdjacentHTML(
                    "beforeend",
                    "<style>.swal2-container { z-index: 2000 !important; }</style>"
                );
            },
        }).then((result) => {
            if (result.isConfirmed) {
                showLoadingSwal();
                insertTableInvoiceRule(
                    companyDetails?.companyId,
                    companyDetails?.periodId,
                    id,
                    vkn_tckn
                )
                    .then((resp) => {
                        if (resp) {
                            Swal.fire({
                                title: "Bilgilendirme",
                                text: `"${name}" kuralına ${vkn_tckn} VKN/TCKN bilgisi eklendi.`,
                                icon: "success",
                                confirmButtonText: "Tamam",
                            });
                            getCompanyDetails(
                                companyDetails?.companyId,
                                companyDetails?.periodId
                            )
                                .then(async (resp) => {
                                    await setJsonItem(
                                        `#${companyDetails?.companyId}/${companyDetails?.periodId}`,
                                        resp.data
                                    );
                                    setCompanyDetails(resp.data);
                                })
                                .catch((err) => {
                                    console.log(err);
                                });
                        }
                    })
                    .catch((err) => {
                        Swal.fire({
                            title: "Hata",
                            text: err.response.data.message || "",
                            icon: "error",
                            didOpen: () => {
                                const swalPopup =
                                    document.querySelector(".swal2-container");
                                if (swalPopup) {
                                    swalPopup.style.zIndex = "9999999";
                                }
                            },
                            confirmButtonText: "Tamam",
                        });
                    });
            }
        });
    }

    async function showOriginalInvoice(id, source) {
        Swal.fire({
            title: "Fatura Yükleniyor",
            text: "Lütfen bekleyiniz",
            icon: "info",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            customClass: {
                popup: "swal2-content-centered",
            },
            willOpen: () => {
                Swal.showLoading();
            },
            didOpen: () => {
                const swalPopup = document.querySelector(".swal2-container");
                if (swalPopup) {
                    swalPopup.style.zIndex = "9999";
                }
            },
        });
        fetchInvoice(
            companyDetails?.companyId,
            companyDetails?.periodId,
            id,
            source
        )
            .then((resp) => {
                if (resp.data) {
                    try {
                        if (
                            source === "birfatura" ||
                            source === "turkcell" ||
                            source === "hepsiburadaefaturam"||source === "mysoft"
                        ) {
                            // Handle base64 PDF data
                            const base64Data = resp.data.base64; // Assume `data.base64` contains the base64-encoded PDF data
                            const binaryString = window.atob(base64Data); // Decode base64 to binary string
                            const binaryLen = binaryString.length;
                            const bytes = new Uint8Array(binaryLen);
                            for (let i = 0; i < binaryLen; i++) {
                                bytes[i] = binaryString.charCodeAt(i);
                            }
                            const blob = new Blob([bytes], {
                                type: "application/pdf",
                            });
                            const url = window.URL.createObjectURL(blob);
                            window.open(url, "_blank");
                        } else {
                            // Handle HTML data
                            let parser = new DOMParser();
                            let xmlDoc, xslDoc;
                            let xml = resp.data.modifiedXmlData;
                            let xslt = resp.data.xsltContent;
                            if (typeof xml == "string") {
                                xmlDoc = parser.parseFromString(
                                    xml,
                                    "application/xml"
                                );
                            } else {
                                xmlDoc = xml;
                            }
                            if (typeof xslt == "string") {
                                xslDoc = parser.parseFromString(
                                    xslt,
                                    "application/xml"
                                );
                            } else {
                                xslDoc = xslt;
                            }
                            let processor = new XSLTProcessor();
                            processor.importStylesheet(xslDoc);
                            let result = processor.transformToDocument(xmlDoc);
                            const blob = new Blob(
                                [
                                    new XMLSerializer().serializeToString(
                                        result.doctype
                                    ),
                                    result.documentElement.innerHTML,
                                ],
                                {
                                    type: "text/html",
                                }
                            );
                            const url = window.URL.createObjectURL(blob);
                            window.open(url, "_blank");
                        }
                        Swal.close();
                    } catch (error) {
                        console.error(error);
                        Swal.fire({
                            title: "Hata",
                            icon: "error",
                            text: `${
                                error.responseJSON?.error
                                    ? JSON.stringify(error.responseJSON.error)
                                    : JSON.stringify(error)
                            }`,
                            didOpen: () => {
                                const swalPopup =
                                    document.querySelector(".swal2-container");
                                if (swalPopup) {
                                    swalPopup.style.zIndex = "9999999";
                                }
                            },
                            confirmButtonText: "Tamam",
                        });
                    }
                }
            })
            .catch((err) => {
                Swal.close();
                Swal.fire({
                    title: "Bir hata oluştu!",
                    text: err.response.data.message,
                    icon: "error",
                    confirmButtonText: "Tamam",
                    didOpen: () => {
                        const swalPopup =
                            document.querySelector(".swal2-container");
                        if (swalPopup) {
                            swalPopup.style.zIndex = "9999999";
                        }
                    },
                });
            });
    }

    useEffect(() => {
        loadingRef.current = true;
        if (open) {
            let invoiceRulesList;
            invoiceRulesList = companyDetails?.invoiceRules.filter(
                (item) => item?.type === "tableRules"
            );
            setInvoiceRulesListForSelect(invoiceRulesList);
            if (selectedInvoices.length === 0) {
                Swal.fire({
                    title: "Uyarı",
                    text: "Lütfen en az 1 fatura seçin.",
                    icon: "error",
                    confirmButtonText: "Tamam",
                });
                handleClose();
                return;
            }
            const selectedIds = selectedInvoices.map((detail) => detail.id);

            const tasks = [
                selectedInvoicesDetails(
                    companyDetails?.companyId,
                    companyDetails?.periodId,
                    selectedIds
                ),
            ];

            if (companyDetails?.class === 1) {
                tasks.push(getValueFromServerForLucaAccounts());
            } else if (companyDetails?.class === 2) {
                tasks.push(getValueFromServerFunction());
            }

            let totalOutgoing = 0;
            let totalIngoing = 0;

            selectedInvoices?.forEach((invoice) => {
                if (invoice.direction === 1) {
                    totalOutgoing += 1;
                } else if (invoice.direction === 2) {
                    totalIngoing += 1;
                }
            });

            setTotalIngoing(totalIngoing);
            setTotalOutgoing(totalOutgoing);
            Promise.all(tasks)
                .then(([invoiceResponse]) => {
                    Swal.close();
                    const totalsByCurrencies = {
                        incoming: {},
                        outgoing: {},
                    };

                    invoiceResponse.data?.forEach((data) => {
                        const invoiceData = JSON.parse(data.data);
                        const currencyCode = invoiceData.currency_code;
                        const direction =
                            data.direction === 1 ? "outgoing" : "incoming";

                        // Eğer para birimi için bir giriş yoksa, onu oluşturun ve sıfırla başlayın
                        if (!totalsByCurrencies[direction][currencyCode]) {
                            totalsByCurrencies[direction][currencyCode] = {
                                taxAmount: 0,
                                amount: 0,
                                taxExclusive: 0,
                                kdvTaxAmount: 0,
                            };
                        }
                        // İlgili para birimi için toplamları güncelleyin
                        totalsByCurrencies[direction][
                            currencyCode
                        ].kdvTaxAmount += invoiceData?.tax_subtotals?.reduce(
                            (acc, item) => {
                                if (item.code === "0015") {
                                    return acc + (item.amount || 0);
                                }
                                return acc;
                            },
                            0
                        ); // Başlangıç değeri 0 olmalı

                        totalsByCurrencies[direction][currencyCode].taxAmount +=
                            Number(invoiceData.tax_total) || 0;
                        totalsByCurrencies[direction][currencyCode].amount +=
                            Number(invoiceData.payable_amount) || 0;
                        totalsByCurrencies[direction][
                            currencyCode
                        ].taxExclusive +=
                            Number(invoiceData.tax_exclusive) || 0;
                    });
                    let sortedData = [];
                    if (sortModel.length === 0 || sortModel === undefined) {
                        sortedData = invoiceResponse.data;
                    } else {
                        sortedData = invoiceResponse.data.sort((a, b) => {
                            const parsedDataA =
                                typeof a.data === "string"
                                    ? JSON.parse(a.data)
                                    : a.data;
                            const parsedDataB =
                                typeof b.data === "string"
                                    ? JSON.parse(b.data)
                                    : b.data;

                            const valueA = parsedDataA[sortModel[0].colId];
                            const valueB = parsedDataB[sortModel[0].colId];

                            // Eğer değerlerden biri undefined veya null ise
                            if (valueA == null && valueB == null) return 0;
                            if (valueA == null)
                                return sortModel[0].sort === "asc" ? -1 : 1;
                            if (valueB == null)
                                return sortModel[0].sort === "asc" ? 1 : -1;

                            // Tarih karşılaştırması
                            if (
                                sortModel[0].colId === "issue_datetime" ||
                                sortModel[0].colId === "envelope_datetime"
                            ) {
                                const dateA = new Date(valueA);
                                const dateB = new Date(valueB);
                                return sortModel[0].sort === "asc"
                                    ? dateA - dateB
                                    : dateB - dateA;
                            }

                            // Sayısal karşılaştırma
                            if (
                                typeof valueA === "number" &&
                                typeof valueB === "number"
                            ) {
                                return sortModel[0].sort === "asc"
                                    ? valueA - valueB
                                    : valueB - valueA;
                            }

                            // String karşılaştırması
                            if (
                                typeof valueA === "string" &&
                                typeof valueB === "string"
                            ) {
                                return sortModel[0].sort === "asc"
                                    ? valueA.localeCompare(valueB)
                                    : valueB.localeCompare(valueA);
                            }

                            // Diğer durumlar için basit karşılaştırma
                            return sortModel[0].sort === "asc"
                                ? valueA > valueB
                                    ? 1
                                    : -1
                                : valueB > valueA
                                ? 1
                                : -1;
                        });
                    }
                    setTotalsByCurrency(totalsByCurrencies);
                    setInvoices(sortedData);
                    loadingRef.current = false;
                })
                .catch((err) => {
                    handleClose();
                    Swal.close();
                    console.log(err);
                    loadingRef.current = false;
                    Swal.fire({
                        title: "Hata",
                        text: "Veriler yüklenirken bir hata oluştu.",
                        icon: "error",
                        confirmButtonText: "Tamam",
                        didOpen: () => {
                            const swalPopup =
                                document.querySelector(".swal2-container");
                            if (swalPopup) {
                                swalPopup.style.zIndex = "9999999";
                            }
                        },
                    });
                });
        }
    }, [open, handleClose, selectedInvoices]);

    const closeModal = () => {
        // loading state'ini tekrar true yap
        setInvoices([]);
        handleClose();
        loadingRef.current = false;
    };

    return (
        <Dialog
            open={open}
            onClose={closeModal}
            fullWidth
            maxWidth="lg"
            disableEnforceFocus
        >
            <Button
                onClick={handleClose}
                sx={{
                    borderRadius: 2,
                    position: "absolute",
                    top: 4,
                    right: 4,
                    backgroundColor: "whitesmoke",
                    color: "gray",
                    boxShadow: 4,
                    minWidth: "0px",
                    width: "30px",
                    height: "30px",
                    border: "2px solid transparent",
                    "&:hover": {
                        backgroundColor: "#e7eaeb",
                    },
                    zIndex: 1900,
                }}
            >
                X
            </Button>
            <DialogContent>
                {loadingRef.current ? (
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            minHeight: "300px",
                        }}
                    >
                        <b>
                            <p style={{ marginBottom: "6%", fontSize: "22px" }}>
                                {selectedInvoices?.length >= 2
                                    ? "Faturalar "
                                    : "Fatura "}
                                Yükleniyor
                            </p>
                        </b>

                        <LoadingAnimation />
                        <p style={{ fontSize: "18px", marginTop: "3%" }}>
                            {" "}
                            Lütfen Bekleyiniz...
                        </p>
                    </div>
                ) : (
                    <>
                        <div
                            style={{
                                display:
                                    !invoices || invoices.length < 2
                                        ? "none"
                                        : "",
                            }}
                        >
                            <TableContainer component={Paper} sx={{ mb: 3 }}>
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell style={{ width: "55%" }}>
                                                Giden fatura sayısı
                                            </TableCell>
                                            <TableCell>
                                                {totalOutgoing}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ width: "55%" }}>
                                                Gelen fatura sayısı
                                            </TableCell>
                                            <TableCell>
                                                {totalIngoing}
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <TableContainer component={Paper} sx={{ mb: 3 }}>
                                <Table>
                                    <TableBody>
                                        {Object.keys(totalsByCurrency.incoming)
                                            .concat(
                                                Object.keys(
                                                    totalsByCurrency.outgoing
                                                )
                                            )
                                            .filter(
                                                (value, index, self) =>
                                                    self.indexOf(value) ===
                                                    index
                                            )
                                            .map((currencyCode) => {
                                                // Gelen ve giden toplamları elde et
                                                const incomingTotals =
                                                    totalsByCurrency.incoming[
                                                        currencyCode
                                                    ] || {
                                                        taxAmount: 0,
                                                        amount: 0,
                                                        taxExclusive: 0,
                                                        kdvTaxAmount: 0,
                                                    };
                                                const outgoingTotals =
                                                    totalsByCurrency.outgoing[
                                                        currencyCode
                                                    ] || {
                                                        taxAmount: 0,
                                                        amount: 0,
                                                        taxExclusive: 0,
                                                        kdvTaxAmount: 0,
                                                    };

                                                return (
                                                    <TableContainer
                                                        component={Paper}
                                                        sx={{ mb: 3 }}
                                                        key={currencyCode}
                                                    >
                                                        <Table>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell
                                                                        style={{
                                                                            width: "40%",
                                                                        }}
                                                                    >
                                                                        Para
                                                                        Birimi ={" "}
                                                                        {
                                                                            currencyCode
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell
                                                                        style={{
                                                                            width: "30%",
                                                                        }}
                                                                    >
                                                                        Gelen
                                                                    </TableCell>
                                                                    <TableCell
                                                                        style={{
                                                                            width: "30%",
                                                                        }}
                                                                    >
                                                                        Giden
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableHead>

                                                            <TableRow>
                                                                <TableCell
                                                                    style={{
                                                                        width: "40%",
                                                                    }}
                                                                >
                                                                    Toplam Mal
                                                                    Hizmet
                                                                    Tutarı
                                                                </TableCell>
                                                                <TableCell>
                                                                    {Intl.NumberFormat(
                                                                        "tr-TR",
                                                                        {
                                                                            style: "currency",
                                                                            currency:
                                                                                currencyCode,
                                                                        }
                                                                    ).format(
                                                                        incomingTotals?.taxExclusive?.toFixed(
                                                                            2
                                                                        )
                                                                    )}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {Intl.NumberFormat(
                                                                        "tr-TR",
                                                                        {
                                                                            style: "currency",
                                                                            currency:
                                                                                currencyCode,
                                                                        }
                                                                    ).format(
                                                                        outgoingTotals.taxExclusive.toFixed(
                                                                            2
                                                                        )
                                                                    )}
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell
                                                                    style={{
                                                                        width: "40%",
                                                                    }}
                                                                >
                                                                    {" "}
                                                                    Toplam Vergi
                                                                    Tutarı
                                                                </TableCell>
                                                                <TableCell>
                                                                    {Intl.NumberFormat(
                                                                        "tr-TR",
                                                                        {
                                                                            style: "currency",
                                                                            currency:
                                                                                currencyCode,
                                                                        }
                                                                    ).format(
                                                                        incomingTotals.taxAmount.toFixed(
                                                                            2
                                                                        )
                                                                    )}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {Intl.NumberFormat(
                                                                        "tr-TR",
                                                                        {
                                                                            style: "currency",
                                                                            currency:
                                                                                currencyCode,
                                                                        }
                                                                    ).format(
                                                                        outgoingTotals.taxAmount.toFixed(
                                                                            2
                                                                        )
                                                                    )}
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell
                                                                    style={{
                                                                        width: "40%",
                                                                    }}
                                                                >
                                                                    {" "}
                                                                    Toplam KDV
                                                                    Tutarı
                                                                </TableCell>
                                                                <TableCell>
                                                                    {Intl.NumberFormat(
                                                                        "tr-TR",
                                                                        {
                                                                            style: "currency",
                                                                            currency:
                                                                                currencyCode,
                                                                        }
                                                                    ).format(
                                                                        incomingTotals.kdvTaxAmount.toFixed(
                                                                            2
                                                                        )
                                                                    )}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {Intl.NumberFormat(
                                                                        "tr-TR",
                                                                        {
                                                                            style: "currency",
                                                                            currency:
                                                                                currencyCode,
                                                                        }
                                                                    ).format(
                                                                        outgoingTotals.kdvTaxAmount.toFixed(
                                                                            2
                                                                        )
                                                                    )}
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell
                                                                    style={{
                                                                        width: "40%",
                                                                    }}
                                                                >
                                                                    {" "}
                                                                    Toplam
                                                                    Ödenecek
                                                                    Tutar
                                                                </TableCell>
                                                                <TableCell>
                                                                    {Intl.NumberFormat(
                                                                        "tr-TR",
                                                                        {
                                                                            style: "currency",
                                                                            currency:
                                                                                currencyCode,
                                                                        }
                                                                    ).format(
                                                                        incomingTotals.amount.toFixed(
                                                                            2
                                                                        )
                                                                    )}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {Intl.NumberFormat(
                                                                        "tr-TR",
                                                                        {
                                                                            style: "currency",
                                                                            currency:
                                                                                currencyCode,
                                                                        }
                                                                    ).format(
                                                                        outgoingTotals.amount.toFixed(
                                                                            2
                                                                        )
                                                                    )}
                                                                </TableCell>
                                                            </TableRow>
                                                        </Table>
                                                    </TableContainer>
                                                );
                                            })}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <hr />
                        </div>
                        {invoices?.map((data, index) => {
                            const invoiceData = JSON.parse(data.data);
                            console.log(invoiceData);
                            const separatedData = JSON.parse(
                                data.separatedData
                            );

                            return (
                                <div key={data.id}>
                                    {index !== 0 && (
                                        <hr
                                            style={{
                                                border: "2px solid #04173736",
                                                marginBottom: "3%",
                                                marginTop: "3%",
                                            }}
                                            className="mt-5 mb-5"
                                        />
                                    )}
                                    <Grid
                                        container
                                        spacing={3}
                                        className="mb-3"
                                    >
                                        <Grid
                                            item
                                            md={5}
                                            className="view-invoices"
                                        >
                                            <Typography variant="h6">
                                                Gönderici Bilgileri
                                            </Typography>
                                            <ul>
                                                <li>
                                                    Gönderici Adı:{" "}
                                                    {data.senderName}
                                                </li>
                                                <li>
                                                    Gönderici{" "}
                                                    {data.senderTax.length ===
                                                    11
                                                        ? "TCKN"
                                                        : "Vergi No"}
                                                    : {data.senderTax}
                                                </li>
                                                <li>
                                                    Gönderici Adresi:{" "}
                                                    {invoiceData?.sender_object?.address
                                                        ?.replace(
                                                            /undefined/g,
                                                            ""
                                                        )
                                                        .replace(/null/g, "") ||
                                                        "-"}
                                                </li>
                                                {data.direction === 2 && (
                                                    <Button
                                                        variant="contained"
                                                        sx={{
                                                            lineHeight: "16px",
                                                            fontSize: "13px",
                                                            backgroundColor:
                                                                "#28c76f",
                                                            textTransform:
                                                                "none",
                                                            mt: 1,
                                                            "&:hover": {
                                                                backgroundColor:
                                                                    "#21a55c",
                                                            },
                                                        }}
                                                        size="small"
                                                        onClick={() =>
                                                            handleAddrule(
                                                                data.senderName,
                                                                data.senderTax,
                                                                data.direction,
                                                                companyDetails?.class,
                                                                "vkn"
                                                            )
                                                        }
                                                    >
                                                        VKN İle Kural Ekle
                                                    </Button>
                                                )}
                                            </ul>

                                            <Typography variant="h6">
                                                Alıcı Bilgileri
                                            </Typography>
                                            <ul>
                                                <li>
                                                    Alıcı Adı:{" "}
                                                    {data.receiverName}
                                                </li>
                                                <li>
                                                    Alıcı{" "}
                                                    {data.receiverTax.length ===
                                                    11
                                                        ? "TCKN"
                                                        : "Vergi No"}
                                                    : {data.receiverTax}
                                                </li>
                                                <li>
                                                    Alıcı Adresi:{" "}
                                                    {invoiceData?.receiver_object?.address
                                                        ?.replace(
                                                            /undefined/g,
                                                            ""
                                                        )
                                                        .replace(/null/g, "") ||
                                                        "-"}
                                                </li>
                                                {data.direction === 1 && (
                                                    <Button
                                                        variant="contained"
                                                        sx={{
                                                            lineHeight: "16px",
                                                            fontSize: "13px",
                                                            backgroundColor:
                                                                "#28c76f",
                                                            textTransform:
                                                                "none",
                                                            mt: 1,
                                                            "&:hover": {
                                                                backgroundColor:
                                                                    "#21a55c",
                                                            },
                                                        }}
                                                        size="small"
                                                        onClick={() =>
                                                            handleAddrule(
                                                                data.receiverName,
                                                                data.receiverTax,
                                                                data.direction,
                                                                companyDetails?.class,
                                                                "vkn"
                                                            )
                                                        }
                                                    >
                                                        VKN İle Kural Ekle
                                                    </Button>
                                                )}
                                            </ul>

                                            <Typography variant="body1">
                                                <b>ETTN: {data.uuid}</b>
                                            </Typography>
                                        </Grid>

                                        <Grid
                                            item
                                            md={2}
                                            className="view-invoices"
                                        >
                                            <Typography variant="h6">
                                                {data.profile}
                                            </Typography>
                                            <Button
                                                variant="contained"
                                                size="small"
                                                sx={{
                                                    lineHeight: "16px",
                                                    fontSize: "13px",
                                                    backgroundColor: "#ff9f43",
                                                    textTransform: "none",
                                                    "&:hover": {
                                                        backgroundColor:
                                                            "#eb913e",
                                                    },
                                                    display:
                                                        (data.profile ===
                                                            "EARSIVFATURA" &&
                                                            data.direction ===
                                                                2) ||
                                                        !data.source
                                                            ? "none"
                                                            : "",
                                                    mb: 2,
                                                }}
                                                onClick={() =>
                                                    showOriginalInvoice(
                                                        data.id,
                                                        data.source
                                                    )
                                                }
                                            >
                                                {" "}
                                                Faturanın Orjinalini Göster
                                            </Button>
                                            {companyDetails?.class === 1 && (
                                                <Autocomplete
                                                    fullWidth
                                                    size="small"
                                                    disableClearable
                                                    clearIcon={null}
                                                    PopperComponent={(
                                                        props
                                                    ) => (
                                                        <Popper
                                                            {...props}
                                                            disablePortal
                                                        />
                                                    )}
                                                    defaultValue={
                                                        invoiceRulesListForSelect
                                                            .filter((item) =>
                                                                item.condition.includes(
                                                                    data.direction ===
                                                                        1
                                                                        ? 'invoice.direction == "out"'
                                                                        : 'invoice.direction == "in"'
                                                                )
                                                            )
                                                            .find((item) =>
                                                                item.condition.includes(
                                                                    data.direction ===
                                                                        1
                                                                        ? data.receiverTax
                                                                        : data.senderTax
                                                                )
                                                            ) || null
                                                    }
                                                    onChange={(
                                                        event,
                                                        newValue
                                                    ) => {
                                                        insertInvoiceRulesForAddRuleFromInvoices(
                                                            data.direction === 1
                                                                ? data.receiverTax
                                                                : data.senderTax,
                                                            newValue?.id,
                                                            newValue?.note
                                                        );
                                                    }}
                                                    options={companyDetails?.invoiceRules
                                                        .filter(
                                                            (item) =>
                                                                item?.type ===
                                                                "tableRules"
                                                        )
                                                        .filter((item) =>
                                                            item.condition.includes(
                                                                data.direction ===
                                                                    1
                                                                    ? 'invoice.direction == "out"'
                                                                    : 'invoice.direction == "in"'
                                                            )
                                                        )}
                                                    getOptionLabel={(option) =>
                                                        option
                                                            ? `${option.note}`
                                                            : ""
                                                    }
                                                    noOptionsText={
                                                        "Seçenek bulunamadı"
                                                    }
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Seçiniz"
                                                            size="small"
                                                            variant="outlined"
                                                        />
                                                    )}
                                                />
                                            )}
                                        </Grid>

                                        <Grid
                                            item
                                            md={5}
                                            className="view-invoices"
                                        >
                                            {companyDetails?.class === 1 && (
                                                <>
                                                    <Typography variant="h6">
                                                        Muhasebe Hesapları
                                                    </Typography>
                                                    <TableContainer
                                                        component={Paper}
                                                    >
                                                        <Table>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell>
                                                                        Hesap
                                                                        Kodu
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        Borç
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        Alacak
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {separatedData?.map(
                                                                    (item) => {
                                                                        let hesap =
                                                                            accountCodes?.find(
                                                                                (
                                                                                    code
                                                                                ) =>
                                                                                    code.hesap_kodu ==
                                                                                    item.hesap_kodu
                                                                            );
                                                                        let hesapAdi =
                                                                            hesap?.hesap_adi;
                                                                        if (
                                                                            hesapAdi &&
                                                                            hesapAdi.length >
                                                                                30
                                                                        ) {
                                                                            hesapAdi = `- ${hesapAdi.slice(
                                                                                0,
                                                                                30
                                                                            )}...`;
                                                                        } else if (
                                                                            hesapAdi
                                                                        ) {
                                                                            hesapAdi = `- ${hesapAdi}`;
                                                                        } else {
                                                                            hesapAdi =
                                                                                "";
                                                                        }
                                                                        return (
                                                                            <TableRow
                                                                                key={
                                                                                    item.hesap_kodu
                                                                                }
                                                                            >
                                                                                <TableCell>
                                                                                    {
                                                                                        item.hesap_kodu
                                                                                    }{" "}
                                                                                    {
                                                                                        hesapAdi
                                                                                    }
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    {
                                                                                        item.borc
                                                                                    }
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    {
                                                                                        item.alacak
                                                                                    }
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        );
                                                                    }
                                                                )}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </>
                                            )}
                                            <Grid
                                                sx={{
                                                    mt:
                                                        companyDetails?.class ===
                                                        2
                                                            ? "10%"
                                                            : "2%",
                                                }}
                                            >
                                                <Typography
                                                    variant="h6"
                                                    className="mt-3"
                                                >
                                                    Genel Bilgiler
                                                </Typography>
                                                <ul>
                                                    <li>
                                                        Fatura Tarihi:{" "}
                                                        {moment(
                                                            data.invoiceDate
                                                        ).format(
                                                            "DD-MM-YYYY hh:mm"
                                                        )}
                                                    </li>
                                                    <li>
                                                        Fatura No:{" "}
                                                        {invoiceData.number}
                                                    </li>
                                                    <li>
                                                        Fatura Tipi: {data.type}
                                                    </li>
                                                    <li>
                                                        Fatura Senaryosu:{" "}
                                                        {data.profile}
                                                    </li>
                                                    <li>
                                                        Fatura Yönü:{" "}
                                                        {data.direction === 1
                                                            ? "Giden"
                                                            : "Gelen"}
                                                    </li>
                                                </ul>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={3} sx={{ mb: 3 }}>
                                        <Grid item xs={12}>
                                            <Typography variant="h6">
                                                Fatura Satırları
                                            </Typography>
                                            <div
                                                style={{
                                                    maxHeight: 300,
                                                    overflowY: "auto",
                                                }}
                                            >
                                                <TableContainer
                                                    component={Paper}
                                                >
                                                    <Table>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>
                                                                    Ürün Adı
                                                                </TableCell>
                                                                <TableCell>
                                                                    Miktar
                                                                </TableCell>
                                                                <TableCell>
                                                                    Birim
                                                                </TableCell>
                                                                <TableCell>
                                                                    Birim Fiyat
                                                                </TableCell>
                                                                <TableCell>
                                                                    KDV Oranı
                                                                </TableCell>
                                                                <TableCell>
                                                                    KDV Tutarı
                                                                </TableCell>
                                                                <TableCell>
                                                                    İndirim
                                                                    Oranı
                                                                </TableCell>
                                                                <TableCell>
                                                                    KDV Hariç
                                                                    Tutar
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {data.InvoiceLines?.map(
                                                                (line) => {
                                                                    let lineData =
                                                                        JSON.parse(
                                                                            line.data
                                                                        );
                                                                    return (
                                                                        <TableRow
                                                                            key={
                                                                                lineData.name
                                                                            }
                                                                        >
                                                                            <TableCell>
                                                                                <Button
                                                                                    variant="contained"
                                                                                    sx={{
                                                                                        lineHeight:
                                                                                            "16px",
                                                                                        fontSize:
                                                                                            "13px",
                                                                                        backgroundColor:
                                                                                            "#28c76f",
                                                                                        textTransform:
                                                                                            "none",
                                                                                        mr: 1,
                                                                                        "&:hover":
                                                                                            {
                                                                                                backgroundColor:
                                                                                                    "#1f9755",
                                                                                            },
                                                                                    }}
                                                                                    size="small"
                                                                                    onClick={() =>
                                                                                        handleAddrule(
                                                                                            data.senderName,
                                                                                            lineData.name,
                                                                                            data.direction,
                                                                                            companyDetails?.class,
                                                                                            "line"
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    Satır
                                                                                    İle
                                                                                    Kural
                                                                                    Ekle
                                                                                </Button>
                                                                                {lineData.name ||
                                                                                    "-"}
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                {lineData.quantity ||
                                                                                    "-"}
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                {lineData.quantity_unit ===
                                                                                "C62"
                                                                                    ? "Adet"
                                                                                    : lineData.quantity_unit ||
                                                                                      "-"}
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                {Intl.NumberFormat(
                                                                                    "tr-TR",
                                                                                    {
                                                                                        currency:
                                                                                            invoiceData.currency_code,
                                                                                    }
                                                                                ).format(
                                                                                    lineData.price
                                                                                ) ||
                                                                                    "-"}
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                {lineData?.tax_subtotals?.find(
                                                                                    (
                                                                                        item
                                                                                    ) =>
                                                                                        item?.code ===
                                                                                        "0015"
                                                                                )
                                                                                    ?.percent ||
                                                                                    "-"}
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                {lineData?.tax_subtotals?.find(
                                                                                    (
                                                                                        item
                                                                                    ) =>
                                                                                        item?.code ===
                                                                                        "0015"
                                                                                )
                                                                                    ?.amount ||
                                                                                    "-"}
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                {lineData
                                                                                    .allowances?.[0]
                                                                                    ?.percent ||
                                                                                    "-"}
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                {Intl.NumberFormat(
                                                                                    "tr-TR",
                                                                                    {
                                                                                        style: "currency",
                                                                                        currency:
                                                                                            invoiceData.currency_code,
                                                                                    }
                                                                                ).format(
                                                                                    lineData.extension_amount
                                                                                ) ||
                                                                                    "-"}{" "}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    );
                                                                }
                                                            )}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </div>
                                            <Grid
                                                container
                                                sx={{
                                                    mt: 2,
                                                    justifyContent: "flex-end",
                                                }}
                                            >
                                                <Grid
                                                    item
                                                    xs={12}
                                                    md={4}
                                                    sx={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        alignItems:
                                                            "flex-start",
                                                        justifyContent:
                                                            "flex-end",
                                                        textAlign: "flex-start",
                                                        lineHeight: "35px",
                                                    }}
                                                >
                                                    <Typography>
                                                        Mal Hizmet Toplam
                                                        Tutarı:{" "}
                                                        {Intl.NumberFormat(
                                                            "tr-TR",
                                                            {
                                                                style: "currency",
                                                                currency:
                                                                    invoiceData.currency_code,
                                                            }
                                                        ).format(
                                                            invoiceData.tax_exclusive
                                                        )}{" "}
                                                        {invoiceData.exchange_rate &&
                                                        invoiceData.exchange_rate !=
                                                            1 &&
                                                        typeof invoiceData.exchange_rate ===
                                                            "number"
                                                            ? `(${Intl.NumberFormat(
                                                                  "tr-TR",
                                                                  {
                                                                      style: "currency",
                                                                      currency:
                                                                          "TRY",
                                                                  }
                                                              ).format(
                                                                  Number(
                                                                      invoiceData.exchange_rate
                                                                  ) *
                                                                      Number(
                                                                          invoiceData.tax_exclusive
                                                                      )
                                                              )})`
                                                            : ""}
                                                    </Typography>
                                                    <Typography>
                                                        Toplam İndirim Tutarı:{" "}
                                                        {Intl.NumberFormat(
                                                            "tr-TR",
                                                            {
                                                                style: "currency",
                                                                currency:
                                                                    invoiceData.currency_code,
                                                            }
                                                        ).format(
                                                            invoiceData.allowance_total ||
                                                                0
                                                        )}{" "}
                                                        {invoiceData.exchange_rate &&
                                                        invoiceData.exchange_rate !=
                                                            1
                                                            ? `(${Intl.NumberFormat(
                                                                  "tr-TR",
                                                                  {
                                                                      style: "currency",
                                                                      currency:
                                                                          "TRY",
                                                                  }
                                                              ).format(
                                                                  Number(
                                                                      invoiceData.exchange_rate
                                                                  ) *
                                                                      Number(
                                                                          invoiceData.allowance_total ||
                                                                              0
                                                                      )
                                                              )})`
                                                            : ""}
                                                    </Typography>
                                                    {invoiceData?.tax_subtotals?.map(
                                                        (tax) => {
                                                            const total =
                                                                invoiceData?.tax_subtotals.reduce(
                                                                    (
                                                                        acc,
                                                                        item
                                                                    ) => {
                                                                        if (
                                                                            item.code ===
                                                                                tax.code &&
                                                                            item.percent ===
                                                                                tax.percent &&
                                                                            !item?.isProcessed
                                                                        ) {
                                                                            invoiceData.tax_subtotals.find(
                                                                                (
                                                                                    t
                                                                                ) =>
                                                                                    t.code ===
                                                                                        item.code &&
                                                                                    t.percent ===
                                                                                        item.percent
                                                                            ).isProcessed = true;
                                                                            return (
                                                                                acc +
                                                                                item.amount
                                                                            );
                                                                        }
                                                                        return acc;
                                                                    },
                                                                    0
                                                                );
                                                            return (
                                                                <Typography
                                                                    key={
                                                                        tax.code
                                                                    }
                                                                >
                                                                    Toplam{" "}
                                                                    {tax.name ===
                                                                    "GERÇEK USULDE KATMA DEĞER VERGİSİ"
                                                                        ? "KDV"
                                                                        : tax.name}{" "}
                                                                    (%
                                                                    {
                                                                        tax.percent
                                                                    }
                                                                    ):{" "}
                                                                    {Intl.NumberFormat(
                                                                        "tr-TR",
                                                                        {
                                                                            style: "currency",
                                                                            currency:
                                                                                invoiceData.currency_code,
                                                                        }
                                                                    ).format(
                                                                        total
                                                                    )}{" "}
                                                                    {invoiceData.exchange_rate &&
                                                                    invoiceData.exchange_rate !=
                                                                        1
                                                                        ? `(${Intl.NumberFormat(
                                                                              "tr-TR",
                                                                              {
                                                                                  style: "currency",
                                                                                  currency:
                                                                                      "TRY",
                                                                              }
                                                                          ).format(
                                                                              Number(
                                                                                  invoiceData.exchange_rate
                                                                              ) *
                                                                                  Number(
                                                                                      total
                                                                                  )
                                                                          )})`
                                                                        : ""}
                                                                </Typography>
                                                            );
                                                        }
                                                    )}
                                                    {invoiceData?.withholding_tax_subtotals?.map(
                                                        (tax) => {
                                                            const total =
                                                                invoiceData?.withholding_tax_subtotals.reduce(
                                                                    (
                                                                        acc,
                                                                        item
                                                                    ) => {
                                                                        if (
                                                                            item.code ===
                                                                                tax.code &&
                                                                            item.percent ===
                                                                                tax.percent &&
                                                                            !item?.isProcessed
                                                                        ) {
                                                                            invoiceData.withholding_tax_subtotals.find(
                                                                                (
                                                                                    t
                                                                                ) =>
                                                                                    t.code ===
                                                                                        item.code &&
                                                                                    t.percent ===
                                                                                        item.percent
                                                                            ).isProcessed = true;
                                                                            return (
                                                                                acc +
                                                                                item.amount
                                                                            );
                                                                        }
                                                                        return acc;
                                                                    },
                                                                    0
                                                                );
                                                            return (
                                                                <Typography
                                                                    key={
                                                                        tax.code
                                                                    }
                                                                >
                                                                    Toplam
                                                                    Tevkifat (%
                                                                    {
                                                                        tax.percent
                                                                    }
                                                                    ):{" "}
                                                                    {Intl.NumberFormat(
                                                                        "tr-TR",
                                                                        {
                                                                            style: "currency",
                                                                            currency:
                                                                                invoiceData.currency_code,
                                                                        }
                                                                    ).format(
                                                                        total
                                                                    )}{" "}
                                                                    {invoiceData.exchange_rate &&
                                                                    invoiceData.exchange_rate !=
                                                                        1
                                                                        ? `(${Intl.NumberFormat(
                                                                              "tr-TR",
                                                                              {
                                                                                  style: "currency",
                                                                                  currency:
                                                                                      "TRY",
                                                                              }
                                                                          ).format(
                                                                              Number(
                                                                                  invoiceData.exchange_rate
                                                                              ) *
                                                                                  Number(
                                                                                      invoiceData.total
                                                                                  )
                                                                          )})`
                                                                        : ""}
                                                                </Typography>
                                                            );
                                                        }
                                                    )}
                                                    <Typography>
                                                        Vergiler Toplamı:{" "}
                                                        {Intl.NumberFormat(
                                                            "tr-TR",
                                                            {
                                                                style: "currency",
                                                                currency:
                                                                    invoiceData.currency_code,
                                                            }
                                                        ).format(
                                                            invoiceData.tax_total
                                                        )}{" "}
                                                        {invoiceData.exchange_rate &&
                                                        invoiceData.exchange_rate !=
                                                            1
                                                            ? `(${Intl.NumberFormat(
                                                                  "tr-TR",
                                                                  {
                                                                      style: "currency",
                                                                      currency:
                                                                          "TRY",
                                                                  }
                                                              ).format(
                                                                  Number(
                                                                      invoiceData.exchange_rate
                                                                  ) *
                                                                      Number(
                                                                          invoiceData.tax_total
                                                                      )
                                                              )})`
                                                            : ""}
                                                    </Typography>
                                                    <Typography>
                                                        Ödenecek Tutar:{" "}
                                                        {Intl.NumberFormat(
                                                            "tr-TR",
                                                            {
                                                                style: "currency",
                                                                currency:
                                                                    invoiceData.currency_code,
                                                            }
                                                        ).format(
                                                            invoiceData.payable_amount
                                                        )}{" "}
                                                        {invoiceData.exchange_rate &&
                                                        invoiceData.exchange_rate !=
                                                            1
                                                            ? `(${Intl.NumberFormat(
                                                                  "tr-TR",
                                                                  {
                                                                      style: "currency",
                                                                      currency:
                                                                          "TRY",
                                                                  }
                                                              ).format(
                                                                  Number(
                                                                      invoiceData.exchange_rate
                                                                  ) *
                                                                      Number(
                                                                          invoiceData.payable_amount
                                                                      )
                                                              )})`
                                                            : ""}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </div>
                            );
                        })}
                    </>
                )}
            </DialogContent>
        </Dialog>
    );
};
