import { useEffect } from "react";
import Swal from "sweetalert2";
import { addBlackList } from "../../api/company/invoices/blackList";
// Yükleniyor Swal bileşeni
export const AddBlackListSwal = ({
  open,
  handleClose,
  companyId,
  periodId,
  selectedInvoices,
  setUpdateRow,
}) => {
  useEffect(() => {
    if (open) {
      if (selectedInvoices.length === 0) {
        Swal.fire({
          title: "Uyarı",
          text: "Lütfen en az 1 fatura seçin.",
          icon: "error",
          confirmButtonText: "Tamam",
        });
        handleClose();
        return;
      }
      const filteredBlackList = [];
      selectedInvoices.forEach((item) => {
        if (item.direction === 2) {
          filteredBlackList.push({
            direction: "Gelen",
            vkn_tckn: String(item.sender_tax),
            title: String(item.sender_name),
            address: item.receiver_address ? String(item.receiver_address) : undefined,
          });
        }
      });
      Swal.fire({
        html: `<b>${selectedInvoices.length}</b> adet fatura kara listeye eklenecek. Emin misiniz?`,
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Evet, ekle!",
        cancelButtonText: "Hayır",
      }).then(async (result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: "Faturalar kara listeye Ekleniyor...",
            text: "Lütfen bekleyiniz...",
            icon: "info",
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            customClass: {
              popup: "swal2-content-centered",
            },
            willOpen: () => {
              Swal.showLoading();
            },
          });

          addBlackList(companyId, periodId, filteredBlackList)
            .then((resp) => {
              setUpdateRow(true);
              Swal.fire({
                title: resp.data.message,
                icon: "success",
              });
            })
            .catch((err) => {
              Swal.fire({
                title: "Bir hata oluştu!",
                text: err.response.data.message,
                icon: "error",
                confirmButtonText: "Tamam",
              });
            });
        }
      });

      handleClose();
    }
  }, [open, handleClose, selectedInvoices]);

  return null;
};
