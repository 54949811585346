import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { TextField } from "@mui/material";
const MySwal = withReactContent(Swal);
// Yükleniyor Swal bileşeni

function validateDebtAndReceivable(value) {
  try {
    if (
      value.split("")[0] !== '"' ||
      value.split("")[value.length - 1] !== '"'
    ) {
      throw Error('Kuralın başında ve sonunda " olmalıdır');
    }

  } catch (error) {
    console.log(error);
    return {
      error: error,
    };
  }
  return {
    error: false,
  };
}
export const AddSpecialRule = async () => {
  return new Promise((resolve, reject) => {
    MySwal.fire({
      title: "Özel Kural Ekle",
      icon: "info",
      text: 'Özel kuralı sadece bilgili kişilerin eklemesi önerilir. (Kural JavaScript dilinde yazılmalıdır. Başına ve sonuna " eklenmelidir.)',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off',
      },
      inputValidator: (value) => {
        if (!value) {
          return 'Kural boş olamaz!';
        }
        var result = validateDebtAndReceivable(value);
        if (result.error !== false) {
          return 'Özel kuralda yazım hatası var lütfen düzeltiniz.';
        }
      },
      focusConfirm: false,
      showCancelButton: true,
      confirmButtonText: "Ekle",
      cancelButtonText: "İptal",
    }).then((result) => {
      if (result.isConfirmed) {
        resolve(result.value);
      } 
    });
  });
};