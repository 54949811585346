/**
 * eslint-disable react-hooks/exhaustive-deps
 *
 * @format
 */

//todo MODAL AÇILMASI GEREKTİREN İŞLEMLER SONU MODAL İLE BİTEN DOSYALARDA(fatura seçilerek yapılan işlemlerde),
//todo DİREKT İSTEKLE HALLEDİLEBİLİR İŞLEMLER 'ProcessesAndProcessMenu' DOSYASINDADIR.

import React, {
    useState,
    useMemo,
    useEffect,
    useRef,
    useCallback,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import Swal from "sweetalert2";
import { getLucaAccounts } from "../../api/company/invoices/getLucaAccounts/index.js";
// Grids---------
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import gridSideBar from "../../components/GridSideBar/gridSideBar.js";
import AnimatedAlerts from "../../components/animatedAlerts/index.js";
import { createColumnDefs } from "./gridOptionsAndcolumnDefs.js";
import { detailsGridOptions } from "./gridOptionsAndcolumnDefs.js";
import localeTextTr from "../../locale.tr.js";

//Components
import Sidebar from "../../components/sideBar/sideBar.js";
import Navbar from "../../components/navbar/navbar.js";
import DateRangePicker from "../../components/DateRangePicker/index.js";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import localStorage from "local-storage";
import { getJsonItem } from "../../utils/localStorageProcess/index.js";
import { setJsonItem } from "../../utils/localStorageProcess/index.js";

//Modals and Menus
import ProcessMenu from "./processesAndProcessMenu.js";

import { ChangeDateSelectedExtractsSwal } from "./changeDateSwal.js";
import { ChangeAccountCodeSelectedExtractsSwal } from "./changeAccountCodeSwal.js";
import { XmlFileModalForExtract } from "./getFromXmlFileModalforExtract.js";
//Icons
import KeyboardDoubleArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowLeftOutlined";
import KeyboardDoubleArrowRightOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowRightOutlined";
import { FaRegFileLines } from "react-icons/fa6";
import { TbFileSearch } from "react-icons/tb";
import {
    Grid,
    Typography,
    Button,
    IconButton,
    Box,
    Autocomplete,
    TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
//Icons

//Apis
import { getListBankExtracts } from "../../api/company/bankExtracts/getListBankExtracts/index.js";
import { getCompanyDetails } from "../../api/advisor/getCompanyDetails/index.js";
import { ReFindLucaAccountCodesSwal } from "./reFindLucaAccountCodesSwal.js";
import { DeleteBankExracts } from "./deleteBankExtractsSwal.js";
import { CreateFicheFromSelectedExtracts } from "./createFicheFromSelectedExtractsSwal.js";
import { updateBankExtract } from "../../api/company/bankExtracts/updateBankExtract/index.js";
import { setTitle } from "../../utils/setTitle/index.js";
import { CreateLucaAccountSwal } from "../../components/createLucaAccountSwal/index.js";
import { createTurkishFilterParams } from "../../utils/agGridTextFilterTr/agGridFilterUtils.js";

//Apis

function Extracts() {
    const [isOpen, setIsOpen] = useState(false);

    //modals açılma durumlar
    const [modalId, setModalId] = useState("1");
    const [modalOpen, setModalOpen] = useState(false);
    const handleModalOpen = () => setModalOpen(true);
    const handleModalClose = () => setModalOpen(false);
    //--------

    const [companyDetails, setCompanyDetails] = useState();

    const [loading, setLoading] = useState(true);
    const lucaAccountRef = useRef();
    const [firstLoading, setFirstLoading] = useState(true); //SAYFA İLK AÇTIĞINDA getListInvoices ve companies'e İSTEK ATMAMASI İÇİN FLAG

    //Modallarda Yapılan işlemlerde sonra tablonun güncellemesi için
    const [updateRow, setUpdateRow] = useState(false);

    const location = useLocation();
    const navigate = useNavigate();

    const [rowData, setRowData] = useState([]);
    const gridRef = useRef(null);
    const [selectedExtractIds, setSelectedExtractIds] = useState([]);

    const [accountCodes, setAccountCodes] = useState([]);

    const [filteredCount, setFilteredCount] = useState(0);

    useEffect(() => {
        if (updateRow === true) {
            setLoading(true);
            search(companyDetails?.companyId, companyDetails?.periodId);
            setUpdateRow(false);
        }
    }, [updateRow]);

    const onSelectionChanged = async () => {
        const selectedNodes = gridRef.current.api.getSelectedNodes();
        const selectedIds = selectedNodes.map((node) => node.data.id);
        setSelectedExtractIds(selectedIds);
    };

    const onFilterChanged = useCallback((event) => {
        const filteredRows = event.api.getModel().getRowCount(); // Filtrelenmiş veri sayısını alıyoruz
        setFilteredCount(filteredRows);
    }, []);
    const onRowDataUpdated = useCallback((event) => {
        const filteredRows = event.api.getDisplayedRowCount(); // Filtrelenmiş veri sayısını alıyoruz
        setFilteredCount(filteredRows);
    }, []);

    const [startDate, setStartDate] = useState(
        moment()
            .subtract(1, "month")
            .startOf("month")
            .format("YYYY-MM-DD HH:mm:ss")
    );
    const [endDate, setEndDate] = useState(
        moment()
            .subtract(1, "month")
            .endOf("month")
            .format("YYYY-MM-DD HH:mm:ss")
    );

    const handlePreviousMonth = () => {
        setStartDate(moment(startDate).subtract(1, "month").startOf("month"));
        setEndDate(moment(endDate).subtract(1, "month").endOf("month"));
    };

    const handleNextMonth = () => {
        setStartDate(moment(startDate).add(1, "month").startOf("month"));
        setEndDate(moment(endDate).add(1, "month").endOf("month"));
    };

    useEffect(() => {
        const sideBarOpen = localStorage.get("sidebar");
        if (sideBarOpen === "false") {
            setIsOpen(false);
        } else {
            setIsOpen(true);
        }

        let locationSplit = location.pathname.split("/");

        let companyDetailsControl = getJsonItem(
            `#${locationSplit[2]}/${locationSplit[3]}`
        );
        if (
            moment(companyDetailsControl.year, "YYYY").year() !==
            moment().year()
        ) {
            const year = moment(companyDetailsControl.year, "YYYY");
            setStartDate(
                year
                    .endOf("year")
                    .startOf("month")
                    .format("YYYY-MM-DD HH:mm:ss")
            );
            setEndDate(year.endOf("year").format("YYYY-MM-DD HH:mm:ss"));
        }
        setCompanyDetails(companyDetailsControl);
        if (!companyDetailsControl?.companyId) {
            getCompanyDetails(locationSplit[2], locationSplit[3])
                .then(async (resp) => {
                    if (resp.data.companyId) {
                        await setJsonItem(
                            `#${locationSplit[2]}/${locationSplit[3]}`,
                            resp.data
                        );
                        window.location.reload();
                    } else {
                        navigate("/dashboard", { replace: true });
                    }
                })
                .catch((err) => {
                    navigate("/dashboard", { replace: true });
                });
        } else {
            setTitle(companyDetailsControl?.name, locationSplit[4]);
            search(locationSplit[2], locationSplit[3]);
            getLucaAccounts(locationSplit[2], locationSplit[3])
                .then(async (resp) => {
                    const updateData = resp?.data.list.filter(
                        (item, index, self) =>
                            index === self.findIndex((t) => t.id === item.id)
                    );
                    lucaAccountRef.current = updateData;
                    if (resp.data.message !== "") {
                        Swal.fire({
                            icon: "warning",
                            title: "Uyarı",
                            html: resp.data.message,
                            confirmButtonText: "Tamam",
                        })
                    }
                    setAccountCodes(updateData);
                })
                .catch((err) => {
                    console.log(err.response);
                });
        }
    }, []);

    const search = (companyId, periodId) => {
        getListBankExtracts(companyId, periodId, startDate, endDate)
            .then((resp) => {
                setRowData(resp.data);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    const defaultColDef = useMemo(
        () => ({
            filter: true,
            sortable: true,
            resizable: true,
            floatingFilter: true,
            editable: false,
            filterParams: createTurkishFilterParams(),
            enableCellChangeFlash: true,
        }),
        []
    );

    const getRowId = useCallback((params) => {
        return params.data.id;
    }, []);

    const ItemSelectorCellEditor = (props, accountCodes) => {
        let groupedOptions = [
            {
                group: "Muhtemel Hesaplar",
                options: props.data.possibleLucaAccounts,
            },
            {
                group: "Luca Hesapları",
                options: lucaAccountRef.current,
            },
        ];

        const options = groupedOptions.flatMap((group) => group.options);
        const updateData = useCallback(
            async (newDataAccountCode, newDataAccountName) => {
                let id = await props.data.id;
                const rowNode = props.api.getRowNode(id);
                let extractData = {
                    id: Number(id),
                    lucaCurrentCode: newDataAccountCode,
                    lucaCurrentName: newDataAccountName,
                    status: 104,
                };

                updateBankExtract(
                    companyDetails?.companyId,
                    companyDetails?.periodId,
                    extractData
                )
                    .then(async (resp) => {
                        await rowNode.setDataValue("status", resp.data.status);
                        await rowNode.setDataValue(
                            "lucaCurrentCode",
                            newDataAccountCode
                        );
                        await rowNode.setDataValue(
                            "lucaCurrentName",
                            newDataAccountName
                        );
                    })
                    .catch((err) => {
                        rowNode.setDataValue("lucaCurrentCode", "");
                        rowNode.setDataValue("lucaCurrentName", "");
                        Swal.fire({
                            html: `Hesap kodu güncellerken bir hata oluştu !<br>Lütfen tekrar deneyiniz.<br>`,
                            icon: "error",
                            confirmButtonColor: "#3085d6",
                            confirmButtonText: "Tamam",
                        });
                        props.api.stopEditing();
                        console.log(err);
                    });
            },
            [props.api, props.data]
        );

        const handleChange = async (newValue) => {
            if (newValue?.value === "add-new") {
                let newAccount = await CreateLucaAccountSwal(
                    companyDetails?.companyId,
                    companyDetails?.periodId
                );
                lucaAccountRef.current = [
                    ...lucaAccountRef.current,
                    {
                        id: Math.floor(
                            100000 + Math.random() * 900000
                        ).toString(),
                        hesap_adi: newAccount?.input1,
                        hesap_kodu: newAccount?.input2,
                    },
                ];
                updateData(newAccount?.input2 || "", newAccount?.input1 || "");
                props.stopEditing();
                return;
            } else {
                updateData(
                    newValue?.hesap_kodu ? newValue.hesap_kodu : "",
                    newValue?.hesap_adi ? newValue.hesap_adi : ""
                );
                props.stopEditing();
            }
        };
        const [open, setOpen] = useState(true);

        return (
            <Autocomplete
                style={{ height: "20px" }}
                value={
                    options.find(
                        (option) => option.hesap_kodu === props.value
                    ) || null
                }
                onChange={(event, newValue) => {
                    handleChange(newValue);
                }}
                options={options}
                noOptionsText={"Seçenek bulunamadı"}
                autoHighlight
                selectOnFocus
                blurOnSelect
                openOnFocus
                disableClearable
                onKeyDown={(event) => {
                    if (event.key === "Enter") {
                        // Eğer liste açıksa ve bir seçenek vurgulanmışsa, onu seç
                        const highlightedOption = event.target.querySelector(
                            'li[data-focus="true"]'
                        );
                        if (highlightedOption) {
                            highlightedOption.click();
                        }
                    }
                }}
                filterOptions={(options, params) => {
                    const filtered = options.filter((option) => {
                        let label = `${option.hesap_kodu} ${option.hesap_adi}`;
                        return label
                            .toLocaleLowerCase("tr")
                            .includes(
                                params?.inputValue?.toLocaleLowerCase("tr")
                            );
                    });
                    // Girilen metin, mevcut seçenekler arasında yoksa özel bir seçenek ekle
                    if (filtered?.length === 0) {
                        filtered.push({
                            label: "Yeni Hesap Kodu Eklemek İçin Tıklayın",
                            value: "add-new", // Özel seçenek için belirleyici bir value
                        });
                    }

                    return filtered;
                }}
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                groupBy={(option) => {
                    const group = groupedOptions.find((g) =>
                        g.options.includes(option)
                    );
                    return group ? group.group : "";
                }}
                getOptionLabel={(option) =>
                    option.label
                        ? option.label
                        : `${option.hesap_kodu} - ${option.hesap_adi
                        } - Bakiye: ${option.bakiye || "0"}`
                }
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        autoFocus={true}
                    />
                )}
                renderGroup={(params) => (
                    <Box
                        key={params.key} // Burada 'key' prop'u ekleyin
                        sx={{
                            maxHeight:
                                params.group === "Muhtemel Hesaplar"
                                    ? "150px"
                                    : "200px",
                            display: "flex",
                            overflowX: "auto",
                        }}
                    >
                        <li key={params.key} sx={{ display: "inline" }}>
                            <Typography
                                variant="body1"
                                style={{
                                    fontWeight: "bold",
                                    backgroundColor: "#f0f0f0",
                                    padding: "5px",
                                }}
                            >
                                {params.group}
                            </Typography>
                            <ul
                                style={{
                                    padding: 0,
                                    margin: 0,
                                    listStyle: "none",
                                    whiteSpace: "nowrap",
                                    flexWrap: "nowrap",
                                }}
                            >
                                {params.children}
                            </ul>
                        </li>
                    </Box>
                )}
                renderOption={(props, option) => (
                    <Box
                        component="li"
                        {...props}
                        key={option.hesap_kodu} // Burada 'key' prop'u ekleyin
                        style={{ paddingLeft: "7px", maxWidth: "100%" }}
                    >
                        <Typography>
                            {option.label || (
                                <>
                                    <strong>{option.hesap_kodu}</strong> -{" "}
                                    {option.hesap_adi} - Bakiye:{" "}
                                    {option.bakiye || "0"}
                                </>
                            )}
                        </Typography>
                    </Box>
                )}
                ListboxProps={{
                    style: {
                        maxHeight: "100%",
                        overflow: "hidden",
                    },
                }}
            />
        );
    };

    const ButtonRenderer = (props) => {
        const handleClick = () => {
            setTimeout(() => {
                setModalId("#viewInvoiceOfFiche");
                handleModalOpen();
            }, 0);
        };

        return (
            <Button
                onClick={handleClick}
                sx={{
                    padding: 0,
                    minWidth: "auto",
                    width: "auto",
                    height: "auto",
                    display: "flex",
                }}
            >
                <TbFileSearch color="#3fd549" size={21}></TbFileSearch>
            </Button>
        );
    };

    const columnDefs = useMemo(
        () =>
            createColumnDefs(
                createColumnDefs?.companyId,
                createColumnDefs?.periodId,
                ItemSelectorCellEditor
            ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [companyDetails?.class]
    );

    const detailCellRendererParams = useMemo(() => {
        return {
            detailGridOptions: detailsGridOptions(ButtonRenderer),
            getDetailRowData: (params) => {
                const createTransactionArray = (params) => {
                    const {
                        LucaBankAccountCode,
                        lucaCurrentCode,
                        amount,
                        direction,
                    } = params.data;
                    const transactionArray = [
                        {
                            hesap_kodu: LucaBankAccountCode,
                            borc: direction === 1 ? 0 : amount,
                            alacak: direction === 1 ? amount : 0,
                        },
                    ];

                    if (lucaCurrentCode) {
                        transactionArray.push({
                            hesap_kodu: lucaCurrentCode,
                            borc: direction === 1 ? amount : 0,
                            alacak: direction === 1 ? 0 : amount,
                        });
                    }

                    return transactionArray;
                };
                params.successCallback(createTransactionArray(params));
            },
        };
    }, []);

    const handleSearch = () => {
        let locationSplit = location.pathname.split("/");
        setLoading(true);
        search(locationSplit[2], locationSplit[3]);
    };

    const getMainMenuItems = (params) => {
        const allMenuItems = params.defaultItems;
        const itemsToRemove = ["toolPanelButton"]; // 'choose columns' seçeneği

        return allMenuItems.filter((item) => !itemsToRemove.includes(item));
    };

    return (
        <Grid container>
            <Grid
                item
                sx={{
                    flexBasis: isOpen ? "275px" : "95px",
                    flexShrink: 0,
                    transition: "flex-basis 0.3s ease",
                }}
                zIndex={1}
            >
                <Sidebar
                    status={isOpen}
                    toggleSidebar={toggleSidebar}
                    companyClass={companyDetails?.class}
                />
            </Grid>
            <Grid
                item
                zIndex={0}
                sx={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "flex-end",
                    flexDirection: "column",
                    gap: 1,
                    pr: "12px",
                }}
            >
                <Grid item xs={12}>
                    <Navbar
                        setLoading={setLoading}
                        setFirstLoading={setFirstLoading}
                        firstLoading={firstLoading}
                    />{" "}
                    {/*FATURA YÜKLENENE KADAR LOADİNG GÖSTERME*/}
                </Grid>
                <Grid
                    item
                    md={12}
                    sx={{ display: "flex", flexDirection: "column" }}
                >
                    <Grid item md={12} sx={{ mt: 1 }}>
                        <AnimatedAlerts
                            infoText={`   Bu sayfada, seçtiğiniz tarih aralığındaki 
                    <b style={{ letterSpacing: "0.05em" }}>
                        fişleştirilmemiş banka ekstrelerinizi
                    </b> 
                    görüntüleyebilir ve fiş oluşturabilirsiniz.`}
                            warningText={`Banka ekstesi yükledikten sonra 
                    <b style={{ letterSpacing: "0.05em" }}>
                        toplam adet, tutar,tarih ve yön
                    </b> 
                    kontrolü yapınız.`}
                        />
                    </Grid>
                    <Grid
                        className="grid-area"
                        sx={{
                            padding: 1,
                            mb: 1,
                            alignItems: "center",
                        }}
                    >
                        <Grid
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}
                        >
                            <Grid
                                sx={{ display: "flex", alignItems: "center" }}
                            >
                                <LocalizationProvider
                                    dateAdapter={AdapterMoment}
                                    adapterLocale="tr"
                                >
                                    <Grid
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: 1,
                                            pl: 1,
                                            pt: 0.5,
                                        }}
                                    >
                                        <IconButton
                                            onClick={handlePreviousMonth}
                                            className="date-button"
                                        >
                                            <KeyboardDoubleArrowLeftOutlinedIcon fontSize="small"></KeyboardDoubleArrowLeftOutlinedIcon>
                                        </IconButton>
                                        <DateRangePicker
                                            setStartDate={setStartDate}
                                            startDate={startDate}
                                            endDate={endDate}
                                            setEndDate={setEndDate}
                                            isLimitedRangeForMonth={true}
                                        />
                                        <IconButton
                                            onClick={handleNextMonth}
                                            className="date-button"
                                        >
                                            <KeyboardDoubleArrowRightOutlinedIcon fontSize="small"></KeyboardDoubleArrowRightOutlinedIcon>
                                        </IconButton>
                                        <Button
                                            variant="contained"
                                            className="custom-button"
                                            startIcon={<SearchIcon />}
                                            onClick={() => {
                                                handleSearch();
                                            }}
                                            sx={{
                                                width: "5rem",
                                                ml: 2,
                                            }}
                                        >
                                            Ara
                                        </Button>
                                    </Grid>
                                </LocalizationProvider>
                            </Grid>
                            <Grid
                                sx={{
                                    display: "flex",
                                    gap: 2,
                                    alignItems: "center",
                                }}
                            >
                                <ProcessMenu
                                    startDate={startDate}
                                    endDate={endDate}
                                    setModalId={setModalId}
                                    handleModalOpen={handleModalOpen}
                                    setUpdateRow={setUpdateRow}
                                ></ProcessMenu>
                                {modalId === "#xmlFileModalForExtract" ? (
                                    <Grid>
                                        <XmlFileModalForExtract
                                            open={modalOpen}
                                            handleClose={handleModalClose}
                                            companyId={
                                                companyDetails?.companyId
                                            }
                                            startDate={startDate}
                                            endDate={endDate}
                                            periodId={companyDetails?.periodId}
                                            accountCodeList={accountCodes}
                                            setUpdateRow={setUpdateRow}
                                        ></XmlFileModalForExtract>
                                    </Grid>
                                ) : modalId ===
                                    "#changeAccountCodeSelectedExtractsSwal" ? (
                                    <Grid>
                                        <ChangeAccountCodeSelectedExtractsSwal
                                            open={modalOpen}
                                            handleClose={handleModalClose}
                                            companyId={
                                                companyDetails?.companyId
                                            }
                                            periodId={companyDetails?.periodId}
                                            accountCodeList={accountCodes}
                                            selectedExtractIds={
                                                selectedExtractIds
                                            }
                                            setUpdateRow={setUpdateRow}
                                        ></ChangeAccountCodeSelectedExtractsSwal>
                                    </Grid>
                                ) : modalId ===
                                    "#changeDateSelectedExtractsSwal" ? (
                                    <Grid>
                                        <ChangeDateSelectedExtractsSwal
                                            open={modalOpen}
                                            handleClose={handleModalClose}
                                            companyId={
                                                companyDetails?.companyId
                                            }
                                            periodId={companyDetails?.periodId}
                                            selectedExtractIds={
                                                selectedExtractIds
                                            }
                                            setUpdateRow={setUpdateRow}
                                        ></ChangeDateSelectedExtractsSwal>
                                    </Grid>
                                ) : modalId === "#reFindLucaAccountCodes" ? (
                                    <Grid>
                                        <ReFindLucaAccountCodesSwal
                                            open={modalOpen}
                                            handleClose={handleModalClose}
                                            companyId={
                                                companyDetails?.companyId
                                            }
                                            periodId={companyDetails?.periodId}
                                            selectedExtractIds={
                                                selectedExtractIds
                                            }
                                            setUpdateRow={setUpdateRow}
                                        ></ReFindLucaAccountCodesSwal>
                                    </Grid>
                                ) : modalId ===
                                    "#deleteSelectedBankExtracts" ? (
                                    <Grid>
                                        <DeleteBankExracts
                                            open={modalOpen}
                                            handleClose={handleModalClose}
                                            companyId={
                                                companyDetails?.companyId
                                            }
                                            periodId={companyDetails?.periodId}
                                            selectedExtractIds={
                                                selectedExtractIds
                                            }
                                            setUpdateRow={setUpdateRow}
                                        ></DeleteBankExracts>
                                    </Grid>
                                ) : modalId ===
                                    "#createFicheFromSelectedExtracts" ? (
                                    <Grid>
                                        <CreateFicheFromSelectedExtracts
                                            open={modalOpen}
                                            handleClose={handleModalClose}
                                            companyId={
                                                companyDetails?.companyId
                                            }
                                            periodId={companyDetails?.periodId}
                                            selectedExtractIds={
                                                selectedExtractIds
                                            }
                                            setUpdateRow={setUpdateRow}
                                        ></CreateFicheFromSelectedExtracts>
                                    </Grid>
                                ) : (
                                    ""
                                )}
                                <Button
                                    variant="contained"
                                    onClick={() => {
                                        setModalId(
                                            "#createFicheFromSelectedExtracts"
                                        );
                                        handleModalOpen();
                                    }}
                                    sx={{
                                        textTransform: "none",
                                        backgroundColor: "#f7dbdc",
                                        color: "#737279",
                                        fontSize: "13px",
                                        fontWeight: 600,
                                        boxShadow: "none",
                                        "&:hover": {
                                            backgroundColor: "#facbcc",
                                            color: "#d4181b",
                                            boxShadow: "none",
                                        },
                                    }}
                                    startIcon={<FaRegFileLines size={15} />}
                                >
                                    Fiş Oluştur
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Box
                        className="grid-area"
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 0,
                            backgroundColor: "#ffffff",
                            p: 2,
                            mb: 2,
                        }}
                    >
                        <Grid
                            className="ag-theme-quartz"
                            style={{
                                minHeight: rowData.length > 6 ? "85vh" : "60vh",
                                width: "100%",
                                borderRadius: "10%",
                            }}
                        >
                            <AgGridReact
                                ref={gridRef}
                                getRowId={getRowId}
                                onSelectionChanged={onSelectionChanged}
                                onFilterChanged={onFilterChanged}
                                localeText={localeTextTr}
                                rowSelection="multiple"
                                loading={loading}
                                animateRows={true}
                                rowDragManaged={true}
                                rowMultiSelectWithClick={true}
                                singleClickEdit={true}
                                stopEditingWhenCellsLoseFocus={true}
                                rowData={rowData}
                                columnDefs={columnDefs}
                                enableRangeSelection={true}
                                sideBar={gridSideBar.gridSideBar}
                                copyHeadersToClipboard={false}
                                defaultColDef={defaultColDef}
                                masterDetail={true}
                                detailRowAutoHeight={true}
                                onRowDataUpdated={onRowDataUpdated}
                                detailCellRendererParams={
                                    detailCellRendererParams
                                }
                                getMainMenuItems={getMainMenuItems}
                            />
                        </Grid>
                        <Typography
                            sx={{
                                backgroundColor: "white",
                                fontWeight: 400,
                                color: "#605f66",
                                ml: 1,
                                mt: 1,
                                mb: 1,
                            }}
                        >
                            Ekrandaki Ekstre Sayısı: <b>{filteredCount}</b>
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default Extracts;
