import {
  Grid,
  Button,
  TextField,
  Autocomplete,
  FormControl,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import { Typography, Popover } from "@mui/material";

import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import KeyboardDoubleArrowDownOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowDownOutlined";
import { getJsonItem, setJsonItem } from "../../utils/localStorageProcess";
import { getCompanyList } from "../../api/advisor/getCompanyList";
import { getPeriodList } from "../../api/advisor/getPeriodList";
import { getCompanyDetails } from "../../api/advisor/getCompanyDetails";
const CompanyChoose = ({ setLoading, setFirstLoading, firstLoading }) => {
  const [anchorEl, setAnchorEl] = useState(false);
  const [company, setCompany] = useState("");
  const [period, setPeriod] = useState("");
  const [companyLoading, setCompanyLoading] = useState(true);
  const [periodLoading, setPeriodLoading] = useState(true);

  const [periods, setPeriods] = useState([]);
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    if (anchorEl !== null && firstLoading === true) {
      getCompanyList()
        .then((resp) => {
          
          setCompanies(resp.data);
          setCompanyLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [anchorEl]);

  useEffect(() => {
    if (company) {
      getPeriodList(company.value)
        .then((resp) => {
          setPeriods(resp.data);
          if (resp.data.length > 0) {
            setPeriodLoading(false)
            setPeriod(resp.data[0].value);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [company]);

  const handleClick = (event) => {
    setFirstLoading(false);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setCompany("");
    setPeriod("");
  };
  const location = useLocation();

  const handleNavigate = () => {

    let locationSplit = location.pathname.split("/");
    let companyDetailsControl = getJsonItem(`#${company?.value}/${period?.value}`);
    if (!companyDetailsControl?.companyId) {
      if (
      locationSplit[2] !== String(company?.value) &&
      locationSplit[3] !== String(period?.value) &&
      company &&
      period
    ) {
      setFirstLoading(false);
      setLoading(true);
      setAnchorEl(null);
      getCompanyDetails(company.value, period)
        .then(async (resp) => {
          await setJsonItem(`#${company.value}/${period}`, resp.data);
          
          setCompany("");
          setPeriod("");
          const newPath = `${locationSplit[0]}/${locationSplit[1]}/${company.value}/${period}/invoices`;
          window.location.href = newPath
        })
        .catch((err) => {
          console.log(err);

        });
    }
    }else{
      const newPath = `${locationSplit[0]}/${locationSplit[1]}/${company.value}/${period}/invoices`;
      window.location.href = newPath
    }
    
  };

  const open = Boolean(anchorEl);
  return (
    <Grid>
      <Button
        onClick={handleClick}
        sx={{
          minWidth: 0,
          width: "2.5rem",
          height: "2rem",
          padding: 0,
          boxShadow: "none",
          backgroundColor: "#f8f7fa",
          textTransform: "none",
          borderTopLeftRadius: "0px",
          borderBottomLeftRadius: "0px",
          "&:hover": {
            backgroundColor: "#e5e5e7",
            borderTopLeftRadius: "0px",
            borderBottomLeftRadius: "0px",
          },
        }}
      >
        <KeyboardDoubleArrowDownOutlinedIcon sx={{ color: "#3f3f3f" }} />
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={()=>{setPeriod("")
            setPeriods([])
          handleClose();}}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          sx={{ display: "flex", flexDirection: "column", gap: 2, p: 1 }}
        >
          <Typography variant="h6" color={"gray"}>
            Firma ve Dönem Seçiniz
          </Typography>
          <Grid>
            {" "}
            <Typography variant="subtitle1" color={"041737"} gutterBottom>
              Firma
            </Typography>
            <FormControl fullWidth>
            <Autocomplete
                disablePortal
                options={companies}
                sx={{ width: "100%" }}
                autoHighlight
                selectOnFocus 
                blurOnSelect 
                openOnFocus 

                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    // Eğer liste açıksa ve bir seçenek vurgulanmışsa, onu seç
                    const highlightedOption = event.target.querySelector('li[data-focus="true"]');
                    if (highlightedOption) {
                      highlightedOption.click();
                    }
                  }
                }}
                getOptionLabel={(option) => option.label || option}
                isOptionEqualToValue={(option, value) =>
                  (option.label || option).toLocaleLowerCase("tr-TR") ===
                  (value.label || value).toLocaleLowerCase("tr-TR")
                }
                filterOptions={(options, { inputValue }) => {
                  const normalized = (inputValue).toLocaleLowerCase("tr-TR");
                  return options.filter((option) =>
                    (option.label || option).toLocaleLowerCase("tr-TR").includes(normalized)
                  );
                }}
                noOptionsText={companyLoading ? "Yükleniyor..." : "Seçenek bulunamadı"}
                ListboxProps={{ style: { maxHeight: "140px" } }}
                onChange={(event, newValue) => {
                  setPeriodLoading(true);
                  setPeriod("");
                  setPeriods([]);
                  setCompany(newValue?newValue:"");
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Firma Seçiniz..."
                    className="input-field"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {companyLoading ? (
                            <InputAdornment position="end">
                              
                              <CircularProgress size={17} />
                            </InputAdornment>
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid>
            <Typography variant="subtitle1" color={"041737"} gutterBottom>
              Dönem
            </Typography>
            <FormControl fullWidth>
            <Autocomplete
                disablePortal={false}
                options={periods}
                value={periods.find((p) => p.value === period) || null}
                ListboxProps={{ style: { maxHeight: "15vh" } }}
                sx={{ width: "100%" }}
                getOptionLabel={(option) => `${option.label}` }
                onChange={(event, newValue) => {
                  setPeriod(newValue?.value);
                }}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    // Eğer liste açıksa ve bir seçenek vurgulanmışsa, onu seç
                    const highlightedOption = event.target.querySelector(
                      'li[data-focus="true"]'
                    );
                    if (highlightedOption) {
                      highlightedOption.click();
                    }
                  }
                }}
                noOptionsText={
                  company === ""
                    ? "Lütfen Firma Seçiniz"
                    : periodLoading
                    ? "Yükleniyor..."
                    : "Seçenek bulunamadı"
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Dönem Seçiniz..."
                    className="input-field"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {company !==""&&periodLoading ? (
                            <InputAdornment position="end">
                              
                              <CircularProgress size={17} />
                            </InputAdornment>
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>

          <Grid sx={{ display: "flex" }}>
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              type="submit"
              onClick={() => handleNavigate()}
              sx={{ textTransform: "none" }}
              className={!company||!period ? "" : "custom-button"}
              disabled={!company||!period}
            >
              <Typography fontSize={17}>Giriş</Typography>
            </Button>
          </Grid>
        </Grid>
      </Popover>
    </Grid>
  );
};
export default CompanyChoose;
