import React, { useState, useRef, useEffect } from "react";
import {
  Modal,
  Box,
  Button,
  Typography,
  Checkbox,
  Grid,
  Card,
  CardContent,
} from "@mui/material";
import Swal from "sweetalert2";
import { TbArrowAutofitUp } from "react-icons/tb";
import { TbArrowAutofitDown } from "react-icons/tb";

import { styled } from "@mui/system";
import moment from "moment";
import { syncInvoicesFromXml } from "../../api/company/invoices/fetchFromXmlFile";
const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
  "&.MuiCheckbox-root": {
    padding: 0,
    width: 20,
    height: 20,
    margin: 2, // Ekstra alan için
  },
  "& .MuiSvgIcon-root": {
    display: "none",
  },
}));

const CustomIcon = styled("span")({
  borderRadius: "50%",
  width: 20,
  height: 20,
  border: "2px solid black",
  backgroundColor: "transparent",
  display: "inline-block",
  boxSizing: "border-box",
});

const CustomCheckedIcon = styled(CustomIcon)({
  border: "2px solid #1a3b5d",
  backgroundColor: "#1a3b5d",
  position: "relative",
  "&::after": {
    content: '""',
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 10,
    height: 10,
    borderRadius: "50%",
    backgroundColor: "white",
  },
});

export const XmlFileModal = ({
  open,
  handleClose,
  startDate,
  endDate,
  companyId,
  periodId,
  setUpdateRow,
}) => {
  const [autoDetect, setAutoDetect] = useState(true);

  const [files, setFiles] = useState([]);
  const fileInputRef = useRef(null);

  const handleAutoDetectChange = (event) => {
    setAutoDetect(event.target.checked);
  };
  const [selectedCheckbox, setSelectedCheckbox] = useState("outgoing");

  useEffect(() => {
    setFiles([]);
  }, [handleClose]);

  const handleChange = (event) => {
    setSelectedCheckbox(event.target.name);
  };

  const isXmlFile = (file) => file.name.endsWith(".xml");

  const handleFileSelect = (event) => {
    const files = event.target.files;
    const filteredFiles = Array.from(files).filter(isXmlFile);
    if (filteredFiles.length > 0) {
      setFiles(filteredFiles);
    } else {
      handleCancel(event)
      alert("Yalnızca .xml uzantılı dosyalar seçebilirsiniz.");
    }
  };
  const handleUploadClick = (startDate, endDate, companyId, periodId) => {
    let succesfullUplodedFile = 0;
    let failedUplodedFile = 0;
    let vkn;
    let tckn;
    const failedFiles = [];
    Swal.fire({
      html: `XML dosyasından faturalar yüklenecek. Devam etmek istediğinize emin misiniz?`,
      icon: "warning",
      iconColor: "#FFA500",
      showCancelButton: true,
      confirmButtonText: "Evet, senkronize et!",
      cancelButtonText: "Hayır",
      didOpen: () => {
        document.querySelector(".swal2-container").style.zIndex = "1500";
      },
    }).then((result) => {
      if (result.isConfirmed) {
        handleClose();
        let sd_data = startDate;
        let ed_data = endDate;

        const xmlFiles = Array.from(files).filter((file) =>
          file.name.toLowerCase().endsWith(".xml")
        );
        Swal.fire({
          title: "Lütfen bekleyiniz...",
          html: `
          <p style="color: red;">İşlemin yarıda kalmaması için lütfen sayfayı kapatmayınız ve yenilemeyiniz</p>
          <div class="swal2-progress-container">
            <div class="swal2-progress"></div>
          </div>
          <p id="progress-text">Dosyalar yükleniyor...</p>
            `,
          icon: "info",
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          customClass: {
            popup: "swal2-content-centered",
          },
          willOpen: () => {
            Swal.showLoading();
          },
        });

        (async function () {
          const batchSize = 100; // 100'luk gruplar için batchSize belirleyin
          const fileGroups = [];
          for (let i = 0; i < xmlFiles.length; i += batchSize) {
            fileGroups.push(xmlFiles.slice(i, i + batchSize));
          }

          let index = 0;
          for await (const files of fileGroups) {
            try {
              await fileToBase64AndSend(
                files,
                sd_data,
                ed_data,
                index,
                xmlFiles.length
              );
              index += files.length;
            } catch (e) {
              index += files.length;
            }
          }
          setUpdateRow(true);
          Swal.close(); // İşlem tamamlandıktan sonra yükleme çubuğunu kapat
          Swal.fire({
            html: `
                      <h4 class="mt-3 mb-4">Fatura yükleme sonuçları</h4>
            <div class="result-container">
              ${
                succesfullUplodedFile > 0
                  ? `<div class="result-item">
                      <span class="result-icon success"><i class="ti ti-checks ti-xs"></i></span>
                      <span class="label">Başarılı yüklenen fatura adedi</span>
                      <span style="font-weight: bold; color: #36cb85;" >${succesfullUplodedFile}</span>
                    </div>`
                  : ""
              }
              ${
                failedUplodedFile > 0
                  ? `<div class="result-item">
                      <span class="result-icon failed"><i class="ti ti-x ti-xs"></i></span>
                      <span class="label">Başarısız yüklenen fatura adedi</span>
                      <span style="font-weight: bold; color: #ea5455;" >${failedUplodedFile}</span>
                    </div>`
                  : ""
              }
              <div class="result-item">
                <span class="result-icon total"><i class="ti ti-list-details ti-xs"></i></span>
                <span class="label">Toplam fatura adedi</span>
                <span style="font-weight: bold; color: #00cfe8;" >${
                  succesfullUplodedFile + failedUplodedFile
                }</span>
              </div>
            </div>
            `,
            confirmButtonText: "Tamam",
            denyButtonText: "Hata alan dosyaları göster",
            showDenyButton: failedFiles.length > 0,
          }).then((result) => {
            if (result.isDenied) {
              (() => {
                const failedFilesCountListHTML = `
                  <html>
                  <head>
                    <title>Yüklenemeyen Dosyalar</title>
                    <meta charset="UTF-8">
                    <style>
                      table {
                        border-collapse: collapse;
                        width: 100%;
                      }
                      th, td {
                        border: 1px solid #dddddd;
                        text-align: left;
                        padding: 8px;
                      }
                      th {
                        background-color: #f2f2f2;
                      }
                    </style>
                  </head>
                  <body>
                    <h2>Yüklenemeyen Dosyalar</h2>
                    <p>Yüklendiğiniz dosyalar bozuk olabilir kontrol ediniz.Dosyalarınız bozuk değilse "Faturanın Yönünü Otomatik Algıla" seçeneğini kaldırıp fatura yönünü seçerek tekrar deneyebilirsiniz.</p>
                    <br color:"red">Sadece yüklenemeyen faturaları yönünü doğru şekilde yükleyiniz.Daha önceden fatura yüklenmişse ve sizin seçtiğiniz yönden farklı ise MÜKERRER FATURA KAYDI oluşur</br>
                    <table>
                      <thead>
                        <tr>
                          <th>Sıra</th>
                          <th>Dosya Adı</th>
                          <th>Hata Nedeni</th>
                        </tr>
                      </thead>
                      <tbody>
                        ${failedFiles
                          .map(
                            (file, index) => `
                          <tr>
                            <td>${index + 1}</td>
                            <td>${file.name}</td>
                            <td>${file.error}</td>
                          </tr>
                        `
                          )
                          .join("")}
                      </tbody>
                    </table>
                  </body>
                </html>
                  `;

                const failedFilesCountBlob = new Blob(
                  [failedFilesCountListHTML],
                  { type: "text/html" }
                );
                const failedFilesCountUrl =
                  URL.createObjectURL(failedFilesCountBlob);

                window.open(failedFilesCountUrl, "_blank");
              })();
            }
          });
        })();
      }
    });
    async function fileToBase64AndSend(
      files,
      sd_data,
      ed_data,
      index,
      xmlFilesLength
    ) {
      // AJAX isteği için kullanılacak dosyaları saklamak için bir dizi oluştur
      const filesToSend = [];
      // Dosya dönüşümü işlemlerini Promise'lerle gerçekleştir
      await Promise.all(
        files.map(async (file) => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
              const base64File = reader.result.split(",")[1];
              // Dosya adı ve Base64 verisini içeren bir nesne oluştur ve diziye ekle
              filesToSend.push({ name: file.name, file: base64File });
              resolve(); // Promise'i tamamla
            };
            reader.onerror = (error) => {
              reject(error);
            };
          });
        })
      ); // Tüm dönüşüm işlemleri tamamlandığında AJAX isteğini gönder
      let data = {
        files: filesToSend,
        vkn: vkn,
        tckn: tckn,
        startDate: moment(sd_data).format("YYYY-MM-DD"),
        endDate: moment(ed_data).format("YYYY-MM-DD"),
        direction:autoDetect===false?selectedCheckbox:undefined
      };

      await syncInvoicesFromXml(companyId, periodId, data)
        .then((resp) => {
          Swal.update({
            html: `
          <p style="color: red;">İşlemin yarıda kalmaması için lütfen sayfayı kapatmayınız ve yenilemeyiniz</p>
          <div class="swal2-progress-container">
            <div class="swal2-progress"></div>
          </div>
          <p id="progress-text">Dosyalar yükleniyor... (${index}/${xmlFilesLength})</p>
            `,
          });

          vkn = resp.data.vkn;
          tckn = resp.data.tckn;
          succesfullUplodedFile += resp.data.successfullFilesCount;
          failedUplodedFile += resp.data.failedFilesCount;
          failedFiles.push(...resp.data.failedFiles);
        })
        .catch((err) => {
          failedUplodedFile += filesToSend.length;
          failedFiles.push(...filesToSend.map((item) => item.name));
        });
    }
  };
  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const items = event.dataTransfer.items;
    const files = [];
    for (const item of items) {
      const entry = item.webkitGetAsEntry();
      if (entry.isDirectory) {
        const reader = entry.createReader();
        reader.readEntries((entries) => {
          entries.forEach((entry) => {
            if (entry.isFile) {
              entry.file((file) => {
                if (isXmlFile(file)) {
                  files.push(file);
                }
              });
            }
          });
          if (files.length > 0) {
            setFiles(files);
          } else {
            alert("Klasörde XML dosyası bulunamadı.");
          }
        });
      } else if (isXmlFile(entry)) {
        files.push(entry);
      }
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleCancel = (event) => {
    event.stopPropagation();
    setFiles([]);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "5%",
          left: "50%",
          transform: "translate(-50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          width: "80%",
          maxWidth: "500px",
          border: "2px solid transparent",
        }}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
      >
        <Button
          onClick={handleClose}
          sx={{
            borderRadius: 2,
            position: "absolute",
            top: -15,
            right: -15,
            backgroundColor: "whitesmoke",
            color: "gray",
            boxShadow: 4,
            minWidth: "0px",
            width: "30px",
            height: "30px",
            border: "2px solid transparent",
            "&:hover": {
              backgroundColor: "#e7eaeb",
            },
          }}
        >
          X
        </Button>
        <Typography variant="h6" component="h2" align="center" gutterBottom>
          XML Klasörünü Seçiniz
        </Typography>
        <Grid>
          <Typography variant="body1">Klasör Seçin</Typography>
          <div
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            className="drop-container"
            onClick={handleButtonClick}
          >
            {files.length > 0 ? (
              <span
                className="drop-title"
                sx={{ display: "flex", alignItems: "center" }}
              >
                Yüklenen XML Dosyaları:
                <Typography sx={{ ml: 1 }}>{files.length} dosya</Typography>
              </span>
            ) : (
              <span
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyItems: "center",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <span className="drop-title">Klasörü buraya sürükleyin</span>
                <Typography>veya</Typography>
                <span className="drop-title">Tıklayarak Seçin</span>
              </span>
            )}
          </div>
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileSelect}
            style={{ display: "none" }}
            webkitdirectory=""
            directory=""
            accept=".xml"
          />
        </Grid>
        <Grid container>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
            }}
          >
            <Checkbox
              checked={autoDetect}
              onChange={handleAutoDetectChange}
              sx={{
                "&.Mui-checked": {
                  color: "#041737",
                },
                "&.Mui-checked:hover": {
                  color: "#041737",
                },
                "&:hover": {
                  color: "#041737",
                },
              }}
            />
            <Typography>Faturanın Yönünü Otomatik Algıla</Typography>
          </Grid>

          {!autoDetect && (
            <>
              <Grid
                container
                xs={12}
                md={12}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                  mb: 2,
                }}
              >
                <Grid item xs={12} md={5}>
                  <Card>
                    <CardContent
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center", // Yatayda ortalar
                        justifyContent: "center", // Dikeyde ortalar
                        textAlign: "center", // Metni ortalar
                        height: "110px",
                        border:
                          selectedCheckbox === "outgoing"
                            ? "1px solid #041737"
                            : "",
                        "&:hover": { border: "1px solid gray" },
                      }}
                    >
                      <Grid>
                        <TbArrowAutofitUp size={30} color="#153243" />
                      </Grid>

                      <Typography component="div">Giden Fatura</Typography>
                      <Typography variant="body2" color="text.secondary">
                        Firma tarafından kesilen fatura XML'leri
                      </Typography>

                      <CustomCheckbox
                        icon={<CustomIcon />}
                        checkedIcon={<CustomCheckedIcon />}
                        checked={selectedCheckbox === "outgoing"}
                        onChange={handleChange}
                        name="outgoing"
                      />
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} md={5}>
                  <Card>
                    <CardContent
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center", // Yatayda ortalar
                        justifyContent: "center", // Dikeyde ortalar
                        textAlign: "center", // Metni ortalar
                        height: "110px",
                        border:
                          selectedCheckbox === "incoming"
                            ? "1px solid #041737"
                            : "",
                        "&:hover": { border: "1px solid gray" },
                      }}
                    >
                      <Grid>
                        <TbArrowAutofitDown size={30} color="#153243" />
                      </Grid>

                      <Typography component="div">Gelen Fatura</Typography>
                      <Typography variant="body2" color="text.secondary">
                        Firmaya kesilen fatura XML'leri
                      </Typography>
                      <CustomCheckbox
                        icon={<CustomIcon />}
                        checkedIcon={<CustomCheckedIcon />}
                        checked={selectedCheckbox === "incoming"}
                        onChange={handleChange}
                        name="incoming"
                      />
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </>
          )}

          <Grid item xs={12} textAlign="center">
            <Button
              variant="contained"
              className={files.length === 0 ? "" : "custom-button"}
              onClick={() => {
                handleUploadClick(startDate, endDate, companyId, periodId);
              }}
              sx={{ mr: 2, width: "auto",textTransform: "none" }}
              disabled={files.length === 0}
            >
              Yükle
            </Button>
            <Button variant="outlined" onClick={handleClose}>
              İptal
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};
