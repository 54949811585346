//TODO  BU SAYFADA MASTER GRİD(SUBDETAILS) AYARLARI VE COLDEFS AYARLARI BULUNUR.
//TODO  ANA GRİDİN column ayarları ulunur

import { FaRegCircleCheck } from "react-icons/fa6";
import { TbLoader, TbProgressAlert, TbProgressX } from "react-icons/tb";
import { Typography } from "@mui/material";
import moment from "moment";

const StatusRenderer = (props) => {
  const { value } = props;
  if (value) {
    return (
      <span
        title="Tamamlandı"
        style={{
          display: "inline-flex",
          alignItems: "center",
          justifyContent: "space-around",
          backgroundColor: "#28c76f",
          gap: 2,

          borderRadius: "7px",
          padding: "0px 12px",

          width: "85px",
          height: "25px",
          fontSize: "11px",
          color: "#ffffff",
        }}
      >
        <Typography fontSize={12} marginRight={0.5}>
          Tamamlandı
        </Typography>
        <FaRegCircleCheck style={{ color: "#ffffff" }} size={13} />
      </span>
    );
  } else {
    return (
      <span
        title="Oluşturuluyor"
        style={{
          display: "inline-flex",
          alignItems: "center",
          justifyContent: "space-around",
          backgroundColor: "#ff9f43",
          borderRadius: "7px",
          padding: "0px 12px",
          width: "85px",
          height: "25px",
          color: "white",
          gap: 2,
          fontSize: "12px",
        }}
      >
        Oluşturuluyor
        <TbLoader
          className="spinner"
          style={{
            color: "#ffffff",
          }}
          size={13}
        ></TbLoader>
      </span>
    );
  }
};

export const createColumnDefs = (ButtonRenderer) => [
  {
    headerName: "",
    checkboxSelection: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    headerClass: "checkbox",
    pinned: "left",
    width: 48,
    field: "checkboxBtn",
    resizable: false,
    filter:false,
    lockPosition: "left",
    suppressAutoSize: true,
    suppressColumnsToolPanel: true,
    suppressMenu: true,
    editable: false,
  },
  {
    field: "id",
    hide: true,
    suppressColumnsToolPanel: true,
  },
  {
    field: "companyId",
    hide: true,
    suppressColumnsToolPanel: true,
  },
  {
    field: "isCompleted",
    headerName: "Durum",
    width: 150,
    cellStyle: { textAlign: "center" },
    cellRenderer: StatusRenderer,
    filter: "agSetColumnFilter",
    filterParams: {
      valueFormatter: (params) => {
        return params.value ? "Tamamlandı" : "Oluşturuluyor";
      },
    },
  },
  {
    field: "createdAt",
    headerName: "Başlangıç",
    cellRenderer: (params) => {
      return params.value
        ? moment(params.value).format("DD.MM.YYYY HH:mm")
        : "";
    },
    filter:false,
    flex: 1,
  },
  {
    field: "info",
    headerName: "Bilgi",
    cellRenderer: (params) => {
      return params.data.status === 3
        ? `${params.data.info}`
        : `${params.data.info}`;
    },
    flex: 5,
    filter: "agTextColumnFilter",
    
  },
  {
    field: "file",
    headerName: "Dosya",
    cellStyle: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    filter:false,
    cellRenderer: (params) => {
      // isCompleted alanına göre butonun görünürlüğünü ayarlıyoruz
      return params.data.isCompleted ? ButtonRenderer(params):null;
    },
    width: 250,
  },
];
