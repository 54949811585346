
export async function createBalanceRuleStringForBackend(
  direction,
  process,
  field,
  input,
  subRules,
  note
) {
  let inputValue;
  if (Array.isArray(input)) {
    // Dizi ise elemanları virgül ile ayırarak birleştiriyoruz
    inputValue = input.map((item) => item.trim()).join(",");
  } else {
    inputValue = input;
  }

  let subRuleConditions = subRules
    ?.map((item) => {
      let subInputValue;
      if (Array.isArray(item.input)) {
        // Eğer sub-rule input bir array ise, virgülle ayrılmış string haline dönüştürüyoruz
        subInputValue = `${item.input.map((i) => i.trim()).join(",")}`;
      } else {
        // Eğer sub-rule input bir string ise, olduğu gibi kullanıyoruz
        subInputValue = item.input;
      }
      return calculateRule(item.process, item.field, subInputValue);
    })
    .join(" ");
  let rule = {
    condition: `invoice.direction == "${direction}" ${calculateRule(
      process,
      field,
      inputValue
    )} ${subRuleConditions}`,
    note,
  };
  return rule;
}

function calculateRule(process, field, input) {
  const andValue =
    process === '=='
      ? `&& ${field} == "${input}"`
      : process === '!='
        ? `&& ${field} != "${input}"`
        : process === '>'
          ? `&& ${field} > "${input}"`
          : process === '<'
            ? `&& ${field} < "${input}"`
            : process === '>='
              ? `&& ${field} >= "${input}"`
              : process === '<='
                ? `&& ${field} <= "${input}"`
                : process === 'includes' &&
                    (field === 'invoice.sender_object.vkn_tckn.List' || field === 'invoice.sender_tax.List')
                  ? `&& [${input
                      .split(',')
                      .filter((item) => item.trim() !== '')
                      .map(
                        (item) => `"${item.trim()}"`,
                      )}].find(item=>String(item.trim())?.toLocaleLowerCase("tr") === String(${field.replace('.List', '')})?.toLocaleLowerCase("tr"))`
                  : process === 'includes' &&
                      (field === 'invoice.receiver_object.vkn_tckn.List' || field === 'invoice.receiver_tax.List')
                    ? `&& [${input
                        .split(',')
                        .filter((item) => item.trim() !== '')
                        .map(
                          (item) => `"${item.trim()}"`,
                        )}].find(item=>String(item.trim())?.toLocaleLowerCase("tr") === String(${field.replace('.List', '')})?.toLocaleLowerCase("tr"))`
                    : process === 'includes' && (field === 'line.name.List' || field === 'line?.name.List')
                      ? `&& [${input
                          .split(',')
                          .filter((item) => item.trim() !== '')
                          .map(
                            (item) => `"${item.trim()}"`,
                          )}].find(item=>String(${field.replace('.List', '')})?.toLocaleLowerCase("tr").includes(String(item.trim())?.toLocaleLowerCase("tr")))`
                      : process === 'includes' && (field === 'line.code.List' || field === 'line?.code.List')
                        ? `&& [${input
                            .split(',')
                            .filter((item) => item.trim() !== '')
                            .map(
                              (item) => `"${item.trim()}"`,
                            )}].find(item=>String(${field.replace('.List', '')})?.toLocaleLowerCase("tr").includes(String(item.trim())?.toLocaleLowerCase("tr")))`
                        : process === 'includes' && field === 'invoice.accounting_lines.code.List'
                          ? `&& _.find(invoice.accounting_lines, (i) =>[${input
                              .split(',')
                              .filter((item) => item.trim() !== '')
                              .map((item) => `"${item.trim()}"`)}].includes(String(i.code)))`
                          : process === 'includes' && field === 'invoice.notes'
                            ? `&& _.find([${input
                                .split(',')
                                .filter((item) => item.trim() !== '')
                                .map(
                                  (item) => `"${item.trim()}"`,
                                )}], (key) => {return _.find(invoice.notes, (innerkey) => innerkey?.toLocaleLowerCase("tr").includes(key?.toLocaleLowerCase('tr')))})`
                            : process === 'includes'
                              ? `&& ${field}?.toLocaleLowerCase("tr").includes("${input}"?.toLocaleLowerCase("tr"))`
                              : //
                                process === 'notIncludes' &&
                                  (field === 'invoice.sender_object.vkn_tckn.List' || field === 'invoice.sender_tax.List')
                                ? `&& ![${input
                                    .split(',')
                                    .filter((item) => item.trim() !== '')
                                    .map(
                                      (item) => `"${item.trim()}"`,
                                    )}].find(item=>String(item.trim())?.toLocaleLowerCase("tr") === String(${field.replace('.List', '')})?.toLocaleLowerCase("tr"))`
                                : process === 'notIncludes' &&
                                    (field === 'invoice.receiver_object.vkn_tckn.List' ||
                                      field === 'invoice.receiver_tax.List')
                                  ? `&& ![${input
                                      .split(',')
                                      .filter((item) => item.trim() !== '')
                                      .map(
                                        (item) => `"${item.trim()}"`,
                                      )}].find(item=>String(item.trim())?.toLocaleLowerCase("tr") === String(${field.replace('.List', '')})?.toLocaleLowerCase("tr"))`
                                  : process === 'notIncludes' && (field === 'line.name.List' || field === 'line?.name.List')
                                    ? `&& ![${input
                                        .split(',')
                                        .filter((item) => item.trim() !== '')
                                        .map(
                                          (item) => `"${item.trim()}"`,
                                        )}].find(item=>String(${field.replace('.List', '')})?.toLocaleLowerCase("tr").includes(String(item.trim())?.toLocaleLowerCase("tr")))`
                                    : process === 'notIncludes' && (field === 'line.code.List' || field === 'line?.code.List')
                                      ? `&& ![${input
                                          .split(',')
                                          .filter((item) => item.trim() !== '')
                                          .map(
                                            (item) => `"${item.trim()}"`,
                                          )}].find(item=>String(${field.replace('.List', '')})?.toLocaleLowerCase("tr").includes(String(item.trim())?.toLocaleLowerCase("tr")))`
                                      : process === 'notIncludes' && field === 'invoice.accounting_lines.code.List'
                                        ? `&& _.find(invoice.accounting_lines, (i) =>![${input
                                            .split(',')
                                            .filter((item) => item.trim() !== '')
                                            .map((item) => `"${item.trim()}"`)}].includes(String(i.code)))`
                                        : process === 'notIncludes'
                                          ? `&& !${field}?.toLocaleLowerCase("tr").includes("${input}"?.toLocaleLowerCase("tr"))`
                                          : process === 'endsWith'
                                            ? `&& ${field}?.toLocaleLowerCase("tr").endsWith("${input}"?.toLocaleLowerCase("tr"))`
                                            : process === 'startsWith'
                                              ? `&& ${field}?.toLocaleLowerCase("tr").startsWith("${input}"?.toLocaleLowerCase("tr"))`
                                              : undefined;

  return andValue;
}

export async function createBusinessRuleStringForBackend(
  direction,
  note,
  process,
  field,
  input,
  accountCode,
  subRules
) {
  const inputValue = Array.isArray(input) ? input.join(",") : input;
  let rule = {
    condition: `invoice.direction == ${direction} ${calculateRule(
      process,
      field,
      inputValue
    )} ${subRules
      .map((item) => calculateRule(item.process, item.field, item.input))
      .join(" ")}`,
    note,
    return: accountCode,
  };
  return rule;
}

export function parseBusinessRuleConditionStringForFrontend(condition) {
  const process =
    (condition.includes('!') && condition.includes('includes')) || condition.includes('![')
      ? 'notIncludes'
      : condition.includes('includes') || condition.includes('find')
        ? 'includes'
        : condition.includes('startsWith')
          ? 'startsWith'
          : condition.includes('endsWith')
            ? 'endsWith'
            : condition.includes('!=')
              ? '!='
              : condition.includes('>=')
                ? '>='
                : condition.includes('<=')
                  ? '<='
                  : condition.includes('>')
                    ? '>'
                    : condition.includes('<')
                      ? '<'
                      : condition.includes('==')
                        ? '=='
                        : undefined;
  const field =
    condition.includes('invoice.sender_tax') && condition.includes('find')
      ? 'invoice.sender_tax.List'
      : condition.includes('invoice.sender_tax')
        ? 'invoice.sender_tax'
        : condition.includes('invoice.receiver_tax') && condition.includes('find')
          ? 'invoice.receiver_tax.List'
          : condition.includes('invoice.receiver_tax')
            ? 'invoice.receiver_tax'
            : (condition.includes('line.name') || condition.includes('line?.name')) && condition.includes('find')
              ? 'line?.name.List'
              : condition.includes('line.name') || condition.includes('line?.name')
                ? 'line?.name'
                : undefined;
  const input =
    (process === 'includes' || process === 'notIncludes') &&
    (field === 'invoice.sender_tax.List' ||
      field === 'invoice.receiver_tax.List' ||
      field === 'line.name.List' ||
      field === 'line?.name.List')
      ? condition
          .substring(condition.indexOf('[') + 1, condition.indexOf(']'))
          .trim()
          .split(',')
          .map((item) => item.substring(1, item.length - 1))
      : process === 'includes' || process === 'notIncludes'
        ? condition
            .substring(condition.indexOf('.includes(') + 11, condition.lastIndexOf('?.toLocaleLowerCase("tr")') - 1)
            .trim()
        : process === 'startsWith'
          ? condition.substring(
              condition.indexOf('.startsWith(') + 13,
              condition.lastIndexOf('?.toLocaleLowerCase("tr")') - 1,
            )
          : process === 'endsWith'
            ? condition.substring(
                condition.indexOf('.endsWith(') + 11,
                condition.lastIndexOf('?.toLocaleLowerCase("tr")') - 1,
              )
            : process === '!='
              ? condition.substring(condition.indexOf('!=') + 4, condition.lastIndexOf('"'))
              : process === '>='
                ? condition.substring(condition.indexOf('>=') + 4, condition.lastIndexOf('"'))
                : process === '<='
                  ? condition.substring(condition.indexOf('<=') + 4, condition.lastIndexOf('"'))
                  : process === '>'
                    ? condition.substring(condition.indexOf('>') + 3, condition.lastIndexOf('"'))
                    : process === '<'
                      ? condition.substring(condition.indexOf('<') + 3, condition.lastIndexOf('"'))
                      : process === '=='
                        ? condition.substring(condition.indexOf('==') + 4, condition.lastIndexOf('"'))
                        : undefined;

  return { field, process, input };
}
export function parseBalanceRuleConditionStringForFrontend(condition) {
  const process =
    (condition.includes("!") && condition.includes("includes")) ||
    condition.includes("![")
      ? "notIncludes"
      : condition.includes("includes") || condition.includes("find")
      ? "includes"
      : condition.includes("startsWith")
      ? "startsWith"
      : condition.includes("endsWith")
      ? "endsWith"
      : condition.includes("!=")
      ? "!="
      : condition.includes(">=")
      ? ">="
      : condition.includes("<=")
      ? "<="
      : condition.includes(">")
      ? ">"
      : condition.includes("<")
      ? "<"
      : condition.includes("==")
      ? "=="
      : undefined;
  const field =
    condition.includes("invoice.sender_object.vkn_tckn") &&
    condition.includes("find")
      ? "invoice.sender_object.vkn_tckn.List"
      : condition.includes("invoice.sender_object.vkn_tckn")
      ? "invoice.sender_object.vkn_tckn"
      : condition.includes("invoice.receiver_object.vkn_tckn") &&
        condition.includes("find")
      ? "invoice.receiver_object.vkn_tckn.List"
      : condition.includes("invoice.receiver_object.vkn_tckn")
      ? "invoice.receiver_object.vkn_tckn"
      : condition.includes("invoice.profile_id")
      ? "invoice.profile_id"
      : condition.includes("invoice.type_code")
      ? "invoice.type_code"
      : condition.includes("invoice.payable_amount")
      ? "invoice.payable_amount"
      : condition.includes("invoice.number")
      ? "invoice.number"
      : (condition.includes("line.name") || condition.includes("line?.name")) &&
        condition.includes("find")
      ? "line?.name.List"
      : condition.includes("line.name") || condition.includes("line?.name")
      ? "line?.name"
      : condition.includes("line.type")
      ? "line.type"
      : condition.includes("line.amount")
      ? "line.amount"
      : condition.includes("line.code") && condition.includes("find")
      ? "line.code.List"
      : condition.includes("line.code")
      ? "line.code"
      : condition.includes("line.percent")
      ? "line.percent"
      : condition.includes("invoice.accounting_lines")
      ? "invoice.accounting_lines.code.List"
      : condition.includes("invoice.notes")
      ? "invoice.notes"
      : undefined;
  const input =
    (process === "includes" || process === "notIncludes") &&
    (field === "invoice.accounting_lines.code.List" ||
      field === "invoice.receiver_object.vkn_tckn.List" ||
      field === "invoice.sender_object.vkn_tckn.List" ||
      field === "line.name.List" ||
      field === "line?.name.List" ||
      field === "line.code.List" ||
      field === "invoice.notes")
      ? condition
          .substring(condition.indexOf("[") + 1, condition.indexOf("]"))
          .trim()
          .split(",")
          .map((item) => item.substring(1, item.length - 1))
      : process === "includes" || process === "notIncludes"
      ? condition
          .substring(
            condition.indexOf(".includes(") + 11,
            condition.lastIndexOf('?.toLocaleLowerCase("tr")') - 1
          )
          .trim()
      : process === "startsWith"
      ? condition.substring(
          condition.indexOf(".startsWith(") + 13,
          condition.lastIndexOf('?.toLocaleLowerCase("tr")') - 1
        )
      : process === "endsWith"
      ? condition.substring(
          condition.indexOf(".endsWith(") + 11,
          condition.lastIndexOf('?.toLocaleLowerCase("tr")') - 1
        )
      : process === "!="
      ? condition.substring(
          condition.indexOf("!=") + 4,
          condition.lastIndexOf('"')
        )
      : process === ">="
      ? condition.substring(
          condition.indexOf(">=") + 4,
          condition.lastIndexOf('"')
        )
      : process === "<="
      ? condition.substring(
          condition.indexOf("<=") + 4,
          condition.lastIndexOf('"')
        )
      : process === ">"
      ? condition.substring(
          condition.indexOf(">") + 3,
          condition.lastIndexOf('"')
        )
      : process === "<"
      ? condition.substring(
          condition.indexOf("<") + 3,
          condition.lastIndexOf('"')
        )
      : process === "=="
      ? condition.substring(
          condition.indexOf("==") + 4,
          condition.lastIndexOf('"')
        )
      : undefined;
  return { field, process, input };
}

