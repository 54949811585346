import React, { useEffect, useRef, useState } from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import {

  TbUserCheck,
  TbUserCancel,
  TbUserPlus,
  TbUserEdit,
  TbUserMinus,
} from "react-icons/tb";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Grid, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import { getJsonItem } from "../../../utils/localStorageProcess";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    keepMounted
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
      // Typography için eklenen yeni stiller
      "& .MuiTypography-root:not([data-exempt='true'])": {
        fontSize: "14px",
        fontWeight: 400,
        color: "#605f66",
      },
    },
  },
}));

export default function ProcessMenu({
  startDate,
  endDate,
  setModalId,
  handleModalOpen,
  setUpdateRow,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const location = useLocation();
  const [companyDetails, setCompanyDetails] = useState();
  const companyDetailsRef = useRef(companyDetails);
  useEffect(() => {
    let locationSplit = location.pathname.split("/");
    const details = getJsonItem(`#${locationSplit[2]}/${locationSplit[3]}`);
    setCompanyDetails(details);
    companyDetailsRef.current = details; // referansı güncelle
  }, [location.pathname]);
  


  return (
    <Grid>
      <Button
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        disableElevation
        sx={{
          textTransform: "none",
          backgroundColor: "#dbf0fd",
          color: "#2ea8f3",
          "&:hover": {
            backgroundColor: "#cfe6f5",
          },
        }}
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        İşlemler
      </Button>
      <StyledMenu
        id="simple-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        disableScrollLock
      >
        <MenuItem disabled>
          <Typography
            data-exempt="true"
            variant="subtitle2"
            fontSize={12}
            color={"black"}
            fontWeight={600}
          >
            KULLANICI İŞLEMLERİ
          </Typography>
        </MenuItem>
        <MenuItem
          sx={{ display: "flex", alignItems: "center", gap: 1 }}
          onClick={() => {
            setModalId("#createSelectedUsersSwal");
            handleClose();
            handleModalOpen();
          }}
        >
          <TbUserPlus/>
          <Typography >Kullanıcı Ekle</Typography>
        </MenuItem>
        <MenuItem
          sx={{ display: "flex", alignItems: "center", gap: 1 }}
          onClick={() => {
            setModalId("#updateSelectedUsersSwal");
            handleClose();
            handleModalOpen();
          }}
        >
          <TbUserEdit/>
          <Typography >Kullanıcı Güncelle</Typography>
        </MenuItem>
        <MenuItem
          sx={{ display: "flex", alignItems: "center", gap: 1 }}
          onClick={() => {
            setModalId("#givePermissionToUsers");
            handleClose();
            handleModalOpen();
          }}
        >
          <TbUserCheck/>
          <Typography >Seçilen Kullacılara Mükellef Yetkisi Ver</Typography>
        </MenuItem>
        <MenuItem
          sx={{ display: "flex", alignItems: "center", gap: 1 }}
          onClick={() => {
            setModalId("#removePermissionFromToUsers");
            handleClose();
            handleModalOpen();
          }}
        >
          <TbUserCancel />
          <Typography>Seçilen Kullacılardan Mükellef Yetkisi Kaldır</Typography>
        </MenuItem>
        <MenuItem disabled>
          <Typography
            data-exempt="true"
            variant="subtitle2"
            fontSize={12}
            color={"black"}
            fontWeight={600}
          >
            SİLME
          </Typography>
        </MenuItem>
        <MenuItem
          sx={{ display: "flex", alignItems: "center", gap: 1 }}
          onClick={() => {
            setModalId("#deleteSelectedUsers");
            handleClose();
            handleModalOpen();
          }}
        >
          <TbUserMinus color="red" />
          <Typography>Seçili Kullanıcıları Sil</Typography>
        </MenuItem>
      </StyledMenu>
    </Grid>
  );
}
