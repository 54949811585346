/** @format */

import axios from "axios";
import CheckToken from "../../../utils/checkToken/CheckToken";

export const copyAdminRuleToCompanies = async (data) => {
  const token = await CheckToken();

  if (!token) {
    window.location.href = "/login";
  } else {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/admin/copy-rule-to-selected-companies`,{...data},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      } 
    );
  }
};