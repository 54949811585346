/**
 * eslint-disable react-hooks/exhaustive-deps
 *
 * @format
 */

//todo MODAL AÇILMASI GEREKTİREN İŞLEMLER SONU MODAL İLE BİTEN DOSYALARDA(fatura seçilerek yapılan işlemlerde),
//todo DİREKT İSTEKLE HALLEDİLEBİLİR İŞLEMLER 'ProcessesAndProcessMenu' DOSYASINDADIR.

import React, {
    useState,
    useMemo,
    useEffect,
    useRef,
    useCallback,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";

// Grids---------
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import gridSideBar from "../../components/GridSideBar/gridSideBar.js";

import { createColumnDefs } from "./gridOptionsAndcolumnDefs.js";
import { detailsGridOptions } from "./gridOptionsAndcolumnDefs.js";
import localeTextTr from "../../locale.tr.js";

//Components
import Sidebar from "../../components/sideBar/sideBar.js";
import Navbar from "../../components/navbar/navbar.js";
import DateRangePicker from "../../components/DateRangePicker/index.js";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import localStorage, { set } from "local-storage";
import { getJsonItem } from "../../utils/localStorageProcess/index.js";
import { setJsonItem } from "../../utils/localStorageProcess/index.js";

//Modals and Menus
import ProcessMenu from "./processesAndProcessMenu.js";

import { XmlFileModal } from "./getFromXmlFileModal.js";
import { ViewSyncs } from "./viewSyncsModal.js";
import { SeparateSelectedInvoicesSwal } from "./separateSelectedInvoicesSwal.js";
import { AddBlackListSwal } from "./addBlackListSwal.js";
import { RemoveBlackListSwal } from "./removeBlackListSwal.js";
import { ViewSelectedInvoices } from "./viewSelectedInvoicesModal.js";
import { DeleteSelectedInvoicesSwal } from "./deleteInvoicesSwal.js";
import { CreateSelectedFiscesSwal } from "./createSelectedFichesSwal.js";
//Icons
import KeyboardDoubleArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowLeftOutlined";
import KeyboardDoubleArrowRightOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowRightOutlined";
import { FaRegFileLines } from "react-icons/fa6";
import { TbFileSearch } from "react-icons/tb";
import {
    Grid,
    Alert,
    Typography,
    Button,
    IconButton,
    Box,
    TextField,
    Autocomplete,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
//Icons

//Apis
import { getListInvoices } from "../../api/company/invoices/getListInvoices/index.js";
import { getCompanyDetails } from "../../api/advisor/getCompanyDetails/index.js";
import { setTitle } from "../../utils/setTitle/index.js";
import { createTurkishFilterParams } from "../../utils/agGridTextFilterTr/agGridFilterUtils.js";
import AnimatedAlerts from "../../components/animatedAlerts/index.js";
import { CreateLucaAccountSwal } from "../../components/createLucaAccountSwal/index.js";
import { getLucaAccounts } from "../../api/company/invoices/getLucaAccounts/index.js";
import { updateSeparatedDataOfInvoices } from "../../api/company/invoices/updateSeparatedDataOfInvoices/index.js";
import Swal from "sweetalert2";
import { showLoadingSwal } from "../../components/loadingSwal/index.js";
import { Update } from "@mui/icons-material";
import { UpdateSelectedInvoicesSeparatedData } from "./updateSelectedInvoicesSeparatedData.js";

//Apis

function Invoices() {
    const [isOpen, setIsOpen] = useState(false);
    const lucaAccountRef = useRef();

    //modals açılma durumlar
    const [modalId, setModalId] = useState("1");
    const [modalOpen, setModalOpen] = useState(false);
    const handleModalOpen = () => setModalOpen(true);
    const handleModalClose = () => setModalOpen(false);
    //--------
    //grid fatura görüntüleme modalı için açılır kapanır state'si
    //grid fatura görüntüleme modalı için açılır kapanır state'si

    const [companyDetails, setCompanyDetails] = useState();

    const [loading, setLoading] = useState(true);
    const [firstLoading, setFirstLoading] = useState(true); //SAYFA İLK AÇTIĞINDA getListInvoices ve companies'e İSTEK ATMAMASI İÇİN FLAG

    //Modallarda Yapılan işlemlerde sonra tablonun güncellemesi için
    const [updateRow, setUpdateRow] = useState(false);
    const expandedRowIdRef = useRef(null);
    const [expandedRowId, setExpandedRowId] = useState(null);
    useEffect(() => {
        if (updateRow === true) {
            setLoading(true);
            search(companyDetails?.companyId, companyDetails?.periodId);
            setUpdateRow(false);
        }
    }, [updateRow]);
    //Modallarda Yapılan işlemlerde sonra tablonun güncellemesi için

    const location = useLocation();
    const navigate = useNavigate();

    const [rowData, setRowData] = useState([]);
    const gridRef = useRef(null);
    const [selectedInvoices, setSelectedInvoices] = useState([]);
    const [invoice, setInvoice] = useState([]);
    const [filteredCount, setFilteredCount] = useState(0);
    const [sortModel, setSortModel] = useState([]);
    const [updatedInvoices, setUpdatedInvoices] = useState([]);
    const updateChangedInvoices = async (isShortCut = true) => {
        const gridApi = gridRef.current.api;

        const editedRows = []; // Get all row nodes from the ag-Grid
        await gridApi.forEachNode((node) => {
            if (node.data?.separatedData.some((item) => item?.isEdited)) {
                editedRows.push(node.data);
            }
        });
        setUpdatedInvoices(editedRows);
        if (isShortCut) {
            setModalId("#updateSelectedInvoicesSeparatedData");
            handleModalOpen();
        }
    };

    useEffect(() => {
        const handleKeyDown = async (event) => {
            const gridApi = gridRef.current.api;

            // Alt + H tuş kombinasyonu
            if (event.altKey && event.keyCode === 72 ) {
                // Get the expanded row node using the expandedRowIdRef
                const expandedRowNode = gridApi.getRowNode(
                    expandedRowIdRef.current
                );
                if (expandedRowNode) {
                    // Get the children of the expanded row
                    const separatedData =
                        expandedRowNode.data.separatedData || []; // Ensure separatedData is defined

                    // Calculate total borç and alacak
                    let totalBorc = 0; // Start with any initial value
                    let totalAlacak = 0;

                    separatedData.forEach((row) => {
                        totalBorc += row.borc || 0; // Ensure borc is defined
                        totalAlacak += row.alacak || 0; // Ensure alacak is defined
                    });

                    const diff = totalBorc - totalAlacak;
                    let newRow = null;
                    console.log(separatedData, "separatedData");
                    let newId =
                        separatedData.sort((a, b) => b.id - a.id)[0].id + 1;
                    console.log(newId);
                    if (diff > 0) {
                        newRow = {
                            hesap_kodu: "",
                            borc: 0,
                            alacak: parseFloat(Math.abs(diff).toFixed(2)),
                            isEdited: true,
                            id: newId,
                        };
                    } else if (diff < 0) {
                        newRow = {
                            hesap_kodu: "",
                            borc: parseFloat(Math.abs(diff).toFixed(2)),
                            alacak: 0,
                            isEdited: true,
                            id: newId,
                        };
                    }

                    // If a newRow is created, add it to separatedData
                    if (newRow) {
                        separatedData.push(newRow); // Add the new row to the array

                        // Optionally, if you need to update the grid with the new data
                        expandedRowNode.setData({
                            ...expandedRowNode.data,
                            separatedData: [...separatedData], // Spread operator to ensure a new reference
                            isEdited: true,
                        });
                    }
                }
            }

            // Alt + K tuş kombinasyonu
            if (event.altKey && event.keyCode===75) {
                updateChangedInvoices();
            }
        };

        // Attach the keydown event listener
        document.addEventListener("keydown", (e)=>
            console.log(e)
        );

        // Clean up the event listener on component unmount
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [expandedRowIdRef]);

    const onSortChanged = useCallback(() => {
        const value = gridRef.current.api
            .getColumnState()
            .find((s) => s.sort != null);
        if (value) {
            setSortModel([value]);
        } else {
            setSortModel([]);
        }
    }, [gridRef, setSortModel]);

    const onSelectionChanged = () => {
        const selectedNodes = gridRef.current.api.getSelectedNodes();
        const selectedData = selectedNodes.map((node) => node.data);
        // Seçili satırları grid'in mevcut sıralamasına göre sırala
        const sortedSelectedData = selectedData.sort((a, b) => {
            const rowIndexA =
                gridRef.current.api.getRowNode(a.id)?.rowIndex ?? -1;
            const rowIndexB =
                gridRef.current.api.getRowNode(b.id)?.rowIndex ?? -1;
            return rowIndexA - rowIndexB;
        });

        setSelectedInvoices(sortedSelectedData);
    };

    const [startDate, setStartDate] = useState(
        moment()
            .subtract(1, "month")
            .startOf("month")
            .format("YYYY-MM-DD HH:mm:ss")
    );
    const [endDate, setEndDate] = useState(
        moment()
            .subtract(1, "month")
            .endOf("month")
            .format("YYYY-MM-DD HH:mm:ss")
    );

    const handlePreviousMonth = () => {
        setStartDate(moment(startDate).subtract(1, "month").startOf("month"));
        setEndDate(moment(endDate).subtract(1, "month").endOf("month"));
    };
    const ItemSelectorCellEditor = (props, accountCodes) => {
        let groupedOptions = [
            {
                group: "Muhasebe Hesapları",
                options: lucaAccountRef.current,
            },
        ];

        const options = groupedOptions.flatMap((group) => group.options);
        const updateData = useCallback(
            async (newDataAccountCode) => {
                const rowNode = props.node;
                // Detail gridde 'hesap_kodu' alanını güncelle
                rowNode.setData({
                    ...props.data,
                    hesap_kodu: newDataAccountCode,
                });

                // Ana griddeki satırı bul ve güncelle
                const mainGridApi = gridRef.current.api; // Ana grid API'sini context üzerinden alalım
                const mainRowNode = mainGridApi.getRowNode(
                    expandedRowIdRef.current
                );

                if (mainRowNode) {
                    let needToUpdateData = mainRowNode.data.separatedData;
                    const dataIndex = needToUpdateData.findIndex(
                        (d) => d.id === props.data.id
                    );
                    needToUpdateData[dataIndex] = {
                        ...mainRowNode.data.separatedData[dataIndex],
                        hesap_kodu: newDataAccountCode,
                        isEdited: true,
                    };
                    mainRowNode.setData({
                        ...mainRowNode.data,
                        separatedData: needToUpdateData,
                    }); // Ana griddeki veriyi güncelle
                }
            },
            [props.node, props.context]
        );

        const handleChange = async (newValue) => {
            if (newValue?.value === "add-new") {
                let newAccount = await CreateLucaAccountSwal(
                    companyDetails?.companyId,
                    companyDetails?.periodId
                );
                lucaAccountRef.current = [
                    ...lucaAccountRef.current,
                    {
                        id: Math.floor(
                            100000 + Math.random() * 900000
                        ).toString(),
                        hesap_adi: newAccount?.input1,
                        hesap_kodu: newAccount?.input2,
                    },
                ];
                updateData(newAccount?.input2 || "", newAccount?.input1 || "");
                props.stopEditing();
                return;
            } else {
                updateData(
                    newValue?.hesap_kodu ? newValue.hesap_kodu : "",
                    newValue?.hesap_adi ? newValue.hesap_adi : ""
                );
                props.stopEditing();
            }
        };
        const [open, setOpen] = useState(true);
        return (
            <Autocomplete
                style={{ height: "20px" }}
                value={
                    options.find(
                        (option) => option?.hesap_kodu === props?.value
                    ) || null
                }
                onChange={(event, newValue) => {
                    handleChange(newValue);
                }}
                options={options}
                noOptionsText={"Seçenek bulunamadı"}
                autoHighlight
                selectOnFocus
                blurOnSelect
                openOnFocus
                disableClearable
                onKeyDown={(event) => {
                    if (event.key === "Enter") {
                        const highlightedOption = event.target.querySelector(
                            'li[data-focus="true"]'
                        );
                        if (highlightedOption) {
                            highlightedOption.click();
                        }
                    }
                }}
                filterOptions={(options, params) => {
                    const filtered = options.filter((option) => {
                        let label = `${option.hesap_kodu} ${option.hesap_adi}`;
                        return label
                            .toLocaleLowerCase("tr")
                            .includes(
                                params?.inputValue?.toLocaleLowerCase("tr")
                            );
                    });
                    if (filtered?.length === 0) {
                        filtered.push({
                            label: "Yeni Hesap Kodu Eklemek İçin Tıklayın",
                            value: "add-new",
                        });
                    }
                    return filtered;
                }}
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                groupBy={(option) => {
                    const group = groupedOptions.find((g) =>
                        g.options.includes(option)
                    );
                    return group ? group.group : "";
                }}
                getOptionLabel={(option) =>
                    option.label
                        ? option.label
                        : `${option.hesap_kodu} - ${
                              option.hesap_adi
                          } - Bakiye: ${option.bakiye || "0"}`
                }
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        autoFocus={true}
                    />
                )}
                renderGroup={(params) => (
                    <Box key={params.key}>
                        <li
                            key={params.key}
                            style={{
                                display: "block",
                            }}
                        >
                            <Typography
                                variant="body1"
                                style={{
                                    fontWeight: "bold",
                                    backgroundColor: "#f0f0f0",
                                    padding: "5px",
                                }}
                            >
                                {params.group}
                            </Typography>
                            <ul
                                style={{
                                    padding: 0,
                                    margin: 0,
                                    whiteSpace: "nowrap",
                                    display: "block",
                                }}
                            >
                                {params.children}
                            </ul>
                        </li>
                    </Box>
                )}
                renderOption={(props, option) => (
                    <Box
                        component="li"
                        {...props}
                        key={option.hesap_kodu}
                        style={{
                            paddingLeft: "7px",
                            maxWidth: "100%",
                        }}
                    >
                        <Typography>
                            {option.label || (
                                <>
                                    <strong>{option.hesap_kodu}</strong> -{" "}
                                    {option.hesap_adi} - Bakiye:{" "}
                                    {option.bakiye || "0"}
                                </>
                            )}
                        </Typography>
                    </Box>
                )}
                ListboxProps={{
                    style: {
                        maxHeight: "200px",
                    },
                }}
            />
        );
    };
    const handleNextMonth = () => {
        setStartDate(moment(startDate).add(1, "month").startOf("month"));
        setEndDate(moment(endDate).add(1, "month").endOf("month"));
    };

    useEffect(() => {
        const sideBarOpen = localStorage.get("sidebar");
        if (sideBarOpen === "false") {
            setIsOpen(false);
        } else {
            setIsOpen(true);
        }
        let locationSplit = location.pathname.split("/");
        let details = getJsonItem(`#${locationSplit[2]}/${locationSplit[3]}`);
        if (moment(details.year, "YYYY").year() !== moment().year()) {
            const year = moment(details.year, "YYYY");
            setStartDate(
                year
                    .endOf("year")
                    .startOf("month")
                    .format("YYYY-MM-DD HH:mm:ss")
            );
            setEndDate(year.endOf("year").format("YYYY-MM-DD HH:mm:ss"));
        }
        setCompanyDetails(details);
        if (!details?.companyId) {
            getCompanyDetails(locationSplit[2], locationSplit[3])
                .then(async (resp) => {
                    if (resp.data.companyId) {
                        await setJsonItem(
                            `#${locationSplit[2]}/${locationSplit[3]}`,
                            resp.data
                        );
                        window.location.reload();
                    } else {
                        navigate("/dashboard", { replace: true });
                    }
                })
                .catch((err) => {
                    navigate("/dashboard", { replace: true });
                });
        } else {
            setTitle(details?.name, locationSplit[4]);
            search(locationSplit[2], locationSplit[3]);

            getLucaAccounts(locationSplit[2], locationSplit[3])
                .then(async (resp) => {
                    const updateData = resp?.data.list.filter(
                        (item, index, self) =>
                            index === self.findIndex((t) => t.id === item.id)
                    );
                    lucaAccountRef.current = updateData;
                    if (resp.data.message !== "") {
                        Swal.fire({
                        icon: "warning",
                        title: "Uyarı",
                        html: resp.data.message,
                        confirmButtonText: "Tamam",
                        })
                    }
                })
                .catch((err) => {
                    console.log(err.response);
                });
        }
    }, []);

    const search = (companyId, periodId) => {
        getListInvoices(companyId, periodId, startDate, endDate)
            .then((resp) => {
                setRowData(resp.data);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    const handleSearch = () => {
        let locationSplit = location.pathname.split("/");
        const details = getJsonItem(`#${locationSplit[2]}/${locationSplit[3]}`);
        setLoading(true);
        search(details?.companyId, details?.periodId);
        const filteredRows = gridRef.current.api.getModel().getRowCount(); // Filtrelenmiş veri sayısını alıyoruz
        setFilteredCount(filteredRows);
    };
    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    const onFilterChanged = useCallback((event) => {
        const filteredRows = event.api.getDisplayedRowCount(); // Filtrelenmiş veri sayısını alıyoruz
        setFilteredCount(filteredRows);
    }, []);

    const onRowDataUpdated = useCallback((event) => {
        const filteredRows = event.api.getDisplayedRowCount(); // Filtrelenmiş veri sayısını alıyoruz
        setFilteredCount(filteredRows);
    }, []);

    const defaultColDef = useMemo(
        () => ({
            filter: true,
            sortable: true,
            resizable: true,
            floatingFilter: true,
            filterParams: createTurkishFilterParams(),
        }),
        []
    );

    const ButtonRenderer = (props) => {
        const handleClick = () => {
            setInvoice([props.data]);
            setTimeout(() => {
                setModalId("#viewInvoice");
                handleModalOpen();
            }, 0);
        };

        return (
            <Button
                onClick={handleClick}
                sx={{
                    padding: 0,
                    minWidth: "auto",
                    width: "auto",
                    height: "auto",
                    display: "flex",
                }}
            >
                <TbFileSearch color="#3fd549" size={21}></TbFileSearch>
            </Button>
        );
    };

    const columnDefs = useMemo(
        () => createColumnDefs(ButtonRenderer, companyDetails?.class),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [companyDetails?.class]
    );

    const detailCellRendererParams = useMemo(() => {
        return {
            detailGridOptions: detailsGridOptions(
                ItemSelectorCellEditor,
                expandedRowIdRef.current,
                companyDetails?.class
            ),

            /*************  ✨ Codeium Command ⭐  *************/
            /**
             * ag-grid'in detail cell renderer'ına verilecek olan detail row datalarını
             * oluşturur.
             *
             * @param {Object} params detail cell renderer'a gelen parametreler
             *
             * @returns {void}
             */
            /******  635d7e9f-2b64-40da-a509-28d46bf1f64a  *******/
            getDetailRowData: (params) => {
                params.successCallback(params.data.separatedData);
                expandedRowIdRef.current = params.node.parent.id;
                setExpandedRowId(params.node.parent.id);
            },
        };
    }, [expandedRowIdRef.current]);

    const getMainMenuItems = (params) => {
        const allMenuItems = params.defaultItems;
        const itemsToRemove = ["toolPanelButton"]; // 'choose columns' seçeneği

        return allMenuItems.filter((item) => !itemsToRemove.includes(item));
    };

    return (
        <Grid container>
            <Grid
                item
                sx={{
                    flexBasis: isOpen ? "275px" : "95px",
                    flexShrink: 0,
                    transition: "flex-basis 0.3s ease",
                }}
                zIndex={1}
            >
                <Sidebar
                    status={isOpen}
                    toggleSidebar={toggleSidebar}
                    companyClass={companyDetails?.class}
                />
            </Grid>
            <Grid
                item
                zIndex={0}
                sx={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "flex-end",
                    flexDirection: "column",
                    gap: 1,
                    pr: "12px",
                }}
            >
                <Grid item xs={12}>
                    <Navbar
                        setLoading={setLoading}
                        setFirstLoading={setFirstLoading}
                        firstLoading={firstLoading}
                    />{" "}
                </Grid>
                <Grid
                    item
                    md={12}
                    sx={{ display: "flex", flexDirection: "column" }}
                >
                    <Grid item md={12} sx={{ mt: 1 }}>
                        <AnimatedAlerts
                            infoText={`
                        Bu sayfada, seçtiğiniz tarih aralığındaki
                        <b style={{ letterSpacing: "0.05em" }}>
                        fişleştirilmemiş faturalarınızı
                        </b>
                        görüntüleyebilir ve fiş oluşturabilirsiniz.`}
                            warningText={`Faturaların <b style={{ letterSpacing: "0.05em" }}>iptal, red</b> durumlarını ve <b style={{ letterSpacing: "0.05em" }}>toplam fatura sayısını</b> entegratörden kontrol ediniz.`}
                        />
                    </Grid>
                    <Grid
                        className="grid-area"
                        sx={{
                            padding: 1,
                            mb: 1,
                            alignItems: "center",
                        }}
                    >
                        <Grid
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}
                        >
                            <Grid
                                sx={{ display: "flex", alignItems: "center" }}
                            >
                                <LocalizationProvider
                                    dateAdapter={AdapterMoment}
                                    adapterLocale="tr"
                                >
                                    <Grid
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: 1,
                                            pl: 1,
                                            pt: 0.5,
                                        }}
                                    >
                                        <IconButton
                                            onClick={handlePreviousMonth}
                                            className="date-button"
                                        >
                                            <KeyboardDoubleArrowLeftOutlinedIcon fontSize="small"></KeyboardDoubleArrowLeftOutlinedIcon>
                                        </IconButton>
                                        <DateRangePicker
                                            setStartDate={setStartDate}
                                            startDate={startDate}
                                            endDate={endDate}
                                            setEndDate={setEndDate}
                                            isLimitedRangeForMonth={true}
                                        />
                                        <IconButton
                                            onClick={handleNextMonth}
                                            className="date-button"
                                        >
                                            <KeyboardDoubleArrowRightOutlinedIcon fontSize="small"></KeyboardDoubleArrowRightOutlinedIcon>
                                        </IconButton>
                                        <Button
                                            variant="contained"
                                            className="custom-button"
                                            startIcon={<SearchIcon />}
                                            onClick={() => {
                                                handleSearch();
                                            }}
                                            sx={{
                                                width: "5rem",
                                                ml: 2,
                                            }}
                                        >
                                            Ara
                                        </Button>
                                    </Grid>
                                </LocalizationProvider>
                            </Grid>
                            <Grid
                                sx={{
                                    display: "flex",
                                    gap: 2,
                                    alignItems: "center",
                                }}
                            >
                                <ProcessMenu
                                    startDate={startDate}
                                    endDate={endDate}
                                    setModalId={setModalId}
                                    handleModalOpen={handleModalOpen}
                                    setUpdateRow={setUpdateRow}
                                    updateChangedInvoices={
                                        updateChangedInvoices
                                    }
                                ></ProcessMenu>
                                {modalId === "#getFromXMLFile" ? (
                                    <XmlFileModal
                                        open={modalOpen}
                                        handleClose={handleModalClose}
                                        startDate={startDate}
                                        endDate={endDate}
                                        companyId={companyDetails?.companyId}
                                        periodId={companyDetails?.periodId}
                                        setUpdateRow={setUpdateRow}
                                    />
                                ) : modalId === "#viewSyncs" ? (
                                    <Grid>
                                        <ViewSyncs
                                            open={modalOpen}
                                            handleClose={handleModalClose}
                                            companyId={
                                                companyDetails?.companyId
                                            }
                                            periodId={companyDetails?.periodId}
                                            startDate={startDate}
                                            endDate={endDate}
                                        ></ViewSyncs>
                                    </Grid>
                                ) : modalId === "#separateSelectedInvoices" ? (
                                    <Grid>
                                        <SeparateSelectedInvoicesSwal
                                            open={modalOpen}
                                            handleClose={handleModalClose}
                                            companyId={
                                                companyDetails?.companyId
                                            }
                                            periodId={companyDetails?.periodId}
                                            selectedInvoices={selectedInvoices}
                                            setUpdateRow={setUpdateRow}
                                        ></SeparateSelectedInvoicesSwal>
                                    </Grid>
                                ) : modalId === "#addBlackList" ? (
                                    <Grid>
                                        <AddBlackListSwal
                                            open={modalOpen}
                                            handleClose={handleModalClose}
                                            companyId={
                                                companyDetails?.companyId
                                            }
                                            periodId={companyDetails?.periodId}
                                            selectedInvoices={selectedInvoices}
                                            setUpdateRow={setUpdateRow}
                                        ></AddBlackListSwal>
                                    </Grid>
                                ) : modalId === "#removeBlackList" ? (
                                    <Grid>
                                        <RemoveBlackListSwal
                                            open={modalOpen}
                                            handleClose={handleModalClose}
                                            companyId={
                                                companyDetails?.companyId
                                            }
                                            periodId={companyDetails?.periodId}
                                            selectedInvoices={selectedInvoices}
                                            setUpdateRow={setUpdateRow}
                                        ></RemoveBlackListSwal>
                                    </Grid>
                                ) : modalId === "#viewSelectedInvoices" ? (
                                    <Grid>
                                        <ViewSelectedInvoices
                                            open={modalOpen}
                                            handleClose={handleModalClose}
                                            companyDetails={companyDetails}
                                            setCompanyDetails={
                                                setCompanyDetails
                                            }
                                            selectedInvoices={selectedInvoices}
                                            sortModel={sortModel}
                                        ></ViewSelectedInvoices>
                                    </Grid>
                                ) : modalId === "#viewInvoice" ? (
                                    <Grid>
                                        <ViewSelectedInvoices
                                            open={modalOpen}
                                            handleClose={handleModalClose}
                                            companyDetails={companyDetails}
                                            setCompanyDetails={
                                                setCompanyDetails
                                            }
                                            selectedInvoices={invoice}
                                            sortModel={sortModel}
                                        ></ViewSelectedInvoices>
                                    </Grid>
                                ) : modalId === "#deleteSelectedInvoices" ? (
                                    <Grid>
                                        <DeleteSelectedInvoicesSwal
                                            open={modalOpen}
                                            handleClose={handleModalClose}
                                            companyId={
                                                companyDetails?.companyId
                                            }
                                            periodId={companyDetails?.periodId}
                                            selectedInvoices={selectedInvoices}
                                            setUpdateRow={setUpdateRow}
                                        ></DeleteSelectedInvoicesSwal>
                                    </Grid>
                                ) : modalId === "#createSelectedFiches" ? (
                                    <Grid>
                                        <CreateSelectedFiscesSwal
                                            open={modalOpen}
                                            handleClose={handleModalClose}
                                            companyId={
                                                companyDetails?.companyId
                                            }
                                            periodId={companyDetails?.periodId}
                                            selectedInvoices={selectedInvoices}
                                            setUpdateRow={setUpdateRow}
                                        ></CreateSelectedFiscesSwal>
                                    </Grid>
                                ) : modalId ===
                                  "#updateSelectedInvoicesSeparatedData" ? (
                                    <Grid>
                                        <UpdateSelectedInvoicesSeparatedData
                                            open={modalOpen}
                                            handleClose={handleModalClose}
                                            companyId={
                                                companyDetails?.companyId
                                            }
                                            periodId={companyDetails?.periodId}
                                            updatedInvoices={updatedInvoices}
                                            setUpdateRow={setUpdateRow}
                                        ></UpdateSelectedInvoicesSeparatedData>
                                    </Grid>
                                ) : (
                                    ""
                                )}
                                <Button
                                    variant="contained"
                                    onClick={() => {
                                        setModalId("#createSelectedFiches");
                                        handleModalOpen();
                                    }}
                                    sx={{
                                        textTransform: "none",
                                        backgroundColor: "#f7dbdc",
                                        color: "#737279",
                                        fontSize: "13px",
                                        fontWeight: 600,
                                        boxShadow: "none",
                                        "&:hover": {
                                            backgroundColor: "#facbcc",
                                            color: "#d4181b",
                                            boxShadow: "none",
                                        },
                                    }}
                                    startIcon={<FaRegFileLines size={15} />}
                                >
                                    Fiş Oluştur
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Box
                        className="grid-area"
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 0,
                            backgroundColor: "#ffffff",
                            p: 2,
                            mb: 2,
                        }}
                    >
                        <Grid
                            className="ag-theme-quartz"
                            style={{
                                minHeight: rowData.length > 6 ? "85vh" : "60vh",
                                width: "100%",
                                borderRadius: "10%",
                            }}
                        >
                            <AgGridReact
                                ref={gridRef}
                                onSortChanged={onSortChanged}
                                onSelectionChanged={onSelectionChanged}
                                onFilterChanged={onFilterChanged}
                                onRowDataUpdated={onRowDataUpdated}
                                localeText={localeTextTr}
                                rowSelection="multiple"
                                loading={loading}
                                animateRows={true}
                                rowDragManaged={true}
                                rowMultiSelectWithClick={true}
                                readOnlyEdit={true}
                                singleClickEdit={true}
                                stopEditingWhenCellsLoseFocus={true}
                                rowData={rowData}
                                columnDefs={columnDefs}
                                enableRangeSelection={true}
                                sideBar={gridSideBar.gridSideBar}
                                copyHeadersToClipboard={false}
                                defaultColDef={defaultColDef}
                                masterDetail={companyDetails?.class === 1}
                                detailRowAutoHeight={true}
                                detailCellRendererParams={
                                    detailCellRendererParams
                                }
                                onGridReady={(params) => {
                                    params.api.context.mainGridApi = params.api;
                                }}
                                getMainMenuItems={getMainMenuItems}
                            />
                        </Grid>
                        <Typography
                            sx={{
                                backgroundColor: "white",
                                fontWeight: 400,
                                color: "#605f66",
                                ml: 1,
                                mt: 1,
                                mb: 1,
                            }}
                        >
                            Ekrandaki Fatura Sayısı: <b>{filteredCount}</b>
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default Invoices;
