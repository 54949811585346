import axiosInstance from "../../../../utils/maintenanceController/axios";
import moment from "moment";

export const createGibVATListToExclude = async (
  companyId,
  periodId,
  startDate,
  endDate,
  extractData
) => {
  const startDateFormat = moment(startDate)
    .startOf("day")
    .format("YYYY-MM-DD HH:mm:ss");
  const endDateFormat = moment(endDate)
    .endOf("day")
    .format("YYYY-MM-DD HH:mm:ss");
    
  return axiosInstance.post(
    `/company/${companyId}/${periodId}/create-gib-VAT-list-to-exclude`,
    {startDate:startDateFormat,endDate:endDateFormat,...extractData}
  );
};
