/**
 * eslint-disable react-hooks/exhaustive-deps
 *
 * @format
 */

//todo MODAL AÇILMASI GEREKTİREN İŞLEMLER SONU MODAL İLE BİTEN DOSYALARDA(fatura seçilerek yapılan işlemlerde),
//todo DİREKT İSTEKLE HALLEDİLEBİLİR İŞLEMLER 'ProcessesAndProcessMenu' DOSYASINDADIR.

import React, {
    useState,
    useMemo,
    useEffect,
    useRef,
    useCallback,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import Swal from "sweetalert2";

// Grids---------
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import gridSideBar from "../../../components/GridSideBar/gridSideBar.js";

import { createColumnDefs } from "./gridOptionsAndcolumnDefs.js";
import { detailsGridOptions } from "./gridOptionsAndcolumnDefs.js";
import localeTextTr from "../../../locale.tr.js";

//Components
import DateRangePicker from "../../../components/DateRangePicker/index.js";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import localStorage from "local-storage";
import { getJsonItem } from "../../../utils/localStorageProcess/index.js";
import { setJsonItem } from "../../../utils/localStorageProcess/index.js";

//Modals and Menus
import ProcessMenu from "./processesAndProcessMenu.js";

//Icons
import KeyboardDoubleArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowLeftOutlined";
import KeyboardDoubleArrowRightOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowRightOutlined";
import { TbListDetails, TbUserShare } from "react-icons/tb";
import { Grid, Typography, Button, IconButton, Box } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
//Icons

//Apis
import {
    getAdvisorSubSyncList,
    getAdvisorSyncList,
} from "../../../api/advisor/getAdvisorSyncList";
import { getCompanyDetails } from "../../../api/advisor/getCompanyDetails/index.js";
import { showLoadingSwal } from "../../../components/loadingSwal/index.js";
import { ViewSyncs } from "./viewSyncsModal.js";
import { StartBulkFetchInvoices } from "./startBulkFetchInvoicesSwal.js";
import { createTurkishFilterParams } from "../../../utils/agGridTextFilterTr/agGridFilterUtils.js";

//Apis

function SyncPage() {
    const [isOpen, setIsOpen] = useState(false);

    //modals açılma durumlar
    const [modalId, setModalId] = useState("1");
    const [modalOpen, setModalOpen] = useState(false);
    const handleModalOpen = () => setModalOpen(true);
    const handleModalClose = () => setModalOpen(false);
    //--------
    //grid fatura görüntüleme modalı için açılır kapanır state'si
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    //grid fatura görüntüleme modalı için açılır kapanır state'si

    const [loading, setLoading] = useState(true);
    const [firstLoading, setFirstLoading] = useState(true); //SAYFA İLK AÇTIĞINDA getListInvoices ve companies'e İSTEK ATMAMASI İÇİN FLAG

    //Modallarda Yapılan işlemlerde sonra tablonun güncellemesi için
    const [updateRow, setUpdateRow] = useState(false);

    useEffect(() => {
        if (updateRow === true) {
            setLoading(true);
            getAdvisorSyncList(startDate, endDate)
                .then((resp) => {
                    setRowData(resp.data.process);
                    setFilteredCount(resp.data.process.length);
                    setUpdateRow(false);
                    setLoading(false);
                })
                .catch((err) => {
                    setLoading(false);
                    setUpdateRow(false);
                });
        }
    }, [updateRow]);
    //Modallarda Yapılan işlemlerde sonra tablonun güncellemesi için

    const location = useLocation();

    const [rowData, setRowData] = useState([]);
    const gridRef = useRef(null);
    const [selectedCompanies, setSelectedCompanies] = useState([]);
    const [selectedCompaniesDetails, setSelectedCompaniesDetails] = useState(
        []
    );
    const [filteredCount, setFilteredCount] = useState(0);
    const companyId = useRef();
    const periodId = useRef();

    const onSelectionChanged = () => {
        const selectedRows = gridRef.current.api.getSelectedRows();
        setSelectedCompaniesDetails(selectedRows);
        const selectedData = selectedRows.map((detail) => detail.id);
        setSelectedCompanies(selectedData);
    };

    const [startDate, setStartDate] = useState(
        moment().startOf("month").format("YYYY-MM-DD HH:mm:ss")
    );
    const [endDate, setEndDate] = useState(
        moment().endOf("day").format("YYYY-MM-DD HH:mm:ss")
    );

    const handlePreviousMonth = () => {
        setStartDate(moment(startDate).subtract(1, "month").startOf("month"));
        setEndDate(moment(endDate).subtract(1, "month").endOf("month"));
    };

    const handleNextMonth = () => {
        setStartDate(moment(startDate).add(1, "month").startOf("month"));
        setEndDate(moment(endDate).add(1, "month").endOf("month"));
    };

    useEffect(() => {
        const sideBarOpen = localStorage.get("sidebar");
        if (sideBarOpen === "false") {
            setIsOpen(false);
        } else {
            setIsOpen(true);
        }

        getAdvisorSyncList(startDate, endDate)
            .then((resp) => {
                setRowData(resp.data.process);
                setFilteredCount(resp.data.process.length);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        setStartDate(moment().startOf("month").format("YYYY-MM-DD HH:mm:ss"));
        setEndDate(moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"));

        let endDate = moment()
            .subtract(1, "month")
            .endOf("month")
            .format("YYYY-MM-DD HH:mm:ss");

        let startDate = moment()
            .subtract(1, "month")
            .startOf("month")
            .format("YYYY-MM-DD HH:mm:ss");

        if (loading === true && firstLoading === false) {
            getAdvisorSyncList(startDate, endDate)
                .then((resp) => {
                    setRowData(resp.data.process);
                    setFilteredCount(resp.data.process.length);
                    setLoading(false);
                    setFirstLoading(true);
                })
                .catch((err) => {
                    setLoading(false);
                    setFirstLoading(true);
                });
        }
    }, [location.pathname]);

    const onFilterChanged = useCallback((event) => {
        const filteredRows = event.api.getModel().getRowCount(); // Filtrelenmiş veri sayısını alıyoruz
        setFilteredCount(filteredRows);
    }, []);

    const defaultColDef = useMemo(
        () => ({
            filter: true,
            sortable: true,
            resizable: true,
            floatingFilter: true,
            filterParams:createTurkishFilterParams(),
        }),
        []
    );

    const ButtonRenderer = (props) => {
        const { data } = props;
        const handleClick = () => {
            setTimeout(() => {
                companyId.current = data.companyId;
                periodId.current = data.periodId;
                setModalId("#viewSyncs");
                handleModalOpen();
            }, 0);
        };
        const handleNavigate = () => {
            showLoadingSwal("Yönlendiriliyorsunuz...");
            getCompanyDetails(data.companyId, data.periodId)
                .then(async (resp) => {
                    await setJsonItem(
                        `#${data.companyId}/${data.periodId}`,
                        resp.data
                    );
                    Swal.close();
                    var url = `/company/${data.companyId}/${data.periodId}/invoices`;
                    window.open(url, "_blank");
                })
                .catch((err) => {
                    console.log(err);
                });
        };
        return (
            <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px" }}>
                    <Button
                        variant="contained"
                        onClick={handleNavigate}
                        startIcon={<TbUserShare size={15} />}
                        sx={{
                            display: "inline-flex",
                            alignItems: "center",
                            justifyContent: "space-around",
                            backgroundColor: "#dbf0fd",
                            borderRadius: "5px",
                            padding: "0px 12px",
                            minWidth: "100px",
                            color: "#153243",
                            textTransform: "none",
                            "&:hover": {
                                backgroundColor: "#d1e6f3",
                            },
                        }}
                    >
                        Mükellefe Git
                    </Button>
                </div>
                <div>
                    <Button
                        variant="contained"
                        startIcon={<TbListDetails size={15} />}
                        onClick={handleClick}
                        sx={{
                            display: "inline-flex",
                            alignItems: "center",
                            justifyContent: "space-around",
                            backgroundColor: "#fff1e3",
                            borderRadius: "5px",
                            padding: "0px 12px",
                            minWidth: "100px",
                            color: "#ffa44c",
                            textTransform: "none",
                            "&:hover": {
                                backgroundColor: "#f7e9dc",
                            },
                        }}
                    >
                        Detay Göster
                    </Button>
                </div>
            </div>
        );
    };

    const columnDefs = useMemo(() => createColumnDefs);

    const detailCellRendererParams = useMemo(() => {
        return {
            detailGridOptions: detailsGridOptions(ButtonRenderer),
            getDetailRowData: async (params) => {
                let resp = await getAdvisorSubSyncList(params.data.id);
                params.successCallback(resp.data.subProcess);
            },
        };
    }, []);

    const handleSearch = () => {
        setLoading(true);
        getAdvisorSyncList(startDate, endDate)
            .then((resp) => {
                setRowData(resp.data.process);
                setFilteredCount(resp.data.process.length);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    const getMainMenuItems = (params) => {
        const allMenuItems = params.defaultItems;
        const itemsToRemove = ["toolPanelButton"]; // 'choose columns' seçeneği

        return allMenuItems.filter((item) => !itemsToRemove.includes(item));
    };

    return (
        <Grid container>
            <Grid
                item
                md={12}
                sx={{ display: "flex", flexDirection: "column" }}
            >
                <Grid
                    className="grid-area"
                    sx={{
                        padding: 1,
                        mb: 1,
                        alignItems: "center",
                    }}
                >
                    <Grid
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        <Grid sx={{ display: "flex", alignItems: "center" }}>
                            <LocalizationProvider
                                dateAdapter={AdapterMoment}
                                adapterLocale="tr"
                            >
                                <Grid
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: 1,
                                        pl: 1,
                                        pt: 0.5,
                                    }}
                                >
                                    <IconButton
                                        onClick={handlePreviousMonth}
                                        className="date-button"
                                    >
                                        <KeyboardDoubleArrowLeftOutlinedIcon fontSize="small"></KeyboardDoubleArrowLeftOutlinedIcon>
                                    </IconButton>
                                    <DateRangePicker
                                        setStartDate={setStartDate}
                                        startDate={startDate}
                                        endDate={endDate}
                                        setEndDate={setEndDate}
                                        isLimitedRangeForMonth={true}
                                    />
                                    <IconButton
                                        onClick={handleNextMonth}
                                        className="date-button"
                                    >
                                        <KeyboardDoubleArrowRightOutlinedIcon fontSize="small"></KeyboardDoubleArrowRightOutlinedIcon>
                                    </IconButton>
                                    <Button
                                        variant="contained"
                                        className="custom-button"
                                        startIcon={<SearchIcon />}
                                        onClick={() => {
                                            handleSearch();
                                        }}
                                        sx={{
                                            width: "5rem",
                                            ml: 2,
                                        }}
                                    >
                                        Ara
                                    </Button>
                                </Grid>
                            </LocalizationProvider>
                        </Grid>
                        <Grid
                            sx={{
                                display: "flex",
                                gap: 2,
                                alignItems: "center",
                            }}
                        >
                            <ProcessMenu
                                startDate={startDate}
                                endDate={endDate}
                                setModalId={setModalId}
                                handleModalOpen={handleModalOpen}
                                setUpdateRow={setUpdateRow}
                            ></ProcessMenu>
                            {modalId === "#startBulkFettchInvoices" ? (
                                <StartBulkFetchInvoices
                                    open={modalOpen}
                                    handleClose={handleModalClose}
                                    setUpdateRow={setUpdateRow}
                                />
                            ) : modalId === "#viewSyncs" ? (
                                <ViewSyncs
                                    open={modalOpen}
                                    handleClose={handleModalClose}
                                    companyId={companyId.current}
                                    periodId={periodId.current}
                                    startDate={startDate}
                                    endDate={endDate}
                                />
                            ) : (
                                ""
                            )}
                        </Grid>
                    </Grid>
                </Grid>

                <Box
                    className="grid-area"
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 0,
                        backgroundColor: "#ffffff",
                        p: 2,
                        mb: 2,
                    }}
                >
                    <Grid
                        className="ag-theme-quartz"
                        style={{
                            minHeight: rowData.length > 6 ? "85vh" : "60vh",
                            width: "100%",
                            borderRadius: "10%",
                        }}
                    >
                        <AgGridReact
                            ref={gridRef}
                            onSelectionChanged={onSelectionChanged}
                            onFilterChanged={onFilterChanged}
                            localeText={localeTextTr}
                            rowSelection="multiple"
                            loading={loading}
                            animateRows={true}
                            rowDragManaged={true}
                            rowMultiSelectWithClick={true}
                            readOnlyEdit={true}
                            singleClickEdit={true}
                            stopEditingWhenCellsLoseFocus={true}
                            rowData={rowData}
                            columnDefs={columnDefs}
                            enableRangeSelection={true}
                            sideBar={gridSideBar.gridSideBar}
                            copyHeadersToClipboard={false}
                            defaultColDef={defaultColDef}
                            masterDetail={true}
                            detailRowAutoHeight={true}
                            detailCellRendererParams={detailCellRendererParams}
                            getMainMenuItems={getMainMenuItems}
                        />
                    </Grid>
                    <Typography
                        sx={{
                            backgroundColor: "white",
                            fontWeight: 400,
                            color: "#605f66",
                            ml: 1,
                            mt: 1,
                            mb: 1,
                        }}
                    >
                        Ekrandaki Senkronizasyon Sayısı: <b>{filteredCount}</b>
                    </Typography>
                </Box>
            </Grid>
        </Grid>
    );
}

export default SyncPage;
