/**
 * eslint-disable react-hooks/exhaustive-deps
 *
 * @format
 */

//todo MODAL AÇILMASI GEREKTİREN İŞLEMLER SONU MODAL İLE BİTEN DOSYALARDA(fatura seçilerek yapılan işlemlerde),
//todo DİREKT İSTEKLE HALLEDİLEBİLİR İŞLEMLER 'ProcessesAndProcessMenu' DOSYASINDADIR.

import React, {
    useState,
    useMemo,
    useEffect,
    useRef,
    useCallback,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
// Grids---------
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import gridSideBar from "../../components/GridSideBar/gridSideBar.js";

import { createColumnDefs } from "./gridOptionsAndcolumnDefs.js";
import { detailsGridOptions } from "./gridOptionsAndcolumnDefs.js";
import localeTextTr from "../../locale.tr.js";

//Components
import Sidebar from "../../components/sideBar/sideBar.js";
import Navbar from "../../components/navbar/navbar.js";
import DateRangePicker from "../../components/DateRangePicker/index.js";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import localStorage from "local-storage";
import { getJsonItem } from "../../utils/localStorageProcess/index.js";
import { setJsonItem } from "../../utils/localStorageProcess/index.js";

//Modals and Menus
import ProcessMenu from "./processesAndProcessMenu.js";

import { ViewSelectedInvoices } from "./viewSelectedInvoicesModal.js";
import { DeleteSelectedFichesSwal } from "./deleteFichesSwal.js";
import { SendSelectedFiscesToLucaSwal } from "./sendSelectedFichestoLucaSwal.js";
//Icons
import KeyboardDoubleArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowLeftOutlined";
import KeyboardDoubleArrowRightOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowRightOutlined";
import { FaRegFileLines } from "react-icons/fa6";
import { TbFileSearch } from "react-icons/tb";
import { Grid, Typography, Button, IconButton, Box } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
//Icons

//Apis
import { getListFiches } from "../../api/company/fiches/getListFiches/index.js";
import { getCompanyDetails } from "../../api/advisor/getCompanyDetails/index.js";
import { ChangeDateSelectedFichesSwal } from "./changeDateSwal.js";
import { setTitle } from "../../utils/setTitle/index.js";
import { getValueFromServer } from "../../api/company/invoices/getValueFromServer/index.js";
import { createTurkishFilterParams } from "../../utils/agGridTextFilterTr/agGridFilterUtils.js";
import AnimatedAlerts from "../../components/animatedAlerts/index.js";

//Apis

function Fiches() {
    const [isOpen, setIsOpen] = useState(false);

    //modals açılma durumlar
    const [modalId, setModalId] = useState("1");
    const [modalOpen, setModalOpen] = useState(false);
    const handleModalOpen = () => setModalOpen(true);
    const handleModalClose = () => setModalOpen(false);
    //--------

    const [companyDetails, setCompanyDetails] = useState();

    const [loading, setLoading] = useState(true);
    const [firstLoading, setFirstLoading] = useState(true); //SAYFA İLK AÇTIĞINDA getListInvoices ve companies'e İSTEK ATMAMASI İÇİN FLAG

    //Modallarda Yapılan işlemlerde sonra tablonun güncellemesi için
    const [updateRow, setUpdateRow] = useState(false);

    const location = useLocation();
    const navigate = useNavigate();

    const [rowData, setRowData] = useState([]);
    const gridRef = useRef(null);
    const [selectedFicheIds, setSelectedFichesIds] = useState([]);
    const [selectedInvoiceIds, setSelectedInvoiceIds] = useState([]);
    const [invoiceIdsWithFicheId, setInvoiceIdsWithFicheId] = useState([]);
    const [invoice, setInvoice] = useState([]);

    const [filteredCount, setFilteredCount] = useState(0);
    const recordSubtypes = useRef([]);

    useEffect(() => {
        if (updateRow === true) {
            setLoading(true);
            search(companyDetails?.companyId, companyDetails?.periodId);
            setUpdateRow(false);
        }
    }, [updateRow]);
    //Modallarda Yapılan işlemlerde sonra tablonun güncellemesi için
    const onFirstDataRendered = (params) => {
        params.api.getFilterInstance("status", function (filterInstance) {
            filterInstance.setModel({
                values: ["100", "101", "102", "104", "400"], // 200 ve 104'ü dışarıda bırakıyoruz
            });
            params.api.onFilterChanged();
        });
    };

    const onGridReady = (params) => {
        const column = params.api.getColumn("description");

        if (column) {
            // Sütunu A'dan Z'ye sıralayın
            params.api.applyColumnState({
                state: [{ colId: "description", sort: "asc" }],
            });
        }
    };
    const onSelectionChanged = async () => {
        const selectedNodes = gridRef.current.api.getSelectedNodes();
        const ficheIds = selectedNodes.map((node) => node.data.id);
        setSelectedFichesIds(ficheIds);
        const invoiceIds = [];
        const invoiceIdsWithFicheIds = [];

        selectedNodes.forEach((node) =>
            node.data.FicheLines.forEach((invoice) => {
                if (invoice?.invoiceId === null) {
                    return;
                }
                if (!invoiceIds.includes(invoice.invoiceId)) {
                    invoiceIds.push(invoice.invoiceId);
                }
                if (
                    !invoiceIdsWithFicheIds.some(
                        (item) => item.id === invoice.invoiceId
                    )
                ) {
                    invoiceIdsWithFicheIds.push({
                        id: invoice.invoiceId,
                        lucaFicheNo: node.data.sendedFicheNo,
                        direction: node.data.description.includes("GELEN")
                            ? 2
                            : 1,
                        number: invoice.evrak_no,
                    });
                }
            })
        );
        setSelectedInvoiceIds(invoiceIds);
        setInvoiceIdsWithFicheId(invoiceIdsWithFicheIds);
    };

    const onFilterChanged = useCallback((event) => {
        const filteredRows = event.api.getModel().getRowCount(); // Filtrelenmiş veri sayısını alıyoruz
        setFilteredCount(filteredRows);
    }, []);

    const [startDate, setStartDate] = useState(
        moment()
            .subtract(1, "month")
            .startOf("month")
            .format("YYYY-MM-DD HH:mm:ss")
    );
    const [endDate, setEndDate] = useState(
        moment()
            .subtract(1, "month")
            .endOf("month")
            .format("YYYY-MM-DD HH:mm:ss")
    );

    const handlePreviousMonth = () => {
        setStartDate(moment(startDate).subtract(1, "month").startOf("month"));
        setEndDate(moment(endDate).subtract(1, "month").endOf("month"));
    };

    const handleNextMonth = () => {
        setStartDate(moment(startDate).add(1, "month").startOf("month"));
        setEndDate(moment(endDate).add(1, "month").endOf("month"));
    };

    useEffect(() => {
        const sideBarOpen = localStorage.get("sidebar");
        if (sideBarOpen === "false") {
            setIsOpen(false);
        } else {
            setIsOpen(true);
        }

        let locationSplit = location.pathname.split("/");
        let companyDetailsControl = getJsonItem(
            `#${locationSplit[2]}/${locationSplit[3]}`
        );

        if (!companyDetailsControl?.companyId) {
            getCompanyDetails(locationSplit[2], locationSplit[3])
                .then(async (resp) => {
                    if (resp.data.companyId) {
                        await setJsonItem(
                            `#${locationSplit[2]}/${locationSplit[3]}`,
                            resp.data
                        );
                        window.location.reload();
                    } else {
                        navigate("/dashboard", { replace: true });
                    }
                })
                .catch((err) => {
                    navigate("/dashboard", { replace: true });
                });
        } else {
            if (
                moment(companyDetailsControl?.year, "YYYY").year() !==
                moment().year()
            ) {
                const year = moment(companyDetailsControl.year, "YYYY");
                setStartDate(
                    year
                        .endOf("year")
                        .startOf("month")
                        .format("YYYY-MM-DD HH:mm:ss")
                );
                setEndDate(year.endOf("year").format("YYYY-MM-DD HH:mm:ss"));
            }
            setCompanyDetails(companyDetailsControl);

            setTitle(companyDetailsControl?.name, locationSplit[4]);
            search(locationSplit[2], locationSplit[3]);
        }
    }, []);
    const expandFicheLines = (fiche) => {
        const getLabelBySubtype = (subtypeValue) => {
            const foundSubtype = recordSubtypes.current.find(
                (item) => item.value === subtypeValue
            ); // Eşleşeni bul
            return foundSubtype ? foundSubtype.label : ""; // Label'ı döndür veya null
        };
        try {
            // Data'yı parse et
            const parsedData = JSON.parse(fiche.FicheLines[0].data);
            const kdvArray = parsedData.TUTAR_KDV;

            // Yeni FicheLines array'ini oluştur
            const newFicheLines = kdvArray.map((kdv, index) => {
                // Orijinal FicheLine'ı kopyala
                const newFicheLine = {
                    ...fiche.FicheLines[0],
                    TUTAR_KDV: kdv ? kdv : "Hesaplanamadı",
                    TUTAR_KDVSIZ: !Number.isNaN(
                        Number(
                            parsedData.TUTAR_KDVSIZ[index]
                                .replace(/\./g, "")
                                .replace(",", ".")
                        )
                    )
                        ? parsedData.TUTAR_KDVSIZ[index]
                        : "Hesaplanamadı",
                    TUTAR_KDVLI: !Number.isNaN(
                        Number(
                            parsedData.TUTAR_KDVLI[index]
                                .replace(/\./g, "")
                                .replace(",", ".")
                        )
                    )
                        ? parsedData.TUTAR_KDVLI[index]
                        : "Hesaplanamadı",
                    KDV_ORAN: !Number.isNaN(
                        Number(
                            parsedData.KDV_ORAN[index]
                                .replace(/\./g, "")
                                .replace(",", ".")
                        )
                    )
                        ? parsedData.KDV_ORAN[index]
                        : "Hesaplanamadı",
                    TEVKIFAT: !Number.isNaN(
                        Number(
                            parsedData.TEVKIFAT[index]
                                .replace(/\./g, "")
                                .replace(",", ".")
                        )
                    )
                        ? parsedData.TEVKIFAT[index]
                        : "Hesaplanamadı",
                    TEVKIFAT_SABLON: !Number.isNaN(
                        Number(
                            parsedData.TEVKIFAT_SABLON[index]
                                .replace(/\./g, "")
                                .replace(",", ".")
                        )
                    )
                        ? parsedData.TEVKIFAT_SABLON[index]
                        : "Hesaplanamadı",
                    KAYIT_ALT_TURU: parsedData.KAYIT_ALT_TURU[index]
                        ? getLabelBySubtype(parsedData.KAYIT_ALT_TURU[index])
                        : "",
                };

                return newFicheLine;
            });

            // Yeni fiche objesi oluştur
            return {
                ...fiche,
                FicheLines: newFicheLines,
            };
        } catch (error) {
            console.error("Error expanding FicheLines:", error);
            return fiche;
        }
    };

    const search = (companyId, periodId) => {
        if (companyDetails?.class === 2) {
            Promise.all([
                getValueFromServer(companyId, periodId),
                getListFiches(companyId, periodId, startDate, endDate),
            ])
                .then(async ([valueResp, listFichesResp]) => {
                    let recordSubtype = [];
                    for await (const subRecord of valueResp) {
                        recordSubtype.push({
                            value:
                                subRecord.direction === 2
                                    ? `${subRecord.giderKayitTuruKodu}-${subRecord.value}`
                                    : `${subRecord.gelirKayitTuruKodu}-${subRecord.value}`,
                            label: subRecord.label,
                        });
                    }
                    recordSubtypes.current = recordSubtype;
                    const detailsData = listFichesResp.data.map((fiche) => {
                        return expandFicheLines(fiche);
                    });
                    console.log(detailsData);
                    setRowData(detailsData);
                    setLoading(false);
                })
                .catch((err) => {
                    console.log("İstek Hatası", err);
                    setLoading(false);
                });
        } else {
            getListFiches(companyId, periodId, startDate, endDate)
                .then((resp) => {
                    setRowData(resp.data);

                    setLoading(false);
                })
                .catch((err) => {
                    setLoading(false);
                });
        }
    };
    const handleSearch = () => {
        setLoading(true);
        search(companyDetails?.companyId, companyDetails?.periodId);
    };
    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };
    const onRowDataUpdated = useCallback((event) => {
        const filteredRows = event.api.getDisplayedRowCount(); // Filtrelenmiş veri sayısını alıyoruz
        setFilteredCount(filteredRows);
    }, []);
    const defaultColDef = useMemo(
        () => ({
            flex: 1,
            filter: true,
            sortable: true,
            resizable: true,
            floatingFilter: true,
            filterParams: createTurkishFilterParams(),
        }),
        []
    );

    const ButtonRenderer = (props) => {
        const handleClick = () => {
            setInvoice([props.data.invoiceId]);
            setModalId("#viewInvoiceOfFiche");
            handleModalOpen();
        };

        return (
            <Button
                onClick={handleClick}
                sx={{
                    padding: 0,
                    minWidth: "auto",
                    width: "auto",
                    height: "auto",
                    display: "flex",
                }}
            >
                <TbFileSearch color="#3fd549" size={21}></TbFileSearch>
            </Button>
        );
    };

    const columnDefs = useMemo(
        () => createColumnDefs(companyDetails?.class),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [companyDetails?.class]
    );

    const detailCellRendererParams = useMemo(() => {
        return {
            detailGridOptions: detailsGridOptions(
                ButtonRenderer,
                companyDetails?.class
            ),

            getDetailRowData: (params) => {
                console.log(params.data.FicheLines);
                params.successCallback(params.data.FicheLines);
            },
        };
    }, [companyDetails?.class]);

    const getMainMenuItems = (params) => {
        const allMenuItems = params.defaultItems;
        const itemsToRemove = ["toolPanelButton"]; // 'choose columns' seçeneği

        return allMenuItems.filter((item) => !itemsToRemove.includes(item));
    };

    return (
        <Grid container>
            <Grid
                item
                sx={{
                    flexBasis: isOpen ? "275px" : "95px",
                    flexShrink: 0,
                    transition: "flex-basis 0.3s ease",
                }}
                zIndex={1}
            >
                <Sidebar
                    status={isOpen}
                    toggleSidebar={toggleSidebar}
                    companyClass={companyDetails?.class}
                />
            </Grid>
            <Grid
                item
                zIndex={0}
                sx={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "flex-end",
                    flexDirection: "column",
                    gap: 1,
                    pr: "12px",
                }}
            >
                <Grid item xs={12}>
                    <Navbar
                        setLoading={setLoading}
                        setFirstLoading={setFirstLoading}
                        firstLoading={firstLoading}
                    />{" "}
                    {/*FATURA YÜKLENENE KADAR LOADİNG GÖSTERME*/}
                </Grid>
                <Grid
                    item
                    md={12}
                    sx={{ display: "flex", flexDirection: "column" }}
                >
                    <Grid item md={12} sx={{ mt: 1 }}>
                        <AnimatedAlerts
                            infoText={`Bu sayfada, seçtiğiniz tarih aralığındaki
                            <b style={{ letterSpacing: "0.05em" }}>
                                fişlerinizi
                            </b>
                            görüntüleyebilir ve muhasebe programınıza
                            aktarabilirsiniz.`}
                        />
                    </Grid>
                    <Grid
                        className="grid-area"
                        sx={{
                            padding: 1,
                            mb: 1,
                            alignItems: "center",
                        }}
                    >
                        <Grid
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}
                        >
                            <Grid
                                sx={{ display: "flex", alignItems: "center" }}
                            >
                                <LocalizationProvider
                                    dateAdapter={AdapterMoment}
                                    adapterLocale="tr"
                                >
                                    <Grid
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: 1,
                                            pl: 1,
                                            pt: 0.5,
                                        }}
                                    >
                                        <IconButton
                                            onClick={handlePreviousMonth}
                                            className="date-button"
                                        >
                                            <KeyboardDoubleArrowLeftOutlinedIcon fontSize="small"></KeyboardDoubleArrowLeftOutlinedIcon>
                                        </IconButton>
                                        <DateRangePicker
                                            setStartDate={setStartDate}
                                            startDate={startDate}
                                            endDate={endDate}
                                            setEndDate={setEndDate}
                                            isLimitedRangeForMonth={true}
                                        />
                                        <IconButton
                                            onClick={handleNextMonth}
                                            className="date-button"
                                        >
                                            <KeyboardDoubleArrowRightOutlinedIcon fontSize="small"></KeyboardDoubleArrowRightOutlinedIcon>
                                        </IconButton>
                                        <Button
                                            variant="contained"
                                            className="custom-button"
                                            startIcon={<SearchIcon />}
                                            onClick={() => {
                                                handleSearch();
                                            }}
                                            sx={{
                                                width: "5rem",
                                                ml: 2,
                                            }}
                                        >
                                            Ara
                                        </Button>
                                    </Grid>
                                </LocalizationProvider>
                            </Grid>
                            <Grid
                                sx={{
                                    display: "flex",
                                    gap: 2,
                                    alignItems: "center",
                                }}
                            >
                                <ProcessMenu
                                    startDate={startDate}
                                    endDate={endDate}
                                    setModalId={setModalId}
                                    handleModalOpen={handleModalOpen}
                                    setUpdateRow={setUpdateRow}
                                ></ProcessMenu>
                                {modalId === "#viewInvoicesOfSelectedFiches" ? (
                                    <Grid>
                                        <ViewSelectedInvoices
                                            open={modalOpen}
                                            handleClose={handleModalClose}
                                            companyDetails={companyDetails}
                                            setCompanyDetails={
                                                setCompanyDetails
                                            }
                                            selectedInvoiceIds={
                                                selectedInvoiceIds
                                            }
                                            invoiceIdsWithFicheId={
                                                invoiceIdsWithFicheId
                                            }
                                            isFichePage={true}
                                        ></ViewSelectedInvoices>
                                    </Grid>
                                ) : modalId ===
                                  "#changeDateSelectedFichesSwal" ? (
                                    <Grid>
                                        <ChangeDateSelectedFichesSwal
                                            open={modalOpen}
                                            handleClose={handleModalClose}
                                            companyId={
                                                companyDetails?.companyId
                                            }
                                            periodId={companyDetails?.periodId}
                                            selectedFicheIds={selectedFicheIds}
                                            setUpdateRow={setUpdateRow}
                                        ></ChangeDateSelectedFichesSwal>
                                    </Grid>
                                ) : modalId === "#viewInvoiceOfFiche" ? (
                                    <Grid>
                                        <ViewSelectedInvoices
                                            open={modalOpen}
                                            handleClose={handleModalClose}
                                            companyDetails={companyDetails}
                                            setCompanyDetails={
                                                setCompanyDetails
                                            }
                                            selectedInvoiceIds={invoice}
                                        ></ViewSelectedInvoices>
                                    </Grid>
                                ) : modalId === "#deleteSelectedFiches" ? (
                                    <Grid>
                                        <DeleteSelectedFichesSwal
                                            open={modalOpen}
                                            handleClose={handleModalClose}
                                            companyId={
                                                companyDetails?.companyId
                                            }
                                            periodId={companyDetails?.periodId}
                                            selectedFicheIds={selectedFicheIds}
                                            setUpdateRow={setUpdateRow}
                                        ></DeleteSelectedFichesSwal>
                                    </Grid>
                                ) : modalId === "#sendSelectedFiscesToLuca" ? (
                                    <Grid>
                                        <SendSelectedFiscesToLucaSwal
                                            open={modalOpen}
                                            handleClose={handleModalClose}
                                            companyId={
                                                companyDetails?.companyId
                                            }
                                            periodId={companyDetails?.periodId}
                                            selectedFicheIds={selectedFicheIds}
                                            sendAlreadySentFichesToLuca={false}
                                            setUpdateRow={setUpdateRow}
                                        ></SendSelectedFiscesToLucaSwal>
                                    </Grid>
                                ) : (
                                    ""
                                )}
                                <Button
                                    variant="contained"
                                    onClick={() => {
                                        setModalId("#sendSelectedFiscesToLuca");
                                        handleModalOpen();
                                    }}
                                    sx={{
                                        textTransform: "none",
                                        backgroundColor: "#f7dbdc",
                                        color: "#737279",
                                        fontSize: "13px",
                                        fontWeight: 600,
                                        boxShadow: "none",
                                        "&:hover": {
                                            backgroundColor: "#facbcc",
                                            color: "#d4181b",
                                            boxShadow: "none",
                                        },
                                    }}
                                    startIcon={<FaRegFileLines size={15} />}
                                >
                                    Gönder
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Box
                        className="grid-area"
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 0,
                            backgroundColor: "#ffffff",
                            p: 2,
                            mb: 2,
                        }}
                    >
                        <Grid
                            className="ag-theme-quartz"
                            style={{
                                minHeight: rowData.length > 6 ? "85vh" : "60vh",
                                width: "100%",
                                borderRadius: "10%",
                            }}
                        >
                            <AgGridReact
                                ref={gridRef}
                                onGridReady={onGridReady}
                                onSelectionChanged={onSelectionChanged}
                                onFirstDataRendered={onFirstDataRendered}
                                onFilterChanged={onFilterChanged}
                                localeText={localeTextTr}
                                rowSelection="multiple"
                                loading={loading}
                                animateRows={true}
                                rowDragManaged={true}
                                rowMultiSelectWithClick={true}
                                readOnlyEdit={true}
                                singleClickEdit={true}
                                stopEditingWhenCellsLoseFocus={true}
                                copyHeadersToClipboard={false}
                                enableRangeSelection={true}
                                masterDetail={true}
                                detailRowAutoHeight={true}
                                rowData={rowData}
                                columnDefs={columnDefs}
                                sideBar={gridSideBar.gridSideBar}
                                onRowDataUpdated={onRowDataUpdated}
                                defaultColDef={defaultColDef}
                                detailCellRendererParams={
                                    detailCellRendererParams
                                }
                                getMainMenuItems={getMainMenuItems}
                            />
                        </Grid>
                        <Typography
                            sx={{
                                backgroundColor: "white",
                                fontWeight: 400,
                                color: "#605f66",
                                ml: 1,
                                mt: 1,
                                mb: 1,
                            }}
                        >
                            Ekrandaki Fiş Sayısı: <b>{filteredCount}</b>
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default Fiches;
