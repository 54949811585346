import localStorage from "local-storage";


export async function setJsonItem(key, jsonObject) {
    try {
      const jsonString = JSON.stringify(jsonObject);
      localStorage.set(key, jsonString);
    } catch (error) {
      console.error("Error saving JSON to localStorage", error);
    }
  }
  
  // localStorage'dan veriyi alır ve JSON nesnesine dönüştürür
  export function getJsonItem(key) {
    try {
      const jsonString = localStorage.get(key);
      return jsonString ? JSON.parse(jsonString) : null;
    } catch (error) {
      console.error("Error parsing JSON from localStorage", error);
      return null;
    }
  }

