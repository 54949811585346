import { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import {
  deleteSubUser,
} from "../../../api/advisor/advisorMyUserPageApis";
import { showLoadingSwal } from "../../../components/loadingSwal";

// Yükleniyor Swal bileşeni
export const DeleteSelectedUsersSwal = ({
  open,
  handleClose,
  selectedUsers,
  setUpdateRow,
}) => {
  useEffect(() => {
    if (open) {
      if (selectedUsers.length === 0) {
        Swal.fire({
          title: "Uyarı",
          text: "Lütfen en az 1 firma seçiniz.",
          icon: "error",
          confirmButtonText: "Tamam",
        });
        handleClose();
        return;
      }
      Swal.fire({
        title: "Kullanıcı Sil",
        text: "Kullanıcıyı silmek istediğinize emin misiniz?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Evet",
        cancelButtonText: "Vazgeç",
      }).then((result) => {
        if (result.isConfirmed) {
          showLoadingSwal()
          deleteSubUser(selectedUsers.map((user) => user.id))
            .then((resp) => {
              Swal.close()
              Swal.fire({
                icon: "success",
                title: "Başarılı",
                text: resp.data.message,
              });
              setUpdateRow(true);
            })
            .catch((err) => {
              Swal.close()
              Swal.fire({
                title: "Bir hata oluştu!",
                text: err.response.data.message,
                icon: "error",
                confirmButtonText: "Tamam",
              });
            });
        }
      });
      handleClose();
    }
  }, [open, handleClose]);

  return null;
};
