export const createColumnDefs = (ItemSelectorCellEditor) => [
  {
    field: "id",
    hide: true,
    suppressColumnsToolPanel: true,
  },
  {
    field: "direction",
    floatingFilter: true,
    filter: "agSetColumnFilter",
    cellEditor: "agRichSelectCellEditor",
    cellRenderer: (params) => {
      let direction = params.value;
      if (!direction) {
        direction = params.data.condition.includes("1") ? "1" : "2";
      }

      switch (direction) {
        case "2":
          return "Gelen";
        case "1":
          return "Giden";
        default:
          return "";
      }
    },
    filterParams: {
      valueFormatter: (params) => {
        const direction = params.value;
        switch (direction) {
          case "2":
            return "Gelen";
          case "1":
            return "Giden";
          default:
            return "";
        }
      },
    },
    headerName: "Yön",
    flex: 1,
  },
  {
    floatingFilter: true,
    field: "note",
    filter: "agTextColumnFilter",
    headerName: "Açıklama",
    flex: 6,
  },

  {
    field: "hesap_kodu",
    headerName: "Luca Hesap Kodu",
    filter: "agTextColumnFilter",
    floatingFilter: true,
    editable: true,
    width: 400,
    cellRenderer: (params) => params.value,
    cellEditor: ItemSelectorCellEditor,
    suppressKeyboardEvent: (params) => {
      // Eğer Enter tuşuna basılmışsa
      if (params.event.key === "Enter") {
        return true; // Olayı bastır (düzenleyiciyi kapatma)
      }
      return false; // Diğer tuşlar normal çalışsın
    },
    valueSetter: (params) => (params.data.hesap_kodu = params.newValue),
  },
];
