import { useEffect } from "react";
import Swal from "sweetalert2";
import { checkTempProcess } from "../../api/company/invoices/checkTempProcess";
import { sendSelectedFiscesToLuca } from "../../api/company/fiches/sendSelectedFiscesToLuca";
import { cookies } from "../../utils/cookie";
import { jwtDecode } from "jwt-decode";

export const SendSelectedFiscesToLucaSwal = ({
  open,
  handleClose,
  companyId,
  periodId,
  selectedFicheIds,
  sendAlreadySentFichesToLuca,
  setUpdateRow,
}) => {
  const processInvoices = async (sendAlreadySentFichesToLuca) => {

    Swal.fire({
      html: `Seçilen fişler Luca'ya gönderilicek. Devam etmek istediğinize emin misiniz?`,
      icon: "info",
      iconColor: "#FFA500",
      showCancelButton: true,
      confirmButtonText: "Evet, Devam Et",
      cancelButtonText: "Hayır",
      didOpen: () => {
        document.querySelector(".swal2-container").style.zIndex = "1500";
      },
    }).then(async(result) => {
      if (result.isConfirmed) {
        Swal.fire({
      title: "Seçilen fişler Luca'ya gönderiliyor...",
      text: "Lütfen bekleyiniz",
      icon: "info",
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      customClass: {
        popup: "swal2-content-centered",
      },
      willOpen: () => {
        Swal.showLoading();
      },
    });
    try {
      const resp = await sendSelectedFiscesToLuca(
        companyId,
        periodId,
        selectedFicheIds,
        sendAlreadySentFichesToLuca
      );
      let flag = true;

      while (flag) {
        try {
          const checkResp = await checkTempProcess(
            companyId,
            periodId,
            resp.data.id
          );
          flag = checkResp.data?.isActive;

          if (!flag) {
            if (
              checkResp.data?.result?.errorMessage?.includes(
                "Seçilen fişlerden bazıları daha önce gönderilmiş"
              )
            ) {
              Swal.close();
              const result = await Swal.fire({
                icon: "warning",
                title: "Uyarı!",
                text: checkResp.data?.result?.errorMessage,
                showCancelButton: true,
                confirmButtonText: "Fişleri gönder",
                cancelButtonText: "Hayır",
                focusCancel: true,
              });

              if (result.isConfirmed) {
                await processInvoices(true);
              }
            } else if (checkResp.data?.result?.errorMessage) {
              Swal.close();
              await Swal.fire({
                icon: "error",
                title: "Gönderme İşlemi Başarısız!",
                text: checkResp.data?.result?.errorMessage,
                confirmButtonText: "Tamam",
              });
            } else if (checkResp.data?.result?.error.length>0) {
              Swal.close();
              setUpdateRow(true)
              await Swal.fire({
                icon: "warning",
                title: "Aktarım tamamlandı ancak hatalılar var!",
                html: `
                      <h4 class="mt-3 mb-4">Aktarım sonuçları</h4>
            <div class="result-container">
            <div class="result-item">
                      <span class="result-icon failed"><i class="ti ti-x ti-xs"></i></span>
                      <span class="label">Hatalı fiş sayısı</span>
                      <span style="font-weight: bold; color: #ea5455;" >${checkResp.data?.result?.error?.length}</span>
                    </div>
             <div class="result-item">
                      <span class="result-icon success"><i class="ti ti-checks ti-xs"></i></span>
                      <span class="label">Başarılı fiş sayısı</span>
                      <span style="font-weight: bold; color: #36cb85;" >${checkResp.data?.result?.success?.length}</span>
                    </div>
            </div>
            `,
                confirmButtonText: "Tamam",
              });
            } else {
              Swal.close();
              setUpdateRow(true)
              await Swal.fire({
                icon: "success",
                title: "Gönderme İşlemi Başarılı!",
                html: 'Tüm fişler <b class="text-success">başarıyla</b> gönderildi.',
                confirmButtonText: "Tamam",
              });
              
            }
          }
        } catch (err) {
          Swal.close();
          
          await Swal.fire({
            title: "Bir hata oluştu!",
            text: err.response.data.message,
            icon: "error",
            confirmButtonText: "Tamam",
          });
        }
        await new Promise((resolve) => setTimeout(resolve, 10000));
      }
    } catch (err) {
      Swal.close();
      
      await Swal.fire({
        title: "Gönderme İşlemi Başarısız!",
        text: err.response.data.message,
        icon: "error",
        confirmButtonText: "Tamam",
      });
    } finally {
      handleClose();
    }
      }})
    
  };

  useEffect(() => {
    if (open) {
      
      if (selectedFicheIds.length === 0) {
        Swal.fire({
          title: "Uyarı",
          text: "Lütfen en az 1 fiş seçin.",
          icon: "error",
          confirmButtonText: "Tamam",
        });
        handleClose();
        return;
      }
      
    
      processInvoices(sendAlreadySentFichesToLuca);
      
    }
    handleClose();
  }, [open, handleClose, selectedFicheIds, sendAlreadySentFichesToLuca]);

  return null;
};
