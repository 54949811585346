/** @format */

import { useEffect } from "react";
import Swal from "sweetalert2";

import { createExtractFiches } from "../../api/company/bankExtracts/createExtractFiches";

// Yükleniyor Swal bileşeni
export const CreateFicheFromSelectedExtracts = ({
    open,
    handleClose,
    companyId,
    periodId,
    selectedExtractIds,
    setUpdateRow,
}) => {
    useEffect(() => {
        if (open) {
            if (selectedExtractIds.length === 0) {
                Swal.fire({
                    title: "Uyarı",
                    text: "Lütfen en az 1 ekstre seçin.",
                    icon: "error",
                    confirmButtonText: "Tamam",
                });
                handleClose();
                return;
            }
            Swal.fire({
                html: `<b>${selectedExtractIds.length}</b> adet ekstre Luca aktarımı için fişleştirilecek. Emin misiniz?`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Evet, gönder!",
                cancelButtonText: "Hayır",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    Swal.fire({
                        title: "Ekstrelerin fişleri oluşturuluyor...",
                        text: "Lütfen bekleyiniz",
                        icon: "info",
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        showConfirmButton: false,
                        customClass: {
                            popup: "swal2-content-centered",
                        },
                        willOpen: () => {
                            Swal.showLoading();
                        },
                        didOpen: () => {
                            const swalPopup =
                                document.querySelector(".swal2-container");
                            if (swalPopup) {
                                swalPopup.style.zIndex = "9999";
                            }
                        },
                    });
                    createExtractFiches(companyId, periodId, selectedExtractIds)
                        .then(async (resp) => {
                            Swal.close();
                            Swal.fire({
                                title: `${resp.data.message}`,
                                confirmButtonText: "Fişlere Git",
                                icon: "success",
                                showCancelButton: true,
                                cancelButtonText: "Kapat",
                            }).then((result) => {
                                Swal.close();
                                if (result.isConfirmed) {
                                    window.location.href = `fiches`;
                                }
                            });
                            setUpdateRow(true);
                        })
                        .catch((err) => {
                            Swal.close();
                            Swal.fire({
                                title: "Bir hata oluştu!",
                                text: err.response.data.message,
                                icon: "error",
                                confirmButtonText: "Tamam",
                            });
                        });
                }
            });

            handleClose();
        }
    }, [open, handleClose, selectedExtractIds]);

    return null;
};
