import axiosInstance from "../../../../utils/maintenanceController/axios";
import moment from "moment";

export const createCheckSeriesReport = async (companyId, periodId, startDate, endDate) => {
  let startDateFormat = moment(startDate).startOf('day').format("YYYY-MM-DD HH:mm:ss");
  let endDateFormat = moment(endDate).endOf('day').format("YYYY-MM-DD HH:mm:ss");
  
  return axiosInstance.post(
    `/company/${companyId}/${periodId}/create-check-series-report`,
    {
      endDate: endDateFormat,
      startDate: startDateFormat
    }
  );
};
