import axiosInstance from "../../../../utils/maintenanceController/axios";

export const fetchSyncs = async (companyId, periodId, startDate, endDate) => {
  return axiosInstance.get(
    `/company/${companyId}/${periodId}/get-process-list`,
    {
      params: {
        startDate,
        endDate
      }
    }
  );
};

export const fetchDetailSyncs = async (companyId, periodId, processId) => {
  return axiosInstance.get(
    `/company/${companyId}/${periodId}/get-sub-processes-list`,
    {
      params: {
        processId
      }
    }
  );
};

export const cancelSelectedProcess = async (companyId, periodId, processId) => {
  return axiosInstance.post(
    `/company/${companyId}/${periodId}/cancel-process`,
    { processId }
  );
};

export const fetchFailedInvoices = async (companyId, periodId, processId) => {
  return axiosInstance.post(
    `/company/${companyId}/${periodId}/retry-fetch-failed-invoice`,
    { processId }
  );
};