
import axiosInstance from "../../../../utils/maintenanceController/axios";
export const createExtractFiches = async (
  companyId,
  periodId,
  selectedExtractIds
) => {
  return axiosInstance.post(
    `/company/${companyId}/${periodId}/create-extract-fiche`,
    { extracts: selectedExtractIds }
  );
};