import { useEffect } from "react";
import Swal from "sweetalert2";
import { getCheckActiveUsingAndJobs } from "../../../api/adminApis/getCheckActiveUsingAndJobs";
// import { deleteFiches } from "../../api/company/fiches/deleteFiches";
// Yükleniyor Swal bileşeni
export const CheckActiveUsingAndJobs = ({
  open,
  handleClose,
}) => {
  useEffect(() => {
    if (open) {
      getCheckActiveUsingAndJobs().then((resp)=>{
        const { activeJobs, recentUsers, ongoingInvoiceFicheProcessings } = resp.data;

        if (activeJobs.length > 0 || recentUsers.length > 0 || ongoingInvoiceFicheProcessings.length > 0) {
          Swal.fire({
            title: 'Devam eden işlemler veya aktif kullanıcılar var.',
            icon: 'info',
            confirmButtonText: 'Verileri Görüntüle',
            preConfirm: () => {
              // Açılacak yeni sekme için veri hazırlığı
              const htmlContent = `
                <html lang="tr">
                  <head>
                    <meta charset="UTF-8">
                    <title>Veri Tablosu</title>
                  </head>
                  <body>
                    <h1>Devam Eden İşlemler ve Aktif Kullanıcılar</h1>
            
                    ${
                      activeJobs.length > 0
                        ? `
                      <h2>Devam Eden İşlemler</h2>
                      <table border="1" cellpadding="5" cellspacing="0" style="border-collapse: collapse;">
                        <thead>
                          <tr>
                            <th>Job ID</th>
                            <th>Başlangıç Tarihi</th>
                            <th>Güncelleme Tarihi</th>
                            <th>Durum</th>
                            <th>Tamamlanma Oranı</th>
                            <th>Muşavir Adı</th>
                            <th>Mükellef Adı</th>
                          </tr>
                        </thead>
                        <tbody>
                          ${activeJobs
                            .map(
                              (job) => `
                            <tr>
                              <td>${job?.id}</td>
                              <td>${new Date(job?.data?.startedProcessFromQueue?.createdAt)?.toLocaleString()}</td>
                              <td>${new Date(job?.data?.startedProcessFromQueue?.updatedAt)?.toLocaleString()}</td>
                              <td>${job?.data?.startedProcessFromQueue?.status}</td>
                              <td>${job?.progress}</td>
                              <td>${job?.data?.advisor?.name}</td>
                              <td>${job?.data?.company?.name}</td>
                            </tr>
                          `,
                            )
                            .join('')}
                        </tbody>
                      </table>
                    `
                        : '<p>Devam eden işlem bulunmuyor.</p>'
                    }
            
                    ${
                      recentUsers.length > 0
                        ? `
                      <h2>Aktif Kullanıcılar</h2>
                      <table border="1" cellpadding="5" cellspacing="0" style="border-collapse: collapse;">
                        <thead>
                          <tr>
                            <th>Kullanıcı ID</th>
                            <th>Kullanıcı Adı</th>
                            <th>Son Aktivite Zamanı</th>
                            <th>Rol</th>
                            <th>E-posta</th>
                            <th>Telefon Numarası</th>
                          </tr>
                        </thead>
                        <tbody>
                          ${recentUsers
                            .map(
                              (user) => `
                            <tr>
                              <td>${user?.id}</td>
                              <td>${user?.name}</td>
                              <td>${new Date(user?.lastActivityTime)?.toLocaleString()}</td>
                              <td>${user?.role}</td>
                              <td>${user?.email}</td>
                              <td>${user?.phone}</td>
                            </tr>
                          `,
                            )
                            .join('')}
                        </tbody>
                      </table>
                    `
                        : '<p>Aktif kullanıcı bulunmuyor.</p>'
                    }
            
                    ${
                      ongoingInvoiceFicheProcessings.length > 0
                        ? `
                      <h2>Devam Eden Fişleştirme İşlemleri</h2>
                      <table border="1" cellpadding="5" cellspacing="0" style="border-collapse: collapse;">
                        <thead>
                          <tr>
                            <th>İşlem ID</th>
                            <th>Başlangıç Tarihi</th>
                            <th>Durum</th>
                            <th>Mükellef Adı</th>

                          </tr>
                        </thead>
                        <tbody>
                          ${ongoingInvoiceFicheProcessings
                            .map(
                              (processing) => `
                            <tr>
                              <td>${processing?.id}</td>
                              <td>${new Date(processing?.ongoingInvoiceFicheProcessingTime)?.toLocaleString()}</td>
                              <td>${processing?.status}</td>
                              <td>${processing?.name}</td>
                            </tr>
                          `,
                            )
                            .join('')}
                        </tbody>
                      </table>
                    `
                        : '<p>Devam eden fiş olulşturma işlemi bulunmuyor.</p>'
                    }
                  </body>
                </html>
              `;

              // Veriyi blob olarak hazırlama ve yeni sekmede açma
              const blob = new Blob([htmlContent], { type: 'text/html' });
              const url = URL.createObjectURL(blob);
              window.open(url, '_blank');
            },
            cancelButtonText: 'Tamam',
            cancelButtonColor: '#3085d6',
            confirmButtonColor: '#d33',
            showCancelButton: true,
          });
        } else {
          Swal.fire({
            title: 'Devam eden işlem veya aktif kullanıcı bulunmuyor.',
            icon: 'info',
            confirmButtonText: 'Tamam',
          });
        }
      

      }).catch((err)=>{
      Swal.fire({
          title: 'Hata',
          text: err.response.data.message,
          icon: 'error',
          confirmButtonText: 'Tamam',
        }); 
      })
       
      

      handleClose();
    }
  }, [open, handleClose]);

  return null;
};
