import axios from "axios";
import CheckToken from "../../../../utils/checkToken/CheckToken";
import moment from "moment";
import { logout } from "../../../auth/logout";
import axiosInstance from "../../../../utils/maintenanceController/axios";
export const checkRahatProxyVersion = async () => {
  const token = await CheckToken();

  if (!token) {
    logout();
  } else {
    return axios.get(
      `http://localhost:8368/rahat-aktarim/check-rahat-proxy-version`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }
};


export const getCompanyDetailsForEArchiveInvoices = async (companyId, periodId) => {
  return axiosInstance.get(
    `/company/${companyId}/${periodId}/get-company-detail`
  );
};

export const getLucaClientAndDomain = async () => {
  return axiosInstance.get(
    `/advisor/get-luca-client-and-domain`
  );
};

export const fetchIncomingEarchivesForSelectedCompany = async (company,data,startDate,endDate) => {
  const token = await CheckToken();

  if (!token) {
    logout();
  } else {
    return axios.post(
      `http://localhost:8368/rahat-aktarim/fetch-incoming-earchives-for-selected-company`,
      {
          company,
          clientInfo: data.lucaClient,
          startDate: moment(startDate).format("YYYY-MM-DD"),
          endDate: moment(endDate).format("YYYY-MM-DD"),
          domain: data.domain,

      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }
};

export const fetchIncomingEarchivesForCompanies = async (selectedCompanies,data,startDate,endDate) => {
  const token = await CheckToken();

  if (!token) {
    logout();
  } else {
    return axios.post(
      `http://localhost:8368/rahat-aktarim/fetch-incoming-earchives`,
      {
          companies:selectedCompanies,
          clientInfo: data.lucaClient,
          startDate: moment(startDate).format("YYYY-MM-DD"),
          endDate: moment(endDate).format("YYYY-MM-DD"),
          domain: data.domain,

      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
      }
    );
  }
};

export const isRunningCompany = async (company,startDate,endDate) => {
  const token = await CheckToken();

  if (!token) {
    logout();
  } else {
    return axios.post(
      `http://localhost:8368/rahat-aktarim/is-running-company`,
      {
          company,
          startDate: moment(startDate).format("YYYY-MM-DD"),
          endDate: moment(endDate).format("YYYY-MM-DD"),

      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }
};
export const isRunning = async () => {
  const token = await CheckToken();

  if (!token) {
    logout();
  } else {
    return axios.get(
      `http://localhost:8368/rahat-aktarim/is-running`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }
};