/** @format */

import axios from "axios";
import CheckToken from "../checkToken/CheckToken";
import { cookies } from "../cookie";
import { logout } from "../../api/auth/logout";

// Axios instance oluştur
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  // Burada gerekli başka global config ayarlarını ekleyebilirsin.
});

// Axios Interceptors ekle

axiosInstance.interceptors.response.use(
  response => {
    if(response.request.responseURL.includes("redirect=missing-info")){
      window.location.href = "/user/profile";
    }
    return response;
  },
  error => {
    // Hata durumunu yakalıyoruz
    console.log(error,'error')
    if (error?.request?.responseURL.includes("/maintenance")) {
    cookies.remove("token");
      window.location.href = "/maintenance";
    }
    return Promise.reject(error);
  }
);

// İstek yapmadan önce token ekleyen bir interceptor
axiosInstance.interceptors.request.use(async config => {
  const token = await CheckToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  } else {
    logout()
  }
  return config;
});

export default axiosInstance;
