/** @format */

import axiosInstance from "../../../../utils/maintenanceController/axios";

export const fetchBankExtractFromXLS = async (
    companyId,
    periodId,
    extractData
) => {
    return axiosInstance.post(
        `/company/${companyId}/${periodId}/sync-bank-extracts-from-excel`,
        extractData
    );
};

export const syncInvoicesFromXml = async (companyId, periodId, data) => {
    return axiosInstance.post(
        `/company/${companyId}/${periodId}/sync-invoices-from-xml`,
        {
            files: data.files,
            vkn: data.vkn,
            tckn: data.tckn,
            startDate: data.startDate,
            endDate: data.endDate,
            direction: data.direction,
        }
    );
};
