import axiosInstance from "../../../../utils/maintenanceController/axios";

export const sendSelectedFiscesToLuca = async (
  companyId,
  periodId,
  ficheIds,
  sendAlreadySentFichesToLuca
) => {
  return axiosInstance.post(
    `/company/${companyId}/${periodId}/send-fiches`,
    {
      ficheIds,
      sendAlreadySentFichesToLuca,
    }
  );
};
