//TODO  BU SAYFADA MASTER GRİD(SUBDETAILS) AYARLARI VE COLDEFS AYARLARI BULUNUR.
//TODO  ANA GRİDİN column ayarları ulunur


import { FaRegCircleCheck } from "react-icons/fa6";
import { TbProgressAlert, TbProgressX } from "react-icons/tb";
import { Typography } from "@mui/material";

const StatusRenderer = (props) => {
  const { value, data } = props;

  if (value === 'success') {
    return (
      <span
        title="Çekildi"
        style={{
          display: "inline-flex",
          alignItems: "center",
          justifyContent: "space-around",
          backgroundColor:"#28c76f",
          gap:2,
          
          borderRadius: "7px",
          padding: "0px 12px",
          
          width: "85px",
          height: "25px",
          fontSize:"11px",
          color:"#ffffff"
        }}
      ><Typography fontSize={12}marginRight={0.5}>Tamamlandı</Typography> 
        <FaRegCircleCheck style={{ color: "#ffffff" }} size={13} />
      </span>
    );
  } else if (value === 'not fetched') {
    return (
      <span
        title="Çekilmedi"
        style={{
          display: "inline-flex",
          alignItems: "center",
          justifyContent: "space-around",
          backgroundColor:"#dfdfe3",
          gap:4,
          borderRadius: "7px",
          padding: "0px 12px",
          width: "85px",
          height: "25px",
          fontSize:"11px",
        }}
      ><Typography fontSize={12} marginRight={0.5}>Çekilmedi</Typography> 
        <TbProgressX style={{ color: "#ea5455" }} size={18} />
      </span>
    );
  } else {
    return(
        <span
        title="Hata Oluştu"
        style={{
          display: "inline-flex",
          alignItems: "center",
          justifyContent: "space-around",
          backgroundColor:"red",
          gap:4,
          borderRadius: "7px",
          padding: "0px 12px",
          width: "85px",
          height: "25px",
          fontSize:"11px",
          color:"#ffffff"
        }}
      ><Typography fontSize={12} marginRight={0.5}>Hata Oluştu</Typography> 
        <TbProgressAlert
          style={{
            color: "white",
          }}
         size={16}
        />
      </span>
    );
  } 
};

export const createColumnDefs = () => [ 
  {
    headerName: '',
    checkboxSelection: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    headerClass: 'checkbox',
    pinned: 'left',
    width: 48,
    field: 'checkboxBtn',
    resizable: false,
    lockPosition: 'left',
    suppressAutoSize: true,
    suppressColumnsToolPanel: true,
    suppressMenu: true,
    editable: false,
    filter:false
  },
  {
    field: 'id',
    hide: true,
    suppressColumnsToolPanel: true,
  },
  {
    field: 'id',
    hide: true,
    suppressColumnsToolPanel: true,
  },
  {
    field: 'name',
    headerName: 'Mükellef Unvan',
    flex: 2,
    cellStyle: { textAlign: 'center' },
    filter: 'agTextColumnFilter',
  },

  {
    field: 'year',
    headerName: 'Dönem',
    width: 100,
    filter: 'agSetColumnFilter',
  },
  {
    field: 'class',
    headerName: 'Sınıf',
    width: 100,
    filter: 'agSetColumnFilter',
    floatingFilter: true,

  },
  {
    field: 'lastFetchEarchivesStatus',
    headerName: 'Bu Ay Gelen E-Arşiv Çekilme Durumu',
    flex: 1,
    filter: 'agSetColumnFilter',
    filterParams: {
      valueFormatter: (params) => {
        return params.value === 'not fetched' ? 'Çekilmedi' : params.value === 'success' ? 'Tamamlandı' : 'Hata Oluştu';
      },
    },
    cellRenderer: StatusRenderer
  },
  {
    field: 'companyId',
    headerName: 'Luca Firma ID',
    flex: 1,
    filter: 'agTextColumnFilter',
  },
  {
    field: 'periodId',
    headerName: 'Luca Dönem ID',
    flex: 1,
    filter: 'agTextColumnFilter',
  },
];
