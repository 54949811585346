export const setTitle = (companyName,locationSplit) => {
  
 let pageName= locationSplit === "invoices"
    ? "Faturalar"
    : locationSplit === "fiches"
    ? "Fişler"
    : locationSplit === "extracts"
    ? "Banka Ekstreleri":
    locationSplit === "income-expense-fiches"
    ? "Gelir/Gider Fişleri":locationSplit === "reports"?
    "Raporlar":locationSplit === "invoice-settings"?"Fatura Ayarları":locationSplit === "bank-extract-settings"?"Banka Ekstre Ayarları"
    : "";
    document.title =`${companyName} - ${pageName} - Rahat Aktarım`;
    
};
