/** @format */

import { useEffect } from "react";
import Swal from "sweetalert2";
import { separateInvoices } from "../../api/company/invoices/separateSelectedInvoices";
import { checkTempProcess } from "../../api/company/invoices/checkTempProcess";
// Yükleniyor Swal bileşeni
export const SeparateSelectedInvoicesSwal = ({
    open,
    handleClose,
    companyId,
    periodId,
    selectedInvoices,
    setUpdateRow,
}) => {
    useEffect(() => {
        if (open) {
            if (selectedInvoices.length === 0) {
                Swal.fire({
                    title: "Uyarı",
                    text: "Lütfen en az 1 fatura seçin.",
                    icon: "error",
                    confirmButtonText: "Tamam",
                });
                handleClose();
                return;
            }
            Swal.fire({
                html: `<b>${selectedInvoices.length}</b> adet fatura eşleştirilecek. Emin misiniz?`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Evet, uygula!",
                cancelButtonText: "Hayır",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    Swal.fire({
                        title: "Faturalar eşleştiriliyor",
                        text: "Lütfen bekleyiniz",
                        icon: "info",
                        showConfirmButton: false,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        customClass: {
                            popup: "swal2-content-centered",
                        },
                        willOpen: () => {
                            Swal.showLoading();
                        },
                    });
                    const selectedIds = selectedInvoices.map(
                        (detail) => detail.id
                    );
                    separateInvoices(companyId, periodId, selectedIds)
                        .then(async (resp) => {
                            let flag = true;
                            while (flag) {
                                try {
                                    const checkResp = await checkTempProcess(
                                        companyId,
                                        periodId,
                                        resp.data.id
                                    );
                                    flag = checkResp.data?.isActive;
                                    if (checkResp.data?.isActive === false) {
                                        if (checkResp.data.result?.error) {
                                            Swal.close();
                                            Swal.fire({
                                                title: `${checkResp.data.result.error}`,
                                                confirmButtonText:
                                                    "Fatura Ayarlarına Git",
                                                icon: "error",
                                                showCancelButton: true,
                                                cancelButtonText: "Kapat",
                                            }).then((result) => {
                                                if (result.isConfirmed) {
                                                    // window.location.href = fatura ayarları;
                                                }
                                            });
                                            // Hata durumu için Swal.fire kodu
                                        } else if (
                                            checkResp.data.result
                                                .erroredInvoices.length > 0
                                        ) {
                                            Swal.close();
                                            Swal.fire({
                                                title: "Faturaların bazıları eşleştirilemedi!",
                                                text: `Eşleştirilemeyen fatura sayısı: ${checkResp.data.result.erroredInvoices.length}`,
                                                icon: "warning",
                                                confirmButtonText: "Tamam",
                                            });

                                            // Bazı faturaların eşleştirilememesi durumu için Swal.fire kodu
                                        } else {
                                            Swal.close();
                                            Swal.fire({
                                                title: "Faturaların tümü eşleştirildi!",
                                                icon: "success",
                                                confirmButtonText: "Tamam",
                                            });
                                            // Başarılı durum için Swal.fire kodu
                                        }
                                        setUpdateRow(true);
                                    }
                                } catch (err) {
                                    Swal.fire({
                                        title: "Bir hata oluştu!",
                                        text: err.response.data.message,
                                        icon: "error",
                                        confirmButtonText: "Tamam",
                                    });
                                }
                                await new Promise((resolve) =>
                                    setTimeout(resolve, 10000)
                                );
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                            Swal.fire({
                                title: "Bir hata oluştu!",
                                text: err.response.data.message,
                                icon: "error",
                                confirmButtonText: "Tamam",
                            });
                        });
                }
            });

            handleClose();
        }
    }, [open, handleClose, selectedInvoices]);

    return null;
};
