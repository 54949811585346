/** @format */

import React, { useEffect, useState } from "react";
import {
    Button,
    TextField,
    Grid,
    Typography,
    InputAdornment,
    IconButton,
    Checkbox,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Divider from "@mui/material/Divider";
import { updateAdvisorInfo } from "../../api/advisor/updateAdvisorInfo";
import Swal from "sweetalert2";
import { showLoadingSwal } from "../../components/loadingSwal";
import { createApiKey } from "../../api/advisor/ApiKey";
const UserProfileSettingsPage = ({ userInfo, loading }) => {
    const [showPassword, setShowPassword] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);
    const [name, setName] = useState("");
    const [vkn, setVkn] = useState("");
    const [lucaPassword, setLucaPassword] = useState("");
    const [username, setUsername] = useState("");
    const [customerCode, setCustomerCode] = useState("");
    const [phone, setPhone] = useState("");
    const [dvdPassword, setDvdPassword] = useState("");
    const [apiKey, setApiKey] = useState("");
    const [isCopied, setIsCopied] = useState(false);

    const [automaticallyCreateFiches, setAutomaticallyCreateFiches] =
        useState(false);
    const [fetchAutomaticallyInvoices, setFetchAutomaticallyInvoices] =
        useState(false);
    const [automaticallySendFichesToLuca, setAutomaticallySendFichesToLuca] =
        useState(false);

    const [compAutomaticallyCreateFiches, setCompAutomaticallyCreateFiches] =
        useState(false);
    const [compFetchAutomaticallyInvoices, setCompFetchAutomaticallyInvoices] =
        useState(false);
    const [
        compAutomaticallySendFichesToLuca,
        setCompAutomaticallySendFichesToLuca,
    ] = useState(false);
    useEffect(() => {
        if (userInfo) {
            setName(userInfo.name);
            setVkn(userInfo.vkn);
            setLucaPassword(userInfo.lucaPassword);
            setUsername(userInfo.username);
            setCustomerCode(userInfo.customerCode);
            setPhone(userInfo.phone);
            setDvdPassword(userInfo.dvdPassword);
            setAutomaticallyCreateFiches(
                userInfo.preferences?.automaticallyCreateFiches
            );
            setFetchAutomaticallyInvoices(
                userInfo.preferences?.fetchAutomaticallyInvoices
            );
            setAutomaticallySendFichesToLuca(
                userInfo.preferences?.automaticallySendFichesToLuca
            );
            setCompAutomaticallyCreateFiches(
                userInfo.preferences?.companyDefaultPreferences
                    .automaticallyCreateFiches
            );
            setCompFetchAutomaticallyInvoices(
                userInfo.preferences?.companyDefaultPreferences
                    .fetchAutomaticallyInvoices
            );
            setCompAutomaticallySendFichesToLuca(
                userInfo.preferences?.companyDefaultPreferences
                    .automaticallySendFichesToLuca
            );
            setApiKey(userInfo?.ApiKey?.[0]?.key || "");
        }
    }, [userInfo]);
    const handleCopyApiKey = () => {
        // Burada kopyalama işlemini gerçekleştir
        navigator.clipboard.writeText(apiKey);

        // Kopyalandı durumunu güncelle
        setIsCopied(true);

        // 2 saniye sonra helper text'i gizle
        setTimeout(() => {
            setIsCopied(false);
        }, 2000);
    };
    const handleOnClick = () => {
        let data = {
            name: name,
            customerCode: customerCode,
            username: username,
            lucaPassword: lucaPassword,
            dvdPassword: dvdPassword,
            phone: phone,
            preferences: {
                automaticallyCreateFiches: automaticallyCreateFiches,
                automaticallySendFichesToLuca: automaticallySendFichesToLuca,
                fetchAutomaticallyInvoices: fetchAutomaticallyInvoices,
                companyDefaultPreferences: {
                    automaticallyCreateFiches: compAutomaticallyCreateFiches,
                    automaticallySendFichesToLuca:
                        compAutomaticallySendFichesToLuca,
                    fetchAutomaticallyInvoices: compFetchAutomaticallyInvoices,
                },
            },

            vkn: vkn,
        };
        showLoadingSwal();
        updateAdvisorInfo(data)
            .then((resp) => {
                Swal.fire({
                    icon: "success",
                    title: "Başarılı",
                    text: "Ayarlarınız başarıyla güncellendi.",
                });
            })
            .catch((err) => {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text:
                        "Bir hata oluştu! Detay: " + err.response.data.message,
                    confirmButtonText: "Tamam",
                });
            });
    };
    const handleCreateApiKey = () => {
        Swal.fire({
            html: "Yeni API anahtarı oluşturulacak. Mevcut anahtarınız <span color='red'>iptal</span> edilecek. Emin misiniz?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Evet",
            cancelButtonText: "Hayır",
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            focusCancel: true,
        }).then((result) => {
            if (result.isConfirmed) {
                showLoadingSwal("Yeni API anahtarı oluşturuluyor...");
                createApiKey()
                    .then((resp) => {
                        setApiKey(resp.data.apiKey);
                        Swal.fire({
                            icon: "success",
                            title: "Başarılı",
                            text: "Yeni API anahtarı oluşturuldu.",
                        });
                    })
                    .catch((err) => {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text:
                                "Bir hata oluştu! Detay: " +
                                err.response.data.message,
                        });
                    });
            }
        });
    };
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleClickShowPassword2 = () => setShowPassword2((show) => !show);
    return (
        <Grid
            container
            item
            md={12}
            className="grid-area"
            justifyContent={"space-between"}
            sx={{ alignItems: "flex-start", mb: 2, position: "relative" }}
        >
            {loading && (
                <div
                    style={{
                        display: "flex",
                        height: "100%",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        backdropFilter: "blur(5px)", // Sadece bu Grid içinde blur efekti uygular
                        backgroundColor: "rgba(241, 239, 239, 0.5)", // Hafif beyaz saydam arka plan
                        zIndex: 1, // İçerik üzerinde görünmesi için
                        position: "absolute", // Grid içerisinde pozisyonlama
                        top: 0,
                        left: 0,
                    }}
                >
                    <p style={{ margin: 0, fontSize: "25px", color: "#000" }}>
                        Yükleniyor...
                    </p>
                </div>
            )}
            <Grid
                item
                xs={12}
                md={6}
                sx={{
                    display: "flex",
                    gap: 2,
                    flexDirection: "column",
                    paddingRight: 1,
                }}
            >
                <Grid item xs={12} md={12}>
                    <TextField
                        fullWidth
                        className="input-field"
                        label="Mali Müşavir Ünvanı"
                        variant="outlined"
                        value={name}
                        size="small"
                        onChange={(e) => {
                            setName(e.target.value);
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={12}>
                    <Divider
                        variant="middle"
                        sx={{
                            "&::before, &::after": {
                                borderColor: "#e08434",
                                border: "1px solid #e08434",
                            },
                        }}
                    >
                        <Typography
                            variant="overline"
                            display="block"
                            sx={{ textTransform: "none", margin: 0 }}
                        >
                            Luca Bilgileri
                        </Typography>
                    </Divider>
                </Grid>

                <Grid item xs={12} md={12}>
                    <TextField
                        fullWidth
                        label="Luca Müşteri Numarası"
                        className="input-field"
                        variant="outlined"
                        value={customerCode}
                        onChange={(e) => {
                            setCustomerCode(e.target.value);
                        }}
                        size="small"
                    />
                </Grid>
                <Grid item xs={12} md={12}>
                    <TextField
                        fullWidth
                        label="Luca Kullanıcı Adı"
                        variant="outlined"
                        className="input-field"
                        value={username}
                        onChange={(e) => {
                            setUsername(e.target.value);
                        }}
                        size="small"
                    />
                </Grid>
                <Grid item xs={12} md={12}>
                    <TextField
                        fullWidth
                        label="Luca Şifresi"
                        variant="outlined"
                        className="input-field"
                        type={showPassword2 ? "text" : "password"}
                        value={lucaPassword}
                        onChange={(e) => {
                            setLucaPassword(e.target.value);
                        }}
                        size="small"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={handleClickShowPassword2}
                                        edge="end"
                                    >
                                        {showPassword2 ? (
                                            <VisibilityOff />
                                        ) : (
                                            <Visibility />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
            </Grid>
            <Grid
                item
                xs={12}
                md={6}
                sx={{
                    display: "flex",
                    gap: 2,
                    flexDirection: "column",
                    paddingLeft: 1,
                }}
            >
                <Grid item xs={12} md={12}>
                    <TextField
                        fullWidth
                        className="input-field"
                        label="Mali Müşavir Telefon Numarası"
                        variant="outlined"
                        value={phone}
                        onChange={(e) => {
                            setPhone(e.target.value);
                        }}
                        size="small"
                    />
                </Grid>
                <Grid item xs={12} md={12}>
                    <Divider
                        variant="middle"
                        sx={{
                            "&::before, &::after": {
                                borderColor: "#81BEFA",
                                border: "1px solid #81BEFA",
                            },
                            height: "auto",
                            paddingBottom: 0,
                        }}
                    >
                        <Typography
                            variant="overline"
                            display="block"
                            sx={{ textTransform: "none", margin: 0 }}
                        >
                            Dijital Vergi Dairesi Bilgileri
                        </Typography>
                    </Divider>
                </Grid>
                <Grid
                    item
                    display={"flex"}
                    sx={{ justifyContent: "space-between" }}
                >
                    {" "}
                    <Grid item xs={12} md={5.9}>
                        <TextField
                            fullWidth
                            label="Mali Müşavir Vergi Kimlik Numarası"
                            variant="outlined"
                            className="input-field"
                            value={vkn}
                            onChange={(e) => {
                                setVkn(e.target.value);
                            }}
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="Dijital Vergi Dairesi Şifresi"
                            variant="outlined"
                            className="input-field"
                            type={showPassword ? "text" : "password"}
                            value={dvdPassword}
                            onChange={(e) => {
                                setDvdPassword(e.target.value);
                            }}
                            size="small"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={handleClickShowPassword}
                                            edge="end"
                                        >
                                            {showPassword ? (
                                                <VisibilityOff />
                                            ) : (
                                                <Visibility />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                </Grid>

                <Grid item xs={12} md={12} sx={{ mt: 0.7 }}>
                    <Divider
                        variant="middle"
                        sx={{
                            "&::before, &::after": {
                                borderColor: "orange",
                                border: "1px solid orange",
                            },
                            height: "auto",
                            paddingBottom: 0,
                        }}
                    >
                        <Typography
                            variant="overline"
                            display="block"
                            sx={{ textTransform: "none", margin: 0 }}
                        >
                            Chrome Extesion Ayarları
                        </Typography>
                    </Divider>
                </Grid>
                <Grid item xs={12} md={12} sx={{ display: "flex", gap: 2 }}>
                    <div style={{ position: "relative", flex: 3 }}>
                        <TextField
                            label="API Anahtarı"
                            variant="outlined"
                            className="input-field"
                            value={apiKey}
                            size="small"
                            sx={{ width: "100%" }}
                            InputProps={{
                                readOnly: true,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {isCopied && (
                                            <div className="backdrop-text">
                                                Kopyalandı!
                                            </div>
                                        )}
                                        <IconButton
                                            onClick={handleCopyApiKey}
                                            edge="end"
                                        >
                                            <ContentCopyIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>
                    <Button
                        variant="contained"
                        size="small"
                        sx={{
                            textTransform: "none",
                            whiteSpace: "nowrap",
                            flex: 1,
                        }}
                        onClick={handleCreateApiKey}
                        color="primary"
                        className="custom-button"
                    >
                        Yeni API Anahtarı Oluştur
                    </Button>
                </Grid>
            </Grid>

            <Grid
                container
                xs={12}
                sx={{
                    mt: "12px",
                }}
            >
                <Grid item xs={12} md={12} sx={{ width: "100%" }}>
                    <Divider
                        variant="middle"
                        sx={{
                            width: "100%", // Tam genişlik
                            marginBottom: 0,
                            borderColor: "black", // İsteğe bağlı olarak kenar rengini ayarlayın
                        }}
                    >
                        <Typography
                            variant="overline"
                            display="block"
                            sx={{ textTransform: "none", margin: 0 }}
                        >
                            Tercihler
                        </Typography>
                    </Divider>
                </Grid>
                <Grid item xs={12} md={6} sx={{ paddingRight: 1 }}>
                    <Grid item xs={12} md={12} sx={{ marginLeft: 3 }}>
                        <Typography variant="overline">
                            {" "}
                            Otomatik / Toplu işlem tercihleri
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={12} sx={{ paddingRight: 2 }}>
                        <Divider
                            sx={{
                                width: "100%",
                                marginBottom: 1,
                                marginTop: 1,
                                borderColor: "black",
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={12} sx={{ display: "flex" }}>
                        <Checkbox
                            checked={fetchAutomaticallyInvoices}
                            className="default-checked"
                            onChange={(e) =>
                                setFetchAutomaticallyInvoices(e.target.checked)
                            }
                        />
                        <Typography>
                            Faturaları Otomatik / Toplu Getir
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={12} sx={{ display: "flex" }}>
                        <Checkbox
                            checked={automaticallyCreateFiches}
                            className="default-checked"
                            onChange={(e) =>
                                setAutomaticallyCreateFiches(e.target.checked)
                            }
                        />
                        <Typography>
                            Fişleri Otomatik / Toplu Oluştur
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={12} sx={{ display: "flex" }}>
                        <Checkbox
                            checked={automaticallySendFichesToLuca}
                            className="default-checked"
                            onChange={(e) =>
                                setAutomaticallySendFichesToLuca(
                                    e.target.checked
                                )
                            }
                        />
                        <Typography>
                            Fişleri Luca'ya Otomatik / Toplu Gönder
                        </Typography>
                    </Grid>{" "}
                </Grid>

                <Grid item xs={12} md={6} sx={{ paddingLeft: 1 }}>
                    <Grid item xs={12} md={12} sx={{ marginLeft: 3 }}>
                        <Typography variant="overline">
                            Yeni oluşturulan mükelleflerin varsayılan tercihleri
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={12} sx={{ paddingLeft: 2 }}>
                        <Divider
                            sx={{
                                width: "100%",
                                marginBottom: 1,
                                marginTop: 1,
                                borderColor: "black",
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={12} sx={{ display: "flex" }}>
                        <Checkbox
                            checked={compFetchAutomaticallyInvoices}
                            className="default-checked"
                            onChange={(e) =>
                                setCompFetchAutomaticallyInvoices(
                                    e.target.checked
                                )
                            }
                        />
                        <Typography>Faturaları Otomatik Getir</Typography>
                    </Grid>
                    <Grid item xs={12} md={12} sx={{ display: "flex" }}>
                        <Checkbox
                            checked={compAutomaticallyCreateFiches}
                            className="default-checked"
                            onChange={(e) =>
                                setCompAutomaticallyCreateFiches(
                                    e.target.checked
                                )
                            }
                        />
                        <Typography>Fişleri Otomatik Oluştur</Typography>
                    </Grid>
                    <Grid item xs={12} md={12} sx={{ display: "flex" }}>
                        <Checkbox
                            checked={compAutomaticallySendFichesToLuca}
                            className="default-checked"
                            onChange={(e) =>
                                setCompAutomaticallySendFichesToLuca(
                                    e.target.checked
                                )
                            }
                        />
                        <Typography>Fişleri Luca'ya Otomatik Gönder</Typography>
                    </Grid>{" "}
                </Grid>
            </Grid>

            <Grid
                item
                xs={12}
                sx={{
                    display: "flex",
                    mt: "12px",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Grid xs={12} md={2}>
                    <Button
                        variant="contained"
                        color="primary"
                        className="custom-button"
                        onClick={handleOnClick}
                    >
                        Değişiklikleri Kaydet
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default UserProfileSettingsPage;
