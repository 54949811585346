//TODO  BU SAYFADA MASTER GRİD(SUBDETAILS) AYARLARI VE COLDEFS AYARLARI BULUNUR.
//TODO  ANA GRİDİN column ayarları ulunur

import moment from "moment";
import sideBar from "../../../components/GridSideBar/gridSideBar.js";
import localeTextTr from "../../../locale.tr.js";
import { TbCircleCheck, TbLoader } from "react-icons/tb";
import { createTurkishFilterParams } from "../../../utils/agGridTextFilterTr/agGridFilterUtils.js";

const StatusRenderer = (props) => {
  const { value, data } = props;

  if (value) {
    return (
      <span
        title="Çalışıyor"
        style={{
          display: "inline-flex",
          alignItems: "center",
          justifyContent: "space-around",
          backgroundColor: "#ff9f43",
          borderRadius: "7px",
          padding: "0px 12px",
          width: "85px",
          height: "25px",
          color: "white",
          gap: 2,
          fontSize: "12px",
        }}
      >
        Çalışıyor
        <TbLoader
          className="spinner"
          style={{
            color: "#ffffff",
          }}
          size={15}
        ></TbLoader>
      </span>
    );
  } else {
    return (
      <span
        title="Tamamlandı"
        style={{
          display: "inline-flex",
          alignItems: "center",
          justifyContent: "space-around",
          backgroundColor: "#00cfe8",
          borderRadius: "7px",
          padding: "0px 12px",
          width: "85px",
          height: "25px",
          color: "white",
          gap: 2,
          fontSize: "12px",
        }}
      >
        Tamamlandı
        <TbCircleCheck
          style={{
            color: "#ffffff",
          }}
          size={15}
        ></TbCircleCheck>
      </span>
    );
  }
};

export const createColumnDefs = [
  {
    headerName: "",
    checkboxSelection: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    headerClass: "checkbox",
    pinned: "left",
    width: 48,
    field: "checkboxBtn",
    resizable: false,
    lockPosition: "left",
    suppressAutoSize: true,
    suppressColumnsToolPanel: true,
    suppressMenu: true,
    editable: false,
    filter: false,
  },
  {
    field: "master",
    cellRenderer: "agGroupCellRenderer",
    editable: false,
    filter: false,
    width: 48,
  },
  {
    field: "id",
    hide: true,
    suppressColumnsToolPanel: true,
  },
  {
    field: "companyId",
    hide: true,
    suppressColumnsToolPanel: true,
  },

  {
    field: "isActive",
    headerName: "Aktif",
    width: 145,
    filter: "agSetColumnFilter",
    filterParams: {
      valueFormatter: (params) => {
        return params.value ? "Çalışıyor" : "Tamamlandı";
      },
    },
    cellStyle: { textAlign: "center" },
    cellRenderer: StatusRenderer,
  },
  {
    field: "createdAt",
    headerName: "Başlangıç Tarihi",
    cellRenderer: (params) => {
      return params.value
        ? moment(params.value).format("DD.MM.YYYY HH:mm")
        : "";
    },
    filter: false,
    flex: 1,
  },
  {
    field: "updatedAt",
    headerName: "Güncelleme Tarihi",
    cellRenderer: (params) => {
      return params.value
        ? moment(params.value).format("DD.MM.YYYY HH:mm")
        : "";
    },
    filter: false,
    flex: 1,
  },
  {
    field: "statusMessage",
    filter: "agTextColumnFilter",
    headerName: "Aktarım Durumu",
    cellRenderer: (params) => {
      const style = {
        color: params.data.status === 3 ? "#dc3545" : "inherit",
        display: "flex",
        alignItems: "center",
        height: "100%",
      };

      return <div style={style}>{params.data.statusMessage}</div>;
    },
    flex: 4,
  },
];

//////////////////DETAILS AYARLARI/////////////////

const createDetailsColumnDefs = (ButtonRenderer) => [
  {
    headerName: "",
    checkboxSelection: true,
    headerCheckboxSelection: true,
    headerClass: "checkbox",
    pinned: "left",
    width: 48,
    field: "checkboxBtn",
    resizable: false,
    lockPosition: "left",
    suppressAutoSize: true,
    suppressColumnsToolPanel: true,
    suppressMenu: true,
    editable: false,
  },
  {
    field: "createdAt",
    headerName: "İşlem Tarihi",
    cellRenderer: (params) => {
      return params.value
        ? moment(params.value).format("DD.MM.YYYY HH:mm")
        : "";
    },
    width: 140,
  },
  {
    field: "statusMessage",
    floatingFilter: true,
    filter: "agTextColumnFilter",
    headerName: "Bilgi Mesajı",
    flex: 1,
  },
  {
    field: "actions",
    headerName: "İşlemler",
    flex: 1,
    cellRenderer: ButtonRenderer,
    cellStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
];

export const detailsGridOptions = (ButtonRenderer) => ({
  columnDefs: createDetailsColumnDefs(ButtonRenderer),
  defaultColDef: {
    resizable: true,
    filter: false,
    filterParams:createTurkishFilterParams(),
  },
  sideBar: sideBar.detailsSideBar,
  localeText: localeTextTr,
  rowDragManaged: true,
  animateRows: true,
  rowSelection: "multiple",
  singleClickEdit: true,
  rowMultiSelectWithClick: true,
});
