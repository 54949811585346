/** @format */

//TODO  BU SAYFADA MASTER GRİD(SUBDETAILS) AYARLARI VE COLDEFS AYARLARI BULUNUR.
//TODO  ANA GRİDİN column ayarları ulunur

import moment from "moment";
import sideBar from "../../../components/GridSideBar/gridSideBar.js";
import localeTextTr from "../../../locale.tr.js";
import { TbCheck } from "react-icons/tb";
import { FaXmark } from "react-icons/fa6";
import { createTurkishFilterParams } from "../../../utils/agGridTextFilterTr/agGridFilterUtils.js";

const StatusRenderer = (props) => {
    const { value, data } = props;

    if (value === true) {
        return (
            <span
                title="Var"
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 5,
                    width: "100px",
                    height: "60%",
                    backgroundColor: "#28c76f",
                    borderRadius: 5,
                    color: "white",
                    fontSize: "15px",
                }}
            >
                <TbCheck
                    style={{
                        color: "#ffffff",
                    }}
                    size={15}
                ></TbCheck>
                Var
            </span>
        );
    } else {
        return (
            <span
                title="Yok"
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 5,
                    height: "60%",
                    width: "100px",
                    backgroundColor: "#ea5455",
                    borderRadius: 5,
                    color: "white",
                    fontSize: "15px",
                }}
            >
                <FaXmark
                    style={{
                        color: "#ffffff",
                    }}
                    size={15}
                ></FaXmark>
                Yok
            </span>
        );
    }
};

export const createColumnDefs = [
    {
        headerName: "",
        checkboxSelection: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        headerClass: "checkbox",
        pinned: "left",
        width: 48,
        field: "checkboxBtn",
        resizable: false,
        lockPosition: "left",
        suppressAutoSize: true,
        suppressColumnsToolPanel: true,
        suppressMenu: true,
        editable: false,
        filter: false,
    },
    {
        field: "",
        cellRenderer: "agGroupCellRenderer",
        editable: false,
        filter: false,
        width: 48,
    },
    {
        field: "id",
        hide: true,
        suppressColumnsToolPanel: true,
    },
    {
        field: "email",
        headerName: "E-posta",
        flex: 2,
        cellStyle: { textAlign: "center" },
        filter: "agTextColumnFilter",
    },
    {
        field: "username",
        headerName: "Kullanıcı Adı",
        flex: 2,
        cellStyle: { textAlign: "center" },
        filter: "agTextColumnFilter",
    },
    {
        field: "password",
        headerName: "Şifre",
        flex: 2,
        filter: "agTextColumnFilter",
        cellStyle: { textAlign: "center" },
    },
    {
        field: "ApiKey",
        headerName: "API Anahtarı",
        flex: 2,
        filter: "agTextColumnFilter",
        cellStyle: { textAlign: "center" },
    },
    {
        field: "isCanSendFicheToLuca",
        headerName: "Luca Fiş Gönderme İzni",
        cellRenderer: StatusRenderer,
        filter: "agSetColumnFilter",
        filterParams: {
            valueFormatter: (params) => {
                return params.value ? "Var" : "Yok";
            },
        },
        cellStyle: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
    },
    {
        field: "createdAt",
        headerName: "Oluşturulma Tarihi",
        flex: 2,
        filter: false,
        cellStyle: { textAlign: "center" },
        cellRenderer: (params) => {
            return params.value
                ? moment(params.value).format("DD.MM.YYYY HH:mm")
                : "";
        },
    },
    {
        field: "updatedAt",
        headerName: "Güncellenme Tarihi",
        flex: 2,
        cellRenderer: (params) => {
            return params.value
                ? moment(params.value).format("DD.MM.YYYY HH:mm")
                : "";
        },
        cellStyle: { textAlign: "center" },
        filter: false,
    },
];

//////////////////DETAILS AYARLARI/////////////////

const createDetailsColumnDefs = [
    {
        field: "name",
        headerName: "Mükellef Unvan",
        flex: 2,
        cellStyle: { textAlign: "center" },
        filter: "agTextColumnFilter",
    },

    {
        field: "year",
        headerName: "Dönem",
        width: 100,
        filter: "agSetColumnFilter",
    },
    {
        field: "class",
        headerName: "Sınıf",
        width: 100,
        filter: "agSetColumnFilter",
        filterParams: {
            valueFormatter: (params) => {
                return params.value === 1
                    ? "Bilanço"
                    : params.value === 2
                    ? "İşletme"
                    : undefined;
            },
        },

        cellRenderer: (params) => {
            return params.value === 1
                ? "Bilanço"
                : params.value === 2
                ? "İşletme"
                : "";
        },
    },
    {
        field: "companyId",
        headerName: "Luca Firma ID",
        flex: 1,
        filter: "agTextColumnFilter",
    },
    {
        field: "periodId",
        headerName: "Luca Dönem ID",
        flex: 1,
        filter: "agTextColumnFilter",
    },
];

export const detailsGridOptions = {
    columnDefs: createDetailsColumnDefs,
    defaultColDef: {
        flex: 1,
        resizable: true,
        width: 120,
        floatingFilter: true,
        filter: false,
        filterParams: createTurkishFilterParams(),
    },
    sideBar: sideBar.detailsSideBar,
    localeText: localeTextTr,
    rowDragManaged: true,
    animateRows: true,
    rowSelection: "multiple",
    singleClickEdit: true,
    rowMultiSelectWithClick: true,
};
