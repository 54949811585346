/** @format */

import axiosInstance from "../../../../utils/maintenanceController/axios";

export const insertTableInvoiceRule = async (
    companyId,
    periodId,
    id,
    vkn_tckn
) => {
    return axiosInstance.post(
        `/company/${companyId}/${periodId}/insert-table-invoice-rule`,
        { id, vkn_tckn }
    );
};

export const fetchInvoice = async (companyId, periodId, id, source) => {
    return axiosInstance.post(
        `/company/${companyId}/${periodId}/fetch-invoice`,
        {
            id,
            responseType:
                source === "birfatura" ||
                source === "turkcell" ||
                source === "hepsiburadaefaturam" ||
                source === "mysoft"
                    ? "PDF"
                    : "HTML",
        }
    );
};
