import { Grid, Button } from "@mui/material";

import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import { useState } from "react";
import TaxPayerPage from "./taxPayerPage";
import SyncPage from "./syncPage";
import MyUsersPage from "./myUsersPage";
import ReportPage from "./reportPage";

const UserProfileInfoPage = ({ userInfo, isSubUser }) => {
  const [selectedButtonId, setSelectedButtonId] = useState(1);
  const handleButtonClick = (buttonId) => {
    setSelectedButtonId(buttonId);
  };
  return (
    <Grid item md={12} className="grid-area">
      <Grid
        md={12}
        sx={{
          display: "flex",
          gap: 5,
          pl: "20px",
          alignItems: "center",
        }}
      >
        <Grid>
          <Button
            fullWidth
            variant={selectedButtonId === 1 ? "contained" : "text"}
            type="submit"
            className={
              selectedButtonId === 1
                ? "advisorProcess-selected-button"
                : "advisorProcess-unselected-button"
            }
            sx={{ display: "flex" }}
            onClick={() => handleButtonClick(1)}
          >
            <BadgeOutlinedIcon fontSize="medium" />
            Mükelleflerim
          </Button>
        </Grid>
        <Grid item>
          <Button
            fullWidth
            variant={selectedButtonId === 2 ? "contained" : "text"}
            type="submit"
            className={
              selectedButtonId === 2
                ? "advisorProcess-selected-button"
                : "advisorProcess-unselected-button"
            }
            sx={{ display: "flex" }}
            onClick={() => handleButtonClick(2)}
          >
            <ManageAccountsOutlinedIcon fontSize="medium" />
            Senkronizasyonlar
          </Button>
        </Grid>
        <Grid item>
          <Button
            fullWidth
            variant={selectedButtonId === 3 ? "contained" : "text"}
            type="submit"
            className={
              selectedButtonId === 3
                ? "advisorProcess-selected-button"
                : "advisorProcess-unselected-button"
            }
            sx={{ display: "flex" }}
            onClick={() => handleButtonClick(3)}
          >
            <BadgeOutlinedIcon fontSize="medium" />
            Raporlar
          </Button>
        </Grid>
        {!isSubUser && (
          <Grid item>
            <Button
              fullWidth
              variant={selectedButtonId === 4 ? "contained" : "text"}
              type="submit"
              className={
                selectedButtonId === 4
                  ? "advisorProcess-selected-button"
                  : "advisorProcess-unselected-button"
              }
              sx={{ display: "flex" }}
              onClick={() => handleButtonClick(4)}
            >
              <BadgeOutlinedIcon fontSize="medium" />
              Kullanıcılarım
            </Button>
          </Grid>
        )}
      </Grid>
      {selectedButtonId === 1 ? (
        <TaxPayerPage />
      ) : selectedButtonId === 2 ? (
        <SyncPage />
      ) : selectedButtonId === 3 ? (
        <ReportPage />
      ) : selectedButtonId === 4 ? (
        <MyUsersPage />
      ) : (
        ""
      )}
    </Grid>
  );
};

export default UserProfileInfoPage;
