import axiosInstance from "../../../../utils/maintenanceController/axios";
import moment from "moment";

export const updateDateFiches = async (companyId, periodId, selectedIds, date) => {
  return axiosInstance.post(
    `/company/${companyId}/${periodId}/update-fiche-date`,
    {
      ficheIds: selectedIds,
      date: moment(date).format("YYYY-MM-DD")
    }
  );
};
