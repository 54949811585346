import { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import { deleteBankExtracts } from "../../api/company/bankExtracts/deleteBankExtracts";
import { checkTempProcess } from "../../api/company/invoices/checkTempProcess";
import { createExtractFiches } from "../../api/company/bankExtracts/createExtractFiches";
import { createFicheFromReportFiches } from "../../api/company/IncomeExpenseFiches/createFicheFromReportFiches";


// Yükleniyor Swal bileşeni
export const CreateFicheFromSelectedExtracts = ({
  open,
  handleClose,
  companyId,
  periodId,
  selectedFichesIds,
  setUpdateRow,
}) => {
  useEffect(() => {
    if (open) {
      if (selectedFichesIds.length === 0) {
        Swal.fire({
          title: "Uyarı",
          text: "Lütfen en az 1 ekstre seçin.",
          icon: "error",
          confirmButtonText: "Tamam",
        });
        handleClose();
        return;
      }
      Swal.fire({
        html: `<b>${selectedFichesIds.length}</b> adet Fiş Luca aktarımı için fişleştirilecek. Emin misiniz?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet, gönder!',
        cancelButtonText: 'Hayır',
      }).then(async (result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: "Z-Raporu/Gider Fişlerinin fişleri oluşturuluyor...",
            text: "Lütfen bekleyiniz",
            icon: "info",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            customClass: {
              popup: "swal2-content-centered",
            },
            willOpen: () => {
              Swal.showLoading();
            },
            didOpen: () => {
              const swalPopup = document.querySelector(".swal2-container");
              if (swalPopup) {
                swalPopup.style.zIndex = "9999";
              }
            },
          });
          createFicheFromReportFiches(
            companyId,
            periodId,
            selectedFichesIds
          )
          .then(async (resp) => {
            let flag = true;
            while (flag) {
              try {
                const checkResp = await checkTempProcess(
                  companyId,
                  periodId,
                  resp.data.id
                );
                flag = checkResp.data?.isActive;
                console.log()
                if (checkResp.data?.isActive === false) {
                  if (checkResp.data.result?.error) {
                    Swal.close();
                    Swal.fire({
                      title: 'Z-Raporu/Gider Fişleri oluşturulurken bir hata oluştu!',
                      text: checkResp.data.result.error,
                      icon: 'error',
                      confirmButtonText: 'Tamam',
                    });
                  } else {
                    Swal.close();
                    Swal.fire({
                      title: `${checkResp.data?.result?.message}`,
                      confirmButtonText: 'Fişlere Git',
                      icon: 'success',
                      showCancelButton: true,
                      cancelButtonText: 'Kapat',
                    }).then((result) => {
                      Swal.close();
                      if (result.isConfirmed) {
                        window.location.href = `fiches`;
                      }
                    });
                    setUpdateRow(true)
                  }
                  setUpdateRow(true);
                }
              } catch (err) {
                Swal.fire({
                  title: "Bir hata oluştu!",
                  text: err.response.data.message,
                  icon: "error",
                  confirmButtonText: "Tamam",
                });
              }
              await new Promise((resolve) => setTimeout(resolve, 10000));
            }
            
            
          })
          .catch((err) => {
            Swal.close();
            Swal.fire({
              title: "Bir hata oluştu!",
              text: err.response.data.message,
              icon: "error",
              confirmButtonText: "Tamam",
            });
          });
        }
      });

      handleClose();
    }
  }, [open, handleClose, selectedFichesIds]);

  return null;
};
