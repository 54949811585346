import React from 'react';
import { Box, Typography, Container, Grid } from '@mui/material';
import { styled } from '@mui/system';
import './styles.css'; // Animasyonlar için özel stiller

const NeonText = styled(Typography)(({ theme }) => ({
  fontFamily: "'Varela Round', sans-serif",
  fontSize: '90px',
  color: '#5be0b3',
  letterSpacing: '3px',
  textAlign: 'center',
  textShadow: '0 0 5px #6eecc1',
  animation: 'flux 2s linear infinite',
  width: '300px',
  margin: '30px 0 10px',
}));

const DoorFrame = styled(Box)(({ theme }) => ({
  height: '495px',
  width: '295px',
  borderRadius: '90px 90px 0 0',
  backgroundColor: '#8594a5',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const Door = styled(Box)(({ theme }) => ({
  height: '450px',
  width: '250px',
  borderRadius: '70px 70px 0 0',
  backgroundColor: '#a0aec0',
  position: 'relative',
}));

const Rectangle = styled(Box)(({ theme }) => ({
  height: '70px',
  width: '25px',
  backgroundColor: '#cbd8e6',
  borderRadius: '4px',
  position: 'absolute',
  top: '220px',
  left: '20px',
}));

const Handle = styled(Box)(({ theme }) => ({
  height: '8px',
  width: '50px',
  backgroundColor: '#ebf3fc',
  borderRadius: '4px',
  position: 'absolute',
  top: '250px',
  left: '30px',
}));

const Window = styled(Box)(({ theme }) => ({
  height: '40px',
  width: '130px',
  backgroundColor: '#1c2127',
  borderRadius: '3px',
  margin: '80px auto',
  position: 'relative',
}));

const Leaf = styled(Box)(({ theme }) => ({
  height: '40px',
  width: '130px',
  backgroundColor: '#8594a5',
  borderRadius: '3px',
  position: 'absolute',
  animation: 'leaf 7s infinite',
  transformOrigin: 'right',
}));

const Eye = styled(Box)(({ theme }) => ({
  height: '5px',
  width: '15px',
  backgroundColor: 'white',
  borderRadius: '50%',
  position: 'absolute',
  top: '15px',
  animation: 'eye 7s ease-in-out infinite',
}));

const Eye2 = styled(Eye)(({ theme }) => ({
  left: '65px',
}));

const Message = styled(Typography)(({ theme }) => ({
  fontFamily: "'Poppins', sans-serif",
  fontSize: '30px',
  color: 'white',
  fontWeight: 500,
  textAlign: 'center',
  marginTop: '15px',
  width: '350px',
}));

const Message2 = styled(Typography)(({ theme }) => ({
  fontFamily: "'Poppins', sans-serif",
  fontSize: '18px',
  color: 'white',
  fontWeight: 300,
  textAlign: 'center',
  marginTop: '15px',
  width: '350px',
}));

const ForbiddenPage = () => {
  return (

      <Grid  sx={{
        backgroundColor: '#1c2127',
        height: '100vh',
        width:"100vw",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}>
       <NeonText className="neon">403</NeonText>
      <DoorFrame>
        <Door>
          <Rectangle />
          <Handle />
          <Window>
            <Eye className="eye" style={{ left: '25px' }} />
            <Eye2 className="eye eye2" />
            <Leaf className="leaf" />
          </Window>
        </Door>
      </DoorFrame>
      <Message>Yetkisiz Erişim</Message>
      <Message2>Yetkinizin olmadığı bir sayfaya erişmeye çalıştınız.</Message2> 
      </Grid>
      
  );
};

export default ForbiddenPage;
