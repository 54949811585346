/** @format */

import { useEffect } from "react";
import Swal from "sweetalert2";
import { checkTempProcess } from "../../api/company/invoices/checkTempProcess";
import { updateSeparatedDataOfInvoices } from "../../api/company/invoices/updateSeparatedDataOfInvoices";
// Yükleniyor Swal bileşeni
export const UpdateSelectedInvoicesSeparatedData = ({
    open,
    handleClose,
    companyId,
    periodId,
    updatedInvoices,
    setUpdateRow,
}) => {
    useEffect(() => {
        if (open) {
            if (updatedInvoices.length === 0) {
                Swal.fire({
                    title: "Uyarı",
                    text: "Güncellenmiş fatura bulunamadı.",
                    icon: "error",
                    confirmButtonText: "Tamam",
                });
                handleClose();
                return;
            }
            let isValidSeparatedData = true;
            let emptyAccountCode = false;
            console.log(updatedInvoices);
            updatedInvoices.forEach((invoice) => {
                invoice.separatedData.forEach((data) => {
                    if (data?.borc === 0 && data?.alacak === 0) {
                        isValidSeparatedData = false;
                    } else if (data?.hesap_kodu?.trim() === "") {
                        isValidSeparatedData = false;
                        emptyAccountCode = true;
                    }
                });
            });
            if (!isValidSeparatedData) {
                Swal.fire({
                    title: "Uyarı",
                    text: `Güncellenmiş faturalarda ${
                        emptyAccountCode
                            ? "hesap kodu boş olan alan var."
                            : "borc ve alacak bilgisi 0 olan alan var."
                    } var. Lütfen bilgilerinizi kontrol edin.`,
                    icon: "error",
                    confirmButtonText: "Tamam",
                });
                handleClose();
                return;
            }

            Swal.fire({
                title: "Uyarı",
                text: `${updatedInvoices?.length} adet fatura güncellenecek. Devam etmek istediğinizden emin misiniz?`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Evet, devam et!",
                cancelButtonText: "Vazgeç",
                focusCancel: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    Swal.fire({
                        title: "Faturalar güncelleniyor...",
                        text: "Lütfen bekleyiniz",
                        icon: "info",
                        showConfirmButton: false,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        customClass: {
                            popup: "swal2-content-centered",
                        },
                        willOpen: () => {
                            Swal.showLoading();
                        },
                    });
                    updateSeparatedDataOfInvoices(
                        companyId,
                        periodId,
                        updatedInvoices
                    )
                        .then(async (resp) => {
                            let flag = true;
                            while (flag) {
                                try {
                                    const checkResp = await checkTempProcess(
                                        companyId,
                                        periodId,
                                        resp.data.id
                                    );
                                    flag = checkResp.data?.isActive;
                                    if (checkResp.data?.isActive === false) {
                                        if (checkResp.data.result?.error) {
                                            Swal.close();
                                            Swal.fire({
                                                html: `<p> ${checkResp.data.result.error} </p>`,
                                                icon: "error",
                                                confirmButtonText: "Tamam",
                                            });
                                        } else {
                                            Swal.close();
                                            Swal.fire({
                                                title: `${checkResp.data.result.message}`,
                                                confirmButtonText: "Tamam",
                                                icon: "success",
                                            });
                                        }
                                        setUpdateRow(true);
                                    }
                                } catch (err) {
                                    Swal.fire({
                                        title: "Bir hata oluştu!",
                                        text: err.response.data.message,
                                        icon: "error",
                                        confirmButtonText: "Tamam",
                                    });
                                    setUpdateRow(true);
                                }
                                await new Promise((resolve) =>
                                    setTimeout(resolve, 2000)
                                );
                            }
                        })
                        .catch((err) => {
                            setUpdateRow(true);
                            Swal.fire({
                                title: "Bir hata oluştu!",
                                text: err.response.data.message,
                                icon: "error",
                                confirmButtonText: "Tamam",
                            });
                        });
                }
            });

            handleClose();
        }
    }, [open, handleClose, updatedInvoices]);

    return null;
};
