import { Button, Container, Grid, Typography } from "@mui/material";

export const NotFound = () => {
    return (
      <Container
      maxWidth={false}
      style={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor:"#ffffff"
      }}
    >
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ textAlign: 'center' }}
      >
        <Grid item xs={12}sx={{gap:1}}>
          <div className="four_zero_four_bg">
            <Typography variant="h1" style={{ marginBottom: '20px',fontFamily: '"Comic Sans MS", "Arial Rounded MT Bold", sans-serif' }}>
              404
            </Typography>
          </div>
  
          <div className="contant_box_404" style={{gap:1}}>
            <Typography variant="h5" fontWeight={550} sx={{mb:3,fontFamily: '"Comic Sans MS", "Arial Rounded MT Bold", sans-serif',}}>
              Kaybolmuş gibi görünüyorsunuz
            </Typography>
            <Typography  style={{ marginBottom: '20px',fontFamily: '"Comic Sans MS", "Arial Rounded MT Bold", sans-serif', }}>
              Aradığınız sayfa bulunamadı!
            </Typography>
  
            <Button
              href="https://muhasebe.rahataktarim.com/login"
              variant="contained"
              sx={{ marginBottom: 2,textDecoration: 'underline',color:"#fff",backgroundColor:"#39ac31","&:hover": {
              backgroundColor:"#308f2a",
              textDecoration: 'underline',
            } }}
            >
              Geri Dön
            </Button>
            <Button
              href="https://muhasebe.rahataktarim.com/login"
              variant="outlined"
              sx={{borderColor:"#041737",color:"black",
                "&:hover": {borderColor:"#041737",
              backgroundColor:"#c5dafdc7",
            }}}
            >
              Ana Sayfaya Git
            </Button>
          </div>
        </Grid>
      </Grid>
    </Container>
    );
  };