import { Grid, TextField, Button, Typography } from "@mui/material";
import { TbView360Arrow } from "react-icons/tb";
import UserProfileDropdown from "./profilMenu";
import CompanyChoose from "./companyChoose";

import { useEffect, useRef, useState } from "react";
import { getJsonItem } from "../../utils/localStorageProcess";
import { Link, useLocation } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { cookies } from "../../utils/cookie";

const Navbar = ({ setLoading,setFirstLoading,firstLoading }) => {
  const location = useLocation();
  const [companyDetails, setCompanyDetails] = useState();
  const companyDetailsRef = useRef(companyDetails);
const [userTitle,setUserTitle]=useState('')
  useEffect(() => {
    let locationSplit = location.pathname.split("/");
    const details = getJsonItem(`#${locationSplit[2]}/${locationSplit[3]}`);
    const jwtToken = cookies.get("token");
    const user = jwtDecode(jwtToken);
    setUserTitle(user?.subUserName?user.subUserName:user.advisorName)
    setCompanyDetails(details);
    companyDetailsRef.current = details;
     // referansı güncelle
  }, [location.pathname]);
  
  useEffect(() => {
    let locationSplit = location.pathname.split("/");
    const details = getJsonItem(`#${locationSplit[2]}/${locationSplit[3]}`);
    const jwtToken = cookies.get("token");
    const user = jwtDecode(jwtToken);
    setUserTitle(user?.subUserName?user.subUserName:user.advisorName)
    setCompanyDetails(details);
    companyDetailsRef.current = details;

  }, []);

  return (
    <Grid container className="navbar">
      <Grid sx={{ display: "flex", alignItems: "center", gap: 1,pl:1 }}>
        <Typography color={"#04173793"} fontWeight={"550"}>
          {companyDetails?.name} - {companyDetails?.year} -{" "}
          {companyDetails?.class === 2 ? "( İŞLETME )" : "( BİLANÇO )"}{" "}
        </Typography>
      </Grid>
      <Grid
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "50%",
          gap:2
        }}
      >
        {/* <Button  TEMA BUTONU
          sx={{
            borderRadius: "50%",
            minWidth: 0,
            width: "3rem",
            height: "3rem",
            padding: 0,
            boxShadow: "none",
            "&:hover": {
              backgroundColor: "rgba(126, 126, 126, 0.2)",
            },
          }}
        >
          <MoonStars color="black" />
        </Button> */}
        <Grid
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            sx={{
              width: "9rem",
              height: "2rem",
              backgroundColor: "#f8f7fa",
              textTransform: "none",
              borderTopRightRadius: "0px",
              borderBottomRightRadius: "0px",
              "&:hover": {
                backgroundColor: "#e5e5e7",
                borderTopRightRadius: "0px",
                borderBottomRightRadius: "0px",
              },
            }}
            component={Link}
            to={`/dashboard`}
            startIcon={<TbView360Arrow color="3f3f3f"/>}
          >
            <Typography color={"#3f3f3f"} >
              Firma Değiştir
            </Typography>
          </Button>
          <CompanyChoose setLoading={setLoading} setFirstLoading={setFirstLoading} firstLoading={firstLoading} />
        </Grid>

        <UserProfileDropdown
          title={userTitle?userTitle:""}
        />
      </Grid>
    </Grid>
  );
};

export default Navbar;
