/** @format */

//TODO  BU SAYFADA MASTER GRİD(SUBDETAILS) AYARLARI VE COLDEFS AYARLARI BULUNUR.
//TODO  ANA GRİDİN column ayarları ulunur

import moment from "moment";
import sideBar from "../../components/GridSideBar/gridSideBar.js";
import localeTextTr from "../../locale.tr.js";

import { FaCircleCheck } from "react-icons/fa6";
import { FaCircleXmark } from "react-icons/fa6";
import { FaRegCircle, FaRegTrashAlt } from "react-icons/fa";
import { FaHourglass } from "react-icons/fa6";
import { createTurkishFilterParams } from "../../utils/agGridTextFilterTr/agGridFilterUtils.js";
import { IconButton } from "@mui/material";
let parentGridApi = null;
// Helper function to calculate status based on separatedData
const calculateStatus = (data) => {
    if (
        !data.separatedData ||
        data.separatedData.length === 0 ||
        (data.status !== 104 && data.status !== 102)
    ) {
        return data.status;
    }

    let totalBorc = 0;
    let totalAlacak = 0;
    data?.separatedData?.forEach((row) => {
        totalBorc += Number(row.borc);
        totalAlacak += Number(row.alacak);
    });
    totalBorc = parseFloat(totalBorc.toFixed(2));
    totalAlacak = parseFloat(totalAlacak.toFixed(2));
    const payableAmount = parseFloat(data.payable_amount.toFixed(2));
    if (totalBorc === totalAlacak && totalAlacak === payableAmount) {
        return 104; // Aktarılabilir
    } else {
        return 102; // Dengesiz
    }
};

const StatusRenderer = (props) => {
    let { value, data } = props;
    parentGridApi = props.api;

    // Calculate status based on current data
    const currentStatus = calculateStatus(data);

    if (currentStatus === 104) {
        return (
            <span
                title="Aktarılabilir"
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                }}
            >
                <FaCircleCheck style={{ color: "#28c76f" }} size={24} />
            </span>
        );
    }

    // Rest of the status renderer logic remains the same
    if (value === 100) {
        return null;
    } else if (value === 101) {
        return (
            <span
                title="Cari Oluşturulacak"
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                }}
            >
                <FaRegCircle
                    style={{
                        color: "#ffffff",
                        backgroundColor: "#ffa551",
                        padding: 5,
                        borderRadius: "50%",
                    }}
                    size={14}
                />
            </span>
        );
    } else if (value === 102) {
        return (
            <span
                title="Dengesiz"
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                }}
            >
                <FaCircleXmark style={{ color: "#ea5455" }} size={24} />
            </span>
        );
    } else if (value === 103 && data.class !== 2) {
        return (
            <span
                title="Eşleştirilmemiş"
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                }}
            >
                <FaHourglass
                    style={{
                        color: "white",
                        backgroundColor: "#333333",
                        padding: 5,
                        borderRadius: "50%",
                    }}
                    size={14}
                />
            </span>
        );
    } else if (value === 104 || data.class === 2) {
        return (
            <span
                title="Aktarılabilir"
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                }}
            >
                <FaCircleCheck style={{ color: "#28c76f" }} size={24} />
            </span>
        );
    }
    return value;
};
export const createColumnDefs = (ButtonRenderer, companyClass) => [
    {
        headerCheckboxSelection: true,
        checkboxSelection: true,
        maxWidth: 80,
        minWidth: 50,
        pinned: "left",
        filter: false,
        headerName: "",
        headerCheckboxSelectionFilteredOnly: true,
        headerClass: "checkbox",
        field: "checkboxBtn",
        resizable: false,
        lockPosition: "left",
        suppressAutoSize: true,
        suppressColumnsToolPanel: true,
        suppressMenu: true,
        editable: false,
        cellRenderer: ButtonRenderer,
    },
    {
        field: "master",
        headerName: "",
        cellRenderer: "agGroupCellRenderer",
        editable: false,
        filter: false,
        suppressMenu: true,
        width: 48,
        hide: companyClass === 2,
    },
    {
        field: "status",
        headerName: "Aktarım Durumu",
        filter: "agSetColumnFilter",
        cellStyle: { textAlign: "center" },
        width: 65,
        filterParams: {
            valueFormatter: function (params) {
                if (params.value === 100 || params.class === 2) {
                    return "Oluşturuldu";
                } else if (params.value === 101) {
                    return "Cari Oluşturulacak";
                } else if (params.value === 102) {
                    return "Dengesiz";
                } else if (params.value === 103) {
                    return "Eşleştirilmemiş";
                } else if (params.value === 104) {
                    return "Aktarılabilir";
                } else {
                    return params.value;
                }
            },
        },
        editable: false,
        cellRenderer: StatusRenderer,
    },
    {
        field: "uuid",
        headerName: "UUID",
        filter: "agTextColumnFilter",
        hide: true,
        width: 100,
    },
    {
        field: "number",
        headerName: "Fatura No",
        filter: "agTextColumnFilter",
        cellDataType: "text",
        cellRenderer: (params) => {
            if (params.value) {
                return String(params.value);
            }
            return "";
        },
        suppressMenu: false,
        width: 170,
    },
    {
        field: "direction",
        headerName: "Yön",
        width: 80,
        filter: "agSetColumnFilter", // Seçenek filtresi olarak ayarla

        valueGetter: (params) => {
            if (params.data.direction === 1) {
                return "Giden";
            } else if (params.data.direction === 2) {
                return "Gelen";
            }
            return params.data.direction;
        },
        valueFormatter: (params) => {
            if (params.value === 1) {
                return "Giden";
            } else if (params.value === 2) {
                return "Gelen";
            }
            return params.value;
        },
    },
    {
        field: "profile_id",
        headerName: "Fatura Profili",
        filter: "agSetColumnFilter", // Seçenek filtresi olarak ayarla
        width: 150,
    },
    {
        field: "type_code",
        headerName: "Tip",
        filter: "agSetColumnFilter",
        width: 100,
    },
    {
        field: "issue_datetime",
        headerName: "Tarih",
        width: 120,
        filter: false,
        valueFormatter: (params) => {
            moment.locale("tr");
            return moment.utc(params.value).format("DD/MM/YYYY");
        },
    },
    {
        field: "sender_tax",
        headerName: "Gönderici VKN/TCKN",
        filter: "agTextColumnFilter",
        width: 130,
    },
    {
        field: "sender_name",
        headerName: "Gönderici Ünvan",
        filter: "agTextColumnFilter",
        width: 200,
    },
    {
        field: "receiver_tax",
        headerName: "Alıcı VKN/TCKN",
        filter: "agTextColumnFilter",
        width: 130,
    },
    {
        field: "receiver_name",
        headerName: "Alıcı Ünvan",
        filter: "agTextColumnFilter",
        width: 160,
    },
    {
        field: "luca_current_code",
        headerName: "Luca Hesap Kodu",
        filter: "agTextColumnFilter",
        width: 130,
        hide: companyClass === 2,
        cellStyle: (params) =>
            !!params.data.luca_current_code
                ? { backgroundColor: "transparent" }
                : params.data.status !== 103 && companyClass !== 2
                ? { backgroundColor: "#ea5455" }
                : { backgroundColor: "transparent" },
    },
    {
        field: "luca_current_name",
        headerName: "Luca Hesap Adı",
        filter: "agTextColumnFilter",
        width: 200,
        hide: companyClass === 2,
        cellStyle: (params) =>
            !!params.data.luca_current_name
                ? { backgroundColor: "transparent" }
                : params.data.status !== 103 && companyClass !== 2
                ? { backgroundColor: "#ea5455" }
                : { backgroundColor: "transparent" },
    },
    {
        field: "payable_amount",
        headerName: "Tutar",
        filter: "agNumberColumnFilter",
        width: 150,
        valueFormatter: (params) => {
            return new Intl.NumberFormat("tr-TR", {
                style: "currency",
                currency: params.data.currency_code,
            }).format(params.value);
        },
    },
    {
        field: "diff",
        headerName: "Fark",
        filter: "agNumberColumnFilter",
        width: 150,

        valueFormatter: (params) => {
            return new Intl.NumberFormat("tr-TR", {
                style: "currency",
                currency: params.data.currency_code,
            }).format(params.value);
        },
    },
    {
        field: "source",
        headerName: "Kaynak",
        filter: "agSetColumnFilter",
        width: 180,
        hide: true,
    },
];

//////////////////DETAILS AYARLARI/////////////////

const createDetailsColumnDefs = (
    ItemSelectorCellEditor,
    expandedRowId,
    companyClass
) => [
    {
        headerName: "",
        cellRenderer: (params) => {
            if (companyClass === 1) {
                return (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                        }}
                    >
                        <IconButton
                            onClick={() => {
                                const gridApi = params.api;
                                const expandedRowNode = gridApi.getRowNode(
                                    params.node.id
                                );

                                if (expandedRowNode) {
                                    // Remove row from detail grid
                                    gridApi.applyTransaction({
                                        remove: [params.data],
                                    });

                                    // Update main grid data
                                    const mainGridApi = parentGridApi;
                                    const mainRow =
                                        mainGridApi.getRowNode(expandedRowId);
                                    if (mainRow) {
                                        const updatedSeparatedData =
                                            mainRow.data.separatedData.filter(
                                                (item, index) =>
                                                    !(
                                                        item.id ===
                                                        params.data.id
                                                    )
                                            );

                                        // Update main row with new data and recalculated status
                                        const updatedData = {
                                            ...mainRow.data,
                                            separatedData: updatedSeparatedData,
                                        };

                                        mainRow.setData(updatedData);
                                    }
                                }
                            }}
                            color="error"
                        >
                            <FaRegTrashAlt size={15} />
                        </IconButton>
                    </div>
                );
            }

            return "";
        },
        pinned: "left",
        width: 48,
        resizable: false,
        lockPosition: "left",
        suppressAutoSize: true,
        suppressColumnsToolPanel: true,
        suppressMenu: true,
        editable: false,
    },
    {
        field: "hesap_kodu",
        headerName: "Hesap Kodu",
        filter: "agTextColumnFilter",
        width: 400,
        cellRenderer: (params) => params.value,
        cellEditor: ItemSelectorCellEditor,
        editable: companyClass === 1,
        suppressKeyboardEvent: (params) => params.event.key === "Enter",
    },
    {
        field: "borc",
        headerName: "Borç",
        filter: "agNumberColumnFilter",
        valueFormatter: (params) => {
            return new Intl.NumberFormat("tr-TR", {
                style: "currency",
                currency: "TRY",
            }).format(params.value);
        },
        editable: (params) => {
            if (params.data.alacak === 0 && companyClass === 1) {
                return true;
            }
            return false;
        },
    },
    {
        field: "alacak",
        headerName: "Alacak",
        filter: "agNumberColumnFilter",
        valueFormatter: (params) => {
            return new Intl.NumberFormat("tr-TR", {
                style: "currency",
                currency: "TRY",
            }).format(params.value);
        },
        editable: (params) => {
            if (params.data.borc === 0 && companyClass === 1) {
                return true;
            }
            return false;
        },
    },
];

export const detailsGridOptions = (
    ItemSelectorCellEditor,
    expandedRowId,
    companyClass
) => ({
    columnDefs: createDetailsColumnDefs(
        ItemSelectorCellEditor,
        expandedRowId,
        companyClass
    ),
    defaultColDef: {
        flex: 1,
        resizable: true,
        width: 120,
        floatingFilter: true,
        filter: false,
        filterParams: createTurkishFilterParams(),
    },
    sideBar: sideBar.detailsSideBar,
    localeText: localeTextTr,
    rowDragManaged: true,
    animateRows: true,
    singleClickEdit: true,
    stopEditingWhenCellsLoseFocus: true,
    getRowId: (params) => params.data.id, // Replace with your unique field

    onCellEditingStopped: (params) => {
        if (params.data) {
            const gridApi = params.api;
            const mainRow = parentGridApi.getRowNode(expandedRowId);
            if (mainRow) {
                // isEdited bayrağı ile güncellenmiş veriyi ekle
                console.log(params, "params");
                const updatedData = {
                    ...params.data,
                    // Eğer değiştirilen sütun hesap_kodu ise hesap_kodu değerini params.oldValue ile güncelle , birden fazla set ettiğimiz için yeni seçtiğimiz veri oldValue olarak geliyor // TODO bug fix edilmeli ama şuanda çalışyor
                    hesap_kodu:
                        params.colDef.field === "hesap_kodu"
                            ? params.oldValue
                            : params.data.hesap_kodu,
                    isEdited: true,
                };

                console.log(updatedData);
                gridApi.applyTransaction({
                    update: [updatedData],
                });
                let rowIndex = mainRow.data.separatedData.findIndex(
                    (item) => item.id === params.data.id
                );
                // Ana griddeki ilgili satırın separatedData'sını güncelle
                let updatedSeparatedData = mainRow.data.separatedData;
                updatedSeparatedData[rowIndex] = updatedData;

                mainRow.setData({
                    ...mainRow.data,
                    separatedData: updatedSeparatedData,
                });

                // Status sütununu yenile
                parentGridApi.refreshCells({
                    force: true,
                    rowNodes: [mainRow],
                    columns: ["status"],
                });
            }
        }
    },
});
