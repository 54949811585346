import axiosInstance from "../../../../utils/maintenanceController/axios";
import moment from "moment";

export const createGibSalesInvoiceList = async (
  companyId,
  periodId,
  startDate,
  endDate,
  invoiceProfileAndTypes
) => {
  const startDateFormat = moment(startDate)
    .startOf("day")
    .format("YYYY-MM-DD HH:mm:ss");
  const endDateFormat = moment(endDate)
    .endOf("day")
    .format("YYYY-MM-DD HH:mm:ss");
  return axiosInstance.post(
    `/company/${companyId}/${periodId}/create-gib-sales-invoice-list`,
    {startDate:startDateFormat,endDate:endDateFormat,invoiceProfileAndTypes}
  );
};
