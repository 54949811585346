/** @format */

import axiosInstance from "../../../utils/maintenanceController/axios";

export const copyRuleToCompanies = async (data) => {
  return axiosInstance.post(
    `/advisor/copy-rule-to-selected-companies`,
    { ...data }
  );
};

export const copyRuleToCompany = async (companyId, periodId, baseCompanyId) => {
  return axiosInstance.post(
    `/company/${companyId}/${periodId}/copy-rule-to-company`,
    { baseCompanyId: String(baseCompanyId) }
  );
};
