/** @format */
import React, { useState, useEffect, useRef } from "react";
import { Grid, Alert } from "@mui/material";
import { motion, AnimatePresence } from "framer-motion";

const AnimatedAlerts = ({ infoText, warningText, errorText }) => {
    const [alertType, setAlertType] = useState("info");
    const intervalRef = useRef(null);

    const startInterval = () => {
        intervalRef.current = setInterval(() => {
            // `alertType` güncellenir ve render tetiklenir
            setAlertType((prevType) => {
                if (prevType === "info" && warningText) {
                    return "warning";
                } else if (prevType === "warning" && errorText) {
                    return "error";
                } else if (prevType === "error" && infoText) {
                    return "info";
                } else {
                    return infoText
                        ? "info"
                        : warningText
                        ? "warning"
                        : errorText
                        ? "error"
                        : "info";
                }
            });
        }, 10000);
    };

    useEffect(() => {
        // Interval başlat
        startInterval();

        // Component unmount olduğunda interval'i temizle
        return () => clearInterval(intervalRef.current);
    }, [infoText, warningText, errorText]);

    const handleMouseEnter = () => {
        // Alert üzerine gelince interval durdurulur
        clearInterval(intervalRef.current);
    };

    const handleMouseLeave = () => {
        // Alert'ten çıkınca interval tekrar başlar
        startInterval();
    };

    return (
        <Grid item md={12} sx={{ mt: 1 }}>
            <AnimatePresence mode="wait">
                {alertType === "info" && infoText ? (
                    <motion.div
                        key="info"
                        initial={{ y: 10, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        exit={{ y: -10, opacity: 0 }}
                        transition={{ duration: 0.5 }}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                    >
                        <Alert
                            severity="info"
                            sx={{
                                borderRadius: "8px",
                                border: "1px solid #041737",
                                transition: "opacity 0.5s ease-in-out",
                            }}
                        >
                            <div
                                dangerouslySetInnerHTML={{ __html: infoText }}
                            />
                        </Alert>
                    </motion.div>
                ) : alertType === "warning" && warningText ? (
                    <motion.div
                        key="warning"
                        initial={{ y: 10, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        exit={{ y: -10, opacity: 0 }}
                        transition={{ duration: 0.5 }}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                    >
                        <Alert
                            severity="warning"
                            sx={{
                                borderRadius: "8px",
                                border: "1px solid #ef7918",
                                transition: "opacity 0.5s ease-in-out",
                            }}
                        >
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: warningText,
                                }}
                            />
                        </Alert>
                    </motion.div>
                ) : alertType === "error" && errorText ? (
                    <motion.div
                        key="error"
                        initial={{ y: 10, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        exit={{ y: -10, opacity: 0 }}
                        transition={{ duration: 0.5 }}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                    >
                        <Alert
                            severity="error"
                            sx={{
                                borderRadius: "8px",
                                border: "1px solid #d32f2f",
                                transition: "opacity 0.5s ease-in-out",
                            }}
                        >
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: errorText,
                                }}
                            />
                        </Alert>
                    </motion.div>
                ) : null}
            </AnimatePresence>
        </Grid>
    );
};

export default AnimatedAlerts;
