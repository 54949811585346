/** @format */

import axios from "axios";
import CheckToken from "../../../utils/checkToken/CheckToken";

export const finishMaintenance = async (info) => {
  const token = await CheckToken();

  if (!token) {
    window.location.href = "/login";
  } else {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/admin/finish-maintenance`,{info:info},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      } 
    );
  }
};