/** @format */

//TODO  ANA GRİDİN column ayarları ulunur

import { Tooltip } from "@mui/material";
import { FaXmark } from "react-icons/fa6";
import { TbCheck } from "react-icons/tb";
import { HiOutlineExternalLink } from "react-icons/hi";

const getUrlOfIntegrator = (integrator) => {
    switch (integrator) {
        case "parasut":
            return "https://uygulama.parasut.com/kullanici-girisi";
        case "uyumsoft":
            return "https://portal.uyumsoft.com.tr/Giris?ReturnUrl=%2f";
        case "elogo":
            return "https://efatura.elogo.com.tr/Account/LoginPage";
        case "mimsoft":
            return "https://portal.mimsoft.com.tr/";
        case "rahatbelge":
            return "https://kurulus.rahatsistem.com.tr/account/login";
        case "edm":
            return "https://portal4.edmbilisim.com.tr/EFaturaUI/";
        case "veriban":
            return "https://portal.veriban.com.tr/Account/Login?ReturnUrl=%2f";
        case "sovos":
            return "https://cloud.fitbulut.com/kullanici/giris";
        case "nesbilgi":
            return "https://portal.nesbilgi.com.tr/Account/Login?ReturnUrl=%2F";
        case "birfatura":
            return "https://uygulama.birfatura.com/uyegirisi";
        case "turmob":
            return "https://turmobefatura.luca.com.tr/account/login?ReturnUrl=%2f";
        case "kolaysoft":
            return "https://portal.kolaysoft.com.tr/accounting/login";
        case "izibiz":
            return "https://portal.izibiz.com.tr/auth/login";
        case "trendyolefaturam":
            return "https://trendyolefaturam.com/portal/giris";
        case "odeal":
            return "https://portal.odeal.com/giris";
        case "digitalplanet":
            return "https://portaltdy.digitalplanet.com.tr/Login/index.html?returnUrl=/";
        case "turkcell":
            return "https://portal.turkcellesirket.com/auth/login";
        case "hepsiburadaefaturam":
            return "https://www.hepsiburadaefaturam.com/Giris?ReturnUrl=%2f";
        case "mysoft":
            return "https://portal.mysoft.com.tr/#!/login";
        default:
            throw new Error("Integrator not found");
    }
};
const StatusRenderer = (props) => {
    const { value, data } = props;

    if (value === true || value === "true") {
        return (
            <span
                title="Çekilsin"
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-around",
                    width: "80px",
                    height: "60%",
                    backgroundColor: "#28c76f",
                    borderRadius: 5,
                    color: "white",
                    fontSize: "12px",
                }}
            >
                <TbCheck
                    style={{
                        color: "#ffffff",
                    }}
                    size={15}
                ></TbCheck>
                Çekilsin
            </span>
        );
    } else {
        return (
            <span
                title="Çekilmesin"
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-around",
                    height: "60%",
                    width: "80px",
                    backgroundColor: "#ea5455",
                    borderRadius: 5,
                    color: "white",
                    fontSize: "12px",
                }}
            >
                <FaXmark
                    style={{
                        color: "#ffffff",
                    }}
                    size={15}
                ></FaXmark>
                Çekilmesin
            </span>
        );
    }
};

export const createColumnDefs = [
    {
        headerCheckboxSelection: true,
        checkboxSelection: true,
        maxWidth: 80,
        minWidth: 50,
        pinned: "left",
        filter: false,
        headerName: "",
        headerCheckboxSelectionFilteredOnly: true,
        headerClass: "checkbox",
        field: "checkboxBtn",
        resizable: false,
        lockPosition: "left",
        suppressAutoSize: true,
        suppressColumnsToolPanel: true,
        suppressMenu: true,
        editable: false,
        cellRenderer: (params) => {
            const handleIconClick = () => {
                window.open(
                    getUrlOfIntegrator(params.data.integrator),
                    "_blank"
                );
            };

            return (
                <Tooltip title="Entegratörü yeni sekmede aç" arrow>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                            height: "100%",
                            cursor: "pointer",
                        }}
                        onClick={handleIconClick}
                    >
                        <HiOutlineExternalLink size={20} />
                    </div>
                </Tooltip>
            );
        },
    },

    {
        field: "id",
        hide: true,
        suppressColumnsToolPanel: true,
    },
    {
        field: "integrator",
        headerName: "Entegratör",
        width: 120,
        filter: false,
        editable: true,
        cellRenderer: (params) => {
            if (!params.value) {
                return ""; // veya boş string veya varsayılan bir değer döndürebilirsiniz
            }
            const integrator = params.value.toLowerCase();
            switch (integrator) {
                case "parasut":
                    return "Paraşüt";
                case "uyumsoft":
                    return "Uyumsoft";
                case "elogo":
                    return "E-Logo";
                case "mimsoft":
                    return "Mimsoft";
                case "rahatbelge":
                    return "Rahat Belge";
                case "edm":
                    return "EDM";
                case "veriban":
                    return "Veriban";
                case "sovos":
                    return "Sovos";
                case "nesbilgi":
                    return "Nesbilgi";
                case "birfatura":
                    return "Birfatura";
                case "turmob":
                    return "Luca Türmob";
                case "kolaysoft":
                    return "KolaySoft - Eczacı Kart";
                case "izibiz":
                    return "İZİBİZ (Beta)";
                case "trendyolefaturam":
                    return "Trendyol E-Faturam (Beta) ";
                case "odeal":
                    return "Ödeal (Beta)";
                case "digitalplanet":
                    return "Digital Planet (Beta)";
                case "turkcell":
                    return "Turkcell (Beta)";
                case "hepsiburadaefaturam":
                    return "Hepsiburada E-faturam (Beta)";
                case "mysoft":
                    return "Mysoft (Beta)";
                default:
                    return params.value.toUpperCase();
            }
        },
        cellEditor: "agRichSelectCellEditor",
        cellEditorParams: {
            values: [
                "mimsoft",
                "rahatbelge",
                "parasut",
                "uyumsoft",
                "elogo",
                "edm",
                "veriban",
                "sovos",
                "nesbilgi",
                "birfatura",
                "turmob",
                "kolaysoft",
                "izibiz",
                "trendyolefaturam",
                "odeal",
                "digitalplanet",
                "turkcell",
                "hepsiburadaefaturam",
                "mysoft",
            ],
            cellHeight: 30,
            popupHeight: 200,
            valueListMaxHeight: 200,
            valueListMaxWidth: 230,
            suppressScrollWhileEditing: false,
            cellRenderer: (params) => {
                if (!params.value) return ""; // Boş değerler için kontrol
                const integrator = params.value.toLowerCase();
                switch (integrator) {
                    case "parasut":
                        return "Paraşüt";
                    case "uyumsoft":
                        return "Uyumsoft";
                    case "elogo":
                        return "E-Logo";
                    case "mimsoft":
                        return "Mimsoft";
                    case "rahatbelge":
                        return "Rahat Belge";
                    case "edm":
                        return "EDM";
                    case "veriban":
                        return "Veriban";
                    case "sovos":
                        return "Sovos";
                    case "nesbilgi":
                        return "Nesbilgi";
                    case "birfatura":
                        return "Birfatura";
                    case "turmob":
                        return "Luca Türmob";
                    case "kolaysoft":
                        return "KolaySoft - Eczacı Kart";
                    case "izibiz":
                        return "İZİBİZ (Beta)";
                    case "trendyolefaturam":
                        return "Trendyol E-Faturam (Beta) ";
                    case "odeal":
                        return "Ödeal (Beta)";
                    case "digitalplanet":
                        return "Digital Planet (Beta)";
                    case "turkcell":
                        return "Turkcell (Beta)";
                    case "hepsiburadaefaturam":
                        return "Hepsiburada E-faturam (Beta)";
                    case "mysoft":
                        return "Mysoft (Beta)";
                    default:
                        return params.value.toUpperCase();
                }
            },
        },
    },
    {
        field: "username",
        headerName: "Kullanıcı Adı",
        width: 200,
    },
    {
        field: "password",
        headerName: "Şifre",
        cellRenderer: (params) => {
            return params.value ? "******" : "";
        },
        width: 100,
    },
    {
        field: "corporateCode",
        headerName: "Firma Kısa Kod",
        width: 150,
    },
    {
        field: "isWillFetchOutbox",
        headerName: "Giden Belge",
        cellRenderer: StatusRenderer,
        cellEditor: "agRichSelectCellEditor",
        cellStyle: {
            display: "flex",
            alignItems: "center",
        },
        cellEditorParams: {
            values: [true, false],
            cellHeight: 30,
            cellRenderer: StatusRenderer,
        },
    },
    {
        field: "isWillFetchInbox",
        headerName: "Gelen Belge",
        cellRenderer: StatusRenderer,
        cellStyle: {
            display: "flex",
            alignItems: "center",
        },
        cellEditor: "agRichSelectCellEditor",
        cellEditorParams: {
            values: [true, false],
            cellHeight: 30,
            cellRenderer: StatusRenderer,
        },
    },
];
