import { useEffect } from "react";
import Swal from "sweetalert2";
import {  adminUpdateAdvivorsAutoFetchInvoice } from "../../../api/adminApis/updateAdvivors";
// import { deleteFiches } from "../../api/company/fiches/deleteFiches";
// Yükleniyor Swal bileşeni
export const SelectedAdvisorsAutoFetchInvoicesUpdateSwal = ({
  open,
  handleClose,
  selectedAdvisors,
  process,
  setUpdateRow,
}) => {
  useEffect(() => {
    if (open) {
      if (selectedAdvisors.length === 0) {
        Swal.fire({
          title: "Uyarı",
          text: "En az 1 müşavir seçin",
          icon: "error",
          confirmButtonText: "Tamam",
        });
        handleClose();
        return;
      }
          let data = {
            isWillActivate:process==="open"?true:false,
            ids: selectedAdvisors,
          };
          adminUpdateAdvivorsAutoFetchInvoice(data)
            .then(async (resp) => {
              setUpdateRow(true);
              Swal.fire({
                icon: 'success',
                title: 'İşlem Başarılı!',
                text: 'Müşavir bilgileri güncellendi',
                confirmButtonText: 'Tamam',
              });

            })
            .catch((err) => {
              Swal.fire({
                icon: 'error',
                title: 'İşlem Başarısız!',
                text: 'Müşavir bilgileri güncellenirken hata oluştu',
                confirmButtonText: 'Tamam',
              });
            });
       
      

      handleClose();
    }
  }, [open, handleClose, selectedAdvisors]);

  return null;
};
