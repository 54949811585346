/** @format */

import axiosInstance from "../../../../utils/maintenanceController/axios";

export const reFindIncomeExpenseFiches = async (
    companyId,
    periodId,
    ficheReportIds
) => {
    return axiosInstance.post(
        `/company/${companyId}/${periodId}/re-find-account-for-selected-fiche-reports`,
        { ficheReportIds }
    );
};
