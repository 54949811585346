/** @format */

import { useEffect } from "react";
import Swal from "sweetalert2";
import { reFindLucaAccountForSelectedExtracts } from "../../api/company/bankExtracts/reFindLucaAccountForSelectedExtracts";

// Yükleniyor Swal bileşeni
export const ReFindLucaAccountCodesSwal = ({
    open,
    handleClose,
    companyId,
    periodId,
    selectedExtractIds,
    setUpdateRow,
}) => {
    useEffect(() => {
        if (open) {
            if (selectedExtractIds.length === 0) {
                Swal.fire({
                    title: "Uyarı",
                    text: "Lütfen en az 1 ekstre seçin.",
                    icon: "error",
                    confirmButtonText: "Tamam",
                });
                handleClose();
                return;
            }
            Swal.fire({
                html: `<b>${selectedExtractIds.length}</b> adet ekstrenin Luca Hesap Kodu tekrardan eşleştirilecek. Devam etmek istiyor musunuz?`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Evet, Devam et!",
                cancelButtonText: "Hayır",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    Swal.fire({
                        title: "Ekstrelerin Luca Hesap Kodları Eşleştiriliyor...",
                        html: '<i class="fas fa-spinner fa-spin fa-3x"></i><br/><br><p>Lütfen bekleyiniz...</p>',
                        icon: "info",
                        showConfirmButton: false,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                    });
                    reFindLucaAccountForSelectedExtracts(
                        companyId,
                        periodId,
                        selectedExtractIds
                    )
                        .then((resp) => {
                            setUpdateRow(true);
                            Swal.fire({
                                html: `
                      <h4 class="mt-3 mb-4">Sonuçlar</h4>
            <div class="result-container">
              ${
                  resp.data.foundCount > 0
                      ? `<div class="result-item">
                      <span class="result-icon success"><i class="ti ti-checks ti-xs"></i></span>
                      <span class="label">Luca hesabı bulunan ekstre sayısı</span>
                      <span style="font-weight: bold; color: #36cb85;" >${resp.data.foundCount}</span>
                    </div>`
                      : ""
              }
              ${
                  resp.data.notFoundCount > 0
                      ? `<div class="result-item">
                      <span class="result-icon failed"><i class="ti ti-x ti-xs"></i></span>
                      <span class="label">Luca hesabı bulunamayan ekstre sayısı</span>
                      <span style="font-weight: bold; color: #ea5455;" >${resp.data.notFoundCount}</span>
                    </div>`
                      : ""
              }
              <div class="result-item">
                <span class="result-icon total"><i class="ti ti-list-details ti-xs"></i></span>
                <span class="label">Toplam aranan ekstre adedi: </span>
                <span style="font-weight: bold; color: #00cfe8;" >${
                    resp.data.foundCount + resp.data.notFoundCount
                }</span>
              </div>
            </div>
            `,
                                confirmButtonText: "Tamam",
                                icon: "success",
                            });
                        })
                        .catch((err) => {
                            Swal.fire({
                                title: "Bir hata oluştu!",
                                text: err.response.data.message,
                                icon: "error",
                            });
                        });
                }
            });

            handleClose();
        }
    }, [open, handleClose, selectedExtractIds]);

    return null;
};
