/** @format */

import axios from "axios";

export const register = (
  email,
  password,
  phone,
  vkn,
  name,
  accountingProgram
) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/api/register`, {
    email,
    password,
    phone,
    vkn,
    name,
    accountingProgram,
  });
};
