import axiosInstance from "../../../../utils/maintenanceController/axios";

export const updateBankExtracts = async (companyId, periodId, selectedIds, extractData) => {
  return axiosInstance.post(
    `/company/${companyId}/${periodId}/update-bank-extracts`,
    {
      ids: selectedIds,
      extractData,
    }
  );
};

export const updateBankExtract = async (companyId, periodId, extractData) => {
  return axiosInstance.post(
    `/company/${companyId}/${periodId}/update-bank-extract`,
    {
      extractData,
    }
  );
};
