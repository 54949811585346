/**
 * eslint-disable no-loop-func
 *
 * @format
 */

/** @format */

import { useEffect, useRef } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import "moment/locale/tr";

import { TextField } from "@mui/material";
import {
    checkRahatProxyVersion,
    fetchIncomingEarchivesForCompanies,
    getLucaClientAndDomain,
    isRunning,
} from "../../../api/company/invoices/EArchiveInvoicesApis";
const MySwal = withReactContent(Swal);
// Yükleniyor Swal bileşeni
export const GetSelectedCompaniesEArchiveInvoices = ({
    open,
    handleClose,
    selectedCompaniesDetails,
    setUpdateRow,
}) => {
    const startDateRef = useRef(moment().startOf("month"));
    const endDateRef = useRef(moment());

    async function downloadRahatProxy() {
        try {
            window.location.href = "/downloads/RahatProxy.rar";
        } catch (downloadError) {
            Swal.fire({
                icon: "error",
                title: "Dosya İndirilemedi!",
                text: "Dosya indirme işlemi sırasında bir hata oluştu.",
                confirmButtonText: "Tamam",
            });
        }
    }
    useEffect(() => {
        if (open) {
            if (selectedCompaniesDetails.length === 0) {
                Swal.fire({
                    title: "Uyarı",
                    text: "Lütfen en az bir firma seçin",
                    icon: "error",
                    confirmButtonText: "Tamam",
                });
                handleClose();
                return;
            }

            MySwal.fire({
                title: "Tarih Aralığı Seçin",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Devam",
                cancelButtonText: "İptal",
                focusConfirm: false,
                html: (
                    <div style={{ display: "flex" }}>
                        <LocalizationProvider
                            dateAdapter={AdapterMoment}
                            adapterLocale="tr"
                        >
                            <DatePicker
                                label="Başlangıç Tarihi"
                                value={moment(startDateRef.current)}
                                onChange={(newValue) => {
                                    startDateRef.current = moment(newValue);
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} />
                                )}
                            />
                            <DatePicker
                                label="Bitiş Tarihi"
                                value={moment(endDateRef.current)}
                                onChange={(newValue) => {
                                    endDateRef.current = moment(newValue);
                                }}
                                sx={{ marginLeft: 2 }}
                                renderInput={(params) => (
                                    <TextField
                                        sx={{ color: "black" }}
                                        {...params}
                                    />
                                )}
                            />
                        </LocalizationProvider>
                    </div>
                ),
            }).then((result) => {
                if (result.isConfirmed) {
                    let startDateFormat = moment(startDateRef.current).format(
                        "DD.MM.YYYY"
                    );
                    let endDateFormat = moment(endDateRef.current).format(
                        "DD.MM.YYYY"
                    );
                    checkRahatProxyVersion()
                        .then((resp) => {
                            console.log(resp.data.version);
                            if (
                                resp.data.version !==
                                process.env.REACT_APP_RAHAT_PROXY_VERSION
                            ) {
                                Swal.fire({
                                    icon: "error",
                                    title: "Rahat Proxy Güncellemesi Mevcut!",
                                    text: "Lütfen Rahat Proxy uygulamasını güncelleyin.",
                                    confirmButtonText: "Uygulamayı İndir",
                                    showDenyButton: true,
                                    denyButtonText: "Tamam",
                                    allowOutsideClick: false,
                                    allowEscapeKey: false,
                                    allowEnterKey: false,
                                    focusConfirm: true,
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        downloadRahatProxy();
                                    }
                                });
                            } else {
                                let html;
                                html = `<b>${startDateFormat} - ${endDateFormat}</b> tarih aralığındaki gelen <br/> e-arşiv faturalar DVD'den alınacak. Devam etmek istiyor musunuz?`;
                                Swal.fire({
                                    html,
                                    icon: "question",
                                    showCancelButton: true,
                                    confirmButtonText: "Evet, senkronize et!",
                                    cancelButtonText: "Hayır",
                                }).then(async (result) => {
                                    if (result.isConfirmed) {
                                        Swal.fire({
                                            icon: "info",
                                            title: "Seçilen mükelleflerin gelen e-arşiv faturaları çekiliyor...",
                                            html: `
                    
                    <p style="color: red;">İşlemin yarıda kalmaması için lütfen sayfayı kapatmayınız ve yenilemeyiniz</p>
                    <p class="text-info">Yeni sekme açarak diğer işlemlerinize devam edebilirsiniz</p>
                    <p>Faturalar çekiliyor...</p>
                  `,
                                            showConfirmButton: false,
                                            allowOutsideClick: false,
                                            allowEscapeKey: false,
                                            allowEnterKey: false,
                                            customClass: {
                                                popup: "swal2-content-centered",
                                            },
                                            willOpen: () => {
                                                Swal.showLoading();
                                            },
                                        });
                                        getLucaClientAndDomain()
                                            .then((resp) => {
                                                fetchIncomingEarchivesForCompanies(
                                                    selectedCompaniesDetails,
                                                    resp.data,
                                                    startDateRef.current,
                                                    endDateRef.current
                                                )
                                                    .then(async (resp) => {
                                                        var flag = true;
                                                        while (flag) {
                                                            isRunning()
                                                                // eslint-disable-next-line no-loop-func
                                                                .then(
                                                                    (resp) => {
                                                                        if (
                                                                            resp
                                                                                .data
                                                                                ?.isActive !==
                                                                            true
                                                                        ) {
                                                                            flag = false;
                                                                        }
                                                                        if (
                                                                            resp
                                                                                .data
                                                                                ?.isActive !==
                                                                            true
                                                                        ) {
                                                                            Swal.fire(
                                                                                {
                                                                                    icon: "success",
                                                                                    title: "E-arşiv Faturaların Çekme İşlemi Tamamlandı",
                                                                                    html: `<p>${resp.data.message}</p>`,
                                                                                    confirmButtonText:
                                                                                        "Tamam",
                                                                                }
                                                                            );

                                                                            setUpdateRow(
                                                                                true
                                                                            );
                                                                        }
                                                                    }
                                                                )
                                                                // eslint-disable-next-line no-loop-func
                                                                .catch(
                                                                    (err) => {
                                                                        flag = false;

                                                                        if (
                                                                            err.code ===
                                                                            "ERR_NETWORK"
                                                                        ) {
                                                                            Swal.fire(
                                                                                {
                                                                                    icon: "error",
                                                                                    title: "Rahat Proxy ile Bağlantı Kurulamadı!",
                                                                                    text: "Lütfen Rahat Proxy uygulamasını indirin veya uygulamayı başlatın.",
                                                                                    confirmButtonText:
                                                                                        "Uygulamayı İndir",
                                                                                    showDenyButton: true,
                                                                                    denyButtonText:
                                                                                        "Tamam",
                                                                                    allowOutsideClick: false,
                                                                                    allowEscapeKey: false,
                                                                                    allowEnterKey: false,
                                                                                }
                                                                            ).then(
                                                                                (
                                                                                    result
                                                                                ) => {
                                                                                    if (
                                                                                        result.isConfirmed
                                                                                    ) {
                                                                                        downloadRahatProxy();
                                                                                    }
                                                                                }
                                                                            );
                                                                        } else {
                                                                            console.log(
                                                                                err,
                                                                                "Adas"
                                                                            );
                                                                            Swal.fire(
                                                                                {
                                                                                    icon: "error",
                                                                                    title: "E-arşiv Faturaların Çekme İşlemi Başarısız!",
                                                                                    text: err
                                                                                        .response
                                                                                        .data
                                                                                        .message,
                                                                                    confirmButtonText:
                                                                                        "Tamam",
                                                                                }
                                                                            );
                                                                        }
                                                                    }
                                                                );
                                                            await new Promise(
                                                                (resolve) =>
                                                                    setTimeout(
                                                                        resolve,
                                                                        10000
                                                                    )
                                                            );
                                                        }

                                                        Swal.close();
                                                    })
                                                    .catch((err) => {
                                                        if (
                                                            err.code ===
                                                            "ERR_NETWORK"
                                                        ) {
                                                            Swal.fire({
                                                                icon: "error",
                                                                title: "Rahat Proxy ile Bağlantı Kurulamadı!",
                                                                text: "Lütfen Rahat Proxy uygulamasını indirin veya uygulamayı başlatın.",
                                                                confirmButtonText:
                                                                    "Uygulamayı İndir",
                                                                showDenyButton: true,
                                                                denyButtonText:
                                                                    "Tamam",
                                                                allowOutsideClick: false,
                                                                allowEscapeKey: false,
                                                                allowEnterKey: false,
                                                                focusConfirm: true,
                                                            }).then(
                                                                (result) => {
                                                                    if (
                                                                        result.isConfirmed
                                                                    ) {
                                                                        downloadRahatProxy();
                                                                    }
                                                                }
                                                            );
                                                        } else {
                                                            // Handle other Ajax errors as needed
                                                            Swal.fire({
                                                                icon: "error",
                                                                title: "E-arşiv Faturaların Çekme İşlemi Başarısız!",
                                                                text: err
                                                                    .response
                                                                    .data
                                                                    .message,
                                                            });
                                                        }
                                                    });
                                            })
                                            .catch((err) => {
                                                Swal.fire({
                                                    title: "Mükellef bilgileri alınamadı!",
                                                    text: "Mükellef bilgileri alınırken hata oluştu!",
                                                    icon: "warning",
                                                    confirmButtonText: "Tamam",
                                                });
                                                return;
                                            });
                                    }
                                });
                            }
                        })
                        .catch((err) => {
                            if (err.code === "ERR_NETWORK") {
                                Swal.fire({
                                    icon: "error",
                                    title: "Rahat Proxy ile Bağlantı Kurulamadı!",
                                    text: "Lütfen Rahat Proxy uygulamasını indirin veya uygulamayı başlatın.",
                                    confirmButtonText: "Uygulamayı İndir",
                                    showDenyButton: true,
                                    denyButtonText: "Tamam",
                                    allowOutsideClick: false,
                                    allowEscapeKey: false,
                                    allowEnterKey: false,
                                    focusConfirm: true,
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        downloadRahatProxy();
                                    }
                                });
                            }
                        });
                }
            });

            handleClose();
        }
    }, [open, handleClose, selectedCompaniesDetails]);

    return null;
};
