import axiosInstance from "../../../../utils/maintenanceController/axios";

export const deleteFiches = async (companyId, periodId, ficheIds) => {
  return axiosInstance.delete(
    `/company/${companyId}/${periodId}/delete-fiches`,
    {
      data: { ficheIds },
    }
  );
};
