/** @format */

import React, { useState, useRef, useEffect, useMemo } from "react";
import {
  Modal,
  Box,
  Button,
  Typography,
  Checkbox,
  Grid,
  Autocomplete,
  TextField,
  FormControl,
  Popper,
  Alert,
} from "@mui/material";
import Swal from "sweetalert2";
import { getLucaAccounts } from "../../api/company/invoices/getLucaAccounts/index.js";
import { fetchBankExtractFromXLS } from "../../api/company/bankExtracts/fetchBankExtractFromXlsFile/index.js";
import { checkTempProcess } from "../../api/company/invoices/checkTempProcess/index.js";
import { CreateLucaAccountSwal } from "../../components/createLucaAccountSwal/index.js";
import moment from "moment";

export const XmlFileModalForExtract = ({
  open,
  handleClose,
  companyId,
  periodId,
  accountCodeList,
  setUpdateRow,
  startDate,
  endDate,
}) => {
  const bankList = [
    "Kuveyt Türk",
    "Ziraat",
    "İş Bankası",
    "Garanti",
    "Yapı Kredi",
    "Halkbank",
    "QNB Finansbank",
    "Vakıfbank",
    "Denizbank",
    "Akbank",
    "TEB",
    "ING",
    "Albaraka Türk",
    "En Para",
    "Ziraat Katılım",
    "Vakıf Katılım",
  ];
  const uppercaseAlphabet = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];
  const dateFormats = [
    { value: "DD.MM.YYYY", label: "GG.AA.YYYY" },
    { value: "DD-MM-YYYY", label: "GG-AA-YYYY" },
    { value: "YYYY-MM-DD", label: "YYYY-AA-GG" },
    { value: "YYYY.MM.DD", label: "YYYY.AA.GG" },
    { value: "MM/DD/YYYY", label: "AA/GG/YYYY" },
    { value: "DD/MM/YYYY", label: "GG/AA/YYYY" },
    { value: "YYYY/MM/DD", label: "YYYY/AA/GG" },
    { value: "MM-DD-YYYY", label: "AA-GG-YYYY" },
    { value: "YYYY-DD-MM", label: "YYYY-GG-AA" },
    { value: "MM.DD.YYYY", label: "AA.GG.YYYY" },
    { value: "YYYY/DD/MM", label: "YYYY/GG/AA" },
  ];
  const separatorOptions = [
    { value: "none", label: "Yok" },
    { value: ",", label: ", (virgül)" },
    { value: ".", label: ". (nokta)" },
  ];
  const [chooseCustomTemplate, setChooseCustomTemplate] = useState(false);
  const [selectedBank, setSelectedBank] = useState("");
  const [loading, setLoading] = useState(true);
  const [accountCodes, setAccountCodes] = useState(
    accountCodeList ? accountCodeList : []
  );
  const [isForeignCurrencyAccount, SetIsForeignCurrencyAccount] =
    useState(false);
  const [accountCode, setAccountCode] = useState();
  const [accountBankCode, setAccountBankCode] = useState("");
  const [selectedDateFormat, setSelectedDateFormat] = useState("");
  const [selectedDateCol, setSelectedDateCol] = useState("");
  const [selectedDescCol, setSelectedDescCol] = useState("");
  const [selectedProcessCol, setSelectedProcessCol] = useState("");
  const [selectedAmountCol, setSelectedAmountCol] = useState("");
  const [selectedBalanceCol, setSelectedBalanceCol] = useState("");
  const [selectedThousandSeparate, setSelectedThousandSeparate] = useState("");
  const [selectedFractionSeparate, setSelectedFractionSeparate] = useState("");
  const [files, setFiles] = useState([]);
  const [fileBase64, setFileBase64] = useState("");
  const fileInputRef = useRef(null);

  const isOptionDisabled = (option, currentValue, otherValue) => {
    if (option.value === "none") return false;
    if (!otherValue) return false;
    return option.value === otherValue.value;
  };
  let extractData = {
    amountColumn: selectedAmountCol,
    balanceColumn: selectedBalanceCol,
    bankAccountCode: accountBankCode,
    bankName: selectedBank,
    base64Data: fileBase64,
    dateColumn: selectedDateCol,
    dateFormat: selectedDateFormat?.value,
    decimalSeparator: selectedFractionSeparate?.value,
    descriptionColumn: selectedDescCol,
    numberColumn: selectedProcessCol,
    thousandSeparator: selectedThousandSeparate?.value,
    startDate,
    endDate,
    isSpecialTemplate:chooseCustomTemplate,
    isForeignCurrencyAccount,
  };

  useEffect(() => {
    if (!accountCodeList) {
      getLucaAccounts(companyId, periodId)
        .then((resp) => {
          const updateData = resp?.data.list.filter(
            (item, index, self) =>
              index === self.findIndex((t) => t.id === item.id)
          );
          setAccountCodes(updateData);
          setLoading(false);
          if (resp.data.message !== "") {
            Swal.fire({
              icon: "warning",
              title: "Uyarı",
              html: resp.data.message,
              confirmButtonText: "Tamam",
            })
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    }
    setAccountCodes(accountCodeList);
  }, [accountCodeList]);

  useEffect(() => {
    if (
      selectedBank !== "" &&
      !["Kuveyt Türk", "Ziraat", "İş Bankası", "Garanti", "Yapı Kredi"].some(
        (banka) => selectedBank.includes(banka)
      )
    ) {
      setChooseCustomTemplate(true);
    }
  }, [selectedBank]);

  const handleChooseCustomTemplateChange = (event) => {
    setChooseCustomTemplate(event.target.checked);
  };

  useEffect(() => {
    setFiles([]);
    setFileBase64("");
  }, [handleClose]);

  const isXlsFile = (file) =>
    file.name.endsWith(".xls") || file.name.endsWith(".xlsx");

  const handleFileSelect = (event) => {
    const file = event.target.files[0]; // Tek bir dosya seçimi
    if (file) {
      if (isXlsFile(file)) {
        // Dosyayı Base64'e dönüştür
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => {
          const base64String = reader.result.split(",")[1]; // Dosyanın Base64 hali
          setFileBase64(base64String);
          setFiles([file]); // Dosyayı dizinin içine alıyoruz
        };

        reader.onerror = (error) => {
          console.error("Dosya okuma hatası: ", error);
        };
      } else {
        alert("Yalnızca .xls veya .xlsx uzantılı dosyalar seçebilirsiniz.");
      }
    } else {
      handleCancel(event);
      return;
    }
  };

  const handleUploadClick = () => {
    if (!selectedBank || selectedBank === "") {
      Swal.fire({
        title: "Uyarı!",
        text: "Lütfen bir banka seçin.",
        icon: "warning",
        confirmButtonText: "Tamam",
        didOpen: () => {
          document.querySelector(".swal2-container").style.zIndex = "1500";
        },
      });
      return false;
    }
    if (!accountBankCode || accountBankCode === "") {
      Swal.fire({
        title: "Uyarı!",
        text: "Lütfen bankanın hesap kodunu seçin.",
        icon: "warning",
        confirmButtonText: "Tamam",
        didOpen: () => {
          document.querySelector(".swal2-container").style.zIndex = "1500";
        },
      });
      return false;
    }

    if (chooseCustomTemplate === true) {
      if (!selectedDateCol || selectedDateCol === "") {
        Swal.fire({
          title: "Uyarı!",
          text: "Lütfen bir tarih sütunu seçin.",
          icon: "warning",
          didOpen: () => {
            document.querySelector(".swal2-container").style.zIndex = "1500";
          },
        });
        return false;
      }
      if (!selectedDateFormat || selectedDateFormat === "") {
        Swal.fire({
          title: "Uyarı!",
          text: "Lütfen bir tarih formatı seçin.",
          icon: "warning",
          didOpen: () => {
            document.querySelector(".swal2-container").style.zIndex = "1500";
          },
        });
        return false;
      }

      if (!selectedAmountCol || selectedAmountCol === "") {
        Swal.fire({
          title: "Uyarı!",
          text: "Lütfen bir tutar sütunu seçin.",
          icon: "warning",
          didOpen: () => {
            document.querySelector(".swal2-container").style.zIndex = "1500";
          },
        });
        return false;
      }

      if (!selectedDescCol || selectedDescCol === "") {
        Swal.fire({
          title: "Uyarı!",
          text: "Lütfen bir açıklama sütunu seçin.",
          icon: "warning",
          didOpen: () => {
            document.querySelector(".swal2-container").style.zIndex = "1500";
          },
        });
        return false;
      }

      if (!selectedBalanceCol || selectedBalanceCol === "") {
        Swal.fire({
          title: "Uyarı!",
          text: "Lütfen bir bakiye sütunu seçin.",
          icon: "warning",
          didOpen: () => {
            document.querySelector(".swal2-container").style.zIndex = "1500";
          },
        });
        return false;
      }

      if (!selectedProcessCol || selectedProcessCol === "") {
        Swal.fire({
          title: "Uyarı!",
          text: "Lütfen bir işlem numarası sütunu seçin.",
          icon: "warning",
          didOpen: () => {
            document.querySelector(".swal2-container").style.zIndex = "1500";
          },
        });
        return false;
      }
      var selectedColumns = [
        selectedDateCol,
        selectedDateFormat,
        selectedAmountCol,
        selectedDescCol,
        selectedBalanceCol,
        selectedProcessCol,
      ];
      for (var i = 0; i < selectedColumns.length; i++) {
        for (var j = i + 1; j < selectedColumns.length; j++) {
          if (
            selectedColumns[i] &&
            selectedColumns[j] &&
            selectedColumns[i] === selectedColumns[j]
          ) {
            Swal.fire({
              title: "Uyarı!",
              text: "Aynı sütunlar birden fazla kez seçilemez.",
              icon: "warning",
              didOpen: () => {
                document.querySelector(".swal2-container").style.zIndex =
                  "1500";
              },
            });
            return false;
          }
        }
      }
    }

    Swal.fire({
      html: `${moment(startDate).format("DD.MM.YYYY")}-${moment(endDate).format(
        "DD.MM.YYYY"
      )} tarih aralığındaki banka ekstreleriniz excel dosyasından yüklenecek. Devam etmek istediğinize emin misiniz?`,
      icon: "warning",
      iconColor: "#FFA500",
      showCancelButton: true,
      confirmButtonText: "Evet, Devam Et",
      cancelButtonText: "Hayır",
      didOpen: () => {
        document.querySelector(".swal2-container").style.zIndex = "1500";
      },
    }).then((result) => {
      if (result.isConfirmed) {
        handleClose();

        Swal.fire({
          title: "Ekstreler Yükleniyor..",
          html: `
          <p style="color: red;">İşlemin yarıda kalmaması için lütfen sayfayı kapatmayınız ve yenilemeyiniz</p>
          <div class="swal2-progress-container">
            <div class="swal2-progress"></div>
          </div>
          <p id="progress-text">Dosya yükleniyor...</p>
            `,
          icon: "info",
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          customClass: {
            popup: "swal2-content-centered",
          },
          willOpen: () => {
            Swal.showLoading();
          },
        });
        fetchBankExtractFromXLS(companyId, periodId, extractData)
          .then(async (resp) => {
            let flag = true;
            while (flag) {
              try {
                const checkResp = await checkTempProcess(
                  companyId,
                  periodId,
                  resp.data.id
                );
                flag = checkResp.data?.isActive;
                if (checkResp.data?.isActive === false) {
                  if (checkResp.data.result?.errorMessage) {
                    Swal.close();
                    Swal.fire({
                      icon: "error",
                      title: "Uyarı!",
                      text: checkResp.data?.result?.errorMessage,
                      showCancelButton: true,
                      cancelButtonText: "Kapat",
                      confirmButtonText: "Tamam",
                    });
                  } else {
                    Swal.close();
                    Swal.fire({
                      title: "Yükleme Sonuçları",
                      html: `
            <div class="result-container">
              ${
                checkResp.data.result.succesfullBankExtracts > 0
                  ? `<div class="result-item">
                      <span class="result-icon success"><i class="ti ti-checks ti-xs"></i></span>
                      <span class="label">Başarılı işlem adedi</span>
                      <span style="font-weight: bold; color: #36cb85;" >${checkResp.data.result.succesfullBankExtracts}</span>
                    </div>`
                  : ""
              }
              ${
                checkResp.data.result.existingRecords > 0
                  ? `<div class="result-item">
                      <span class="result-icon success"><i class="ti ti-archive ti-xs"></i></span>
                      <span class="label">Mevcut kayıt adedi</span>
                      <span style="font-weight: bold; color: #36cb85;" >${checkResp.data.result.existingRecords}</span>
                    </div>`
                  : ""
              }
              ${
                checkResp.data.result.failedBankExtracts > 0
                  ? `<div class="result-item">
                      <span class="result-icon failed"><i class="ti ti-x ti-xs"></i></span>
                      <span class="label">Başarısız işlem adedi</span>
                      <span style="font-weight: bold; color: #ea5455;" >${checkResp.data.result.failedBankExtracts}</span>
                    </div>`
                  : ""
              }
              <div class="result-item">
                <span class="result-icon total"><i class="ti ti-list-details ti-xs"></i></span>
                <span class="label">Toplam işlem adedi</span>
                <span style="font-weight: bold; color: #00cfe8;" >${
                  checkResp.data.result.totalFoundExtracts
                }</span>
              </div>
            </div>
`,
                      icon: "success",
                      confirmButtonText: "Tamam",
                    });
                  }
                  setUpdateRow(true);
                }
              } catch (err) {
                Swal.fire({
                  title: "Bir hata oluştu!",
                  text: err.response.data.message,
                  icon: "error",
                  confirmButtonText: "Tamam",
                });
              }
              await new Promise((resolve) => setTimeout(resolve, 10000));
            }
          })
          .catch((err) => {
            Swal.fire({
              title: "Bir hata oluştu!",
              text: err.response.data.message,
              icon: "error",
              confirmButtonText: "Tamam",
            });
          });
      }
    });
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const items = event.dataTransfer.items;
    const files = [];
    for (const item of items) {
      const entry = item.webkitGetAsEntry();
      // if (entry.isDirectory) {
      //   const reader = entry.createReader();
      //   reader.readEntries((entries) => {
      //     entries.forEach((entry) => {
      //       if (entry.isFile) {
      //         entry.file((file) => {
      //           if (isXlsFile(file)) {
      //             files.push(file);
      //           }
      //         });
      //       }
      //     });
      //     if (files.length > 0) {
      //       setFiles(files);
      //     } else {
      //       alert("Klasörde XML dosyası bulunamadı.");
      //     }
      //   });
      // } else if (isXlsFile(entry)) {
      //   files.push(entry);
      // }
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleCancel = (event) => {
    event.stopPropagation();
    setFiles([]);
    setFileBase64("");
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  return (
    <Modal open={open} onClose={handleClose} maxWidth="lg" disableEnforceFocus>
      <Box
        sx={{
          position: "absolute",
          top: "5%",
          left: "50%",
          transform: "translate(-50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          width: "30%",
          border: "2px solid transparent",
          overflowY: "auto",
          overflowX: "hidden",
        }}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
      >
        <Button
          onClick={handleClose}
          sx={{
            borderRadius: 2,
            position: "absolute",
            top: 0,
            right: 0,
            backgroundColor: "whitesmoke",
            color: "gray",
            boxShadow: 4,
            minWidth: "0px",
            width: "30px",
            height: "30px",
            border: "2px solid transparent",
            "&:hover": {
              backgroundColor: "#e7eaeb",
            },
          }}
        >
          X
        </Button>
        <Typography variant="h6" component="h2" align="center" gutterBottom>
          Excel Dosyasını Seçiniz
        </Typography>
        <Grid>
          <div
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            className="drop-container"
            onClick={handleButtonClick}
          >
            {files.length > 0 ? (
              <span
                className="drop-title"
                sx={{ display: "flex", alignItems: "center" }}
              >
                Yüklenen EXCEL Dosyası:
                <Typography sx={{ ml: 1 }}>
                  {files.length > 0 ? files[0].name : "Hiçbir dosya seçilmedi"}
                </Typography>
              </span>
            ) : (
              <span
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyItems: "center",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <span className="drop-title">Dosyayı buraya sürükleyin</span>
                <Typography>veya</Typography>
                <span className="drop-title">Tıklayarak Seçin</span>
              </span>
            )}
          </div>
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileSelect}
            style={{ display: "none" }}
            accept=".xls,.xlsx"
          />
        </Grid>
        <Grid container>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
              mt: 2,
            }}
          >
            <Autocomplete
              options={bankList}
              value={selectedBank}
              onChange={(event, newValue) => {
                setSelectedBank(newValue || "");
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  // Eğer liste açıksa ve bir seçenek vurgulanmışsa, onu seç
                  const highlightedOption = event.target.querySelector(
                    'li[data-focus="true"]'
                  );
                  if (highlightedOption) {
                    highlightedOption.click();
                  }
                }
              }}
              autoHighlight
              selectOnFocus
              blurOnSelect
              openOnFocus
              disablePortal
              ListboxProps={{
                style: {
                  maxHeight: chooseCustomTemplate ? "250px" : "150px",
                },
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Banka Seçiniz"
                  variant="outlined"
                  size="small"
                />
              )}
              style={{ width: "100%" }}
            />
          </Grid>
          {(selectedBank === "" ||
            [
              "Kuveyt Türk",
              "Ziraat",
              "İş Bankası",
              "Garanti",
              "Yapı Kredi",
            ].some((banka) => selectedBank.includes(banka))) && (
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                textAlign: "center",
                mt: 1,
                gap: 1,
              }}
            >
              <Checkbox
                checked={chooseCustomTemplate}
                onChange={handleChooseCustomTemplateChange}
                sx={{
                  padding: "0",

                  "&.Mui-checked": {
                    color: "#041737",
                  },
                  "&.Mui-checked:hover": {
                    color: "#041737",
                  },
                  "&:hover": {
                    color: "#041737",
                  },
                }}
              />

              <Typography color={"#2a292b"}>Özel Excel Şablonu Seç</Typography>
            </Grid>
          )}

          {chooseCustomTemplate && (
            <>
              <Grid
                container
                xs={12}
                md={12}
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  mt: 4,
                  mb: 0,
                  gap: 0,
                }}
              >
                <Grid xs={5.7} sx={{ mb: 3 }}>
                  <FormControl fullWidth>
                    <Autocomplete
                      options={uppercaseAlphabet}
                      value={selectedDateCol}
                      onChange={(event, newValue) => {
                        setSelectedDateCol(newValue);
                      }}
                      onKeyDown={(event) => {
                        if (event.key === "Enter") {
                          // Eğer liste açıksa ve bir seçenek vurgulanmışsa, onu seç
                          const highlightedOption = event.target.querySelector(
                            'li[data-focus="true"]'
                          );
                          if (highlightedOption) {
                            highlightedOption.click();
                          }
                        }
                      }}
                      autoHighlight
                      selectOnFocus
                      blurOnSelect
                      openOnFocus
                      disablePortal
                      ListboxProps={{
                        style: { maxHeight: "250px" },
                      }}
                      noOptionsText={
                        loading ? "Yükleniyor..." : "Seçenek bulunamadı"
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Tarih Sütununu Seçiniz"
                          variant="outlined"
                          size="small"
                        />
                      )}
                      style={{ width: "100%" }}
                    />
                  </FormControl>
                </Grid>
                <Grid xs={5.7} sx={{ mb: 3 }}>
                  <FormControl fullWidth>
                    <Autocomplete
                      options={dateFormats}
                      value={selectedDateFormat}
                      onChange={(event, newValue) => {
                        setSelectedDateFormat(newValue);
                      }}
                      onKeyDown={(event) => {
                        if (event.key === "Enter") {
                          // Eğer liste açıksa ve bir seçenek vurgulanmışsa, onu seç
                          const highlightedOption = event.target.querySelector(
                            'li[data-focus="true"]'
                          );
                          if (highlightedOption) {
                            highlightedOption.click();
                          }
                        }
                      }}
                      autoHighlight
                      selectOnFocus
                      blurOnSelect
                      openOnFocus
                      disablePortal
                      ListboxProps={{
                        style: { maxHeight: "250px" },
                      }}
                      noOptionsText={
                        loading ? "Yükleniyor..." : "Seçenek bulunamadı"
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Tarih Formatını Seçiniz"
                          variant="outlined"
                          size="small"
                        />
                      )}
                      style={{ width: "100%" }}
                    />
                  </FormControl>
                </Grid>
                <Grid xs={5.7} sx={{ mb: 3 }}>
                  <FormControl fullWidth>
                    <Autocomplete
                      options={uppercaseAlphabet}
                      value={selectedDescCol}
                      onChange={(event, newValue) => {
                        setSelectedDescCol(newValue);
                      }}
                      onKeyDown={(event) => {
                        if (event.key === "Enter") {
                          // Eğer liste açıksa ve bir seçenek vurgulanmışsa, onu seç
                          const highlightedOption = event.target.querySelector(
                            'li[data-focus="true"]'
                          );
                          if (highlightedOption) {
                            highlightedOption.click();
                          }
                        }
                      }}
                      autoHighlight
                      selectOnFocus
                      blurOnSelect
                      openOnFocus
                      disablePortal
                      ListboxProps={{
                        style: { maxHeight: "250px" },
                      }}
                      noOptionsText={
                        loading ? "Yükleniyor..." : "Seçenek bulunamadı"
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Açıklama Sütununu Seçiniz"
                          variant="outlined"
                          size="small"
                        />
                      )}
                      style={{ width: "100%" }}
                    />
                  </FormControl>
                </Grid>
                <Grid xs={5.7} sx={{ mb: 3 }}>
                  <FormControl fullWidth>
                    <Autocomplete
                      options={uppercaseAlphabet}
                      value={selectedProcessCol}
                      onChange={(event, newValue) => {
                        setSelectedProcessCol(newValue);
                      }}
                      onKeyDown={(event) => {
                        if (event.key === "Enter") {
                          // Eğer liste açıksa ve bir seçenek vurgulanmışsa, onu seç
                          const highlightedOption = event.target.querySelector(
                            'li[data-focus="true"]'
                          );
                          if (highlightedOption) {
                            highlightedOption.click();
                          }
                        }
                      }}
                      autoHighlight
                      selectOnFocus
                      blurOnSelect
                      openOnFocus
                      disablePortal
                      ListboxProps={{
                        style: { maxHeight: "250px" },
                      }}
                      noOptionsText={
                        loading ? "Yükleniyor..." : "Seçenek bulunamadı"
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="İşlem Numarası Sütununu Seçiniz"
                          variant="outlined"
                          size="small"
                        />
                      )}
                      style={{ width: "100%" }}
                    />
                  </FormControl>
                </Grid>
                <Grid xs={5.7} sx={{ mb: 3 }}>
                  <FormControl fullWidth>
                    <Autocomplete
                      options={uppercaseAlphabet}
                      value={selectedAmountCol}
                      onChange={(event, newValue) => {
                        setSelectedAmountCol(newValue);
                      }}
                      onKeyDown={(event) => {
                        if (event.key === "Enter") {
                          // Eğer liste açıksa ve bir seçenek vurgulanmışsa, onu seç
                          const highlightedOption = event.target.querySelector(
                            'li[data-focus="true"]'
                          );
                          if (highlightedOption) {
                            highlightedOption.click();
                          }
                        }
                      }}
                      autoHighlight
                      selectOnFocus
                      blurOnSelect
                      openOnFocus
                      disablePortal
                      ListboxProps={{
                        style: { maxHeight: "250px" },
                      }}
                      noOptionsText={
                        loading ? "Yükleniyor..." : "Seçenek bulunamadı"
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Tutar Sütununu Seçiniz"
                          variant="outlined"
                          size="small"
                        />
                      )}
                      style={{ width: "100%" }}
                    />
                  </FormControl>
                </Grid>
                <Grid xs={5.7} sx={{ mb: 0 }}>
                  <FormControl fullWidth>
                    <Autocomplete
                      options={uppercaseAlphabet}
                      value={selectedBalanceCol}
                      onChange={(event, newValue) => {
                        setSelectedBalanceCol(newValue);
                      }}
                      onKeyDown={(event) => {
                        if (event.key === "Enter") {
                          // Eğer liste açıksa ve bir seçenek vurgulanmışsa, onu seç
                          const highlightedOption = event.target.querySelector(
                            'li[data-focus="true"]'
                          );
                          if (highlightedOption) {
                            highlightedOption.click();
                          }
                        }
                      }}
                      autoHighlight
                      selectOnFocus
                      blurOnSelect
                      openOnFocus
                      disablePortal
                      ListboxProps={{
                        style: { maxHeight: "250px" },
                      }}
                      noOptionsText={
                        loading ? "Yükleniyor..." : "Seçenek bulunamadı"
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Bakiye Sütununu Seçiniz"
                          variant="outlined"
                          size="small"
                        />
                      )}
                      style={{ width: "100%" }}
                    />
                  </FormControl>
                </Grid>
                <Grid xs={5.7} sx={{ mb: 3, justifyContent: "center" }}>
                  <FormControl fullWidth>
                    <Autocomplete
                      options={separatorOptions}
                      value={selectedThousandSeparate}
                      onChange={(event, newValue) => {
                        setSelectedThousandSeparate(newValue);
                      }}
                      getOptionDisabled={(option) =>
                        isOptionDisabled(
                          option,
                          selectedThousandSeparate,
                          selectedFractionSeparate
                        )
                      }
                      onKeyDown={(event) => {
                        if (event.key === "Enter") {
                          // Eğer liste açıksa ve bir seçenek vurgulanmışsa, onu seç
                          const highlightedOption = event.target.querySelector(
                            'li[data-focus="true"]'
                          );
                          if (highlightedOption) {
                            highlightedOption.click();
                          }
                        }
                      }}
                      autoHighlight
                      selectOnFocus
                      blurOnSelect
                      openOnFocus
                      disablePortal
                      ListboxProps={{
                        style: { maxHeight: "250px" },
                      }}
                      noOptionsText={
                        loading ? "Yükleniyor..." : "Seçenek bulunamadı"
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Binlik Ayıracını Seçiniz"
                          variant="outlined"
                          size="small"
                        />
                      )}
                      style={{ width: "100%" }}
                    />
                    <Typography
                      variant="overline"
                      display="block"
                      sx={{
                        textTransform: "none",
                        margin: 0,
                      }}
                    >
                      (opsiyonel)
                    </Typography>
                  </FormControl>
                </Grid>
                <Grid xs={5.7} sx={{ mb: 0 }}>
                  <FormControl fullWidth>
                    <Autocomplete
                      options={separatorOptions}
                      value={selectedFractionSeparate}
                      onChange={(event, newValue) => {
                        setSelectedFractionSeparate(newValue);
                      }}
                      getOptionDisabled={(option) =>
                        isOptionDisabled(
                          option,
                          selectedFractionSeparate,
                          selectedThousandSeparate
                        )
                      }
                      onKeyDown={(event) => {
                        if (event.key === "Enter") {
                          // Eğer liste açıksa ve bir seçenek vurgulanmışsa, onu seç
                          const highlightedOption = event.target.querySelector(
                            'li[data-focus="true"]'
                          );
                          if (highlightedOption) {
                            highlightedOption.click();
                          }
                        }
                      }}
                      autoHighlight
                      selectOnFocus
                      blurOnSelect
                      openOnFocus
                      disablePortal
                      ListboxProps={{
                        style: { maxHeight: "250px" },
                      }}
                      noOptionsText={
                        loading ? "Yükleniyor..." : "Seçenek bulunamadı"
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Küsurat Ayıracını Seçiniz"
                          variant="outlined"
                          size="small"
                        />
                      )}
                      style={{ width: "100%" }}
                    />
                    <Typography
                      variant="overline"
                      display="block"
                      sx={{
                        textTransform: "none",
                        margin: 0,
                      }}
                    >
                      (opsiyonel)
                    </Typography>
                  </FormControl>
                </Grid>
              </Grid>
            </>
          )}
          <Grid
            container
            xs={12}
            md={12}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              mt: 0,
              mb: 2,
            }}
          >
            <Alert
              severity="warning"
              sx={{
                width: "100%",
                borderRadius: "8px",
                border: "1px solid #ef7918",
                transition: "opacity 0.5s ease-in-out",
                mb: 1,
              }}
            >
              Banka ekstesi yükledikten sonra 
              <span>
                <b style={{ letterSpacing: "0.05em" }}>
                  toplam adet,tutar,tarih ve yön
                </b>
              </span>
              <span> kontrolü yapınız.</span>
            </Alert>
            <FormControl fullWidth>
              <Autocomplete
                options={accountCodes}
                value={accountCode}
                onChange={async (event, newValue) => {
                  if (newValue?.hesap_kodu === "add-new") {
                    let newAccount = await CreateLucaAccountSwal(
                      companyId,
                      periodId
                    );
                    setAccountCodes([
                      ...accountCodes,
                      {
                        id: Math.floor(
                          100000 + Math.random() * 900000
                        ).toString(),
                        hesap_adi: newAccount?.input1,
                        hesap_kodu: newAccount?.input2,
                      },
                    ]);
                    setAccountBankCode(
                        newAccount?.input2 || null
                      );
                    return;
                  }
                  setAccountBankCode(
                    newValue?.hesap_kodu || null
                  );
                }}
                noOptionsText={loading ? "Yükleniyor..." : "Seçenek bulunamadı"}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    // Eğer liste açıksa ve bir seçenek vurgulanmışsa, onu seç
                    const highlightedOption = event.target.querySelector(
                      'li[data-focus="true"]'
                    );
                    if (highlightedOption) {
                      highlightedOption.click();
                    }
                  }
                }}
                filterOptions={(options, params) => {
                  const filtered = options.filter((option) => {
                    let label = `${option.hesap_kodu} ${option.hesap_adi}`;
                    return label
                      .toLocaleLowerCase("tr")
                      .includes(params?.inputValue?.toLocaleLowerCase("tr"));
                  });
                  // Girilen metin, mevcut seçenekler arasında yoksa özel bir seçenek ekle
                  if (filtered?.length === 0) {
                    filtered.push({
                      hesap_adi: "Yeni Hesap Kodu Eklemek İçin Tıklayın",
                      hesap_kodu: "add-new", // Özel seçenek için belirleyici bir value
                    });
                  }

                  return filtered;
                }}
                autoHighlight
                selectOnFocus
                blurOnSelect
                openOnFocus
                disablePortal
                ListboxProps={{ style: { maxHeight: "250px" } }}
                getOptionLabel={(option) => {
                  if (option?.hesap_kodu === "add-new") {
                    return `${option.adi || ""}`;
                  }
                  return option
                    ? `${option.hesap_kodu || ""} - ${
                        option.hesap_adi || ""
                      } - Bakiye: ${option.bakiye || "0"}`
                    : "";
                }}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    {...props}
                    key={option?.hesap_kodu || ""}
                    style={{
                      paddingLeft: "7px",
                      maxWidth: "100%",
                    }}
                  >
                    <Typography>
                      {option.hesap_kodu === "add-new" ? (
                        <>{option.hesap_adi}</>
                      ) : (
                        <>
                          <strong>{option.hesap_kodu}</strong> -{" "}
                          {option.hesap_adi} - Bakiye: {option.bakiye||"0"}
                        </>
                      )}
                    </Typography>
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Seçilen Bankanın Hesap Kodu"
                    variant="outlined"
                    size="small"
                  />
                )}
                style={{ width: "100%" }}
              />
            </FormControl>
            <FormControl fullWidth sx={{ mt: 2 }}>
              <Autocomplete
                disablePortal
                value={
                  isForeignCurrencyAccount
                    ? { value: true, label: "Evet" }
                    : { value: false, label: "Hayır" }
                }
                options={[
                  {
                    value: false,
                    label: "Hayır",
                  },
                  {
                    value: true,
                    label: "Evet",
                  },
                ]}
                autoHighlight
                selectOnFocus
                disableClearable
                onChange={(event, newValue) => {
                  SetIsForeignCurrencyAccount(newValue.value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Döviz Hesabı Mı?"
                    variant="outlined"
                    size="small"
                  />
                )}
                style={{ width: "100%" }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Button
              variant="contained"
              className={files.length === 0 ? "" : "custom-button"}
              onClick={() => {
                handleUploadClick();
              }}
              sx={{ mr: 2, width: "auto", textTransform: "none" }}
              disabled={files.length === 0}
            >
              Yükle
            </Button>
            <Button variant="outlined" onClick={handleClose}>
              İptal
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};
