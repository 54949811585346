/* eslint-disable react-hooks/exhaustive-deps */
//todo MODAL AÇILMASI GEREKTİREN İŞLEMLER SONU MODAL İLE BİTEN DOSYALARDA(fatura seçilerek yapılan işlemlerde),
//todo DİREKT İSTEKLE HALLEDİLEBİLİR İŞLEMLER 'ProcessesAndProcessMenu' DOSYASINDADIR.

import React, {
  useState,
  useMemo,
  useEffect,
  useRef,
  useCallback,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import Swal from "sweetalert2";
import ProcessMenu from "./processesAndProcessMenu.js";
// Grids---------
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import gridSideBar from "../../../components/GridSideBar/gridSideBar.js";

import { createColumnDefs } from "./gridOptionsAndcolumnDefs.js";
import { detailsGridOptions } from "./gridOptionsAndcolumnDefs.js";
import localeTextTR from "../../../locale.tr.js";

//Components

import AdminSidebar from "../../../components/adminSideBar/adminSideBar.js";
import AdminNavbar from "../../../components/adminNavbar/index.js";
import localStorage from "local-storage";

import { HiOutlineIdentification } from "react-icons/hi";
import {
  Grid,
  Alert,
  Typography,
  Button,
  Box,
} from "@mui/material";
//Icons

//Apis
import { getListInvoices } from "../../../api/company/invoices/getListInvoices/index.js";
import { getAdvisors } from "../../../api/adminApis/getAdvisors/index.js";
import { getCompaniesFromAdvisor } from "../../../api/adminApis/getCompaniesFromAdvisor/index.js";
import { CopyRuleToCompanySwal } from "./copyRuleToCompanySwal.js";
import { SelectedAdvisorsStatusUpdateSwal } from "./selectedAdvisorsStatusUpdateSwal.js";
import { SelectedAdvisorsAutoFetchInvoicesUpdateSwal } from "./selectedAdvisorsAutoFetchInvoicesUpdateSwal.js";
import { CheckActiveUsingAndJobs } from "./checkActiveUsingAndJobsSwal.js";
import { CreateMaintenanceSwal } from "./createMaintenanceSwal.js";
import { FinishMaintenanceSwal } from "./finishMaintenanceSwal.js";
import { ViewAdvisor } from "./viewAdvisorModal.js";
import { createTurkishFilterParams } from "../../../utils/agGridTextFilterTr/agGridFilterUtils.js";

//Apis

function AdminAdvisors() {
  const [isOpen, setIsOpen] = useState(false);

  //modals açılma durumlar
  const [modalId, setModalId] = useState("1");
  const [modalOpen, setModalOpen] = useState(false);
  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);
  //--------
  //grid fatura görüntüleme modalı için açılır kapanır state'si
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  //grid fatura görüntüleme modalı için açılır kapanır state'si

  const [companyDetails, setCompanyDetails] = useState();

  const [loading, setLoading] = useState(true);
  const [firstLoading, setFirstLoading] = useState(true); //SAYFA İLK AÇTIĞINDA getListInvoices ve companies'e İSTEK ATMAMASI İÇİN FLAG

  //Modallarda Yapılan işlemlerde sonra tablonun güncellemesi için
  const [updateRow, setUpdateRow] = useState(false);
  useEffect(() => {
    if (firstLoading === false) {
      const selectedRows = gridRef.current.api.getSelectedRows();
      if (modalId === "#copyRuleToCompanyControl") {
        const selectedDetailRows = [];
        selectedRows.forEach((item) => {
          const detailApi = gridRef.current.api.getDetailGridInfo(
            `detail_${item.id}`
          );
          const companies = detailApi?.api.getSelectedRows();
          companies?.forEach((company) => {
            selectedDetailRows.push(company.id);
          });
        });
        setSelectedGridRows(selectedDetailRows);
        setModalId("#copyRuleToCompany");
        handleModalOpen();
      } else if (modalId === "#selectedAdvisorsMakeActiveControl") {
        const selectedData = selectedRows.map((detail) => detail.id);
        setSelectedGridRows(selectedData);
        setModalId("#selectedAdvisorsMakeActive");
        handleModalOpen();
      }else if (modalId === "#selectedAdvisorsMakeDeactiveControl") {
        const selectedData = selectedRows.map((detail) => detail.id);
        setSelectedGridRows(selectedData);
        setModalId("#selectedAdvisorsMakeDeactive");
        handleModalOpen();
      }else if (modalId === "#selectedAdvisorsAutoFetchInvoicesOpenControl") {
        const selectedData = selectedRows.map((detail) => detail.id);
        setSelectedGridRows(selectedData);
        setModalId("#selectedAdvisorsAutoFetchInvoicesOpen");
        handleModalOpen();
      }else if (modalId === "#selectedAdvisorsAutoFetchInvoicesCloseControl") {
        const selectedData = selectedRows.map((detail) => detail.id);
        setSelectedGridRows(selectedData);
        setModalId("#selectedAdvisorsAutoFetchInvoicesClose");
        handleModalOpen();
      }
    }
  }, [modalId]);

  useEffect(() => {
    if (updateRow === true) {
      setLoading(true);
      setUpdateRow(false)
      search()
    }
  }, [updateRow]);
  //Modallarda Yapılan işlemlerde sonra tablonun güncellemesi için

  const location = useLocation();

  const [rowData, setRowData] = useState([]);
  const gridRef = useRef(null);
  const [selectedGridRows, setSelectedGridRows] = useState([]);
  const [advisorId, setAdvisorId] = useState([]);
  const [filteredCount, setFilteredCount] = useState(0);

  useEffect(() => {
    const sideBarOpen = localStorage.get("sidebar");
    if (sideBarOpen === "false") {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
    search();
    setFirstLoading(false)
  }, []);

  const search = () => {
    getAdvisors()
      .then((resp) => {
        setRowData(resp.data.advisors);
        setFilteredCount(resp.data.advisors.length);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {

    if (loading === true && firstLoading === false) {
     search()
     setFirstLoading(true);
    }
  }, [location.pathname]);

  const onFilterChanged = useCallback((event) => {
    const filteredRows = event.api.getModel().getRowCount(); // Filtrelenmiş veri sayısını alıyoruz
    setFilteredCount(filteredRows);
  }, []);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const defaultColDef = useMemo(
    () => ({
      filter: true,
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filterParams:createTurkishFilterParams(),
    }),
    []
  );

  const ButtonRenderer = (props) => {
    const handleClick = () => {
      setAdvisorId(props.data.id);
      setTimeout(() => {
        setModalId("#advisorDetail");
        handleModalOpen();
      }, 0);
    };

    return (
      <Button
        onClick={handleClick}
        sx={{
          padding: 0,
          minWidth: "auto",
          width: "auto",
          height: "auto",
          display: "flex",
        }}
      >
        <HiOutlineIdentification size={21}></HiOutlineIdentification>
      </Button>
    );
  };

  const columnDefs = useMemo(
    () => createColumnDefs(ButtonRenderer, companyDetails?.class),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [companyDetails?.class]
  );

  const detailCellRendererParams = useMemo(() => {
    return {
      detailGridOptions: detailsGridOptions,
      getDetailRowData: async (params) => {
        let resp = await getCompaniesFromAdvisor(params.data.id);
        params.successCallback(resp.data.companies);
      },
    };
  }, []);

  const getMainMenuItems = (params) => {
    const allMenuItems = params.defaultItems;
    const itemsToRemove = ["toolPanelButton"]; // 'choose columns' seçeneği

    return allMenuItems.filter((item) => !itemsToRemove.includes(item));
  };

  return (
    <Grid container>
      <Grid
        item
        sx={{
          width: isOpen ? "280px" : "100px",
          flexShrink: 0,
          transition: "width 0.3s ease",
        }}
        zIndex={1}
      >
        <AdminSidebar
          status={isOpen}
          toggleSidebar={toggleSidebar}
          location={"homePage"}
        />
      </Grid>
      <Grid
        item
        zIndex={0}
        sx={{
          flexGrow: 1,
          display: "flex",
          justifyContent: "flex-end",
          flexDirection: "column",
          gap: 1,
          pr: "12px",
        }}
      >
        <Grid item xs={12}>
          <AdminNavbar
            setLoading={setLoading}
            setFirstLoading={setFirstLoading}
            firstLoading={firstLoading}
            page={"Müşavirler"}
          />{" "}
        </Grid>
        <Grid item md={12} sx={{ display: "flex", flexDirection: "column" }}>
          <Grid
            className="grid-area"
            sx={{
              padding: 1,
              mb: 1,
              alignItems: "center",
            }}
          >
            <Grid
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Alert
                severity="info"
                sx={{
                  borderRadius: "8px",
                  border: "1px solid #041737",
                }}
              >
                İşlem yapmak için seçtiğiniz firmaların{" "}
                <b style={{ letterSpacing: "0.05em" }}>
                  müşavirleri de seçilmiş
                </b>{" "}
                olmalıdır.
              </Alert>

              <ProcessMenu
                setModalId={setModalId}
                handleModalOpen={handleModalOpen}
                setUpdateRow={setUpdateRow}
              ></ProcessMenu>
              
              {modalId === "#advisorDetail" ? (
                  <ViewAdvisor
                    open={modalOpen}
                    handleClose={handleModalClose}
                    advisorId={advisorId}
                  />
                ) :modalId === "#selectedAdvisorsMakeActive" ? (
                <SelectedAdvisorsStatusUpdateSwal
                  open={modalOpen}
                  handleClose={handleModalClose}
                  selectedAdvisors={selectedGridRows}
                  process={"active"}
                  setUpdateRow={setUpdateRow}
                />
              ) :modalId === "#selectedAdvisorsMakeDeactive" ? (
                <SelectedAdvisorsStatusUpdateSwal
                  open={modalOpen}
                  handleClose={handleModalClose}
                  selectedAdvisors={selectedGridRows}
                  process={"deactive"}
                  setUpdateRow={setUpdateRow}
                />
              ) :modalId === "#selectedAdvisorsAutoFetchInvoicesOpen" ? (
                <SelectedAdvisorsAutoFetchInvoicesUpdateSwal
                  open={modalOpen}
                  handleClose={handleModalClose}
                  selectedAdvisors={selectedGridRows}
                  process={"open"}
                  setUpdateRow={setUpdateRow}
                />
              ) :modalId === "#selectedAdvisorsAutoFetchInvoicesClose" ? (
                <SelectedAdvisorsAutoFetchInvoicesUpdateSwal
                  open={modalOpen}
                  handleClose={handleModalClose}
                  selectedAdvisors={selectedGridRows}
                  process={"close"}
                  setUpdateRow={setUpdateRow}
                />
              ) : modalId === "#copyRuleToCompany" ? (
                <CopyRuleToCompanySwal
                  open={modalOpen}
                  handleClose={handleModalClose}
                  selectedCompanies={selectedGridRows}
                />
              ) :modalId === "#checkActiveUsingAndJobs" ? (
                <CheckActiveUsingAndJobs
                  open={modalOpen}
                  handleClose={handleModalClose}
                />
              ) :modalId === "#createMaintenance" ? (
                <CreateMaintenanceSwal
                  open={modalOpen}
                  handleClose={handleModalClose}
                />
              ) :modalId === "#finishMaintenance" ? (
                <FinishMaintenanceSwal
                  open={modalOpen}
                  handleClose={handleModalClose}
                />
              ) : (
                ""
              )}
            </Grid>
          </Grid>

          <Box
            className="grid-area"
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 0,
              backgroundColor: "#ffffff",
              p: 2,
              mb: 2,
            }}
          >
            <Grid
              className="ag-theme-quartz"
              style={{
                minHeight: rowData.length > 6 ? "85vh" : "60vh",
                width: "100%",
                borderRadius: "10%",
              }}
            >
              <AgGridReact
                ref={gridRef}
                onFilterChanged={onFilterChanged}
                localeText={localeTextTR}
                rowSelection="multiple"
                loading={loading}
                animateRows={true}
                rowDragManaged={true}
                rowMultiSelectWithClick={true}
                readOnlyEdit={true}
                singleClickEdit={true}
                stopEditingWhenCellsLoseFocus={true}
                rowData={rowData}
                columnDefs={columnDefs}
                enableRangeSelection={true}
                sideBar={gridSideBar.gridSideBar}
                copyHeadersToClipboard={false}
                defaultColDef={defaultColDef}
                masterDetail={true}
                detailRowAutoHeight={true}
                detailCellRendererParams={detailCellRendererParams}
                getMainMenuItems={getMainMenuItems}
                getRowId={(params) => params.data.id}
              />
            </Grid>
            <Typography
              sx={{
                backgroundColor: "white",
                fontWeight: 400,
                color: "#605f66",
                ml: 1,
                mt: 1,
                mb: 1,
              }}
            >
              Ekrandaki Müşavir Sayısı: <b>{filteredCount}</b>
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default AdminAdvisors;
