import React, { useEffect, useRef, useState } from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { TbReport, TbFileStack, TbList } from "react-icons/tb";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Grid, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import { getJsonItem } from "../../../utils/localStorageProcess";
import Swal from "sweetalert2";

import {
  createVknTcknCheckReport,
  getMonthlyTaxReportCompanies,
  getMonthlyWithholdingCompanies,
} from "../../../api/advisor/advisorReportPageApis";
import moment from "moment";
import withReactContent from "sweetalert2-react-content";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { TextField } from "@mui/material";
// Yükleniyor Swal bileşeni
const MySwal = withReactContent(Swal);
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    keepMounted
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
      // Typography için eklenen yeni stiller
      "& .MuiTypography-root:not([data-exempt='true'])": {
        fontSize: "14px",
        fontWeight: 400,
        color: "#605f66",
      },
    },
  },
}));

export default function ProcessMenu({
  startDate,
  endDate,
  setModalId,
  handleModalOpen,
  setUpdateRow,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const location = useLocation();
  const [companyDetails, setCompanyDetails] = useState();
  const companyDetailsRef = useRef(companyDetails);
  useEffect(() => {
    let locationSplit = location.pathname.split("/");
    const details = getJsonItem(`#${locationSplit[2]}/${locationSplit[3]}`);
    setCompanyDetails(details);
    companyDetailsRef.current = details; // referansı güncelle
  }, [location.pathname]);

  const createMonthlyTaxReportCompanies = () => {
    let specialStartDate = moment()
      .subtract(1, "month")
      .startOf("month")
      .format("YYYY-MM-DD HH:mm:ss");
    let specialEndDate = moment()
      .subtract(1, "month")
      .endOf("month")
      .format("YYYY-MM-DD HH:mm:ss");

    MySwal.fire({
      title: "Tarih Aralığı Seçin",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Devam",
      cancelButtonText: "İptal",
      focusConfirm: false,
      html: (
        <div style={{ display: "flex" }}>
          <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="tr">
            <DatePicker
              label="Başlangıç Tarihi"
              value={moment(specialStartDate)}
              onChange={(newValue) => {
                specialStartDate = moment(newValue);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
            <DatePicker
              label="Bitiş Tarihi"
              value={moment(specialEndDate)}
              onChange={(newValue) => {
                specialEndDate = moment(newValue);
              }}
              sx={{ marginLeft: 2 }}
              renderInput={(params) => (
                <TextField sx={{ color: "black" }} {...params} />
              )}
            />
          </LocalizationProvider>
        </div>
      ),
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Rapor Oluşturuluyor...",
          text: "Lütfen bekleyiniz",
          icon: "info",
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          customClass: {
            popup: "swal2-content-centered",
          },
          willOpen: () => {
            Swal.showLoading();
          },
        });
        getMonthlyTaxReportCompanies(
          moment(specialStartDate).format("YYYY-MM-DD HH:mm:ss"),
          moment(specialEndDate).format("YYYY-MM-DD HH:mm:ss")
        )
          .then((resp) => {
            Swal.fire({
              title: "Başarılı!",
              html: resp.data.message,
              icon: "success",
              confirmButtonText: "Tamam",
            });
            setUpdateRow(true);
          })
          .catch((err) => {
            Swal.fire({
              title: "Bir hata oluştu!",
              text: err.response.data.message,
              icon: "error",
              confirmButtonText: "Tamam",
            });
          });
      }
    });
  };

  const createMonthlyWithholdingCompanies = () => {
    let specialStartDate = moment()
      .subtract(1, "month")
      .startOf("month")
      .format("YYYY-MM-DD HH:mm:ss");
    let specialEndDate = moment()
      .subtract(1, "month")
      .endOf("month")
      .format("YYYY-MM-DD HH:mm:ss");

    MySwal.fire({
      title: "Tarih Aralığı Seçin",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Devam",
      cancelButtonText: "İptal",
      focusConfirm: false,
      html: (
        <div style={{ display: "flex" }}>
          <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="tr">
            <DatePicker
              label="Başlangıç Tarihi"
              value={moment(specialStartDate)}
              onChange={(newValue) => {
                specialStartDate = moment(newValue);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
            <DatePicker
              label="Bitiş Tarihi"
              value={moment(specialEndDate)}
              onChange={(newValue) => {
                specialEndDate = moment(newValue);
              }}
              sx={{ marginLeft: 2 }}
              renderInput={(params) => (
                <TextField sx={{ color: "black" }} {...params} />
              )}
            />
          </LocalizationProvider>
        </div>
      ),
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "PDF Oluşturuluyor...",
          text: "Lütfen bekleyiniz",
          icon: "info",
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          customClass: {
            popup: "swal2-content-centered",
          },
          willOpen: () => {
            Swal.showLoading();
          },
        });
        getMonthlyWithholdingCompanies(
          moment(specialStartDate).format("YYYY-MM-DD HH:mm:ss"),
          moment(specialEndDate).format("YYYY-MM-DD HH:mm:ss")
        )
          .then((resp) => {
            Swal.fire({
              title: "Başarılı!",
              html: resp.data.message,
              icon: "success",
              confirmButtonText: "Tamam",
            });
            setUpdateRow(true);
          })
          .catch((err) => {
            Swal.fire({
              title: "Bir hata oluştu!",
              text: err.response.data.message,
              icon: "error",
              confirmButtonText: "Tamam",
            });
          });
      }
    });
  };
  const createVknTcknCheckReportOfLucaAccounts = () => {
    Swal.fire({
      title: "Rapor Oluşturuluyor...",
      text: "Lütfen bekleyiniz",
      icon: "info",
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      customClass: {
        popup: "swal2-content-centered",
      },
      willOpen: () => {
        Swal.showLoading();
      },
    });
    createVknTcknCheckReport()
      .then((resp) => {
        setUpdateRow(true);
        Swal.fire({
          title: "Başarılı!",
          html: resp.data.message,
          icon: "success",
          confirmButtonText: "Tamam",
        });
      })
      .catch((err) => {
        Swal.fire({
          title: "Bir hata oluştu!",
          text: err.response.data.message,
          icon: "error",
          confirmButtonText: "Tamam",
        });
      });
  };

  return (
    <Grid>
      <Button
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        disableElevation
        sx={{
          textTransform: "none",
          backgroundColor: "#dbf0fd",
          color: "#2ea8f3",
          "&:hover": {
            backgroundColor: "#cfe6f5",
          },
        }}
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        İşlemler
      </Button>
      <StyledMenu
        id="simple-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        disableScrollLock
      >
        <MenuItem disabled>
          <Typography
            data-exempt="true"
            variant="subtitle2"
            fontSize={12}
            color={"black"}
            fontWeight={600}
          >
            RAPOR İŞLEMLERİ
          </Typography>
        </MenuItem>
        <MenuItem
          sx={{ display: "flex", alignItems: "center", gap: 1 }}
          onClick={() => {
            handleClose();
            createMonthlyWithholdingCompanies();
          }}
        >
          <TbReport />
          <Typography>Tevkifatlı Faturası Olan Mükellef Listesi</Typography>
        </MenuItem>
        <MenuItem
          sx={{ display: "flex", alignItems: "center", gap: 1 }}
          onClick={() => {
            handleClose();
            createMonthlyTaxReportCompanies();
          }}
        >
          <TbFileStack />
          <Typography>Toplu KDV Raporu</Typography>
        </MenuItem>
        <MenuItem
          sx={{ display: "flex", alignItems: "center", gap: 1 }}
          onClick={() => {
            handleClose();
            createVknTcknCheckReportOfLucaAccounts();
          }}
        >
          <TbList />
          <Typography>Cari Hesap VKN/TCKN Kontrol Raporu Oluştur</Typography>
        </MenuItem>
      </StyledMenu>
    </Grid>
  );
}
