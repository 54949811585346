import React, { useEffect, useRef, useState } from "react";
import { Grid, Button, Typography, TextField } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useNavigate } from "react-router-dom";
import { forgotPassword } from "../../api/auth/forgotPassword/index";
import forgotPasswordImage from "../../assets/images/forgot-password.png"
import Swal from "sweetalert2";

import { emailFormatControl } from "../../utils/emailFormatControl";


const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const [helperText, setHelperText] = useState('');
  const [helperEmailText, setHelperEmailText] = useState('');
  
  const [error, setError] = useState(false);
 

  const forgotPasswordButtonRef = useRef(null);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        forgotPasswordButtonRef.current.click();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
  const handleForgotPassword = (event) => {
    if (email.trim() === "") {
      setError(true);
      setHelperText("Bu alan boş bırakılamaz");
      return
    }else if (!emailFormatControl(email)) {
      setError(true);
      setHelperEmailText("Geçersiz email adresi formatı");
      return;
    } else {
      setError(false);
      forgotPassword(email)
      .then((resp) => {
        Swal.fire({
          text: resp.data.message,
          icon: 'success',
          confirmButtonText: 'Tamam',
          customClass: {
            confirmButton: 'custom-button'
          }
        });
      })
      .catch((err) => {
        Swal.fire({
          title: err.response.data.message,
          icon: "error",
          confirmButtonText: "Tamam",
          customClass: {
            confirmButton: 'custom-button'
          }
        });
      });
    }
    
    
  };

  return (
    <Grid
      container
      sx={{
        height: "90vh",
        alignItems: "center",
        justifyContent:  {
          xs: 'center',
          sm: 'space-between', 
        },
      }}
      md={12}
    >
      <Grid
        item
        sm={8.5}
        xs={0}
        
        sx={{ display: {
          xs: 'none',
          sm: 'flex', 
        }, justifyContent: "center" }}
      >
        <Grid
          component="img"
          sx={{
            backgroundRepeat: "no-repeat", // Resmin tekrarını belirtir
            width: "90%", // Genişliği %100 yaparak gridin tamamını kaplar
            height: "90%", // Yüksekliği otomatik yaparak orijinal oranları korur
          }}
          alt="forgot-password"
          src={forgotPasswordImage}  // public klasöründen çağırma
        />
      </Grid>

      <Grid
        item
        xs={11}
        sm={3.3}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 3,
          alignItems: "center",
          justifyContent: "center",
          marginTop: "25vh",
          marginBottom: "20vh",
          backgroundColor: "#ffffff",
          padding: "8px",
          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
          borderRadius: "4px",
          marginRight:{
            xs: '0px',
            sm: '15px', 
          },
        }}
      >
        {/* Sağ taraftaki giriş formu */}

        <Grid
          item
          md={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 3,
          }}
        >
          <Typography variant="h4">Parolanızı mı unuttunuz 🔒</Typography>
          <Typography variant="body1" color="textSecondary">
            Lütfen sisteme kayıtlı E-postanızı girin, şifrenizi sıfırlamanız
            için size bağlantı göndereceğiz.
          </Typography>
        </Grid>
        <Grid item md={12} sx={{ width: "100%" }}>
          <TextField
            className="input-field"
            fullWidth
            placeholder="E-mail adresinizi giriniz"
            label="Email"
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setHelperEmailText('')
            }}
            error={(error&&email.length===0)||helperEmailText.length!==0}
            helperText={error&&email.length===0 ? `${helperText}` : error&&email.length!==0 ?`${helperEmailText}`:""}
          />
        </Grid>
        <Grid item md={12} sx={{ width: "100%" }}>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            type="submit"
            ref={forgotPasswordButtonRef}
            onClick={handleForgotPassword}
            className="custom-button"
          >
            Şifremi Sıfırla
          </Button>
        </Grid>
        <Grid
          item
          md={12}
          sx={{ display: "flex", alignItems: "center", gap:2 }}
        >
          <Button
            variant="body2"
            onClick={() => navigate("/login")}
            className="unframed-button "
            sx={{
              color: "#041737",
              textDecoration: "none",
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <ArrowBackIosNewIcon fontSize="small" />
            Giriş sayfasına dön
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ForgotPassword;
