import { getJsonItem, setJsonItem } from "../../../../utils/localStorageProcess";
import axiosInstance from "../../../../utils/maintenanceController/axios";

export const getValueFromServer = async (companyId, periodId, whichData) => {
  try {
    let localSubRecordsTypes = getJsonItem(`#localSubRecordsTypes`);
    if (!localSubRecordsTypes) {
      const [response1, response2] = await Promise.all([
      axiosInstance.get(
        `/company/${companyId}/${periodId}/get-sub-record-list?direction=1`
      ),
      axiosInstance.get(
        `/company/${companyId}/${periodId}/get-sub-record-list?direction=2`
      ),
    ]);
    const subRecordTypes = [...response1.data, ...response2.data];
    await setJsonItem(`#localSubRecordsTypes`, subRecordTypes);
    return subRecordTypes;
    }else{
      return Promise.resolve(localSubRecordsTypes)
    }

  } catch (error) {
    console.error("Error fetching sub record lists:", error);
    throw error; // Hata durumunu üst seviyeye iletmek için
  }
};
