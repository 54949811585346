/** @format */

import axiosInstance from "../../../utils/maintenanceController/axios";
import moment from "moment";

export const getAdvisorSyncList = async (startDate, endDate) => {
  const startDateFormat = moment(startDate).format("YYYY-MM-DD HH:mm:ss");
  const endDateFormat = moment(endDate).format("YYYY-MM-DD HH:mm:ss");

  return axiosInstance.get(
    `/advisor/get-bulk-fetch-processes?startDate=${startDateFormat}&endDate=${endDateFormat}`
  );
};

export const getAdvisorSubSyncList = async (processId) => {
  return axiosInstance.get(
    `/advisor/get-bulk-sub-processes-list?processId=${processId}`
  );
};

export const startBulkFetchInvoices = async (startDate, endDate) => {
  const startDateFormat = moment(startDate).format("YYYY-MM-DD");
  const endDateFormat = moment(endDate).format("YYYY-MM-DD");

  return axiosInstance.post(
    `/advisor/start-bulk-fetch-invoices`,
    { startDate: startDateFormat, endDate: endDateFormat }
  );
};
