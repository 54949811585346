/** @format */

import axios from "axios";
import CheckToken from "../../../utils/checkToken/CheckToken";

export const getCheckActiveUsingAndJobs = async () => {
  const token = await CheckToken();

  if (!token) {
    window.location.href = "/login";
  } else {
    return axios.get(
      `${process.env.REACT_APP_API_URL}/admin/check-active-using-and-jobs`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }
};
