import { useEffect } from "react";
import Swal from "sweetalert2";
import { deleteFiches } from "../../api/company/fiches/deleteFiches";
import { checkTempProcess } from "../../api/company/invoices/checkTempProcess/index.js";
// Yükleniyor Swal bileşeni
export const DeleteSelectedFichesSwal = ({
  open,
  handleClose,
  companyId,
  periodId,
  selectedFicheIds,
  setUpdateRow,
}) => {
  useEffect(() => {
    if (open) {
      if (selectedFicheIds.length === 0) {
        Swal.fire({
          title: "Uyarı",
          text: "Lütfen en az 1 fatura seçin.",
          icon: "error",
          confirmButtonText: "Tamam",
        });
        handleClose();
        return;
      }
      Swal.fire({
        html: `<b>${selectedFicheIds.length}</b> adet fiş silinecek. Emin misiniz?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Evet, Sil!",
        cancelButtonText: "Hayır",
      }).then(async (result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: "Seçilen fişler siliniyor...",
            text: "Lütfen bekleyiniz",
            icon: "info",
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            customClass: {
              popup: "swal2-content-centered",
            },
            willOpen: () => {
              Swal.showLoading();
            },
          });
          deleteFiches(companyId, periodId, selectedFicheIds)
            .then(async (resp) => {
              let flag = true;
              while (flag) {
                try {
                  const checkResp = await checkTempProcess(
                    companyId,
                    periodId,
                    resp.data.id
                  );
                  flag = checkResp.data?.isActive;
                  if (checkResp.data?.isActive === false) {
                    if (checkResp.data?.result?.error) {
                      Swal.fire({
                        icon: 'error',
                        title: 'Silme İşlemi Başarısız!',
                        text: checkResp.data?.result?.error,
                        confirmButtonText: 'Tamam',
                      });
                    } else {setUpdateRow(true);
                      Swal.fire({
                          icon: 'success',
                          title: 'Silme İşlemi Başarılı!',
                          text: checkResp?.data?.result?.message || 'Tüm fişler başarıyla silindi.',
                          confirmButtonText: 'Tamam',
                        });
                    }
                    
                  }
                } catch (err) {
                  Swal.fire({
                    title: "Bir hata oluştu!",
                    text: err.response.data.message,
                    icon: "error",
                    confirmButtonText: "Tamam",
                  });
                }
                await new Promise((resolve) => setTimeout(resolve, 10000));
              }
            })
            .catch((err) => {
              setUpdateRow(true);
              Swal.fire({
                icon: "error",
                title: "Silme İşlemi Başarısız!",
                text:
                  "Fişler silinirken bir hata oluştu. Hata: " +
                  err.response.data.message,
                confirmButtonText: "Tamam",
              });
            });
        }
      });

      handleClose();
    }
  }, [open, handleClose, selectedFicheIds]);

  return null;
};
