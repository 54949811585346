/** @format */

import axiosInstance from "../../../../utils/maintenanceController/axios";

export const createTaxReport = async (
    companyId,
    periodId,
    startDate,
    endDate
) => {
    return axiosInstance.get(
        `/company/${companyId}/${periodId}/create-tax-report?startDate=${startDate}&endDate=${endDate}`
    );
};
