/** @format */

import { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Autocomplete, Button, FormControl, TextField } from "@mui/material";
import { updateBankExtracts } from "../../api/company/bankExtracts/updateBankExtract";
import { getLucaAccounts } from "../../api/company/invoices/getLucaAccounts";
import { reFindLucaAccountForSelectedExtracts } from "../../api/company/bankExtracts/reFindLucaAccountForSelectedExtracts";
import { deleteBankExtracts } from "../../api/company/bankExtracts/deleteBankExtracts";
import { checkTempProcess } from "../../api/company/invoices/checkTempProcess";
import { deleteIncomeExpenseFiches } from "../../api/company/IncomeExpenseFiches/deleteIncomeExpenseFiches";
const MySwal = withReactContent(Swal);

// Yükleniyor Swal bileşeni
export const DeleteIncomeExpenseFichesSwal = ({
    open,
    handleClose,
    companyId,
    periodId,
    selectedFichesIds,
    setUpdateRow,
}) => {
    useEffect(() => {
        if (open) {
            console.log(selectedFichesIds);
            if (selectedFichesIds.length === 0) {
                Swal.fire({
                    title: "Uyarı",
                    text: "Lütfen en az 1 Z-Raporu / Gider Fişi seçin.",
                    icon: "error",
                    confirmButtonText: "Tamam",
                });
                handleClose();
                return;
            }
            Swal.fire({
                html: `<b>${selectedFichesIds.length}</b> adet Z-Raporu / Gider Fişi silinecek. Emin misiniz?`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Evet, sil!",
                cancelButtonText: "Hayır",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    Swal.fire({
                        title: "Z-Raporu / Gider Fişleri siliniyor...",
                        text: "Lütfen bekleyiniz",
                        icon: "info",
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        showConfirmButton: false,
                        customClass: {
                            popup: "swal2-content-centered",
                        },
                        willOpen: () => {
                            Swal.showLoading();
                        },
                        didOpen: () => {
                            const swalPopup =
                                document.querySelector(".swal2-container");
                            if (swalPopup) {
                                swalPopup.style.zIndex = "9999";
                            }
                        },
                    });
                    deleteIncomeExpenseFiches(
                        companyId,
                        periodId,
                        selectedFichesIds
                    )
                        .then(async (resp) => {
                            let flag = true;
                            while (flag) {
                                try {
                                    const checkResp = await checkTempProcess(
                                        companyId,
                                        periodId,
                                        resp.data.id
                                    );
                                    flag = checkResp.data?.isActive;
                                    if (checkResp.data?.isActive === false) {
                                        if (checkResp.data.result?.error) {
                                            Swal.close();
                                            Swal.fire({
                                                title: "Z-Raporu / Gider Fişleri silinirken bir hata oluştu!",
                                                text: checkResp.data.result
                                                    .error,
                                                icon: "error",
                                                confirmButtonText: "Tamam",
                                            });
                                        } else {
                                            Swal.close();
                                            Swal.fire({
                                                title: "Z-Raporu / Gider Fişleri silindi!",
                                                text: checkResp.data.result
                                                    .message,
                                                icon: "success",
                                                confirmButtonText: "Tamam",
                                            });
                                        }
                                        setUpdateRow(true);
                                    }
                                } catch (err) {
                                    Swal.fire({
                                        title: "Bir hata oluştu!",
                                        text: err.response.data.message,
                                        icon: "error",
                                        confirmButtonText: "Tamam",
                                    });
                                }
                                await new Promise((resolve) =>
                                    setTimeout(resolve, 10000)
                                );
                            }
                        })
                        .catch((err) => {
                            Swal.fire({
                                title: "Bir hata oluştu!",
                                text: err.response.data.message,
                                icon: "error",
                                confirmButtonText: "Tamam",
                            });
                        });
                }
            });

            handleClose();
        }
    }, [open, handleClose, selectedFichesIds]);

    return null;
};
