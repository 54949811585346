import axiosInstance from "../../../../utils/maintenanceController/axios";
import moment from "moment";

export const getListInvoices = async (companyId, periodId, startDate, endDate) => {
  const startDateFormat = moment(startDate).format("YYYY-MM-DD HH:mm:ss");
  const endDateFormat = moment(endDate).format("YYYY-MM-DD HH:mm:ss");

  return axiosInstance.get(
    `/company/${companyId}/${periodId}/list-invoices?startDate=${startDateFormat}&endDate=${endDateFormat}`,
  );
};
