//TODO  BU SAYFADA MASTER GRİD(SUBDETAILS) AYARLARI VE COLDEFS AYARLARI BULUNUR.
//TODO  ANA GRİDİN column ayarları ulunur

import moment from "moment";
import sideBar from "../../components/GridSideBar/gridSideBar.js";
import localeTextTr from "../../locale.tr.js";
import { FaExclamationTriangle } from "react-icons/fa";

import { FaCircleCheck } from "react-icons/fa6";
import { FaCircleXmark } from "react-icons/fa6";
import { FaRegCircle } from "react-icons/fa";
import { FaHourglass } from "react-icons/fa6";
import { FaCheck } from "react-icons/fa6";
import { createTurkishFilterParams } from "../../utils/agGridTextFilterTr/agGridFilterUtils.js";


const StatusRenderer = (props) => {
  const { value, data } = props;

  if (value === 100) {
    return null;
  } else if (value === 101 || value === 102) {
    return (
      <span
        title={value === 101 ? "İşlem Gerekiyor" : "Dengesiz Fiş"}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <FaExclamationTriangle
          style={{
            color: "#ffffff",
            backgroundColor: "#e1306c",
            borderRadius: "50%",
            padding: "4 5 6 5",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            overflow: "visible", // İkonun container dışına taşmasına izin verin
          }}
          size={14}
        />
      </span>
    );
  } else if (value === 104) {
    return (
      <span
        title="Aktarılabilir"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <FaRegCircle
          style={{
            color: "#0077b5",
            backgroundColor: "#d9ebf4",
            padding: 5,
            borderRadius: "50%",
          }}
          size={14}
        />
      </span>
    );
  } else if (value === 200) {
    return (
      <span
        title="Başarılı Aktarım"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <FaCheck
          style={{
            color: "#32c988",
            backgroundColor: "#dff7e9",
            padding: 5,
            borderRadius: "50%",
          }}
          size={14}
        />
      </span>
    );
  } else if (value === 400) {
    return (
      <span
        title="Hatalı Aktarım"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <FaCircleXmark style={{ color: "#ea5455" }} size={24} />
      </span>
    );
  } else {
    return value;
  }
};

export const createColumnDefs  =(companyClass)=>  [
  {
    headerName: "",
    checkboxSelection: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    headerClass: "checkbox",
    pinned: "left",
    width: 48,
    field: "checkboxBtn",
    resizable: false,
    lockPosition: "left",
    suppressAutoSize: true,
    suppressColumnsToolPanel: true,
    suppressMenu: true,
    editable: false,
    filter: false,
  },
  {
    field: "master",
    headerName: "",
    cellRenderer: "agGroupCellRenderer",
    editable: false,
    filter: false,
    suppressMenu: true,
    flex:0,
    width: 48,
    resizable:false
  },
  {
    field: "status",
    headerName: "Aktarım Durumu",
    flex:0,
    filter: "agSetColumnFilter",
    cellStyle: { textAlign: "center" },
    width: 165,
    filterParams: {
      valueFormatter: function (params) {
        if (params.value === 100) {
          return "Fiş Oluşturuldu";
        } else if (params.value === 101) {
          return "İşlem Gerekiyor";
        } else if (params.value === 102) {
          return "Dengesiz Fiş";
        } else if (params.value === 200) {
          return "Başarılı Aktarım";
        } else if (params.value === 104) {
          return "Aktarılabilir";
        } else if (params.value === 400) {
          return "Hatalı Aktarım";
        } else {
          return params.value;
        }
      },
    },
    editable: false,
    cellRenderer: StatusRenderer,
  },
  {
    field: "id",
    hide: true,
    suppressColumnsToolPanel: true,
    width: 100,
  },
  {
    field: "date",
    headerName: "Fiş Tarihi",
    filter: "agTextColumnFilter",
    width: 180,
    editable: false,
    valueFormatter: function (params) {
      return moment.utc(params.value).utc(false).format("DD/MM/YYYY");
    },
  },
  ...(companyClass === 2 ? [{
    field: "FicheLines.0.evrak_no",
    headerName: "Evrak No",
    filter: "agTextColumnFilter",
    width: 180,
  }] : []),
  {
    field: "description",
    headerName: "Fiş Açıklaması",
    filter: "agTextColumnFilter",
    width: 400,
    editable: false,
  },
  {
    field: "totalBorc",
    headerName: "Borç",
    filter: "agNumberColumnFilter",
    cellStyle: (params) =>
      params.data.totalBorc === params.data.totalAlacak
        ? { backgroundColor: "transparent" }
        : { backgroundColor: "#ea5455" },
    valueFormatter: (params) => {
      return new Intl.NumberFormat("tr-TR", {
        currency: params.data.currency_code,
      }).format(params.value);
    },
  },
  {
    field: "totalAlacak",
    headerName: "Alacak",
    filter: "agNumberColumnFilter",
    cellStyle: (params) =>
      params.data.totalBorc === params.data.totalAlacak
        ? { backgroundColor: "transparent" }
        : { backgroundColor: "#ea5455" },
    valueFormatter: (params) => {
      return new Intl.NumberFormat("tr-TR", {
        currency: params.data.currency_code,
      }).format(params.value);
    },
  },
  {
    feild: "sendedFicheId",
    headerName: "Luca Fiş Id",
    filter: "agTextColumnFilter",
    hide: true,
  },
  {
    field: "sendedFicheNo",
    headerName: "Luca Fiş No",
    filter: "agTextColumnFilter",
    hide:companyClass===2?true:false,
    width: 180,
  },
  {
    field: "error",
    headerName: "Hata",
    filter: "agTextColumnFilter",
    width: 400,
    hide: true,
  },
];

//////////////////DETAILS AYARLARI/////////////////

const createDetailsColumnDefs = (ButtonRenderer,companyClass) => [
  {
    headerCheckboxSelection: true,
    checkboxSelection: true,
    maxWidth: 80,
    minWidth: 50,
    pinned: "left",
    filter: false,
    headerName: "",
    headerCheckboxSelectionFilteredOnly: true,
    headerClass: "checkbox",
    field: "checkboxBtn",
    resizable: false,
    lockPosition: "left",
    suppressAutoSize: true,
    suppressColumnsToolPanel: true,
    suppressMenu: true,
    editable: false,
    cellRenderer:(params) => {
      return params.data.invoiceId?ButtonRenderer(params):undefined
    },
  },
  ...(companyClass === 1 ? [{
    field: "hesap_kodu",
    headerName: "Hesap Kodu",
    filter: "agTextColumnFilter",
    cellStyle: (params) => {
      if (
        !params.data.hesap_kodu ||
        params.data.hesap_kodu.includes("{") ||
        params.data.hesap_kodu.includes("}")
      ) {
        return { backgroundColor: "#ea5455" };
      }
      return { backgroundColor: "transparent" };
    },
  },] : []),
  ...(companyClass === 2 ? [{
    field: "KAYIT_ALT_TURU",
    headerName: "Kayıt Alt Türü",
    filter: "agTextColumnFilter",
    
  },] : []),
  ...(companyClass === 2 ? [{
    field: "TUTAR_KDVSIZ",
    headerName: "Matrah",
    filter: "agTextColumnFilter",
    
  },] : []),
  ...(companyClass === 2 ? [{
    field: "KDV_ORAN",
    headerName: "KDV Oranı",
    filter: "agTextColumnFilter",
    valueGetter: (params) => `%${parseFloat(params.data.KDV_ORAN)}`,
    
  },] : []),
  ...(companyClass === 2 ? [{
    field: "TUTAR_KDV",
    headerName: "KDV Tutarı",
    filter: "agTextColumnFilter",
    
  },] : []),
  ...(companyClass === 2 ? [{
    field: "TUTAR_KDVLI",
    headerName: "Toplam Tutar",
    filter: "agTextColumnFilter",
    
  },] : []),
  ...(companyClass === 2 ? [{
    field: "TEVKIFAT",
    headerName: "Tevkifat Tutarı",
    filter: "agTextColumnFilter",
    
  },] : []),
  ...(companyClass === 2 ? [{
    field: "TEVKIFAT_SABLON",
    headerName: "Tevkifat Oranı",
    filter: "agTextColumnFilter",
    valueGetter: (params) => params.data.TEVKIFAT_SABLON&&params.data.TEVKIFAT_SABLON!="0"?`%${parseFloat(params.data.TEVKIFAT_SABLON)}`:params.data.TEVKIFAT_SABLON,
    
  },] : []),
  ...(companyClass === 1 ? [{
    field: "borc",
    headerName: "Borç",
    filter: "agNumberColumnFilter",
    cellStyle: (params) => {
      if (
        (!params.data.borc && params.data.borc !== 0) ||
        Number(params.data.borc) === NaN
      ) {
        return { backgroundColor: "#ea5455" };
      }
      return { backgroundColor: "transparent" };
    },
    valueFormatter: (params) => {
      return new Intl.NumberFormat("tr-TR", {
        currency: params.data.currency_code,
      }).format(params.value);
    },
  },] : []),
  ...(companyClass === 1 ? [{
    field: "alacak",
    headerName: "Alacak",
    filter: "agNumberColumnFilter",
    cellStyle: (params) => {
      if (
        (!params.data.alacak && params.data.alacak !== 0) ||
        Number(params.data.alacak) === NaN
      ) {
        return { backgroundColor: "#ea5455" };
      }
      return { backgroundColor: "transparent" };
    },
    valueFormatter: (params) => {
      if (params.value !== null && params.value !== undefined) {
        // Number to locale string conversion
        return new Intl.NumberFormat("tr-TR", {
          currency: params.data.currency_code,
        }).format(params.value);
      } else {
        return "";
      }
    },
  },] : []),

  {
    field: "evrak_no",
    headerName: "Evrak No",
    filter: "agTextColumnFilter",
    width: 220,
  },
  {
    field: "evrak_tarihi",
    headerName: "Evrak Tarihi",
    filter: "agTextColumnFilter",
  },
  {
    field: "aciklama",
    headerName: "Açıklama",
    filter: "agTextColumnFilter",
    width: 400,
  },
];
export const detailsGridOptions = (ButtonRenderer,companyClass) => {
  return {
    columnDefs: createDetailsColumnDefs(ButtonRenderer,companyClass),
    defaultColDef: {
      resizable: true,
      floatingFilter: true,
      flex:1,
      filterParams:createTurkishFilterParams(),
    },
    sideBar: sideBar.detailsSideBar,
    localeText: localeTextTr,
    rowDragManaged: true,
    animateRows: true,
    rowSelection: "multiple",
    singleClickEdit: true,
    rowMultiSelectWithClick: true,
  };
};
