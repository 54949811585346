import { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";

import { Autocomplete, Button, TextField } from "@mui/material";
import { emailFormatControl } from "../../../utils/emailFormatControl";

import withReactContent from "sweetalert2-react-content";
import { createSubUser } from "../../../api/advisor/advisorMyUserPageApis";
const MySwal = withReactContent(Swal);

// Yükleniyor Swal bileşeni
export const CreateSelectedUsersSwal = ({
  open,
  handleClose,
  setUpdateRow,
}) => {
  const emailRef = useRef();
  const usernameRef = useRef();
  const passwordRef = useRef();
  const isCanSendFiche = useRef();

  useEffect(() => {
    if (open) {
      MySwal.fire({
        title: "Kullanıcı Ekle",
        html: (
          <div
            style={{ display: "flex", flexDirection: "column", gap: "20px" }}
          >
            <TextField
              inputRef={emailRef}
              className="input-field"
              fullWidth
              label="E-Posta"
              placeholder="E-posta adresini giriniz"
              InputLabelProps={{ shrink: true }}
              variant="outlined"
            />
            <TextField
              inputRef={usernameRef}
              className="input-field"
              fullWidth
              label="Kullanıcı Adı"
              placeholder="Kullanıcı adı giriniz"
              InputLabelProps={{ shrink: true }}
              variant="outlined"
            />
            <TextField
              inputRef={passwordRef}
              className="input-field"
              fullWidth
              label="Şifre"
              placeholder="Şifre giriniz"
              InputLabelProps={{ shrink: true }}
              variant="outlined"
            />
            <Autocomplete
              disablePortal
              options={[
                { value: true, label: "Evet" },
                { value: false, label: "Hayır" },
              ]}
              autoHighlight
              selectOnFocus
              blurOnSelect
              disableClearable
              onChange={(event, newValue) => {
                isCanSendFiche.current = newValue.value;
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Fiş Gönderme Yetkisi Verilsin mi?"
                  variant="outlined"
                  size="small"
                />
              )}
              style={{ width: "100%" }}
            />
          </div>
        ),
        showCancelButton: true,
        confirmButtonText: "Ekle",
        cancelButtonText: "İptal",

        preConfirm: () => {
          const email = emailRef.current.value;
          const username = usernameRef.current.value;
          const password = passwordRef.current.value;
          const isCanSendFicheToLuca=isCanSendFiche.current

          if (!email || !username || !password||isCanSendFicheToLuca===""||isCanSendFicheToLuca===null) {
            Swal.showValidationMessage("Lütfen tüm alanları doldurunuz!");
            return false;
          }

          if (!emailFormatControl(email)) {
            Swal.showValidationMessage(
              "Lütfen E-Posta adresinizi doğru giriniz!"
            );
            return false;
          }
          let data = { email, username, password,isCanSendFicheToLuca };
          return data;
        },
      }).then((result) => {
        if (result.isConfirmed) {
          createSubUser(result.value)
            .then((resp) => {
              setUpdateRow(true);
              Swal.fire({
                title: "Başarılı!",
                html: resp.data.message,
                icon: "success",
                confirmButtonText: "Tamam",
              });
            })
            .catch((err) => {
              setUpdateRow(true);
              Swal.fire({
                title: "Bir hata oluştu!",
                text: err.response.data.message,
                icon: "error",
                confirmButtonText: "Tamam",
              });
            });
        }
      });

      handleClose();
    }
  }, [open, handleClose]);

  return null;
};
