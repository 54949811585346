/** @format */

import axiosInstance from "../../../utils/maintenanceController/axios";

export const getAdvisorMyUserList = async () => {
  return axiosInstance.get("/advisor/list-sub-users");
};

export const deleteSubUser = async (selectedUsers) => {
  return axiosInstance.post(
    "/advisor/delete-sub-user",
    { subUserIds: selectedUsers }
  );
};

export const createSubUser = async (data) => {
  return axiosInstance.post(
    "/advisor/create-sub-user",
    { ...data }
  );
};

export const updateSubUser = async (data) => {
  return axiosInstance.post(
    "/advisor/update-sub-user",
    { ...data }
  );
};

export const givePermissionToUsers = async (data) => {
  return axiosInstance.post(
    "/advisor/give-permission-to-users",
    { ...data }
  );
};

export const removePermissionFromUsers = async (data) => {
  return axiosInstance.post(
    "/advisor/remove-permission-from-users",
    { ...data }
  );
};
