/** @format */
export const createRuleTableColumnDefs = (ItemSelectorCellEditor) => [
    {
        headerName: "",
        checkboxSelection: function (params) {
            return !params.data.isDefaultRule;
        },
        headerCheckboxSelectionFilteredOnly: true,
        headerClass: "checkbox",
        pinned: "left",
        width: 48,
        field: "checkboxBtn",
        resizable: false,
        lockPosition: "left",
        suppressAutoSize: true,
        suppressColumnsToolPanel: true,
        suppressMenu: true,
        editable: false,
    },
    {
        field: "id",
        hide: true,
        suppressColumnsToolPanel: true,
    },
    {
        field: "direction",
        editable: function (params) {
            return !params.data.isDefaultRule;
        },
        floatingFilter: true,
        filter: "agSetColumnFilter",
        cellEditor: "agRichSelectCellEditor",
        cellRenderer: (params) => {
            let direction = params.value;
            if (!direction) {
                direction = params.data.condition.includes("out")
                    ? "out"
                    : "in";
            }

            switch (direction) {
                case "in":
                    return "Gelen";
                case "out":
                    return "Giden";
                default:
                    return "";
            }
        },
        valueGetter: (params) => {
            if (params.data.direction) {
                return params.data.direction;
            }
            return params.data.condition.includes("out") ? "out" : "in";
        },
        filterValueGetter: (params) => {
            return (
                params.data.direction ||
                (params.data.condition.includes("out") ? "out" : "in")
            );
        },
        cellEditorParams: {
            values: ["in", "out"],
            cellHeight: 30,
            cellRenderer: (params) => {
                const direction = params.value;
                switch (direction) {
                    case "in":
                        return "Gelen";
                    case "out":
                        return "Giden";
                    default:
                        return "";
                }
            },
        },
        filterParams: {
            valueFormatter: (params) => {
                const direction = params.value;
                switch (direction) {
                    case "in":
                        return "Gelen";
                    case "out":
                        return "Giden";
                    default:
                        return "";
                }
            },
        },
        headerName: "Yön",
        flex: 1,
    },
    {
        floatingFilter: true,
        field: "note",
        filter: "agTextColumnFilter",
        headerName: "Açıklama",
        editable: function (params) {
            return !params.data.isDefaultRule;
        },
        flex: 6,
    },

    {
        field: "hesap_kodu",
        headerName: "Luca Hesap Kodu",
        filter: "agTextColumnFilter",
        floatingFilter: true,
        editable: true,
        width: 400,
        cellRenderer: (params) => params.value,
        cellEditor: ItemSelectorCellEditor,
        suppressKeyboardEvent: (params) => {
            // Eğer Enter tuşuna basılmışsa
            if (params.event.key === "Enter") {
                return true; // Olayı bastır (düzenleyiciyi kapatma)
            }
            return false; // Diğer tuşlar normal çalışsın
        },
        valueSetter: (params) => (params.data.hesap_kodu = params.newValue),
    },
    {
        field: "vkn_tckn",
        filter: "agTextColumnFilter",
        floatingFilter: true,
        editable: function (params) {
            return (
                params.data.condition.includes("].find") ||
                !params.data.isDefaultRule
            );
        },
        cellStyle: (params) => {
            if (
                !params.data.condition.includes("].find") &&
                params.data.isDefaultRule
            ) {
                return { backgroundColor: "#EEEEEE", cursor: "not-allowed" };
            }
            return {};
        },
        tooltipValueGetter: (params) => {
            if (
                !params.data.condition.includes("].find") &&
                params.data.isDefaultRule
            ) {
                return "Bu alan düzenlenemez!";
            }
            return null;
        },

        cellEditor: "agLargeTextCellEditor",
        cellEditorPopup: true,
        headerName: "VKN/TCKN (LİSTE)",
        flex: 3,
    },
];
