function calculateRule(process, field, input) {
  const andValue =
    process === '=='
      ? `&& value.${field} == "${input}"`
      : process === '!='
        ? `&& value.${field} != "${input}"`
        : process === '>'
          ? `&& value.${field} > "${input}"`
          : process === '<'
            ? `&& value.${field} < "${input}"`
            : process === '>='
              ? `&& value.${field} >= "${input}"`
              : process === '<='
                ? `&& value.${field} <= "${input}"`
                : process === 'includes' && field.includes('.List')
                  ? `&& [${input
                      .split(',')
                      .filter((item) => item.trim() !== '')
                      .map(
                        (item) => `"${item.trim()}"`,
                      )}].find(item=>String(value.${field.replace('.List', '')})?.toLocaleLowerCase("tr").includes(String(item.trim())?.toLocaleLowerCase("tr")))`
                  : process === 'notIncludes' && field.includes('.List')
                    ? `&& ![${input
                        .split(',')
                        .filter((item) => item.trim() !== '')
                        .map(
                          (item) => `"${item.trim()}"`,
                        )}].find(item=>String(value.${field.replace('.List', '')})?.toLocaleLowerCase("tr").includes(String(item.trim())?.toLocaleLowerCase("tr")))`
                    : process === 'includes'
                      ? `&& value.${field}.toLocaleLowerCase("tr").includes("${input}".toLocaleLowerCase("tr"))`
                      : process === 'notIncludes'
                        ? `&& !value.${field}.toLocaleLowerCase("tr").includes("${input}".toLocaleLowerCase("tr"))`
                        : process === 'endsWith'
                          ? `&& value.${field}.toLocaleLowerCase("tr").endsWith("${input}".toLocaleLowerCase("tr"))`
                          : process === 'startsWith'
                            ? `&& value.${field}.toLocaleLowerCase("tr").startsWith("${input}".toLocaleLowerCase("tr"))`
                            : false;

  return andValue;
}

export async function createRuleStringForBackend(direction, bankName, process, field, input, accountCode, subRules) {
  let rule = {
    condition: `value.direction == ${direction} ${bankName === 'Tüm Bankalar' ? `&& "${bankName}"==="${bankName}"` : `&& bankName==="${bankName}"`} ${calculateRule(process, field, input)} ${subRules.map((item) => calculateRule(item.process, item.field, item.input)).join(' ')}`,
    return: accountCode,
  };
  return rule;
}

export function parseRuleConditionStringForFrontend(condition) {
  const process =
    condition.includes('!value.') || condition.includes('![')
      ? 'notIncludes'
      : condition.includes('includes') || condition.includes('find')
        ? 'includes'
        : condition.includes('startsWith')
          ? 'startsWith'
          : condition.includes('endsWith')
            ? 'endsWith'
            : condition.includes('!=')
              ? '!='
              : condition.includes('>=')
                ? '>='
                : condition.includes('<=')
                  ? '<='
                  : condition.includes('>')
                    ? '>'
                    : condition.includes('<')
                      ? '<'
                      : condition.includes('==')
                        ? '=='
                        : undefined;
  const field =
    condition.includes('amount') && condition.includes('find')
      ? 'amount.List'
      : condition.includes('value.amount')
        ? 'amount'
        : condition.includes('date') && condition.includes('find')
          ? 'date.List'
          : condition.includes('value.date')
            ? 'date'
            : condition.includes('description') && condition.includes('find')
              ? 'description.List'
              : condition.includes('value.description')
                ? 'description'
                : undefined;
  const input =
    (process === 'includes' || process === 'notIncludes') && field.includes('.List')
      ? condition
          .substring(condition.indexOf('[') + 1, condition.indexOf(']'))
          .trim()
          .split(',')
          .map((item) => item.substring(1, item.length - 1))
      : process === 'includes' || process === 'notIncludes'
        ? condition
            .substring(condition.indexOf('.includes(') + 11, condition.lastIndexOf('.toLocaleLowerCase("tr")') - 1)
            .trim()
        : process === 'startsWith'
          ? condition.substring(
              condition.indexOf('.startsWith(') + 13,
              condition.lastIndexOf('.toLocaleLowerCase("tr")') - 1,
            )
          : process === 'endsWith'
            ? condition.substring(
                condition.indexOf('.endsWith(') + 11,
                condition.lastIndexOf('.toLocaleLowerCase("tr")') - 1,
              )
            : process === '!='
              ? condition.substring(condition.indexOf('!=') + 4, condition.lastIndexOf('"'))
              : process === '>='
                ? condition.substring(condition.indexOf('>=') + 4, condition.lastIndexOf('"'))
                : process === '<='
                  ? condition.substring(condition.indexOf('<=') + 4, condition.lastIndexOf('"'))
                  : process === '>'
                    ? condition.substring(condition.indexOf('>') + 3, condition.lastIndexOf('"'))
                    : process === '<'
                      ? condition.substring(condition.indexOf('<') + 3, condition.lastIndexOf('"'))
                      : process === '=='
                        ? condition.substring(condition.indexOf('==') + 4, condition.lastIndexOf('"'))
                        : undefined;

  return { field, process, input };
}
