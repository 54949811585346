/** @format */

import { useState, useEffect } from "react";
import {
    Grid,
    Avatar,
    Typography,
    Button,
    Card,
    CardContent,
    Chip,
    Divider,
} from "@mui/material";
import localStorage from "local-storage";
import Sidebar from "./sideBar.js";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import UserInfo from "./userProfileInfoPage.js";
import UserSetting from "./userProfileSettingsPage.js";
import moment from "moment";
import "moment/locale/tr";
import { getAdvisorInfo } from "../../api/advisor/getAdvisorInfo/index.js";
import { useLocation } from "react-router-dom";
import { cookies } from "../../utils/cookie/index.js";
import { jwtDecode } from "jwt-decode";

const ProfilePage = () => {
    const [isOpen, setIsOpen] = useState(false);
    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };
    moment.locale("tr");
    const [userInfo, setUserInfo] = useState({});
    const [isSubUser, setIsSubUser] = useState(false);
    const [userInfoToken, setUserInfoToken] = useState(false);

    const [userStatus, setUserStatus] = useState(false); //Kullanıcının aktif pasiflik durumunu belirtir.
    const [selectedButtonId, setSelectedButtonId] = useState("");
    const [loading, setLoading] = useState(true);
    const location = useLocation();
    const { from } = location.state || {};
    const handleButtonClick = (buttonId) => {
        setSelectedButtonId(buttonId);
    };
    useEffect(() => {
        document.title = `Ayarlar - Rahat Aktarım`;
        if (from) {
            setSelectedButtonId(from);
        } else {
            setSelectedButtonId("settings");
        }
        const jwtToken = cookies.get("token");
        if (jwtToken) {
            const decodedToken = jwtDecode(jwtToken);
            const user = decodedToken;
            setUserInfoToken(user);
            if (user.isSubUser && user.subUserId) {
                setIsSubUser(true);

                handleButtonClick("profile");
            } else {
                getAdvisorInfo().then((resp) => {
                    setUserInfo(resp.data.advisor);
                    setUserStatus(resp.data.advisor.isActive);
                    setLoading(false);
                });
            }
        }
        const sideBarOpen = localStorage.get("sidebar");
        if (sideBarOpen === "false") {
            setIsOpen(false);
        } else {
            setIsOpen(true);
        }
    }, []);

    return (
        <Grid container>
            <Grid
                item
                sx={{
                    flexBasis: isOpen ? "275px" : "95px",
                    flexShrink: 0,
                    transition: "flex-basis 0.3s ease",
                }}
                zIndex={1000}
            >
                <Sidebar status={isOpen} toggleSidebar={toggleSidebar} />
            </Grid>
            <Grid
                item
                sx={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "flex-end",
                    flexDirection: "column",
                    gap: 1,
                    pr: "12px",
                }}
            >
                <Grid //Profil Header Alanı
                    item
                    md={12}
                    sx={{
                        // display:selectedButtonId === 1 ?"none":"",
                        marginTop: "20px",
                        backgroundColor: "white",
                        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    }}
                >
                    <Card sx={{ width: "100%" }}>
                        <Grid
                            sx={{
                                height: 100,
                                background:
                                    "linear-gradient(90deg, rgba(255,39,39,1) 8%, rgba(226,135,43,1) 24%, rgba(176,75,233,1) 65%, rgba(30,144,255,1) 100%)",
                            }}
                        />
                        <CardContent sx={{ position: "relative" }}>
                            <Avatar
                                alt={
                                    isSubUser
                                        ? `${userInfoToken?.advisorName}-${userInfoToken?.subUserName}`
                                        : userInfoToken.advisorName
                                }
                                src="/path-to-profile-image.jpg"
                                sx={{
                                    width: 120,
                                    height: 120,
                                    border: "4px solid white",
                                    position: "absolute",
                                    top: -60,
                                    left: 20,
                                }}
                            />

                            <Grid
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 3,
                                    position: "relative",
                                    top: 0,
                                    left: 150,
                                }}
                            >
                                <Grid item sx={{ marginRight: "1vh" }}>
                                    <Typography variant="h5" component="div">
                                        {isSubUser
                                            ? `${
                                                  userInfoToken?.advisorName?.split(
                                                      " "
                                                  ).length > 3
                                                      ? userInfoToken?.advisorName?.split(
                                                            " "
                                                        )[0] +
                                                        " " +
                                                        userInfoToken?.advisorName?.split(
                                                            " "
                                                        )[1] +
                                                        " " +
                                                        userInfoToken?.advisorName?.split(
                                                            " "
                                                        )[2]
                                                      : userInfoToken.advisorName
                                              } - ${
                                                  userInfoToken?.subUserName?.split(
                                                      " "
                                                  ).length > 3
                                                      ? userInfoToken?.subUserName?.split(
                                                            " "
                                                        )[0] +
                                                        " " +
                                                        userInfoToken?.subUserName?.split(
                                                            " "
                                                        )[1] +
                                                        " " +
                                                        userInfoToken?.subUserName?.split(
                                                            " "
                                                        )[2]
                                                      : userInfoToken.subUserName
                                              }`
                                            : userInfoToken?.advisorName?.split(
                                                  " "
                                              ).length > 3
                                            ? userInfoToken?.advisorName?.split(
                                                  " "
                                              )[0] +
                                              " " +
                                              userInfoToken?.advisorName?.split(
                                                  " "
                                              )[1] +
                                              " " +
                                              userInfoToken?.advisorName?.split(
                                                  " "
                                              )[2]
                                            : userInfoToken.advisorName}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 2,
                                    position: "absolute",
                                    top: 20,
                                    right: 20,
                                }}
                            >
                                <Divider
                                    orientation="vertical"
                                    flexItem
                                    sx={{
                                        display:
                                            selectedButtonId === 1
                                                ? "none"
                                                : undefined,
                                        height: "20px",
                                    }}
                                />
                                {!isSubUser && (
                                    <Grid
                                        item
                                        sx={{
                                            display:
                                                selectedButtonId === 1
                                                    ? "none"
                                                    : "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <CalendarTodayIcon
                                            fontSize="small"
                                            sx={{ mr: 1 }}
                                        />

                                        <Typography
                                            variant="body2"
                                            color="text.secondary"
                                        >
                                            {moment(userInfo.createdAt).format(
                                                "MMMM YYYY"
                                            )}
                                        </Typography>
                                    </Grid>
                                )}

                                <Divider
                                    orientation="vertical"
                                    flexItem
                                    sx={{
                                        display:
                                            selectedButtonId === 1
                                                ? "none"
                                                : undefined,
                                        height: "20px",
                                    }}
                                />
                                <Grid
                                    item
                                    sx={{
                                        display:
                                            selectedButtonId === 1
                                                ? "none"
                                                : "flex",
                                        alignItems: "center",
                                        gap: 1,
                                    }}
                                >
                                    <Typography
                                        variant="body2"
                                        color="text.secondary"
                                    >
                                        Durum :
                                    </Typography>
                                    <Chip
                                        label={
                                            isSubUser || userStatus
                                                ? "Aktif"
                                                : "Pasif"
                                        }
                                        color={
                                            isSubUser || userStatus
                                                ? "success"
                                                : "error"
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid
                    md={12}
                    sx={{
                        display: "flex",
                        mt: "10px",
                        gap: 5,
                        pt: "10px",
                        pb: "10px",
                        alignItems: "center",
                    }}
                >
                    <Grid item>
                        <Button
                            fullWidth
                            variant={
                                selectedButtonId === "profile"
                                    ? "contained"
                                    : "text"
                            }
                            type="submit"
                            className={
                                selectedButtonId === "profile"
                                    ? "selected-button"
                                    : "unselected-button"
                            }
                            sx={{ display: "flex", gap: 1 }}
                            onClick={() => handleButtonClick("profile")}
                        >
                            <BadgeOutlinedIcon fontSize="medium" />
                            Müşavir İşlemleri
                        </Button>
                    </Grid>
                    {!isSubUser && (
                        <Grid item>
                            <Button
                                fullWidth
                                variant={
                                    selectedButtonId === "settings"
                                        ? "contained"
                                        : "text"
                                }
                                type="submit"
                                className={
                                    selectedButtonId === "settings"
                                        ? "selected-button"
                                        : "unselected-button"
                                }
                                sx={{
                                    display: "flex",
                                    gap: 1,
                                    pt: 1,
                                    pb: 1,
                                    pr: 1,
                                    pl: 1,
                                }}
                                onClick={() => handleButtonClick("settings")}
                            >
                                <ManageAccountsOutlinedIcon fontSize="medium" />
                                Müşavir Ayarları
                            </Button>
                        </Grid>
                    )}
                </Grid>
                {selectedButtonId === "profile" ? (
                    <UserInfo userInfo={userInfo} isSubUser={isSubUser} />
                ) : selectedButtonId === "settings" && !isSubUser ? (
                    <UserSetting userInfo={userInfo} loading={loading} />
                ) : (
                    ""
                )}
            </Grid>
        </Grid>
    );
};

export default ProfilePage;
