/** @format */

import axiosInstance from "../../../../utils/maintenanceController/axios";
import moment from "moment";

export const getListReports = async (companyId, periodId, startDate, endDate) => {
  const startDateFormat = moment(startDate).format("YYYY-MM-DD HH:mm:ss");
  const endDateFormat = moment(endDate).format("YYYY-MM-DD HH:mm:ss");

  return axiosInstance.get(
    `/company/${companyId}/${periodId}/list-reports?startDate=${startDateFormat}&endDate=${endDateFormat}`,
  );
};



export const getMonthlyTaxReportCompanies = async (startDate, endDate) => {
  return axiosInstance.get(
    `/advisor/get-monthly-tax-report-companies?startDate=${startDate}&endDate=${endDate}`
  );
};

export const getMonthlyWithholdingCompanies = async (startDate, endDate) => {
  return axiosInstance.get(
    `/advisor/get-monthly-withholding-companies?startDate=${startDate}&endDate=${endDate}`
  );
};

export const createVknTcknCheckReport = async () => {
  return axiosInstance.post(
    `/advisor/create-vkn-tckn-check-report-of-luca-accounts`,
    {}
  );
};
