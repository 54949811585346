/** @format */

import axios from "axios";
import CheckToken from "../../../utils/checkToken/CheckToken";

export const getAllCompanyList = async () => {
  const token = await CheckToken();

  if (!token) {
    window.location.href = "/login";
  } else {
    return axios.get(
      `${process.env.REACT_APP_API_URL}/admin/get-all-companies`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      } 
    );
  }
};
