/** @format */

import React, { useState, useRef, useEffect, useMemo } from "react";
import Swal from "sweetalert2";
import { Modal, Grid, Typography, Box, Button, Alert } from "@mui/material";
import { TbReload, TbTrash } from "react-icons/tb";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import moment from "moment";
import localeTextTr from "../../locale.tr";
import { TbCircleCheck } from "react-icons/tb";
import { TbLineHeight } from "react-icons/tb";
import { TbExclamationCircle } from "react-icons/tb";
import { TbClockCancel } from "react-icons/tb";
import { TbLoader } from "react-icons/tb";
import { showLoadingSwal } from "../../components/loadingSwal";
import {
    fetchSyncs,
    fetchDetailSyncs,
    cancelSelectedProcess,
    fetchFailedInvoices,
} from "../../api/company/invoices/fetchSyncs";

export const ViewSyncs = ({
    open,
    handleClose,
    companyId,
    periodId,
    startDate,
    endDate,
}) => {
    const [loading, setLoading] = useState(true);
    const [firstLoading, setFirstLoading] = useState(true); //Sayfa ilk açıldığında 2 istek atmasın diye bir flag
    const [rowData, setRowData] = useState();
    const gridRef = useRef(null);
    const handleCancelProcess = () => {
        if (gridRef.current) {
            const selectedRows = gridRef.current.api.getSelectedRows();
            if (
                selectedRows.length === 0 ||
                selectedRows === undefined ||
                selectedRows[0].status !== 10
            ) {
                Swal.fire({
                    icon: "warning",
                    title: "Uyarı",
                    text:
                        selectedRows.length === 0 || selectedRows === undefined
                            ? "Lütfen bir satır seçin."
                            : selectedRows[0].status === 2
                            ? "Tamamlanan senkronizasyon iptal edilemez."
                            : selectedRows[0].status === 0 ||
                              selectedRows[0].status === 1
                            ? "Devam eden senkronizasyon iptal edilemez."
                            : selectedRows[0].status === 3
                            ? "Hatalı senkronizasyon iptal edilemez."
                            : "İptal edilen senkronizasyon tekrar iptal edilemez.",
                    confirmButtonText: "Tamam",
                });
                return;
            }
            const processId = selectedRows[0].id;
            Swal.fire({
                text: `Seçilen senkronizasyon iptal edilecek. Emin misiniz?`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Evet, iptal et!",
                cancelButtonText: "Hayır",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    showLoadingSwal();
                    cancelSelectedProcess(companyId, periodId, processId)
                        .then((response) => {
                            Swal.close();
                            Swal.fire({
                                icon: "success",
                                title: "Başarılı",
                                text: response.data.message,
                            });
                            setLoading(true);
                            fetchSyncs(
                                companyId,
                                periodId,
                                moment(startDate)
                                    .add(1, "month")
                                    .format("YYYY-MM-DD"),
                                moment(endDate)
                                    .add(1, "month")
                                    .format("YYYY-MM-DD")
                            )
                                .then((resp) => {
                                    setRowData(resp.data.process);
                                    setLoading(false);
                                })
                                .catch((err) => {
                                    setLoading(false);
                                });
                        })
                        .catch((error) => {
                            Swal.close();
                            Swal.fire({
                                icon: "error",
                                title: "Bir hata oluştu!",
                                text: error.response.data.message,
                                confirmButtonText: "Tamam",
                            });
                        });
                }
            });
        }
    };
    const handleFetchFailedInvoices = () => {
        if (gridRef.current) {
            const selectedRows = gridRef.current.api.getSelectedRows();

            if (
                selectedRows.length === 0 ||
                selectedRows === undefined ||
                selectedRows[0].status === 1 ||
                selectedRows[0].status === 0 ||
                selectedRows[0].status === 10
            ) {
                Swal.fire({
                    icon: "warning",
                    title: "Uyarı",
                    text:
                        selectedRows.length === 0 || selectedRows === undefined
                            ? "Lütfen bir satır seçin."
                            : selectedRows[0].status === 1 ||
                              selectedRows[0].status === 0
                            ? "Devam eden senkronizasyon tekrar çekilemez."
                            : "Kuyrukta bekleyen senkronizasyon tekrar çekilemez",
                    confirmButtonText: "Tamam",
                });
                return;
            }
            const processId = selectedRows[0].id;
            Swal.fire({
                text: `Seçilen senkronizasyonun hatalı faturaları varsa tekrar çekilecek. Devam etmek istiyor musunuz?`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Evet, devam et!",
                cancelButtonText: "Hayır",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    showLoadingSwal();
                    fetchFailedInvoices(companyId, periodId, processId)
                        .then((response) => {
                            Swal.close();
                            Swal.fire({
                                icon: "success",
                                title: "Başarılı",
                                html: response.data.message,
                            });
                            setLoading(true);
                            fetchSyncs(
                                companyId,
                                periodId,
                                moment(startDate)
                                    .add(1, "month")
                                    .format("YYYY-MM-DD"),
                                moment(endDate)
                                    .add(1, "month")
                                    .format("YYYY-MM-DD")
                            )
                                .then((resp) => {
                                    setRowData(resp.data.process);
                                    setLoading(false);
                                })
                                .catch((err) => {
                                    setLoading(false);
                                });
                        })
                        .catch((error) => {
                            Swal.close();
                            Swal.fire({
                                icon: "error",
                                title: "Bir hata oluştu!",
                                text: error.response.data.message,
                                confirmButtonText: "Tamam",
                            });
                        });
                }
            });
        }
    };
    const columnDefs = [
        {
            headerName: "",
            checkboxSelection: true,
            headerClass: "checkbox",
            pinned: "left",
            width: 48,
            field: "checkboxBtn",
            resizable: false,
            lockPosition: "left",
            suppressAutoSize: true,
            suppressColumnsToolPanel: true,
            suppressMenu: true,
            editable: false,
        },
        {
            field: "master",
            cellRenderer: "agGroupCellRenderer",
            editable: false,
            width: 48,
        },
        {
            field: "id",
            hide: true,
            suppressColumnsToolPanel: true,
        },
        {
            field: "companyId",
            hide: true,
            suppressColumnsToolPanel: true,
        },
        {
            field: "status",
            headerName: "Durum",
            width: 130,
            resizable: false,
            cellStyle: {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            },
            cellRenderer: (params) => {
                return params.value === 0 || params.value === 1 ? (
                    <span
                        title="Çalışıyor"
                        style={{
                            display: "inline-flex",
                            alignItems: "center",
                            justifyContent: "space-around",
                            backgroundColor: "#ff9f43",
                            borderRadius: 5,
                            padding: "0px 12px",
                            width: "85px",
                            height: "25px",
                            color: "white",
                            gap: 2,
                            fontSize: "12px",
                        }}
                    >
                        Çalışıyor
                        <TbLoader
                            className="spinner"
                            style={{
                                color: "#ffffff",
                            }}
                            size={15}
                        ></TbLoader>
                    </span>
                ) : params.value === 2 ? (
                    <span
                        title="Tamamlandı"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-around",
                            width: "90%",
                            height: "60%",
                            backgroundColor: "#00cfe8",
                            borderRadius: 5,
                            color: "white",
                            paddingRight: 7,
                            paddingLeft: 7,
                            gap: 7,
                            fontSize: "12px",
                        }}
                    >
                        Tamamlandı
                        <TbCircleCheck
                            style={{
                                color: "#ffffff",
                            }}
                            size={15}
                        ></TbCircleCheck>
                    </span>
                ) : params.value === 10 ? (
                    <span
                        title="Kuyrukta"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-around",
                            height: "60%",
                            width: "90%",
                            backgroundColor: "#dfdfe3",
                            borderRadius: 5,
                            color: "black",
                            paddingRight: 7,
                            paddingLeft: 7,
                            gap: 7,
                            fontSize: "12px",
                        }}
                    >
                        Kuyrukta
                        <TbLineHeight
                            style={{
                                color: "#000000",
                            }}
                            size={15}
                        ></TbLineHeight>
                    </span>
                ) : params.value === 3 ? (
                    <span
                        title="Hata oluştu"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-around",
                            height: "60%",
                            width: "90%",
                            backgroundColor: "#ea5455",
                            borderRadius: 5,
                            color: "white",
                            paddingRight: 7,
                            paddingLeft: 7,
                            gap: 7,
                            fontSize: "12px",
                        }}
                    >
                        Hata oluştu
                        <TbExclamationCircle
                            style={{
                                color: "#ffffff",
                            }}
                            size={15}
                        ></TbExclamationCircle>
                    </span>
                ) : (
                    <span
                        title="İptal Edildi"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-around",
                            width: "90%",
                            height: "60%",
                            backgroundColor: "#f28585",
                            borderRadius: 5,
                            color: "white",
                            paddingRight: 7,
                            paddingLeft: 7,
                            gap: 7,
                            fontSize: "12px",
                        }}
                    >
                        İptal Edildi
                        <TbClockCancel
                            style={{
                                color: "#ffffff",
                            }}
                            size={15}
                        ></TbClockCancel>
                    </span>
                );
            },
        },
        {
            field: "createdAt",
            headerName: "Başlangıç",
            cellRenderer: (params) => {
                return params.value
                    ? moment(params.value).format("DD.MM.YYYY HH:mm")
                    : "";
            },
            width: 150,
        },
        {
            field: "statusMessage",
            headerName: "Aktarım Durumu",
            cellRenderer: (params) => {
                return params.data.status === 3
                    ? params.data.statusMessage
                    : params.data.statusMessage;
            },
            flex: 1,
        },
        {
            field: "totalOutgoingInvoices",
            headerName: "T.Giden",
            cellRenderer: (params) => {
                return params.value > 0 ? params.value : params.value || 0;
            },
        },
        {
            field: "totalOutgoingInvoicesError",
            headerName: "Hat.Giden",
            cellRenderer: (params) => {
                const value =
                    !params.value || params.value === null ? 0 : params.value;
                // Eğer değer 0'dan büyükse kırmızı renk ile yazdır
                return (
                    <span style={{ color: value > 0 ? "red" : "black" }}>
                        {value}
                    </span>
                );
            },
        },
        {
            field: "totalIngoingInvoices",
            headerName: "T.Gelen",
            cellRenderer: (params) => {
                return params.value > 0 ? params.value : params.value || 0;
            },
        },
        {
            field: "totalIngoingInvoicesError",
            headerName: "Hat.Gelen",
            cellRenderer: (params) => {
                const value =
                    !params.value || params.value === null ? 0 : params.value;
                // Eğer değer 0'dan büyükse kırmızı renk ile yazdır
                return (
                    <span style={{ color: value > 0 ? "red" : "black" }}>
                        {value}
                    </span>
                );
            },
        },
    ];
    const activeProcessMasterColumnDefs = [
        {
            headerName: "",
            checkboxSelection: true,
            headerCheckboxSelection: true,
            headerClass: "checkbox",
            pinned: "left",
            width: 48,
            field: "checkboxBtn",
            resizable: false,
            lockPosition: "left",
            suppressAutoSize: true,
            suppressColumnsToolPanel: true,
            suppressMenu: true,
            editable: false,
        },
        {
            field: "createdAt",
            headerName: "İşlem Tarihi",
            cellRenderer: (params) => {
                return params.value
                    ? moment(params.value).format("DD.MM.YYYY HH:mm")
                    : "";
            },
            width: 160,
        },
        {
            field: "statusMessage",
            headerName: "Bilgi Mesajı",
            cellRenderer: (params) => {
                return params.data.status?.includes("Error")
                    ? params.data.statusMessage
                    : params.data.statusMessage;
            },
            flex: 1,
        },
    ];
    const defaultColDef = useMemo(
        () => ({
            resizable: true,
            width: 100,
        }),
        []
    );
    const detailsGridOptions = {
        columnDefs: activeProcessMasterColumnDefs,
        defaultColDef: {
            resizable: true,
            width: 120,
        },
        localeText: localeTextTr,
        rowDragManaged: true,
        animateRows: true,
        singleClickEdit: true,
    };
    const detailCellRendererParams = useMemo(() => {
        return {
            detailGridOptions: detailsGridOptions,
            getDetailRowData: (params) => {
                // Burada detay verisini getirmek için API çağrısını yapıyoruz
                fetchDetailSyncs(companyId, periodId, params.data.id)
                    .then((response) => {
                        params.successCallback(response.data.subProcess);
                    })
                    .catch((error) => {
                        console.error("Error fetching detail syncs:", error);
                        params.failCallback();
                    });
            },
        };
    }, []);
    const getMainMenuItems = (params) => {
        const allMenuItems = params.defaultItems;
        const itemsToRemove = ["toolPanelButton"]; // 'choose columns' seçeneği

        return allMenuItems.filter((item) => !itemsToRemove.includes(item));
    };
    useEffect(() => {
        fetchSyncs(
            companyId,
            periodId,
            moment(startDate)
                .add(1, "month")
                .startOf("day")
                .format("YYYY-MM-DD HH:mm:ss"),
            moment(endDate)
                .add(1, "month")
                .endOf("day")
                .format("YYYY-MM-DD HH:mm:ss")
        )
            .then((resp) => {
                setRowData(resp.data.process);
                setLoading(false);
                setFirstLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                setFirstLoading(false);
            });
    }, []);
    useEffect(() => {
        setRowData([]);
        setLoading(true);
    }, [handleClose]);
    useEffect(() => {
        if (open === true && firstLoading !== true) {
            fetchSyncs(
                companyId,
                periodId,
                moment(startDate).add(1, "month").format("YYYY-MM-DD"),
                moment(endDate).add(1, "month").format("YYYY-MM-DD")
            )
                .then((resp) => {
                    setRowData(resp.data.process);
                    setLoading(false);
                })
                .catch((err) => {
                    setLoading(false);
                });
        }
    }, [open]);

    return (
        <Modal open={open} onClose={handleClose} style={{ zIndex: 1000 }}>
            <Box
                sx={{
                    position: "absolute",
                    top: "50%", // "3%" yerine "50%" kullanın
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2,
                    width: "70%",
                    border: "2px solid transparent",
                    maxHeight: "80vh" /* Maksimum yükseklik */,
                }}
            >
                <Button
                    onClick={handleClose}
                    sx={{
                        borderRadius: 2,
                        position: "absolute",
                        top: -15,
                        right: -15,
                        backgroundColor: "whitesmoke",
                        color: "gray",
                        boxShadow: 4,
                        minWidth: "0px",
                        width: "33px",
                        height: "33px",
                        border: "2px solid transparent",
                        "&:hover": {
                            backgroundColor: "#e7eaeb",
                        },
                    }}
                >
                    X
                </Button>
                <Grid
                    container
                    xs={12}
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: 2,
                    }}
                >
                    <Grid item xs={12}>
                        <Typography
                            variant="h4"
                            color={"#363636"}
                            fontWeight={400}
                        >
                            Senkronizasyonlar
                        </Typography>
                    </Grid>
                    <Grid
                        container
                        xs={12}
                        sx={{
                            gap: 2,
                            alignItems: "center",
                            justifyContent: "flex-end",
                        }}
                    >
                        <Grid item md={12}>
                            <Alert
                                severity="info"
                                sx={{
                                    borderRadius: "8px",
                                    border: "1px solid #041737",
                                    padding: 0,
                                    pl: 1,
                                }}
                            >
                                <b style={{ letterSpacing: "0.01em" }}>
                                    {moment(startDate)
                                        .add(1, "month")
                                        .format("YYYY-MM-DD")}{" "}
                                    /{" "}
                                    {moment(endDate)
                                        .add(1, "month")
                                        .isAfter(moment())
                                        ? moment().format("YYYY-MM-DD")
                                        : moment(endDate)
                                              .add(1, "month")
                                              .format("YYYY-MM-DD")}
                                </b>{" "}
                                aralığındaki senkronizasyonları
                                görüntülüyorsunuz.
                            </Alert>
                        </Grid>
                        <Grid item sx={5}>
                            <Button
                                startIcon={<TbReload />}
                                variant="outlined"
                                onClick={handleFetchFailedInvoices}
                                sx={{
                                    textTransform: "none",
                                    color: "#555", // Metin rengi
                                    borderColor: "#00cfe8", // Kenarlık rengi
                                    "&:hover": {
                                        borderColor: "#00cfe8", // Hover durumunda kenarlık rengi
                                        color: "#00cfe8",
                                        backgroundColor:
                                            "rgba(27, 197, 216, 0.1)", // Hover durumunda arka plan rengi
                                    },
                                }}
                            >
                                Seçili Senkronizasyonda Hata Alan Faturaları
                                Tekrar Çek
                            </Button>
                        </Grid>
                        <Grid item sx={5}>
                            <Button
                                startIcon={<TbTrash />}
                                variant="outlined"
                                onClick={handleCancelProcess}
                                sx={{
                                    textTransform: "none",
                                    color: "error.main",
                                    borderColor: "error.main",
                                    "&:hover": {
                                        borderColor: "error.main",
                                        color: "error.main",
                                        backgroundColor: "rgba(232, 0, 0, 0.1)",
                                    },
                                }}
                            >
                                Seçili Senkronizasyonu İptal Et
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid
                        sx={{
                            width: "100%", // AG Grid'in genişliğini kapsayıcıya uygun hale getirin
                            height: "60vh", // Yükseklik ayarı (isteğe bağlı)
                        }}
                    >
                        <AgGridReact
                            ref={gridRef}
                            localeText={localeTextTr}
                            loading={loading}
                            rowData={rowData}
                            columnDefs={columnDefs}
                            className="ag-theme-quartz"
                            animateRows={true}
                            rowDragManaged={true}
                            readOnlyEdit={true}
                            singleClickEdit={true}
                            stopEditingWhenCellsLoseFocus={true}
                            copyHeadersToClipboard={false}
                            defaultColDef={defaultColDef}
                            masterDetail={true}
                            detailRowAutoHeight={true}
                            detailCellRendererParams={detailCellRendererParams}
                            getMainMenuItems={getMainMenuItems}
                        />
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    );
};
