import { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";

import withReactContent from "sweetalert2-react-content";
import { Autocomplete, Button, TextField } from "@mui/material";
import { copyRuleToCompanies, copyRuleToCompany } from "../../../api/advisor/copyRuleToCompanies";
import {showLoadingSwal} from "../../../components/loadingSwal"
import { getAdvisorCompanyList } from "../../../api/advisor/getAdvisorCompanyList";
const MySwal = withReactContent(Swal);
// Yükleniyor Swal bileşeni
export const CopyRuleToCompanySwal = ({
  open,handleClose,
  companyId,
  periodId,
  companyName,
  setUpdateRow
}) => {

  const listCompanies = useRef([]);
  const [company, setCompany] = useState();
  const [loading, setLoading] = useState(true);
  const baseCompany = useRef("");
  useEffect(() => {
    if (open) {
      baseCompany.current=""
        showLoadingSwal();
        getAdvisorCompanyList().then((resp)=>{ 
            Swal.close()
            listCompanies.current=resp.data.companies;
            setLoading(false)
            MySwal.fire({
            title: "Referans Firmayı Seçiniz...",
            icon: "warning",
            showConfirmButton: false,
            showCancelButton: false,
            html: (
              <div style={{ width: "100%", padding: "10px 0" }}>
                {" "}
                <Autocomplete
                  options={listCompanies.current}
                  value={company}
                  onChange={(event, newValue) => {
                    baseCompany.current = {
                      id: newValue.id,
                      label: `${newValue.name} - ${newValue.companyId}-${
                        newValue.periodId
                      }-${newValue.year}${
                        newValue.class === 1
                          ? "-BİLANÇO"
                          : newValue.class === 2
                          ? "-İŞLETME"
                          : ""
                      }`,
                    };
                  }}
                  noOptionsText={loading ? "Yükleniyor..." : "Seçenek bulunamadı"}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      const highlightedOption = event.target.querySelector(
                        'li[data-focus="true"]'
                      );
                      if (highlightedOption) {
                        highlightedOption.click();
                      }
                    }
                  }}
                  autoHighlight
                  selectOnFocus
                  blurOnSelect
                  openOnFocus
                  disablePortal
                  ListboxProps={{ className: "specialListBox" }}
                  getOptionLabel={(option) =>
                    `${option.name} - ${option.companyId}-${option.periodId}-${
                      option.year
                    }${
                      option.class === 1
                        ? "-BİLANÇO"
                        : option.class === 2
                        ? "-İŞLETME"
                        : ""
                    }`
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Firma seçiniz..."
                      variant="outlined"
                      size="small"
                    />
                  )}
                  style={{ width: "100%" }}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 10,
                    gap: 10,
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor:
                        "#15356d" /* Onay butonunun rengini mavi yapar */,
                      color: "#ffffff",
                      "&:hover": {
                        backgroundColor: "#112d5e",
                      },
                    }}
                    onClick={() => MySwal.clickConfirm()}
                    className="swal2-confirm swal2-styled"
                  >
                    Devam
                  </Button>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#d33",
                      color: "#ffffff",
                      "&:hover": {
                        backgroundColor: "#a12929",
                      },
                    }}
                    onClick={() => MySwal.clickCancel()}
                    className="swal2-cancel swal2-styled"
                  >
                    İptal
                  </Button>
                </div>
              </div>
            ),
            customClass: {
              popup: "swal2-custom-autocomplete",
            },
            preConfirm: () => {
              if (!baseCompany.current) {
                MySwal.showValidationMessage("Lütfen bir firma seçiniz.");
                return false; // Bu, Swal penceresini açık tutar ve form onayını engeller
              } 
              return baseCompany.current;
            },
          }).then((result) => {
            if (result.isConfirmed) {
              const value = result.value.id;
              const label = result.value.label;
              Swal.fire({
                html: `<p>${label} firmasından ${companyName} firmasına kural kopyalanacak.Devam etmek istiyor musunuz ?</p>
          <p > <span style="color:red"><br>Bu işlem geri ALINAMAZ </br> </span>   </p>`,
                confirmButtonText: "Devam Et",
                cancelButtonText: "İptal",
                icon: "warning",
                showCancelButton: true,
              }).then((subRes) => {
                if (subRes.isConfirmed) {
                  showLoadingSwal();
                  copyRuleToCompany(companyId,periodId,value)
                    .then((resp) => {
                      Swal.close()
                      Swal.fire({
                        title: "Başarılı",
                        text: resp.data.message,
                        icon: "success",
                        confirmButtonText: "Tamam",
                      }).then(() => {
                        window.location.reload();
                      });
                      
                    })
                    .catch((err) => {
                        Swal.close();
                        Swal.fire({
                          title: "Bir hata oluştu!",
                          text: err.response.data.message,
                          icon: "error",
                          confirmButtonText: "Tamam",
                        });
                      });
                }
              });
    
              }
          });}).catch((err)=>{})
     
      handleClose();
    }
  }, [open, handleClose]);

  return null;
};
