/** @format */

import axiosInstance from "../../../../utils/maintenanceController/axios";

export const updateSeparatedDataOfInvoices = async (
    companyId,
    periodId,
    invoices
) => {
    return axiosInstance.post(
        `/company/${companyId}/${periodId}/update-separated-data-of-invoices`,
        { invoices }
    );
};
