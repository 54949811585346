import { useEffect } from "react";
import Swal from "sweetalert2";import { createMaintenance } from "../../../api/adminApis/createMaintenance";
// import { deleteFiches } from "../../api/company/fiches/deleteFiches";
// Yükleniyor Swal bileşeni
export const CreateMaintenanceSwal = ({
  open,
  handleClose
}) => {
  useEffect(() => {
    if (open) {
      Swal.fire({
        text: `Bakım Modunu Açmak istediğinize emin misiniz?`,
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Evet",
        cancelButtonText: "Hayır",
      }).then(async (result) => {
        if (result.isConfirmed) {
          createMaintenance()
            .then(async (resp) => {
              Swal.fire({
                icon: 'success',
                title: 'İşlem Başarılı!',
                text: resp.data.message,
                confirmButtonText: 'Tamam',
              });

            })
            .catch((err) => {
              Swal.fire({
                icon: 'error',
                title: 'İşlem Başarısız!',
                text: err.response.data.message,
                confirmButtonText: 'Tamam',
              });
            });}})
          
       
      

      handleClose();
    }
  }, [open, handleClose]);

  return null;
};
