/** @format */

import axios from "axios";
import CheckToken from "../../../utils/checkToken/CheckToken";

export const adminUpdateAdvivorsStatus = async (data) => {
  const token = await CheckToken();

  if (!token) {
    window.location.href = "/login";
  } else {
    return axios.put(
      `${process.env.REACT_APP_API_URL}/admin/update-advisors`,
      {
        ...data,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }
};
export const adminUpdateAdvivorsAutoFetchInvoice = async (data) => {
  const token = await CheckToken();

  if (!token) {
    window.location.href = "/login";
  } else {
    return axios.put(
      `${process.env.REACT_APP_API_URL}/admin/change-auto-fetch-invoices-preferences`,
      {
        ...data,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }
};

export const adminUpdateAdvivorSettings = async (data) => {
  const token = await CheckToken();

  if (!token) {
    window.location.href = "/login";
  } else {
    return axios.put(
      `${process.env.REACT_APP_API_URL}/admin/update-advisor`,
      {
        ...data,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }
};