import axiosInstance from "../../../../utils/maintenanceController/axios";
import moment from "moment";

export const fetchInvoices = async (companyId, periodId, startDate, endDate) => {
  let startDateFormat = moment(startDate).format("YYYY-MM-DD");
  let endDateFormat = moment(endDate).format("YYYY-MM-DD");
  
  return axiosInstance.post(
    `/company/${companyId}/${periodId}/fetch-invoices`,
    {
      startDate: startDateFormat,
      endDate: endDateFormat
    }
  );
};
