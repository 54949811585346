export const createTurkishFilterParams = () => ({
    filterOptions: ["contains", "notContains", "equals", "notEqual"],
    caseSensitive: false,
    suppressAndOrCondition: true,
    textCustomComparator: turkishTextComparator
  });
  
  export const normalizeText = (text) => {
    return text
      ?.toLocaleLowerCase('tr-TR')
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
  };
  
  export const turkishTextComparator = (filter, value, filterText) => {
    const valueText = normalizeText(value) || "";
    const filterLower = normalizeText(filterText) || "";
    
    switch (filter) {
      case "contains":
        return valueText.includes(filterLower);
      case "notContains":
        return !valueText.includes(filterLower);
      case "equals":
        return valueText === filterLower;
      case "notEqual":
        return valueText !== filterLower;
      default:
        return false;
    }
  };