import React, { useEffect } from 'react';
import lottie from 'lottie-web';
import styled from 'styled-components';

const PageMaintenance = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #fff;
  font-family: 'Poppins', sans-serif;
  text-align: center;
`;

const LottieBg = styled.div`
  width: 200%;
  height: 400px;
  position: relative;
  margin-bottom: 20px;
  margin-left: -62%;
`;

const LottieAnimation = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

const MaintenanceText = styled.div`
  h1 {
    font-size: 80px;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 20px;
  }

  h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }

  p {
    font-size: 16px;
    color: #555;
    margin-bottom: 30px;
  }
`;



export const MaintenancePage = () => {
  useEffect(() => {
    // Lottie animasyonu başlatma
    const anim = lottie.loadAnimation({
      container: document.getElementById('lottie_animation'),
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: 'https://lottie.host/acb0d2eb-7ae0-4563-9574-fb6bae2aa31a/9ndl0vAy7c.json',
    });

    return () => anim.destroy();
  }, []);


  return (
    <PageMaintenance>
      <div>
        <LottieBg>
          <LottieAnimation id="lottie_animation" />
        </LottieBg>

        <MaintenanceText>
          <h1>Bakım Modu</h1>
          <h2>Yakında geri döneceğiz!</h2>
          <p>Sitemiz şu anda bakımda. Lütfen daha sonra tekrar ziyaret edin.</p>
        </MaintenanceText>

      </div>
    </PageMaintenance>
  );
};

export default MaintenancePage;